import React, { useContext } from "react";
import {
  FlexboxGrid,
  Form,
  Input,
  InputGroup,
  Radio,
  RadioGroup,
  SelectPicker,
} from "rsuite";
import tipoImovelData from "../../enums/tipoImovel";
import { AprovacaoLocatarioContext } from "./AprovacaoLocatarioContext";

const Valores = ({ handleFormChange, isSeguroSelected }) => {
  const { aprovacaoLocatario, setAprovacaoLocatario } = useContext(
    AprovacaoLocatarioContext
  ); // Use o contexto correto

  // Valor correspondente ao tipo de imóvel
  const tipoImovel = aprovacaoLocatario?.imovel?.tipoImovel;

  // Encontre o objeto correspondente ao tipo de imóvel
  const tipoImovelInfo = tipoImovel
    ? tipoImovelData.find((item) => item.valor === tipoImovel)
    : null;

  const corretoraData = ["Corretora1", "Corretora2"].map((item) => ({
    label: item,
    value: item,
  }));

  const seguradoraData = ["Seguradora1", "Seguradora2"].map((item) => ({
    label: item,
    value: item,
  }));

  const qtdParcelaTaxaLocacaoData = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ].map((item) => ({
    label: item,
    value: item,
  }));

  const percentualTaxaAdmMensalData = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ].map((item) => ({
    label: item,
    value: item,
  }));

  const formaPagamentoData = ["Crédito", "Débito"].map((item) => ({
    label: item,
    value: item,
  }));

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={18}>
          <Form.Group controlId="rateioObs">
            <Form.ControlLabel>Observação de rateio</Form.ControlLabel>
            <Input
              as="textarea"
              rows={5}
              size="lg"
              name="rateioObs"
              placeholder="Observação de rateio"
              value={aprovacaoLocatario.rateioObs || ""} // Vincula o valor ao rateioObs
              onChange={(event) => {
                handleFormChange({
                  ...aprovacaoLocatario,
                  rateioObs: event,
                });
              }}
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={1} />
        <FlexboxGrid.Item colspan={5}>
          <Form.Group controlId="rateioAgua">
            <Form.ControlLabel>Rateio Água</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="rateioAgua"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="rateioEnergia">
            <Form.ControlLabel>Rateio energia elétrica</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="rateioEnergia"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          <Form.Group controlId="valorAluguel">
            <Form.ControlLabel>Valor Aluguel</Form.ControlLabel>
            <InputGroup>
              <InputGroup.Addon>R$</InputGroup.Addon>
              <Form.Control
                size="lg"
                name="valorAluguel"
                placeholder="Valor Aluguel"
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={7} />
        <FlexboxGrid.Item colspan={12}>
          <Form.Group controlId="corretora">
            <Form.ControlLabel>Corretora</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="corretora"
              data={corretoraData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Corretora"
              disabled={!isSeguroSelected} // Desativa o campo se isSeguroSelected for falso
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          <Form.Group controlId="diaVencimentoAluguel">
            <Form.ControlLabel>Dia do vencimento do aluguel</Form.ControlLabel>
            <InputGroup>
              <Form.Control
                size="lg"
                name="diaVencimentoAluguel"
                placeholder="Vencimento do aluguel"
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={7} />
        <FlexboxGrid.Item colspan={12}>
          <Form.Group controlId="seguradora">
            <Form.ControlLabel>Seguradora</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="seguradora"
              data={seguradoraData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Seguradora"
              disabled={!isSeguroSelected} // Desativa o campo se isSeguroSelected for falso
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          <Form.Group controlId="valorTaxaLocacao">
            <Form.ControlLabel>Valor da Taxa de Locação</Form.ControlLabel>
            <InputGroup>
              <InputGroup.Addon>R$</InputGroup.Addon>
              <Form.Control
                size="lg"
                name="valorTaxaLocacao"
                placeholder="Valor da Taxa de Locação"
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={7} />
        <FlexboxGrid.Item colspan={5}>
          <Form.Group controlId="valorParcela">
            <Form.ControlLabel>Valor parcela seguro</Form.ControlLabel>
            <InputGroup>
              <InputGroup.Addon>R$</InputGroup.Addon>
              <Form.Control
                size="lg"
                name="valorParcela"
                placeholder="Valor parcela seguro"
                disabled={!isSeguroSelected} // Desativa o campo se isSeguroSelected for falso
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={7} />
        <FlexboxGrid.Item colspan={5}>
          <Form.Group controlId="qtdParcelaTaxaLocacao">
            <Form.ControlLabel>
              Quantidade de parcelas de pagamento
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="qtdParcelaTaxaLocacao"
              data={qtdParcelaTaxaLocacaoData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Quantidade de parcelas"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={7} />
        <FlexboxGrid.Item colspan={5}>
          <Form.Group controlId="formaPagamento">
            <Form.ControlLabel>Forma de pagamento</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="formaPagamento"
              data={formaPagamentoData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Forma de pagamento"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={7} />
        <FlexboxGrid.Item colspan={5}>
          <Form.Group controlId="percentualTaxaAdmMensal">
            <Form.ControlLabel>Taxa de administração(%)</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="percentualTaxaAdmMensal"
              data={percentualTaxaAdmMensalData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Taxa de administração"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={7} />
        <FlexboxGrid.Item colspan={5}>
          <Form.Group controlId="valorCondominio">
            <Form.ControlLabel>Valor condomínio</Form.ControlLabel>
            <InputGroup>
              <InputGroup.Addon>R$</InputGroup.Addon>
              <Form.Control
                size="lg"
                name="valorCondominio"
                placeholder="Valor condomínio"
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={7} />
        <FlexboxGrid.Item colspan={5}>
          <Form.Group controlId="valorTaxaLocacaoAdm">
            <Form.ControlLabel>Valor Taxa de Administração</Form.ControlLabel>
            <InputGroup>
              <InputGroup.Addon>R$</InputGroup.Addon>
              <Form.Control
                value={
                  aprovacaoLocatario?.valorAluguel &&
                  aprovacaoLocatario?.percentualTaxaAdmMensal
                    ? (parseFloat(aprovacaoLocatario.valorAluguel) *
                        parseFloat(
                          aprovacaoLocatario.percentualTaxaAdmMensal
                        )) /
                      100
                    : ""
                }
                readOnly
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={7} />
        <FlexboxGrid.Item colspan={5}>
          <Form.Group controlId="valorIptu">
            <Form.ControlLabel>Valor IPTU</Form.ControlLabel>
            <InputGroup>
              <InputGroup.Addon>R$</InputGroup.Addon>
              <Form.Control
                size="lg"
                name="valorIptu"
                placeholder="Valor IPTU"
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={7} />
        <FlexboxGrid.Item colspan={24}>
          <Form.Group controlId="informacaoAdicional">
            <Form.ControlLabel>Informações adicionais</Form.ControlLabel>
            <Input
              as="textarea"
              rows={5}
              size="lg"
              name="informacaoAdicional"
              placeholder="Informações adicionais"
              value={aprovacaoLocatario.informacaoAdicional || ""} // Vincula o valor ao informacaoAdicional
              onChange={(event) => {
                handleFormChange({
                  ...aprovacaoLocatario,
                  informacaoAdicional: event,
                });
              }}
            />
          </Form.Group>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Valores;
