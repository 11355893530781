import React, { useContext } from "react";
import { Panel, CheckboxGroup, Checkbox, FlexboxGrid } from "rsuite";
import { TransferenciaContasContext } from "./TransferenciaContasContext";
import { contasTransferencia } from "../../enums/contasTransferencia";

const FaseInicial = () => {
  const { transferenciaContas, setTransferenciaContas } = useContext(
    TransferenciaContasContext
  );

  const groupedData = contasTransferencia.reduce((acc, item) => {
    acc[item.group] = acc[item.group] || [];
    acc[item.group].push(item);
    return acc;
  }, {});

  // Atualiza diretamente `transferenciaContas` com os valores do grupo
  const handleCheckAll = (groupValues, checked) => {
    setTransferenciaContas((prev) => {
      const novasContas = checked
        ? [...new Set([...(prev.contasSelecionadas || []), ...groupValues])]
        : prev.contasSelecionadas.filter(
            (value) => !groupValues.includes(value)
          );

      return { ...prev, contasSelecionadas: novasContas };
    });
  };

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={24}>
        <FlexboxGrid.Item colspan={12}>
          {Object.keys(groupedData).map((group) => {
            const groupItems = groupedData[group];
            const groupValues = groupItems.map((item) => item.value);

            const contasSelecionadas =
              transferenciaContas.contasSelecionadas || [];

            const isChecked = groupValues.every((value) =>
              contasSelecionadas.includes(value)
            );
            const isIndeterminate =
              contasSelecionadas.some((value) => groupValues.includes(value)) &&
              !isChecked;

            return (
              <Panel
                className="imovel-panel"
                shaded
                header={group}
                key={group}
                bordered
                style={{ marginBottom: 10 }}
              >
                <Checkbox
                  indeterminate={isIndeterminate}
                  checked={isChecked}
                  onChange={() => {
                    handleCheckAll(groupValues, !isChecked); // Passando o estado invertido diretamente
                  }}
                >
                  Selecionar Todos ({group})
                </Checkbox>

                <CheckboxGroup
                  name={group}
                  value={contasSelecionadas}
                  onChange={(values) => {
                    setTransferenciaContas((prev) => ({
                      ...prev,
                      contasSelecionadas: values,
                    }));
                  }}
                  style={{ marginLeft: 36 }}
                >
                  {groupItems.map((item) => (
                    <Checkbox key={item.value} value={item.value}>
                      {item.label}
                    </Checkbox>
                  ))}
                </CheckboxGroup>
              </Panel>
            );
          })}
          <div>
            <strong>Selecionados:</strong>{" "}
            {(transferenciaContas.contasSelecionadas || []).join(", ")}
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default FaseInicial;
