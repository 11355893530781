import { Schema } from "rsuite";

import { situacaoCpflDaeData } from "../../enums/situacaoCpflDaeData";

const { StringType, NumberType, ObjectType, DateType } = Schema.Types;

export const step1Schema = Schema.Model({
  dataPrevistaDesocupacao: DateType().isRequired(
    "A data prevista para a desocupação é obrigatória."
  ),
  motivoRescisao: StringType().isRequired(
    "O motivo da rescisão é obrigatório."
  ),
  avisouProprietario: StringType()
    .isRequired("É necessário informar se avisou o proprietário.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'"
    ),
  imagemDeclaracaoAdimplenciaField: ObjectType().isRequired(
    "A declaração de adimplência é obrigatória."
  ),
  possuiDebitoAguaEnergia: StringType()
    .isRequired("É necessário informar se possui débitos de água e energia.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'"
    ),
  temSinistroAberto: StringType()
    .isRequired("É necessário informar se tem sinistro aberto.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'"
    ),
});

export const step2Schema = Schema.Model({
  realizarBenfeitoria: StringType().isRequired("Campo obrigatório."),
  custearBenfeitoria: StringType().isRequired("Campo obrigatório."),
  proprietarioAprovou: StringType().isRequired("Campo obrigatório."),
  dataAprovacaoProprietario: DateType().isRequired("Campo obrigatório."),
  vistoriaConcluida: StringType().isRequired("Campo obrigatório."),
  dataServicoExecutado: DateType().isRequired("Campo obrigatório."),
  vistoriador: StringType().isRequired("Campo obrigatório."),
  lancadaAplicativo: StringType().isRequired("Campo obrigatório."),
  dataVistoria: DateType().isRequired("Campo obrigatório."),
  // fornecedor: StringType().isRequired("Campo obrigatório."),
});

export const step3Schema = Schema.Model({
  situacaoCpfl: StringType()
    .isRequired("A situação do CPFL é obrigatória.")
    .addRule(
      (value) => situacaoCpflDaeData.some((item) => item.value === value),
      "Valor inválido para CPFL."
    ),
  situacaoDae: StringType()
    .isRequired("A situação do DAE é obrigatória.")
    .addRule(
      (value) => situacaoCpflDaeData.some((item) => item.value === value),
      "Valor inválido para DAE."
    ),
  debitosPendentes: StringType()
    .isRequired("É necessário informar se há débitos pendentes.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'."
    ),
  declaracaoEnviadaSeguradora: StringType()
    .isRequired("É necessário informar se a declaração foi enviada.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'."
    ),
  debitosEnviadosSinistro: StringType()
    .isRequired("É necessário informar se os débitos foram enviados.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'."
    ),
  imagemDistratoField: ObjectType().isRequired(
    "O arquivo do distrato é obrigatório."
  ),
  imagemTermoConfissaoField: ObjectType().isRequired(
    "O arquivo do termo de confissão de dívida é obrigatório."
  ),
  imagemAcordoParcelamentoField: ObjectType().isRequired(
    "O arquivo do acordo de parcelamento é obrigatório."
  ),
  imagemTermoChavesField: ObjectType().isRequired(
    "O arquivo do termo de entrega de chaves é obrigatório."
  ),
  propAssinouDistrato: StringType()
    .isRequired("É necessário informar se o proprietário assinou o distrato.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'."
    ),
  locAssinouDistrato: StringType()
    .isRequired("É necessário informar se o locatário assinou o distrato.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'."
    ),
});

export const step4Schema = Schema.Model({
  seguroFiancaCancelado: StringType()
    .isRequired("Informe se o Seguro Fiança foi cancelado.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'."
    ),
  seguroIncendioCancelado: StringType()
    .isRequired("Informe se o Seguro Incêndio foi cancelado.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'."
    ),
});

export const step5Schema = Schema.Model({});

export const step6Schema = Schema.Model({
  dataRepasse: DateType()
    .isRequired("A data do repasse é obrigatória.")
    .addRule(
      (value) => value instanceof Date,
      "A data do repasse deve ser válida."
    ),
  repasseLiquidado: StringType()
    .isRequired("É necessário informar se o repasse foi liquidado.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'."
    ),
  multaRescisoria: NumberType()
    .isRequired("A multa rescisória é obrigatória.")
    .addRule(
      (value) => value >= 0,
      "A multa rescisória deve ser um número positivo."
    ),
  ajustesRepasse: StringType()
    .isRequired("É necessário informar se os ajustes foram efetuados.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'."
    ),
});
