import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./styles.css";

import TopHeader from "./components/Header";
import LeftSidebar from "./components/Sidebar";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "rsuite";

import AprovacaoLocatario from "./pages/AprovacaoLocatario";
import { AprovacaoLocatarioProvider } from "./pages/AprovacaoLocatario/AprovacaoLocatarioContext";
import ListaAprovacaoExistente from "./pages/AprovacaoLocatario/ListaAprovacaoExistente";
import Imovel from "./pages/Imovel";
import Login from "./pages/Login";
import Pessoa from "./pages/Pessoa";

// Imports para ContratoLocacao
import ContratoLocacao from "./pages/ContratoLocacao";
import { ContratoLocacaoProvider } from "./pages/ContratoLocacao/ContratoLocacaoContext";
import ListaContratoLocacao from "./pages/ContratoLocacao/ListaContratoLocacao";

// Imports para RescisaoContrato
import RescisaoContrato from "./pages/RescisaoContrato";
import ListaRescisaoContrato from "./pages/RescisaoContrato/ListaRescisaoContrato";
import { RescisaoContratoProvider } from "./pages/RescisaoContrato/RescisaoContratoContext";

// Imports para TransferenciaContas
import TransferenciaContas from "./pages/TransferenciaContas";
import ListaTransferenciaContas from "./pages/TransferenciaContas/ListaTransferenciaContas";
import { TransferenciaContasProvider } from "./pages/TransferenciaContas/TransferenciaContasContext";

// Imports para Sinistro
import Sinistro from "./pages/Sinistro";
import ListaSinistro from "./pages/Sinistro/ListaSinistro";
import { SinistroProvider } from "./pages/Sinistro/SinistroContext";

import ProtectedRoute from "./protectedRoute";
import { authenticateSuccess } from "./store/modules/auth/actions"; // Importe a ação de autenticação

import { jwtDecode } from "jwt-decode";

const Routers = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  //Trecho do código para solucionar problema do rsuit de redimenisonamento de tela
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message.startsWith("ResizeObserver loop")) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    if (token) {
      localStorage.setItem("token", token);
      const decoded = jwtDecode(token);
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("email", decoded.email);
      dispatch(authenticateSuccess());
    }
  }, [dispatch]);
  return (
    <>
      <Container style={{ height: "100%", overflowY: "auto" }}>
        <BrowserRouter>
          {isAuthenticated && <LeftSidebar />}
          <Container>
            {isAuthenticated && <TopHeader />}
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="/pessoa"
                element={<ProtectedRoute element={<Pessoa />} />}
              />
              <Route
                path="/imovel"
                element={<ProtectedRoute element={<Imovel />} />}
              />
              <Route
                path="/listaAprovacaoExistente"
                element={
                  <ProtectedRoute
                    element={
                      <AprovacaoLocatarioProvider>
                        <ListaAprovacaoExistente />
                      </AprovacaoLocatarioProvider>
                    }
                  />
                }
              />
              <Route
                path="/aprovacaoLocatario"
                element={
                  <ProtectedRoute
                    element={
                      <AprovacaoLocatarioProvider>
                        <AprovacaoLocatario />
                      </AprovacaoLocatarioProvider>
                    }
                  />
                }
              />

              <Route
                path="/listaContratoLocacao"
                element={
                  <ProtectedRoute
                    element={
                      <ContratoLocacaoProvider>
                        <ListaContratoLocacao />
                      </ContratoLocacaoProvider>
                    }
                  />
                }
              />
              <Route
                path="/contratoLocacao"
                element={
                  <ProtectedRoute
                    element={
                      <ContratoLocacaoProvider>
                        <ContratoLocacao />
                      </ContratoLocacaoProvider>
                    }
                  />
                }
              />

              {/* Rota para a tela de Rescisão de Contrato */}
              <Route
                path="/listaRescisaoContrato"
                element={
                  <ProtectedRoute
                    element={
                      <RescisaoContratoProvider>
                        <ListaRescisaoContrato />
                      </RescisaoContratoProvider>
                    }
                  />
                }
              />
              <Route
                path="/rescisaoContrato"
                element={
                  <ProtectedRoute
                    element={
                      <RescisaoContratoProvider>
                        <RescisaoContrato />
                      </RescisaoContratoProvider>
                    }
                  />
                }
              />

              {/* Rota para a tela de Transferência de Contas de Consumo */}
              <Route
                path="/listaTransferenciaContas"
                element={
                  <ProtectedRoute
                    element={
                      <TransferenciaContasProvider>
                        <ListaTransferenciaContas />
                      </TransferenciaContasProvider>
                    }
                  />
                }
              />
              <Route
                path="/transferenciaContas"
                element={
                  <ProtectedRoute
                    element={
                      <TransferenciaContasProvider>
                        <TransferenciaContas />
                      </TransferenciaContasProvider>
                    }
                  />
                }
              />

              {/* Rota para a tela de Sinistro */}
              <Route
                path="/listaSinistro"
                element={
                  <ProtectedRoute
                    element={
                      <SinistroProvider>
                        <ListaSinistro />
                      </SinistroProvider>
                    }
                  />
                }
              />
              <Route
                path="/sinistro"
                element={
                  <ProtectedRoute
                    element={
                      <SinistroProvider>
                        <Sinistro />
                      </SinistroProvider>
                    }
                  />
                }
              />
            </Routes>
          </Container>
        </BrowserRouter>
      </Container>
    </>
  );
};

export default Routers;
