import React, { useEffect, useState, useContext } from "react";
import { Table, Input, Button, Content } from "rsuite";
import { fetchWithAuth } from "../../services/apiNoState";
import { showError } from "../../components/Utils/toastUtils";
import { useNavigate } from "react-router-dom";
import EditIcon from "@rsuite/icons/Edit";
import { AprovacaoLocatarioContext } from "./AprovacaoLocatarioContext";

const { Column, HeaderCell, Cell } = Table;

const ListaAprovacaoExistente = () => {
  const [aprovacoes, setAprovacoes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const { getStatusClass } = useContext(AprovacaoLocatarioContext);

  // Função para carregar as aprovações existentes
  useEffect(() => {
    const fetchAprovacoes = async () => {
      setLoading(true);
      try {
        const response = await fetchWithAuth("/aprovacaoLocatario", {
          method: "GET",
        });

        if (response.error) {
          showError("Erro ao carregar aprovações:", response.message);
          console.error("Erro ao carregar aprovações:", response.message);
        } else {
          setAprovacoes(response.aprovacaoLocatarios);
        }
      } catch (error) {
        console.error("Erro ao buscar aprovações:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAprovacoes();
  }, []);

  const handleSortColumn = (sortColumn, sortType) => {
    setSortColumn(sortColumn);
    setSortType(sortType);
  };

  // Filtragem e ordenação dos dados
  const filteredData = aprovacoes
    .filter((item) => {
      if (!search) return true;
      const endereco = item.imovel?.endereco || {};
      return (
        endereco.rua?.toLowerCase().includes(search.toLowerCase()) ||
        endereco.bairro?.toLowerCase().includes(search.toLowerCase()) ||
        endereco.cidade?.toLowerCase().includes(search.toLowerCase()) ||
        item.status?.toLowerCase().includes(search.toLowerCase())
      );
    })
    .sort((a, b) => {
      if (sortColumn && sortType) {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];
        if (sortType === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      }
      return 0;
    });

  return (
    <Content className="m-2">
      <h3
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}
      >
        Lista de Aprovações
      </h3>

      {/* Campo de busca */}
      <Input
        placeholder="Buscar por endereço"
        value={search}
        onChange={setSearch}
        style={{ marginBottom: 10, width: 300 }}
      />

      <Table
        height={400}
        data={filteredData}
        loading={loading}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        rowHeight={60} // Aumenta a altura de cada linha
      >
        <Column width={200} align="center" sortable>
          <HeaderCell>Rua e Número</HeaderCell>
          <Cell>
            {(rowData) => {
              const rua = rowData.imovel?.endereco?.rua || "Sem informação";
              const numero = rowData.imovel?.endereco?.numero || "";
              return `${rua} - ${numero}`.trim(); // Retorna "Rua Nome 123" ou apenas "Rua Nome" se não houver número
            }}
          </Cell>
        </Column>

        <Column width={200} align="center" sortable>
          <HeaderCell>Bairro</HeaderCell>
          <Cell>
            {(rowData) => rowData.imovel?.endereco?.bairro || "Sem informação"}
          </Cell>
        </Column>

        <Column width={200} align="center" sortable>
          <HeaderCell>Cidade</HeaderCell>
          <Cell>
            {(rowData) => rowData.imovel?.endereco?.cidade || "Sem informação"}
          </Cell>
        </Column>

        <Column width={200} align="center" sortable>
          <HeaderCell>Status</HeaderCell>
          <Cell>
            {(rowData) => (
              <span
                className={`status-value ${getStatusClass(rowData.status)}`}
              >
                {rowData.status || "Sem informação"}
              </span>
            )}
          </Cell>
        </Column>

        <Column width={200} fixed="right">
          <HeaderCell>Ação</HeaderCell>
          <Cell>
            {(rowData) => (
              <EditIcon
                appearance="link"
                className="icon-big icon-link"
                onClick={() =>
                  navigate("/aprovacaoLocatario", {
                    state: { aprovacao: rowData }, // Agora rowData está acessível aqui
                  })
                }
              />
            )}
          </Cell>
        </Column>
      </Table>
      <Button
        appearance="primary"
        onClick={() => navigate("/aprovacaoLocatario")}
      >
        Cadastrar Nova Aprovação
      </Button>
    </Content>
  );
};

export default ListaAprovacaoExistente;
