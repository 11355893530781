import React from "react";
import {
  FlexboxGrid,
  Form,
  SelectPicker,
  Radio,
  InputGroup,
  RadioGroup,
} from "rsuite";

const corretoraData = ["Corretora1", "Corretora2"].map((item) => ({
  label: item,
  value: item,
}));

const seguradoraData = ["Seguradora1", "Seguradora2"].map((item) => ({
  label: item,
  value: item,
}));

const formaPagamentoData = ["Crédito", "Débito"].map((item) => ({
  label: item,
  value: item,
}));

const Seguradora = () => {
  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          <Form.Group controlId="corretora">
            <Form.ControlLabel>Corretora</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="corretora"
              data={corretoraData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Corretora"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24}>
          <Form.Group controlId="aprovado">
            <Form.ControlLabel>Seguro aprovado?</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="aprovado"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6}>
          <Form.Group controlId="valorParcela">
            <Form.ControlLabel>Valor parcela do seguro</Form.ControlLabel>
            <InputGroup>
              <InputGroup.Addon>R$</InputGroup.Addon>
              <Form.Control size="lg" name="valorParcela" placeholder="Valor" />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={18} />
        <FlexboxGrid.Item colspan={24}>
          <Form.Group controlId="seguradora">
            <Form.ControlLabel>Seguradora</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="seguradora"
              data={seguradoraData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Seguradora"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24}>
          <Form.Group controlId="formaPagamento">
            <Form.ControlLabel>Forma de pagamento</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="formaPagamento"
              data={formaPagamentoData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Forma de pagamento"
            />
          </Form.Group>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Seguradora;
