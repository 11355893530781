import { all, call, put, select, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import {
  allPessoa as allPessoaAction,
  resetPessoa,
  updatePessoa,
} from "./actions";
import types from "./types";
//import const from "../../../consts";

export function* allPessoa() {
  const { form } = yield select((state) => state.pessoa);

  try {
    yield put(updatePessoa({ form: { ...form, filtering: true } }));
    const { data: res } = yield call(api.get, `/pessoa/pessoas/`);

    yield put(updatePessoa({ form: { ...form, filtering: false } }));

    if (res.error) {
      alert(res.message);
      return false;
    }

    yield put(updatePessoa({ pessoas: res.pessoas }));
  } catch (err) {
    yield put(updatePessoa({ form: { ...form, filtering: false } }));
    alert(err.message);
  }
}

export function* filterPessoas() {
  const { form, pessoa } = yield select((state) => state.pessoa);

  try {
    yield put(updatePessoa({ form: { ...form, filtering: true } }));
    const { data: res } = yield call(api.post, `/pessoa/filter/`, {
      filters: {
        email: pessoa.email,
      },
    });

    yield put(updatePessoa({ form: { ...form, filtering: false } }));

    if (res.error) {
      alert(res.message);
      return false;
    }

    if (res.pessoas.length > 0) {
      yield put(
        updatePessoa({
          pessoa: res.pessoas[0],
          form: { ...form, filtering: false, disabled: true },
        })
      );
    } else {
      yield put(
        updatePessoa({
          form: { ...form, disabled: false },
        })
      );
    }
  } catch (err) {
    yield put(updatePessoa({ form: { ...form, filtering: false } }));
    alert(err.message);
  }
}

// const showError = (message) => {
//   toaster.push(
//     <Message type="error" showIcon>
//       {message}
//     </Message>,
//     {
//       placement: "topEnd",
//       duration: 5000,
//     }
//   );
// };

// const showSuccess = (message) => {
//   toaster.push(
//     <Message type="success" showIcon>
//       {message}
//     </Message>,
//     {
//       placement: "topEnd",
//       duration: 5000,
//     }
//   );
// };

function appendFormData(formData, data, parentKey = "") {
  if (data instanceof Array) {
    data.forEach((item, index) => {
      appendFormData(formData, item, `${parentKey}[${index}]`);
    });
  } else if (data instanceof Object && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      appendFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}.${key}` : key
      );
    });
  } else {
    formData.append(parentKey, data);
  }
}

export function* addPessoa() {
  const { form, pessoa, components } = yield select((state) => state.pessoa);
  try {
    yield put(updatePessoa({ form: { ...form, saving: true } }));
    const formData = new FormData();
    console.log("pessoa", pessoa);
    appendFormData(formData, pessoa);
    console.log("formData", formData);

    const { data: res } = yield call(api.post, `/pessoa`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    yield put(updatePessoa({ form: { ...form, saving: false } }));

    if (res.error) {
      // showError(res.message);
      return false;
    }

    // showSuccess("Pessoa adicionada com sucesso.");

    yield put(updatePessoa({ components: { ...components, drawer: false } }));
    yield put(resetPessoa());
  } catch (err) {
    yield put(updatePessoa({ form: { ...form, saving: false } }));

    if (err.response && err.response.status === 400) {
      // showError(err.response.data.message || "Erro ao adicionar pessoa.");
    } else {
      // showError(err.message || "Erro desconhecido.");
    }
  }
}

export function* savePessoa() {
  const { pessoa, form, components } = yield select((state) => state.pessoa);

  try {
    yield put(updatePessoa({ form: { ...form, saving: true } }));

    const formData = new FormData();
    // console.log(pessoa);
    appendFormData(formData, pessoa);
    // console.log(formData);
    const { data: res } = yield call(
      api.put,
      `/pessoa/${formData.get("cpf")}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    yield put(updatePessoa({ form: { ...form, saving: false } }));

    if (res.error) {
      // showError(res.message);
      return false;
    }

    yield put(allPessoaAction());
    yield put(updatePessoa({ components: { ...components, drawer: false } }));
    yield put(resetPessoa());
  } catch (err) {
    // COLOCAR AQUI O ALERT DO RSUITE
    yield put(updatePessoa({ form: { ...form, saving: false } }));
    // showError(err.message);
  }
}

export function* unlinkPessoa() {
  const { form, pessoa, components } = yield select((state) => state.pessoa);

  try {
    yield put(updatePessoa({ form: { ...form, saving: true } }));
    const { data: res } = yield call(api.delete, `/pessoa/${pessoa._id}`);

    yield put(
      updatePessoa({
        form: { ...form, saving: false },
      })
    );

    if (res.error) {
      alert(res.message);
      return false;
    }

    yield put(allPessoaAction());
    yield put(
      updatePessoa({
        components: { ...components, drawer: false, confirmDelete: false },
      })
    );
    yield put(resetPessoa());
  } catch (err) {
    yield put(updatePessoa({ form: { ...form, saving: false } }));
    alert(err.message);
  }
}

export default all([
  takeLatest(types.ALL_PESSOA, allPessoa),
  takeLatest(types.FILTER_PESSOAS, filterPessoas),
  takeLatest(types.ADD_PESSOA, addPessoa),
  takeLatest(types.SAVE_PESSOA, savePessoa),
  takeLatest(types.UNLINK_PESSOA, unlinkPessoa),
]);
