import { Icon } from "@rsuite/icons";
import React, { useEffect, useState } from "react";
import { InputPicker } from "rsuite";
import { fetchWithAuth } from "../../services/apiNoState";
import { showError } from "../Utils/toastUtils";

const SearchFieldContrato = ({ setContrato }) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const styles = { width: "100%" };

  const getContratos = async (word) => {
    // Faz a requisição para buscar os documentos da pessoa
    const response = await fetchWithAuth(`/contrato/search?query=${word}`, {
      method: "GET",
    });

    if (response.error) {
      showError("Erro ao buscar os documentos: ", response.message);
      throw response.message; // Propaga o erro para ser tratado em outro lugar
    }
    setLoading(false);
    setItems(response);
  };

  const handleSearch = (word) => {
    if (!word) {
      return;
    }
    setLoading(true);
    getContratos(word);
  };

  const handleSelect = async (value, item) => {
    // Faz a requisição para buscar os documentos da pessoa
    const response = await fetchWithAuth(`/contrato/filter`, {
      method: "POST",
      body: JSON.stringify({ _id: item._id }), // Salva os dados
      headers: {
        "Content-Type": "application/json", // Especifica o tipo de conteúdo
      },
    });

    if (response.error) {
      showError("Erro ao buscar os documentos: ", response.message);
      throw response.message; // Propaga o erro para ser tratado em outro lugar
    }
    const contratoData = response.contrato;
    // console.log(contratoData);
    // Dispatch the action to update the Redux state
    setContrato(contratoData);
  };

  useEffect(() => {
    getContratos(""); // Initial fetch
  }, []);

  return (
    <InputPicker
      data={items}
      style={styles}
      labelKey="nome"
      valueKey="nome"
      size="lg"
      placeholder="Selecione"
      locale={{
        noResultsText: "Não encontrado",
      }}
      onSearch={handleSearch}
      onSelect={handleSelect}
      renderMenu={(menu) => {
        if (loading) {
          return (
            <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
              <Icon icon="spinner" spin /> Carregando...
            </p>
          );
        }
        return menu;
      }}
    />
  );
};

export default SearchFieldContrato;
