import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useContext,
} from "react";
import {
  List,
  DatePicker,
  Uploader,
  FlexboxGrid,
  IconButton,
  Table,
  Modal,
  Text,
  SelectPicker,
  Panel,
  RadioGroup,
  Heading,
  Form,
  Radio,
  Button,
  Divider,
} from "rsuite";
import { Plus, Check, Edit, Trash } from "@rsuite/icons";
import { SinistroContext } from "./SinistroContext";
import "./style.css";
import { format } from "date-fns";
import { tipoSinistroData } from "../../enums/tipoSinistroData";
import useHandlePreview from "../../hooks/useHandlePreview";

const Indenizacao = () => {
  const { contratoLocacao, sinistro, setSinistro } =
    useContext(SinistroContext); // Use o contexto correto
  // Objeto para armazenar as refs dos EditableCells da linha em edição
  const editingRowRefs = useRef({});
  const [editingRow, setEditingRow] = useState(null);

  const { Column, HeaderCell, Cell } = Table;

  const EditableCell = forwardRef(
    ({ rowIndex, dataKey, rowData, onSave }, ref) => {
      const initialValue =
        dataKey === "dataVencimento"
          ? rowData[dataKey] instanceof Date
            ? rowData[dataKey]
            : new Date(rowData[dataKey]) // Converter a data para objeto Date se necessário
          : rowData[dataKey] || "";

      const [value, setValue] = useState(initialValue);

      const handleChange = (e) => {
        setValue(e.target.value);
      };

      // Função commit (para outros campos) que envia o valor atual para o pai
      const commit = () => {
        onSave(rowIndex, dataKey, value);
      };

      useImperativeHandle(ref, () => ({
        commit,
        getValue: () => value,
      }));

      if (dataKey === "dataVencimento") {
        return (
          <Form.Control
            size="lg"
            searchable={false}
            style={{ width: "100%" }}
            accepter={DatePicker}
            value={value}
            format="dd/MM/yyyy"
            placeholder="Data de Vencimento"
            onChange={(newValue) => {
              // Atualiza o estado local e chama onSave diretamente com o novo valor.
              setValue(newValue);
              onSave(rowIndex, dataKey, newValue);
            }}
          />
        );
      } else {
        return (
          <input
            style={{ width: "100%" }}
            value={value}
            onChange={handleChange}
          />
        );
      }
    }
  );

  // Função para atualizar o sinistro (listaDebitos) quando o usuário confirmar a edição
  const handleSaveCell = (rowIndex, dataKey, newValue) => {
    setSinistro((prev) => {
      const newDebitos = [...(prev.listaDebitos || [])];
      if (!newDebitos[rowIndex]) {
        newDebitos[rowIndex] = {};
      }
      newDebitos[rowIndex][dataKey] = newValue;
      return { ...prev, listaDebitos: newDebitos };
    });
  };

  const toggleEditingRow = (rowIndex) => {
    if (editingRow === rowIndex) {
      // Se a linha estiver em edição, chama commit em todas as células dessa linha
      Object.entries(editingRowRefs.current).forEach(([key, cellRef]) => {
        if (
          cellRef &&
          cellRef.current &&
          typeof cellRef.current.commit === "function"
        ) {
          cellRef.current.commit();
        }
      });

      // Em vez de ler do estado (que pode não ter sido atualizado ainda),
      // obtém os valores diretamente dos refs:
      const valor = parseFloat(
        editingRowRefs.current["valor"]?.current?.getValue() || 0
      );
      const multa = parseFloat(
        editingRowRefs.current["multa"]?.current?.getValue() || 0
      );
      const juros = parseFloat(
        editingRowRefs.current["juros"]?.current?.getValue() || 0
      );
      const computedTotal = (valor + multa + juros).toFixed(2);

      // Salva o total calculado
      handleSaveCell(rowIndex, "total", computedTotal);

      // Limpa as refs da linha em edição e fecha o modo de edição
      editingRowRefs.current = {};
      setEditingRow(null);
    } else {
      editingRowRefs.current = {};
      setEditingRow(rowIndex);
    }
  };

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          <h5 style={{ textAlign: "center", marginBottom: "10px" }}>
            Lista de conferência de débitos
          </h5>

          <Table
            data={sinistro.listaDebitos || []}
            height={200}
            bordered
            cellBordered
            wordWrap
            autoHeight
          >
            {/* item */}
            <Column flexGrow={1}>
              <HeaderCell>Item</HeaderCell>
              <Cell>{(rowData) => <span>{rowData.item}</span>}</Cell>
            </Column>

            {/* valor */}
            <Column flexGrow={1}>
              <HeaderCell>Valor</HeaderCell>
              <Cell>{(rowData) => <span>{rowData.valor}</span>}</Cell>
            </Column>

            {/* multa */}
            <Column flexGrow={1}>
              <HeaderCell>Multa</HeaderCell>
              <Cell>{(rowData) => <span>{rowData.multa}</span>}</Cell>
            </Column>

            {/* juros */}
            <Column flexGrow={1}>
              <HeaderCell>Juros</HeaderCell>
              <Cell>{(rowData, rowIndex) => <span>{rowData.juros}</span>}</Cell>
            </Column>

            {/* dataVencimento */}
            <Column flexGrow={1}>
              <HeaderCell>Data de Vencimento</HeaderCell>
              <Cell>
                {(rowData) => (
                  <span>
                    {rowData.dataVencimento
                      ? new Date(rowData.dataVencimento).toLocaleDateString(
                          "pt-BR"
                        )
                      : "-"}
                  </span>
                )}
              </Cell>
            </Column>

            {/* total */}
            <Column flexGrow={1}>
              <HeaderCell>Total</HeaderCell>
              <Cell>
                {(rowData) => {
                  const total = (
                    (parseFloat(rowData.valor) || 0) +
                    (parseFloat(rowData.multa) || 0) +
                    (parseFloat(rowData.juros) || 0)
                  ).toFixed(2);
                  return <span>{total}</span>;
                }}
              </Cell>
            </Column>

            {/* Valor Indenizado - campo editável */}
            <Column flexGrow={1}>
              <HeaderCell>Valor Indenizado</HeaderCell>
              <Cell>
                {(rowData, rowIndex) =>
                  editingRow === rowIndex ? (
                    <EditableCell
                      ref={
                        editingRowRefs.current["valorIndenizado"] ||
                        (editingRowRefs.current["valorIndenizado"] =
                          React.createRef())
                      }
                      rowIndex={rowIndex}
                      dataKey="valorIndenizado"
                      rowData={rowData}
                      onSave={handleSaveCell}
                    />
                  ) : (
                    <span>{rowData.valorIndenizado}</span>
                  )
                }
              </Cell>
            </Column>

            {/* Saldo - campo calculado */}
            <Column flexGrow={1}>
              <HeaderCell>Saldo</HeaderCell>
              <Cell>
                {(rowData) => {
                  // Calcula o total solicitado
                  const total =
                    (parseFloat(rowData.valor) || 0) +
                    (parseFloat(rowData.multa) || 0) +
                    (parseFloat(rowData.juros) || 0);
                  // Valor indenizado (se não existir, assume 0)
                  const valorIndenizado =
                    parseFloat(rowData.valorIndenizado) || 0;
                  const saldo = (total - valorIndenizado).toFixed(2);
                  return <span>{saldo}</span>;
                }}
              </Cell>
            </Column>
            {/* Ações */}
            <Column width={150} fixed="right">
              <HeaderCell>Ações</HeaderCell>
              <Cell>
                {(rowData, rowIndex) => (
                  <div style={{ display: "flex", gap: 10 }}>
                    <IconButton
                      icon={editingRow === rowIndex ? <Check /> : <Edit />}
                      size="xs"
                      appearance="primary"
                      onClick={() => toggleEditingRow(rowIndex)}
                    />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Indenizacao;
