import { Schema } from "rsuite";
const { StringType } = Schema.Types;

const validacao = Schema.Model({
  // Dados Pessoais
  nome: StringType().isRequired("Campo obrigatório."),
  cpf: StringType().isRequired("Campo obrigatório."),
});

export default validacao;
