import { all, call, put, select, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import {
  allAprovacaoLocatario as allAprovacaoLocatarioAction,
  resetAprovacaoLocatario,
  updateAprovacaoLocatario,
} from "./actions";
import types from "./types";
//import const from "../../../consts";

export function* allAprovacaoLocatario() {
  const { form } = yield select((state) => state.aprovacaoLocatario);

  try {
    yield put(updateAprovacaoLocatario({ form: { ...form, filtering: true } }));
    const { data: res } = yield call(
      api.get,
      `/aprovacaoLocatario/aprovacaoLocatarios/`
    );

    yield put(
      updateAprovacaoLocatario({ form: { ...form, filtering: false } })
    );

    if (res.error) {
      alert(res.message);
      return false;
    }

    yield put(
      updateAprovacaoLocatario({ aprovacaoLocatarios: res.aprovacaoLocatarios })
    );
  } catch (err) {
    yield put(
      updateAprovacaoLocatario({ form: { ...form, filtering: false } })
    );
    alert(err.message);
  }
}

export function* filterAprovacaoLocatarios() {
  const { form, aprovacaoLocatario } = yield select(
    (state) => state.aprovacaoLocatario
  );

  try {
    yield put(updateAprovacaoLocatario({ form: { ...form, filtering: true } }));
    const { data: res } = yield call(api.post, `/aprovacaoLocatario/filter/`, {
      filters: {
        email: aprovacaoLocatario.email,
      },
    });

    yield put(
      updateAprovacaoLocatario({ form: { ...form, filtering: false } })
    );

    if (res.error) {
      alert(res.message);
      return false;
    }

    if (res.aprovacaoLocatarios.length > 0) {
      yield put(
        updateAprovacaoLocatario({
          aprovacaoLocatario: res.aprovacaoLocatarios[0],
          form: { ...form, filtering: false, disabled: true },
        })
      );
    } else {
      yield put(
        updateAprovacaoLocatario({
          form: { ...form, disabled: false },
        })
      );
    }
  } catch (err) {
    yield put(
      updateAprovacaoLocatario({ form: { ...form, filtering: false } })
    );
    alert(err.message);
  }
}

// const showError = (message) => {
//   toaster.push(
//     <Message type="error" showIcon>
//       {message}
//     </Message>,
//     {
//       placement: "topEnd",
//       duration: 5000,
//     }
//   );
// };

// const showSuccess = (message) => {
//   toaster.push(
//     <Message type="success" showIcon>
//       {message}
//     </Message>,
//     {
//       placement: "topEnd",
//       duration: 5000,
//     }
//   );
// };

function appendFormData(formData, data, parentKey = "") {
  if (data instanceof Array) {
    data.forEach((item, index) => {
      appendFormData(formData, item, `${parentKey}[${index}]`);
    });
  } else if (data instanceof Object && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      appendFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}.${key}` : key
      );
    });
  } else {
    formData.append(parentKey, data);
  }
}

export function* addAprovacaoLocatario() {
  const { form, aprovacaoLocatario, components } = yield select(
    (state) => state.aprovacaoLocatario
  );
  try {
    yield put(updateAprovacaoLocatario({ form: { ...form, saving: true } }));
    const formData = new FormData();
    console.log("aprovacaoLocatario", aprovacaoLocatario);
    appendFormData(formData, aprovacaoLocatario);
    console.log("formData", formData);

    const { data: res } = yield call(
      api.post,
      `/aprovacaoLocatario`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    yield put(updateAprovacaoLocatario({ form: { ...form, saving: false } }));

    if (res.error) {
      // showError(res.message);
      return false;
    }

    // showSuccess("AprovacaoLocatario adicionada com sucesso.");

    yield put(
      updateAprovacaoLocatario({ components: { ...components, drawer: false } })
    );
    yield put(resetAprovacaoLocatario());
  } catch (err) {
    yield put(updateAprovacaoLocatario({ form: { ...form, saving: false } }));

    if (err.response && err.response.status === 400) {
      // showError(
      //   err.response.data.message || "Erro ao adicionar aprovacaoLocatario."
      // );
    } else {
      // showError(err.message || "Erro desconhecido.");
    }
  }
}

export function* saveAprovacaoLocatario() {
  const { aprovacaoLocatario, form, components } = yield select(
    (state) => state.aprovacaoLocatario
  );

  try {
    yield put(updateAprovacaoLocatario({ form: { ...form, saving: true } }));

    const formData = new FormData();
    // console.log(aprovacaoLocatario);
    appendFormData(formData, aprovacaoLocatario);
    // console.log(formData);
    const { data: res } = yield call(
      api.put,
      `/aprovacaoLocatario/${formData.get("cpf")}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    yield put(updateAprovacaoLocatario({ form: { ...form, saving: false } }));

    if (res.error) {
      // showError(res.message);
      return false;
    }

    yield put(allAprovacaoLocatarioAction());
    yield put(
      updateAprovacaoLocatario({ components: { ...components, drawer: false } })
    );
    yield put(resetAprovacaoLocatario());
  } catch (err) {
    // COLOCAR AQUI O ALERT DO RSUITE
    yield put(updateAprovacaoLocatario({ form: { ...form, saving: false } }));
    // showError(err.message);
  }
}

export function* unlinkAprovacaoLocatario() {
  const { form, aprovacaoLocatario, components } = yield select(
    (state) => state.aprovacaoLocatario
  );

  try {
    yield put(updateAprovacaoLocatario({ form: { ...form, saving: true } }));
    const { data: res } = yield call(
      api.delete,
      `/aprovacaoLocatario/${aprovacaoLocatario._id}`
    );

    yield put(
      updateAprovacaoLocatario({
        form: { ...form, saving: false },
      })
    );

    if (res.error) {
      alert(res.message);
      return false;
    }

    yield put(allAprovacaoLocatarioAction());
    yield put(
      updateAprovacaoLocatario({
        components: { ...components, drawer: false, confirmDelete: false },
      })
    );
    yield put(resetAprovacaoLocatario());
  } catch (err) {
    yield put(updateAprovacaoLocatario({ form: { ...form, saving: false } }));
    alert(err.message);
  }
}

export default all([
  takeLatest(types.ALL_APROVACAOLOCATARIO, allAprovacaoLocatario),
  takeLatest(types.FILTER_APROVACAOLOCATARIOS, filterAprovacaoLocatarios),
  takeLatest(types.ADD_APROVACAOLOCATARIO, addAprovacaoLocatario),
  takeLatest(types.SAVE_APROVACAOLOCATARIO, saveAprovacaoLocatario),
  takeLatest(types.UNLINK_APROVACAOLOCATARIO, unlinkAprovacaoLocatario),
]);
