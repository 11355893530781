const referenciaTipoData = [
  "Esposo",
  "Esposa",
  "Pai",
  "Mãe",
  "Filho",
  "Filha",
  "Irmão",
  "Irmã",
  "Avô",
  "Avó",
  "Neto",
  "Neta",
  "Primo",
  "Prima",
  "Sobrinho",
  "Sobrinha",
  "Genro",
  "Nora",
  "Tio",
  "Tia",
  "Sogro",
  "Sogra",
].map((item) => ({
  label: item,
  value: item,
}));

module.exports = { referenciaTipoData };
