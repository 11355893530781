const padraoImovelData = [
  "Alto",
  "Médio",
  "Baixo",
  "Não informado",
  "Regular",
].map((item) => ({
  label: item,
  value: item,
}));

module.exports = { padraoImovelData };
