import React, { useContext } from "react";
import {
  FlexboxGrid,
  Form,
  Panel,
  Input,
  Heading,
  List,
  SelectPicker,
  Button,
  InputGroup,
} from "rsuite";
import SearchFieldPessoas from "../../components/Search/SearchFieldPessoas";
import { AprovacaoLocatarioContext } from "./AprovacaoLocatarioContext";
import tipoData from "../../enums/tipoImovel";

const Fiador = ({ handleFormChange }) => {
  const { aprovacaoLocatario, setAprovacaoLocatario } = useContext(
    AprovacaoLocatarioContext
  ); // Use o contexto correto

  const handleSetFiador = (locatarioData) => {
    console.log("aprovacaoLocatario: ", aprovacaoLocatario);
    setAprovacaoLocatario((prevState) => ({
      ...prevState, // preserva o restante das informações
      fiadores: [...prevState.fiadores, locatarioData], // adiciona o novo fiador
    }));
  };

  const removeFiador = (index) => {
    setAprovacaoLocatario((prevState) => ({
      ...prevState, // preserva o restante das informações
      fiadores: prevState.fiadores.filter((_, i) => i !== index), // remove o fiador pelo índice
    }));
  };

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          <Heading level={3} className="titulo-fiador">
            Selecione um ou mais Fiador(es)
          </Heading>
          <SearchFieldPessoas
            setPessoa={handleSetFiador} // Função para definir o fiador
            pessoasAssociadas={aprovacaoLocatario.fiadores} // Lista de fiadores
          />
          <hr />
          <List size="lg" autoScroll bordered hover>
            {aprovacaoLocatario.fiadores &&
            aprovacaoLocatario?.fiadores.length === 0 ? (
              <List.Item>Sem fiadores associados no momento</List.Item>
            ) : (
              aprovacaoLocatario?.fiadores?.map((doc, index) => (
                <List.Item key={index} index={index}>
                  <FlexboxGrid align="middle">
                    <FlexboxGrid.Item colspan={10}>{doc.nome}</FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={10}>{doc.cpf}</FlexboxGrid.Item>
                    <FlexboxGrid.Item
                      colspan={4}
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        color="red"
                        size="lg"
                        onClick={() => removeFiador(index)} // Função para remover o fiador
                      >
                        🗑️
                      </Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </List.Item>
              ))
            )}
          </List>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24}>
          <Heading level={3} className="titulo-imovel">
            Imóvel de garantia
          </Heading>
          <hr />

          <Panel className="imovel-panel" shaded>
            <>
              <FlexboxGrid.Item colspan={24}>
                <Form.Group controlId="tipo">
                  <Form.ControlLabel>Tipo Imóvel</Form.ControlLabel>
                  <Form.Control
                    size="lg"
                    name="tipo"
                    data={tipoData}
                    groupBy="tipoImovel"
                    labelKey="label"
                    valueKey="valor"
                    searchable={false}
                    style={{ width: "100%" }}
                    accepter={SelectPicker}
                    placeholder="Tipo Imóvel"
                  />
                </Form.Group>
                <Form.Group controlId="endereco">
                  <Form.ControlLabel>Endereço</Form.ControlLabel>
                  <Input
                    as="textarea"
                    rows={3}
                    size="lg"
                    name="endereco"
                    placeholder="Endereço"
                    value={aprovacaoLocatario.endereco || ""} // Vincula o valor ao estado
                    onChange={(event) => {
                      handleFormChange({
                        ...aprovacaoLocatario,
                        endereco: event,
                      });
                    }}
                  />
                </Form.Group>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={6}>
                <Form.Group controlId="valor">
                  <Form.ControlLabel>Valor Imovel</Form.ControlLabel>
                  <InputGroup>
                    <InputGroup.Addon>R$</InputGroup.Addon>
                    <Form.Control size="lg" name="valor" placeholder="Valor" />
                  </InputGroup>
                </Form.Group>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={18} />
              <FlexboxGrid.Item colspan={5}>
                <Form.Group controlId="numMatricula">
                  <Form.ControlLabel>Número matrícula</Form.ControlLabel>
                  <InputGroup>
                    <Form.Control
                      size="lg"
                      name="numMatricula"
                      placeholder="Número matrícula"
                    />
                  </InputGroup>
                </Form.Group>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={19} />
              <FlexboxGrid.Item colspan={5}>
                <Form.Group controlId="numIptu">
                  <Form.ControlLabel>Número IPTU</Form.ControlLabel>
                  <InputGroup>
                    <Form.Control
                      size="lg"
                      name="numIptu"
                      placeholder="Número IPTU"
                    />
                  </InputGroup>
                </Form.Group>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={19} />
            </>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Fiador;
