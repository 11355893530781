import CryptoJS from "crypto-js";
import { call, put, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { authenticateFailure, authenticateSuccess } from "./actions";
import { LOGIN_REQUEST, LOGOUT } from "./types";

// Função para encriptar o código de acesso
const encryptAccessCode = (accessCode) => {
  const encrypted = CryptoJS.AES.encrypt(
    accessCode,
    "your-secret-key"
  ).toString();
  return encrypted;
};

// Função simulada para verificar se o código de acesso é válido (API call)
const apiCheckAccessCode = async (encryptedAccessCode) => {
  // Supondo que você tenha uma API que verifica o código de acesso
  const response = await api.get("/colaborador/checkAccessCode", {
    params: { accessCode: encryptedAccessCode },
  });
  return response.data;
};

// Função para verificar o código de acesso
const checkAccessCode = async (accessCode) => {
  const encryptedAccessCode = encryptAccessCode(accessCode);
  const colaboradorCheckAccessCode = await apiCheckAccessCode(
    encryptedAccessCode
  );
  return colaboradorCheckAccessCode;
};

// Função do saga para lidar com a solicitação de login
function* login(action) {
  try {
    const { accessCode } = action.payload;
    const colaboradorCheckAccessCode = yield call(checkAccessCode, accessCode);
    if (colaboradorCheckAccessCode.isValid) {
      localStorage.setItem("isAuthenticated", "true"); // Armazena no localStorage
      localStorage.setItem(
        "colaborador_id",
        colaboradorCheckAccessCode.colaborador_id
      ); // Armazena no localStorage
      yield put(authenticateSuccess());
    } else {
      yield put(authenticateFailure("Código de acesso inválido"));
    }
  } catch (error) {
    yield put(authenticateFailure("Erro ao tentar autenticar"));
  }
}

function* logout() {
  localStorage.removeItem("isAuthenticated"); // Remove do localStorage
  localStorage.removeItem("colaborador_id");
  yield null; // Adiciona um `yield` apenas para evitar o erro do ESLint
}

// Função do saga para assistir a solicitações de login
export default function* authSaga() {
  yield takeLatest(LOGIN_REQUEST, login);
  yield takeLatest(LOGOUT, logout);
}
