const tipoImovelData = [
  {
    tipoImovel: "Apartamento",
    valor: "Com Terraço",
    label: "Com Terraço",
    abreviacaoTipoImovel: "AP",
  },
  {
    tipoImovel: "Apartamento",
    valor: "Apartamento.Duplex",
    label: "Duplex",
    abreviacaoTipoImovel: "AP",
  },
  {
    tipoImovel: "Casa",
    valor: "De Vila",
    label: "De Vila",
    abreviacaoTipoImovel: "CS",
  },
  {
    tipoImovel: "Casa",
    valor: "Casa.Duplex",
    label: "Duplex",
    abreviacaoTipoImovel: "CS",
  },
  {
    tipoImovel: "Outros",
    valor: "Box de Garagem",
    label: "Box de Garagem",
    abreviacaoTipoImovel: "OT",
  },
  {
    tipoImovel: "Outros",
    valor: "Chacara",
    label: "Chacara",
    abreviacaoTipoImovel: "OT",
  },
  {
    tipoImovel: "Outros",
    valor: "Fazenda",
    label: "Fazenda",
    abreviacaoTipoImovel: "OT",
  },
  {
    tipoImovel: "Outros",
    valor: "Ilha",
    label: "Ilha",
    abreviacaoTipoImovel: "OT",
  },
  {
    tipoImovel: "Outros",
    valor: "Kitnet",
    label: "Kitnet",
    abreviacaoTipoImovel: "OT",
  },
  {
    tipoImovel: "Outros",
    valor: "Lançamento",
    label: "Lançamento",
    abreviacaoTipoImovel: "OT",
  },
  {
    tipoImovel: "Outros",
    valor: "Loft",
    label: "Loft",
    abreviacaoTipoImovel: "OT",
  },
  {
    tipoImovel: "Outros",
    valor: "Rancho",
    label: "Rancho",
    abreviacaoTipoImovel: "OT",
  },
  {
    tipoImovel: "Outros",
    valor: "Sitio",
    label: "Sitio",
    abreviacaoTipoImovel: "OT",
  },
  {
    tipoImovel: "Outros",
    valor: "Sobrado",
    label: "Sobrado",
    abreviacaoTipoImovel: "OT",
  },
  {
    tipoImovel: "Outros",
    valor: "Studio",
    label: "Studio",
    abreviacaoTipoImovel: "OT",
  },
  {
    tipoImovel: "Terreno",
    valor: "Area",
    label: "Area",
    abreviacaoTipoImovel: "TR",
  },
  {
    tipoImovel: "Terreno",
    valor: "Lote",
    label: "Lote",
    abreviacaoTipoImovel: "TR",
  },
  {
    tipoImovel: "Terreno",
    valor: "Loteamento",
    label: "Loteamento",
    abreviacaoTipoImovel: "TR",
  },
  {
    tipoImovel: "Terreno",
    valor: "Em condomínio",
    label: "Em condomínio",
    abreviacaoTipoImovel: "TR",
  },
  {
    tipoImovel: "Terreno",
    valor: "Padrão",
    label: "Padrão",
    abreviacaoTipoImovel: "TR",
  },
];

export default tipoImovelData;
