import { format } from "date-fns";
import React, { useContext, useState } from "react";
import { FlexboxGrid, Heading, Panel, Text } from "rsuite";
import { TransferenciaContasContext } from "./TransferenciaContasContext";
import "./style.css";

const CaixaDeEntrada = () => {
  const { contratoLocacao, transferenciaContas, setTransferenciaContas } =
    useContext(TransferenciaContasContext); // Use o contexto correto
  const [showModal, setShowModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  // Função para calcular a validade do contrato
  const calcularValidadeContrato = () => {
    if (contratoLocacao.inicioContrato && contratoLocacao.validadeContrato) {
      const inicioContrato = new Date(contratoLocacao.inicioContrato);
      const dataInicioFormatada = format(inicioContrato, "dd/MM/yyyy");
      const mesesValidade = parseInt(contratoLocacao.validadeContrato, 10);
      const dataFinalContrato = new Date(inicioContrato);
      dataFinalContrato.setMonth(dataFinalContrato.getMonth() + mesesValidade);
      const dataFinalFormatada = format(dataFinalContrato, "dd/MM/yyyy");
      return `${dataInicioFormatada} à ${dataFinalFormatada}`;
    }
    return "";
  };

  // Função para renderizar a lista de proprietários
  const renderizarProprietarios = () => {
    if (contratoLocacao.imovel) {
      return contratoLocacao.imovel.proprietario.map((item, index) => (
        <div key={index}>
          {item.proprietarioId.nome} - {item.proprietarioId.cpf}
          <br />
          Telefones:
          {item.proprietarioId.telefones.map((telefone, idx) => (
            <>
              <br />
              {telefone.tipo}: {telefone.telefone}
            </>
          ))}
        </div>
      ));
    }
    return "";
  };

  // Função para renderizar a lista de locatario
  const renderizarLocatarios = () => {
    if (contratoLocacao.locatario) {
      return contratoLocacao.locatario.map((item, index) => (
        <div key={index}>
          {item.nome} - {item.cpf}
          <br />
          Telefones:
          {item.telefones.map((telefone, idx) => (
            <>
              <br />
              {telefone.tipo}: {telefone.telefone}
            </>
          ))}
        </div>
      ));
    }
    return "";
  };

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          <Text block className="imovel-info">
            <b>Origem:</b>{" "}
            {transferenciaContas.origem ? transferenciaContas.origem : "Manual"}
          </Text>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Heading level={3} className="titulo-imovel">
            Resumo do Contrato
          </Heading>
          <Panel className="rescisao-panel" shaded>
            <>
              <Text block className="imovel-info">
                Validade do contrato: {calcularValidadeContrato()}
              </Text>
              <Text block className="imovel-info">
                Proprietários:
                {renderizarProprietarios()}
              </Text>

              <Text block className="imovel-info">
                Inquilinos:
                {renderizarLocatarios()}
              </Text>

              <hr />
              <Text block className="contrato-info">
                Rateio Água:{" "}
                {
                  contratoLocacao?.contratoTarefaId?.aprovacaoLocatarioId
                    ?.rateioAgua
                }
              </Text>
              <Text block className="contrato-info">
                Rateio CPFL:{" "}
                {
                  contratoLocacao?.contratoTarefaId?.aprovacaoLocatarioId
                    ?.rateioEnergia
                }
              </Text>
              <hr />
              <Text block className="contrato-info">
                Cobrar IPTU do locatário:{" "}
                {contratoLocacao?.contratoTarefaId?.cobrancaIptu}
              </Text>
              <Text block className="contrato-info">
                Responsável financeiro pelo IPTU:{" "}
                {contratoLocacao?.contratoTarefaId?.responsavelFinanceiroIptu}
              </Text>
              <Text block className="contrato-info">
                Cobrar Condominio do locatário:{" "}
                {contratoLocacao?.contratoTarefaId?.cobrancaCondominio}
              </Text>
              <Text block className="contrato-info">
                Responsável financeiro pelo Condominio:{" "}
                {
                  contratoLocacao?.contratoTarefaId
                    ?.responsavelFinanceiroCondominio
                }
              </Text>
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Heading level={3} className="titulo-imovel">
            Resumo do imóvel
          </Heading>
          <Panel className="rescisao-panel" shaded>
            <>
              {contratoLocacao.endereco && (
                <>
                  <Text block className="transferencia-subtitulo">
                    Endereço:
                  </Text>
                  <Text block className="proprietario-info">
                    {contratoLocacao.endereco.rua},{" "}
                    {contratoLocacao.endereco.numero},{" "}
                    {contratoLocacao.endereco.complemento}
                    {" - "}
                    {contratoLocacao.endereco.bairro}
                  </Text>
                </>
              )}
              <hr />
              <Text block className="transferencia-subtitulo">
                Códigos
              </Text>
              <Text block className="contrato-info">
                Imóvel: {contratoLocacao?.imovel?.imovelCodigo?.imovel || "N/A"}
              </Text>
              <Text block className="contrato-info">
                IPTU: {contratoLocacao?.imovel?.imovelCodigo?.iptu || "N/A"}
              </Text>
              <hr />
              <Text block className="contrato-info">
                Água: {contratoLocacao?.imovel?.imovelCodigo?.dae || "N/A"}
              </Text>
              <Text block className="contrato-info">
                Num. Medidor Água:{" "}
                {contratoLocacao?.imovel?.imovelCodigo?.medidorAgua || "N/A"}
              </Text>
              <Text block className="contrato-info">
                Insc Água:{" "}
                {contratoLocacao?.imovel?.imovelCodigo?.inscAgua || "N/A"}
              </Text>
              <hr />
              <Text block className="contrato-info">
                Energia: {contratoLocacao?.imovel?.imovelCodigo?.cpfl || "N/A"}
              </Text>
              <Text block className="contrato-info">
                Num. Medidor Energia:{" "}
                {contratoLocacao?.imovel?.imovelCodigo?.medidorEnergia || "N/A"}
              </Text>
            </>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default CaixaDeEntrada;
