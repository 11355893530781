import { Sidebar, Sidenav, Nav, IconButton, Stack } from "rsuite";
import { Icon } from "@rsuite/icons";
import logo from "../../assets/Logo.jpg";
import {
  MdDashboard,
  MdKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useState } from "react";

const LeftSidebar = () => {
  const [expand, setExpand] = useState(true);
  return (
    <Sidebar
      style={{ display: "flex", flexDirection: "column" }}
      width={expand ? 202 : 56}
      collapsible
    >
      <Sidenav.Header>
        <img src={logo} alt="Logo" style={{ width: expand ? 202.5 : 56 }} />
      </Sidenav.Header>
      <Sidenav
        expanded={expand}
        defaultOpenKeys={["1", "2"]}
        appearance="inverse"
      >
        <Sidenav.Body>
          <Nav defaultActiveKey="1">
            <Nav.Menu
              eventKey="1"
              trigger="hover"
              title="Cadastro"
              icon={<Icon as={MdDashboard} />}
              placement="rightStart"
            >
              <Nav.Item href="/pessoa" eventKey="1-1">
                Pessoa
              </Nav.Item>
              <Nav.Item href="/imovel" eventKey="1-2">
                Imóvel
              </Nav.Item>
            </Nav.Menu>
            <Nav.Menu
              eventKey="2"
              trigger="hover"
              title="Tarefas"
              icon={<Icon as={MdDashboard} />}
              placement="rightStart"
            >
              <Nav.Item href="/listaAprovacaoExistente" eventKey="1-1">
                Aprovação Locatário
              </Nav.Item>
              <Nav.Item href="/listaContratoLocacao" eventKey="1-2">
                Contrato de Locação
              </Nav.Item>
              <Nav.Item href="/listaRescisaoContrato" eventKey="1-3">
                Rescisão de Contrato
              </Nav.Item>
              <Nav.Item href="/listaTransferenciaContas" eventKey="1-4">
                Transferência de Contas de Consumo
              </Nav.Item>
              <Nav.Item href="/listaSinistro" eventKey="1-5">
                Sinistro
              </Nav.Item>
            </Nav.Menu>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
      <NavToggle expand={expand} onChange={() => setExpand(!expand)} />
    </Sidebar>
  );
};

const NavToggle = ({ expand, onChange }) => {
  return (
    <Stack
      className="nav-toggle"
      justifyContent={expand ? "flex-end" : "center"}
    >
      <IconButton
        onClick={onChange}
        appearance="subtle"
        size="lg"
        icon={
          expand ? <MdKeyboardArrowLeft /> : <MdOutlineKeyboardArrowRight />
        }
      />
    </Stack>
  );
};

export default LeftSidebar;
