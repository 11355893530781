const tipoPisoData = [
  { name: "porcelanato", label: "Porcelanato" },
  { name: "granito", label: "Granito" },
  { name: "ardosia", label: "Ardósia" },
  { name: "tacoMadeira", label: "Taco de madeira" },
  { name: "pisoLaminado", label: "Piso laminado" },
  { name: "carpete", label: "Carpete" },
  { name: "carpeteMadeira", label: "Carpete de Madeira" },
  { name: "vinilico", label: "Vinílico" },
  { name: "marmore", label: "Mármore" },
  { name: "acrilico", label: "Acrílico" },
  { name: "contrapiso", label: "Contrapiso" },
  { name: "tabuaMadeira", label: "Tábua-Madeira" },
];

module.exports = { tipoPisoData };
