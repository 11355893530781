import { format } from "date-fns";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Content,
  Divider,
  FlexboxGrid,
  Form,
  Modal,
  SelectPicker,
  Table,
  Text,
} from "rsuite";
import { showError, showSuccess } from "../../components/Utils/toastUtils";
import { fetchWithAuth } from "../../services/apiNoState";
import { addFileToUploader, fazerUpload } from "../../services/manageUploads";
import MyComponent from "./MyComponent";
import MySteps from "./MySteps";
import { RescisaoContratoContext } from "./RescisaoContratoContext";
import {
  step1Schema,
  step2Schema,
  step3Schema,
  step4Schema,
  step5Schema,
  step6Schema,
} from "./validacao";

/**
 * Componente principal do contrato de locação.
 * Este componente gerencia o estado do contrato, a navegação entre etapas e a validação do formulário.
 */
const RescisaoContrato = () => {
  const {
    setContratoLocacao,
    rescisaoContrato,
    setRescisaoContrato,
    validateCurrentStep,
    stepData,
    handleStepChange,
    preencherSteps,
    filterStep1Data,
    filterStep2Data,
    filterStep3Data,
    filterStep4Data,
    filterStep5Data,
    filterStep6Data,
    getStatusClass,
    usuarioLogado,
  } = useContext(RescisaoContratoContext);

  const { Column, HeaderCell, Cell } = Table;

  const [step, setStep] = useState(1);

  const [formError, setFormError] = useState({});
  const [formDisabled, setFormDisabled] = useState(false); // Estado para controlar o formulário

  const location = useLocation();
  const contratoParam = location.state?.contrato; // Acessa 'contrato' de 'location.state'
  const rescisaoParam = location.state?.rescisao; // Acessa 'rescisao' de 'location.state'

  const [showModalComunicacao, setShowModalComunicacao] = useState(false);
  const [showModalPerdeu, setShowModalPerdeu] = useState(false);
  const [showModalGanhou, setShowModalGanhou] = useState(false);
  const [showModalAdicionarRegistro, setShowModalAdicionarRegistro] =
    useState(false);

  const [motivoDesaprovadoPerdeu, setMotivoDesaprovadoPerdeu] = useState(null);

  const formRef = useRef();

  const motivoDesaprovadoData = [
    "Desistencia proprietário",
    "Desistencia locatário",
    "Motivo força maior",
  ].map((item) => ({
    label: item,
    value: item,
  }));

  const quemData = ["Seguradora", "Locatário", "Proprietário", "Interna"].map(
    (item) => ({
      label: item,
      value: item,
    })
  );

  /**
   * Efeito colateral que carrega os dados do contrato de locação ao montar o componente.
   * Atualiza o estado com os dados do contrato se o parâmetro de contrato estiver presente na localização.
   */
  useEffect(() => {
    const carregarRescisaoContrato = async () => {
      if (contratoParam) {
        setContratoLocacao(contratoParam);
      }

      if (rescisaoParam) {
        setContratoLocacao(rescisaoParam.contratoId);

        // Atualiza o estado com os dados vindos de `contratoParam`
        setRescisaoContrato(() => {
          let updates = {
            ...rescisaoParam,
            locatarios: rescisaoParam.contratoId.locatario
              ? rescisaoParam.locatario
              : [],
            // moradores: rescisaoParam.contratoId.morador
            // ? rescisaoParam.morador
            // : [],
            // fiadores: rescisaoParam.contratoId.fiador
            //   ? rescisaoParam.fiador
            //   : [],
            proprietarios: rescisaoParam.contratoId.locador
              ? rescisaoParam.contratoId.locador
              : [],
          };

          // Verifica se existem as informações de imagemDeclaracaoAdimplencia
          if (rescisaoParam.imagemDeclaracaoAdimplenciaField) {
            updates.imagemDeclaracaoAdimplenciaField = addFileToUploader(
              rescisaoParam.imagemDeclaracaoAdimplenciaField.conteudo,
              rescisaoParam.imagemDeclaracaoAdimplenciaField.nome
            );
          }

          /*Tela de Etapa Final */
          if (rescisaoParam.imagemDistratoField) {
            updates.imagemDistratoField = addFileToUploader(
              rescisaoParam.imagemDistratoField.conteudo,
              rescisaoParam.imagemDistratoField.nome
            );
          }

          if (rescisaoParam.imagemTermoConfissaoField) {
            updates.imagemTermoConfissaoField = addFileToUploader(
              rescisaoParam.imagemTermoConfissaoField.conteudo,
              rescisaoParam.imagemTermoConfissaoField.nome
            );
          }

          if (rescisaoParam.imagemAcordoParcelamentoField) {
            updates.imagemAcordoParcelamentoField = addFileToUploader(
              rescisaoParam.imagemAcordoParcelamentoField.conteudo,
              rescisaoParam.imagemAcordoParcelamentoField.nome
            );
          }

          if (rescisaoParam.imagemTermoChavesField) {
            updates.imagemTermoChavesField = addFileToUploader(
              rescisaoParam.imagemTermoChavesField.conteudo,
              rescisaoParam.imagemTermoChavesField.nome
            );
          }

          // Processa documentosOrcamento e adiciona ao estado
          if (
            rescisaoParam.documentosOrcamento &&
            rescisaoParam.documentosOrcamento.length
          ) {
            updates.documentosOrcamento = rescisaoParam.documentosOrcamento.map(
              (documento) => {
                const arquivoUploader = addFileToUploader(
                  documento.imagemDocumento.conteudo,
                  documento.imagemDocumento.nome
                );
                return {
                  tipo: documento.tipo, // Preserva o tipo
                  imagemDocumento: arquivoUploader, // Inclui os dados formatados para o Uploader
                };
              }
            );
          }

          //Valida se existe um motivoDesaprovado, e se o mesmo está vazio
          if (rescisaoParam.status === "Perdeu") {
            setFormPerdeu();
          } else if (rescisaoParam.status === "Ganhou") {
            setFormGanhou();
          } else {
            handleAndamentoClick(); // Se nenhuma das condições acima for atendida
          }

          return updates;
        });
        preencherSteps(rescisaoParam);
      }
    };

    carregarRescisaoContrato();
  }, []);

  /**
   * Manipula as mudanças no formulário e atualiza o estado do contrato de locação.
   * @param {Object} formValue - Os valores do formulário que foram alterados.
   */
  const handleFormChange = (formValue) => {
    setRescisaoContrato((prevState) => ({
      ...prevState,
      ...formValue,
    }));
  };

  /**
   * Envia os dados do formulário para o backend.
   * Valida o passo atual e verifica se houve alterações antes de enviar os dados.
   * @param {string} status - O status atual do contrato.
   */
  const handleSubmit = async (status = rescisaoContrato.status) => {
    if (validateCurrentStep(step, formRef)) {
      // if (rescisaoContrato.status === "Aberto") {
      //   await handleAndamentoClick();
      // }

      // Verifica se houve alterações
      if (!rescisaoContrato._id) {
        await handleAndamentoClick();
        try {
          //Verifica se o step atual possui imagens
          await validaEUploadImagens();

          await setRescisaoContrato((prev) => {
            const savedRescisao = saveRescisaoContrato(prev);
            const atualizado = { ...prev, _id: savedRescisao.id };
            return atualizado;
          });
          // // Salva a aprovação caso o ID não exista
          // const savedRescisao = await saveRescisaoContrato(rescisaoContrato);

          // // Atualiza o rescisaoContrato com o ID retornado
          // setRescisaoContrato((prev) => ({
          //   ...prev,
          //   _id: savedRescisao.id, // ou o campo correspondente retornado pela função
          // }));

          // Se houver mudanças, salva as alterações no stepData
          handleStepChange(step);
        } catch (error) {
          console.error("Erro ao salvar a rescisão Contrato:", error);
          return; // Interrompe se houver erro ao salvar
        }
      } else if (await hasAnyChanges(step)) {
        //Verifica se o step atual possui imagens
        await validaEUploadImagens();

        //Chama o backend e atualiza todos os campos que tiveram mudança
        await atualizarRescisaoContrato(status);
        // Se houver mudanças, salva as alterações no stepData
        handleStepChange(step);
      }
    } else {
      return false;
    }
  };

  /**
   * Verifica se houve alterações nos dados do formulário.
   * Compara os dados atuais com os dados salvos para determinar se houve alguma mudança.
   * @param {number} step - O número do passo atual do formulário.
   * @returns {boolean} - Retorna true se houver alterações, caso contrário false.
   */
  const hasAnyChanges = async (step) => {
    let currentData;

    switch (step) {
      case 1:
        currentData = filterStep1Data(rescisaoContrato); // Filtra os dados relevantes do step 1
        break;
      case 2:
        currentData = filterStep2Data(rescisaoContrato); // Filtra os dados relevantes do step 2
        break;
      case 3:
        currentData = filterStep3Data(); // Filtra os dados relevantes do step 3
        break;
      case 4:
        currentData = filterStep4Data(rescisaoContrato); // Filtra os dados relevantes do step 4
        break;
      case 5:
        currentData = filterStep5Data(rescisaoContrato); // Filtra os dados relevantes do step 5
        break;
      case 6:
        currentData = filterStep6Data(rescisaoContrato); // Filtra os dados relevantes do step 6
        break;
      default:
        currentData = rescisaoContrato[`step${step}`]; // Use os dados completos para outros steps
    }

    const savedData = stepData[`step${step}`]; // Dados salvos para o step atual

    // Função para verificar se um campo é de imagem
    const isImageField = (field) => field.startsWith("imagem");

    // Comparação dos dados
    const hasChanges = Object.keys(currentData).some((key) => {
      const currentValue = currentData[key]
        ? JSON.parse(JSON.stringify(currentData[key]))
        : null;

      const savedValue =
        savedData && savedData[key]
          ? JSON.parse(JSON.stringify(savedData[key]))
          : null;

      if (isImageField(key)) {
        // Captura o nome correto se existir
        const currentName = currentValue?.name ?? currentValue?.nome;
        const savedName = savedValue?.name ?? savedValue?.nome;

        // Se ambos currentName e savedName existirem, compara pelos nomes
        if (currentName !== undefined && savedName !== undefined) {
          return currentName !== savedName;
        }

        // Caso contrário, compara diretamente os valores
        return currentValue !== savedValue;
      }

      // Comparação padrão para outros campos
      return JSON.stringify(currentValue) !== JSON.stringify(savedValue);
    });

    return hasChanges;
  };

  /**
   * Valida e faz upload das imagens se houver alterações.
   * Verifica quais arquivos de imagem foram alterados e os envia para o backend.
   */
  const validaEUploadImagens = async () => {
    // Map de campos relacionados ao step, incluindo sub-elementos para arrays
    const stepFieldsMap = {
      1: ["imagemDeclaracaoAdimplenciaField"], // Campo simples
      2: ["documentosOrcamento.imagemDocumento"], // Campo em lista
      3: [
        "imagemDistratoField",
        "imagemTermoConfissaoField",
        "imagemAcordoParcelamentoField",
        "imagemTermoChavesField,",
      ],
    };

    // Recupera os campos baseados no step atual
    const fieldsToValidate = stepFieldsMap[step];
    if (!fieldsToValidate) return; // Se não houver campos para o step, encerra.

    // Obter os campos do rescisaoContrato e savedFields
    const savedFieldsForStep = stepData[`step${step}`];

    const arquivosParaUpload = [];

    fieldsToValidate.forEach((fieldPath) => {
      const [field, subField] = fieldPath.split("."); // Divide em campo e subcampo, se existir

      if (Array.isArray(rescisaoContrato[field])) {
        // Caso seja uma lista de objetos
        rescisaoContrato[field].forEach((item, index) => {
          const currentField = item?.[subField]?.blobFile;
          const savedField = savedFieldsForStep?.[field]?.[index]?.[subField];

          // Verifica se há alterações
          if (currentField && currentField.name !== savedField) {
            arquivosParaUpload.push({
              arquivo: currentField,
              contexto: { field, subField, index }, // Passa o contexto
            });
          }
        });
      } else {
        // Caso seja um campo simples
        const currentField = rescisaoContrato[field]?.blobFile;
        const savedField =
          savedFieldsForStep?.[field]?.nome ||
          savedFieldsForStep?.[field]?.name;

        // Verifica se há alterações
        if (currentField && currentField.name !== savedField) {
          arquivosParaUpload.push({
            arquivo: currentField,
            contexto: { field }, // Passa o contexto
          });
        }
      }
    });

    if (arquivosParaUpload.length === 0) return; // Nada a ser feito se não houver alterações.

    // Faz o upload utilizando o método ajustado no manageUpload
    const resultado = await fazerUpload(arquivosParaUpload);

    // Atualiza o rescisaoContrato com os nomes retornados
    if (resultado) {
      resultado.forEach((file) => {
        const { field, subField, index } = file.contexto;

        if (
          Array.isArray(rescisaoContrato[field]) &&
          typeof index === "number"
        ) {
          // Atualiza o subcampo dentro da lista
          rescisaoContrato[field][index][subField] = file.fileName;
        } else {
          // Atualiza o estado no contexto
          setRescisaoContrato((prevState) => {
            const updatedState = {
              ...prevState,
              [field.replace("Field", "")]: file.fileName, // Atualiza o campo correspondente
            };
            return updatedState; // Retorna o estado atualizado
          });
        }
      });
    }
  };

  /**
   * Atualiza os dados do contrato de locação no backend.
   * Envia uma requisição PUT para atualizar o contrato com os dados atuais.
   * @param {string} status - O status atual do contrato.
   */
  const atualizarRescisaoContrato = async (status) => {
    try {
      rescisaoContrato.step = step;
      //Caso o handleSubmit seja chamado pelo aprovação está aprovada?, então alterar o status
      if (status === "Perdeu" || status === "Ganhou") {
        rescisaoContrato.status = status;
      }

      const response = await fetchWithAuth(
        `/rescisaoContrato/${rescisaoContrato._id}`,
        {
          method: "PUT",
          body: JSON.stringify(rescisaoContrato), // Salva os dados
          headers: {
            "Content-Type": "application/json", // Especifica o tipo de conteúdo
          },
        }
      );

      if (response.error) {
        showError("Erro ao alterar a contrato: ", response.message);
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Contrato parcial alterado");
      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao salvar a contrato: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  const saveRescisaoContrato = async (rescisaoContrato) => {
    try {
      const response = await fetchWithAuth("/rescisaoContrato", {
        method: "POST",
        body: JSON.stringify({
          ...rescisaoContrato,
          contratoId: contratoParam._id,
        }), // Salva os dados
        headers: {
          "Content-Type": "application/json", // Especifica o tipo de conteúdo
        },
      });

      if (response.error) {
        showError("Erro ao salvar a rescisão contrato: ", response.message);
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Rescisão contrato parcial salvo");
      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao salvar a rescisão contrato: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  /**
   * Navega para o próximo passo do formulário.
   * Valida o passo atual e, se tudo estiver correto, avança para o próximo passo.
   */
  const onNext = async () => {
    const status = rescisaoContrato.status;
    if (status === "Perdeu" || status === "Ganhou") {
      goNextStep();
      return;
    }

    if (validateCurrentStep(step, formRef)) {
      // Verifica se o ID da aprovação já existe, caso não, significa que preciso ter a primeira inclusão da contrato no banco
      if (status === "Aberto") {
        await handleAndamentoClick();
      }

      // Verifica se houve alterações
      if (!rescisaoContrato._id) {
        await handleAndamentoClick();
        try {
          // Aguarda a validação e upload das imagens
          await validaEUploadImagens();

          // Aguarda a atualização do estado e captura o valor atualizado antes de salvar
          await setRescisaoContrato((prev) => {
            const savedRescisao = saveRescisaoContrato(prev);
            const atualizado = { ...prev, _id: savedRescisao.id };
            return atualizado;
          });

          // Usa o estado atualizado para salvar a rescisão
          // const savedRescisao = await saveRescisaoContrato(rescisaoContrato);

          // Atualiza o estado com o _id retornado
          // setRescisaoContrato((prev) => ({
          //   ...prev,
          //   _id: savedRescisao.id,
          // }));

          // Se houver mudanças, salva as alterações no stepData
          handleStepChange(step);
        } catch (error) {
          console.error("Erro ao salvar a rescisão Contrato:", error);
          return; // Interrompe se houver erro ao salvar
        }
      } else if (await hasAnyChanges(step)) {
        //Verifica se o step atual possui imagens
        await validaEUploadImagens();
        //Chama o backend e atualiza todos os campos que tiveram mudança
        await atualizarRescisaoContrato(status); // Chama o método separado
        // Se houver mudanças, salva as alterações no stepData
        handleStepChange(step);
      }
      goNextStep();
    } else {
      showError(
        "Falha na validação, por favor valide todos os campos obrigatórios"
      );
    }
  };

  const goNextStep = () => {
    // Valida os campos do step atual
    try {
      setStep(step + 1);
    } catch (error) {
      console.error("Error saving step data:", error);
    }
  };

  /**
   * Navega para o passo anterior do formulário.
   * Atualiza o estado para o passo anterior.
   */
  const onPrevious = () => {
    let prevStep = step - 1;

    setStep(prevStep);
  };

  /**
   * Obtém o esquema de validação para o passo atual.
   * Retorna o esquema de validação correspondente ao passo atual.
   * @param {number} step - O número do passo atual.
   * @returns {Object} - O esquema de validação para o passo atual.
   */
  const getSchemaForStep = (step) => {
    switch (step) {
      case 1:
        return step1Schema;
      case 2:
        return step2Schema;
      case 3:
        return step3Schema;
      case 4:
        return step4Schema;
      case 5:
        return step5Schema;
      case 6:
        return step6Schema;
      default:
    }
  };

  const handleComunicacaoClick = () => {
    setShowModalComunicacao(true); // Abrir a modal de "Comunicacao"
  };
  /**
   * Manipula a ação de clicar no botão "Perdeu".
   * Abre a modal de confirmação para alterar o status para "Perdeu".
   */
  const handlePerdeuClick = () => {
    setShowModalPerdeu(true); // Abrir a modal de "Perdeu"
  };

  /**
   * Manipula a ação de clicar no botão "Ganhou".
   * Abre a modal de confirmação para alterar o status para "Ganhou".
   */
  const handleGanhouClick = () => {
    setShowModalGanhou(true); // Abrir a modal de "Ganhou"
  };

  const handleAdicionarRegistroComunicacaoClick = () => {
    setShowModalAdicionarRegistro(true);
  };

  const handleSubmitComunicacao = async () => {
    // Ações ao confirmar a Comunicação
    try {
      rescisaoContrato.step = step;
      const response = await fetchWithAuth(
        `/rescisaoContrato/comunicacao/${rescisaoContrato._id}`,
        {
          method: "PUT",
          body: JSON.stringify(rescisaoContrato.listaComunicacao), // Salva os dados
          headers: {
            "Content-Type": "application/json", // Especifica o tipo de conteúdo
          },
        }
      );

      if (response.error) {
        showError(
          "Erro interno ao atualizar a comunicação: ",
          response.message
        );
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Comunicação atualizada com sucesso!");

      setShowModalComunicacao(false); // Fecha a modal de "Perdeu"

      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao atualizar a comunicação: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  /**
   * Envia os dados para alterar o status do contrato para "Perdeu".
   * Envia uma requisição para atualizar o status do contrato no backend.
   */
  const handleSubmitPerdeu = async () => {
    // Ações ao confirmar a mudança para "Perdeu"
    try {
      rescisaoContrato.step = step;
      const response = await fetchWithAuth(
        `/rescisaoContrato/perdeu/${rescisaoContrato._id}`,
        {
          method: "PUT",
          body: JSON.stringify({ motivoDesaprovadoPerdeu }), // Salva os dados
          headers: {
            "Content-Type": "application/json", // Especifica o tipo de conteúdo
          },
        }
      );

      if (response.error) {
        showError(
          "Erro interno ao alterar o status para perdeu: ",
          response.message
        );
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Status alterado para perdeu com sucesso");

      setFormPerdeu();
      setShowModalPerdeu(false); // Fecha a modal de "Perdeu"

      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao alterar o status para perdeu: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  const handleSubmitAdicionarRegistro = async () => {
    handleAddRecord();

    // Ação de confirmar a adção de Registro
    showSuccess("Registro adicionado com sucesso");

    setShowModalAdicionarRegistro(false); // Fecha a modal de Adicionar Registro
  };

  /**
   * Envia os dados para alterar o status do contrato para "Ganhou".
   * Envia uma requisição para atualizar o status do contrato no backend.
   */
  const handleSubmitGanhou = async () => {
    // Ações ao confirmar a mudança para "Ganhou"
    try {
      rescisaoContrato.step = step;
      const response = await fetchWithAuth(
        `/rescisaoContrato/ganhou/${rescisaoContrato._id}`,
        {
          method: "PUT",
        }
      );

      if (response.error) {
        showError(
          "Erro interno ao alterar o status para ganhou: ",
          response.message
        );
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Status alterado para ganhou com sucesso");

      setFormGanhou();
      setShowModalGanhou(false); // Fecha a modal de "Ganhou"

      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao alterar o status para ganhou: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  /**
   * Altera o formulário para o estado "Perdeu".
   * Desabilita o formulário e atualiza o status para "Perdeu".
   */
  const setFormPerdeu = () => {
    setFormDisabled(true); // Desabilita o formulário
    setRescisaoContrato((prevState) => ({
      ...prevState,
      status: "Perdeu",
    }));
  };

  /**
   * Altera o formulário para o estado "Ganhou".
   * Desabilita o formulário e atualiza o status para "Ganhou".
   */
  const setFormGanhou = () => {
    setFormDisabled(true); // Desabilita o formulário
    setRescisaoContrato((prevState) => ({
      ...prevState,
      status: "Ganhou",
    }));
  };

  /**
   * Manipula a ação de clicar no botão de andamento.
   * Atualiza o status do contrato para "Andamento".
   */
  const handleAndamentoClick = async () => {
    rescisaoContrato.status = "Andamento";
  };

  /*Informações referente a manipulação da tabela dinamica */
  // Adicionar um novo registro
  const handleAddRecord = () => {
    const newRecord = {
      quem: rescisaoContrato.quem,
      descricao: rescisaoContrato.descricao,
      criadoEm: format(new Date(), "dd/MM/yyyy HH:mm"), // Formato personalizado
      criadoPor: usuarioLogado?.name || "Desconhecido", // Preenche com o nome do usuário
    };
    setRescisaoContrato((prev) => ({
      ...prev,
      listaComunicacao: [...(prev.listaComunicacao || []), newRecord],
    }));
  };
  /*Fim das informações da tabela dinamica */

  /**
   * Manipula a ação de clicar no botão de andamento.
   * Atualiza o status do contrato para "Andamento".
   */
  return (
    <Content className="m-2">
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          <Form
            ref={formRef}
            className="subContent formSpacing"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            model={getSchemaForStep(step)} // Esquema dinâmico com base no step atual
            formValue={rescisaoContrato}
            onChange={handleFormChange}
            onCheck={(formError) => setFormError(formError)}
            onSubmit={handleSubmit}
            disabled={formDisabled} // Desabilita o formulário com base no estado formDisabled
            fluid
          >
            {/* Modal para adicionar Comunicacao */}
            <Modal
              open={showModalAdicionarRegistro}
              onClose={() => setShowModalAdicionarRegistro(false)}
              backdrop="static"
            >
              <Modal.Header>
                <Modal.Title>Adicionar registro de comunicação</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FlexboxGrid justify="space-between">
                  <FlexboxGrid.Item colspan={12}>
                    <Form.Group controlId="quem">
                      <Form.ControlLabel>Quem</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="quem"
                        data={quemData}
                        searchable={false}
                        style={{ width: 224 }}
                        accepter={SelectPicker}
                        placeholder="Quem"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={12}>
                    <Form.Group controlId="descricao">
                      <Form.ControlLabel>Descrição</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="descricao"
                        placeholder="Descrição"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>

                  <Divider />
                </FlexboxGrid>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="lg"
                  appearance="ghost"
                  onClick={() => setShowModalAdicionarRegistro(false)}
                >
                  Cancelar
                </Button>
                <Button
                  size="lg"
                  appearance="primary"
                  onClick={handleSubmitAdicionarRegistro}
                >
                  Adicionar Registro Novo
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Fim Modal para adicionar Comunicacao */}

            {/* Modal de Comunicacao */}
            <Modal
              size="lg"
              open={showModalComunicacao}
              onClose={() => setShowModalComunicacao(false)}
            >
              <Modal.Header>
                <Modal.Title className="modal-title">Comunicação</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="modal-message">
                  Gerenciamento de comunicações internas
                </p>
                <hr />
                <Form fluid>
                  <FlexboxGrid.Item colspan={24}>
                    <Button
                      size="lg"
                      appearance="primary"
                      color="gray"
                      className="button-spacing" // Adiciona a classe de estilo e espaçamento
                      onClick={handleAdicionarRegistroComunicacaoClick}
                    >
                      Adicionar Registro de Comunicação
                    </Button>
                    <hr />
                    <Table
                      data={rescisaoContrato.listaComunicacao || []}
                      height={200}
                      bordered
                      cellBordered
                      wordWrap
                      autoHeight
                    >
                      {/* Quem */}
                      <Column flexGrow={1}>
                        <HeaderCell>Quem</HeaderCell>
                        <Cell>{(rowData) => <span>{rowData.quem}</span>}</Cell>
                      </Column>

                      {/* Descrição */}
                      <Column flexGrow={1}>
                        <HeaderCell>Descrição</HeaderCell>
                        <Cell>
                          {(rowData) => <span>{rowData.descricao}</span>}
                        </Cell>
                      </Column>

                      {/* Criado em */}
                      <Column flexGrow={1}>
                        <HeaderCell>Criado em</HeaderCell>
                        <Cell>
                          {(rowData) => <span>{rowData.criadoEm}</span>}
                        </Cell>
                      </Column>

                      {/* Criado por */}
                      <Column flexGrow={1}>
                        <HeaderCell>Criado por</HeaderCell>
                        <Cell>
                          {(rowData) => <span>{rowData.criadoPor}</span>}
                        </Cell>
                      </Column>
                    </Table>
                  </FlexboxGrid.Item>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  appearance="ghost"
                  onClick={() => setShowModalComunicacao(false)}
                >
                  Cancelar
                </Button>
                <Button appearance="primary" onClick={handleSubmitComunicacao}>
                  Confirmar
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Modal Perdeu */}
            <Modal
              open={showModalPerdeu}
              onClose={() => setShowModalPerdeu(false)}
            >
              <Modal.Header>
                <Modal.Title className="modal-title">
                  Alterar status para "Perdeu"
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="modal-message">
                  Tem certeza que deseja alterar o status para "Perdeu"?
                </p>
                <hr />
                {/* Campo de Motivo Desaprovado para "Perdeu" */}
                <Form fluid>
                  <Form.Group controlId="motivoDesaprovadoPerdeu">
                    <Form.ControlLabel>Motivo Desaprovado</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      name="motivoDesaprovadoPerdeu"
                      data={motivoDesaprovadoData}
                      accepter={SelectPicker}
                      searchable={false}
                      style={{ width: "100%" }}
                      placeholder="Motivo Desaprovado"
                      onChange={setMotivoDesaprovadoPerdeu} // Atualiza o estado do motivo de "Perdeu"
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  appearance="ghost"
                  onClick={() => setShowModalPerdeu(false)}
                >
                  Cancelar
                </Button>
                <Button appearance="primary" onClick={handleSubmitPerdeu}>
                  Confirmar
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Modal de confirmação de "Ganhou" */}
            <Modal
              open={showModalGanhou}
              onClose={() => setShowModalGanhou(false)}
            >
              <Modal.Header>
                <Modal.Title className="modal-title">
                  Alterar status para "Ganhou"
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="modal-message">
                  Tem certeza que deseja alterar o status para "Ganhou"?
                </p>
                <hr />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  appearance="ghost"
                  onClick={() => setShowModalGanhou(false)}
                >
                  Cancelar
                </Button>
                <Button appearance="primary" onClick={handleSubmitGanhou}>
                  Confirmar
                </Button>
              </Modal.Footer>
            </Modal>
            <MySteps step={step} setStep={setStep} />
            <FlexboxGrid.Item colspan={24} align="right">
              <Text bold className="status-label">
                Status:
                <span
                  className={`status-value ${getStatusClass(
                    rescisaoContrato.status || "Aberto"
                  )}`}
                >
                  {rescisaoContrato.status || "Aberto"}
                </span>
              </Text>
            </FlexboxGrid.Item>
            <MyComponent
              step={step}
              handleFormChange={handleFormChange}
              handleGanhouClick={handleGanhouClick}
              handleSubmitComunicacao={handleSubmitComunicacao}
            />
            <FlexboxGrid
              className="d-flex justify-content-start"
              style={{ marginTop: "auto" }}
            >
              <Divider />
              <FlexboxGrid.Item colspan={12} className="justify-content-start">
                <ButtonGroup>
                  <Button onClick={onPrevious} disabled={step === 1}>
                    Anterior
                  </Button>
                  <Button onClick={onNext} disabled={step === 6}>
                    Próximo
                  </Button>
                </ButtonGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item
                colspan={12}
                className="d-flex justify-content-end"
              >
                <Button
                  size="lg"
                  appearance="primary"
                  color="gray"
                  className="button-spacing" // Adiciona a classe de estilo e espaçamento
                  onClick={handleComunicacaoClick}
                >
                  Comunicação
                </Button>
                <Button
                  size="lg"
                  appearance="primary"
                  color="red"
                  className="button-spacing" // Adiciona a classe de estilo e espaçamento
                  onClick={handlePerdeuClick}
                >
                  Perdeu
                </Button>
                <Button
                  size="lg"
                  appearance="primary"
                  type="submit"
                  className="button-spacing"
                >
                  Salvar Rescisão
                </Button>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Form>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Content>
  );
};

export default RescisaoContrato;
