import { Schema } from "rsuite";

const { ArrayType } = Schema.Types;

// Validação para garantir que ao menos um checkbox de um grupo seja selecionado
const atLeastOneCheckbox = (value) => {
  if (Array.isArray(value) && value.length > 0) {
    return true;
  }
  return false;
};

export const step2Schema = Schema.Model({
  contasSelecionadas: ArrayType()
    .addRule(atLeastOneCheckbox, "Selecione pelo menos um item.")
    .isRequired("Este campo é obrigatório."),
});

export const step3Schema = Schema.Model({});
