const finalidadeData = [
  "Residencial",
  "Comercial",
  "Corporativo",
  "Rural",
  "Industrial",
].map((item) => ({
  label: item,
  value: item,
}));

module.exports = { finalidadeData };
