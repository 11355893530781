import React, { useRef, useState, useContext } from "react";
import {
  FlexboxGrid,
  Text,
  Panel,
  RadioGroup,
  Heading,
  List,
  Form,
  Radio,
  Divider,
  Modal,
  Button,
  SelectPicker,
  Uploader,
} from "rsuite";
import { AprovacaoLocatarioContext } from "./AprovacaoLocatarioContext";
import tipoImovelData from "../../enums/tipoImovel";
import { fetchWithAuth } from "../../services/apiNoState";
import { showError, showSuccess } from "../../components/Utils/toastUtils";

const Documentos = () => {
  const formRefPessoa = useRef();
  const [pessoa, setPessoa] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [showModalProp, setShowModalProp] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [formData] = useState(new FormData());
  const [showModal, setShowModal] = useState(false);
  const [documentos, setDocumentos] = useState([]);

  const { aprovacaoLocatario, setAprovacaoLocatario } = useContext(
    AprovacaoLocatarioContext
  ); // Use o contexto correto

  // Valor correspondente ao tipo de imóvel
  const tipoImovel = aprovacaoLocatario?.imovel?.tipoImovel;

  // Encontre o objeto correspondente ao tipo de imóvel
  const tipoImovelInfo = tipoImovel
    ? tipoImovelData.find((item) => item.valor === tipoImovel)
    : null;

  const documentoTipoData = ["RG", "CPF", "Certidão de casamento"].map(
    (item) => ({
      label: item,
      value: item,
    })
  );

  const handleImageChange = (files) => {
    if (files.length > 1) {
      files = [files[files.length - 1]];
    }
    setFileList(files);
  };

  const handleFormChangePessoa = (formValue) => {
    setPessoa(formValue);
    for (const key in formValue) {
      formData.set(key, formValue[key]);
    }
  };

  const handlePreview = (file) => {
    const reader = new FileReader();

    if (typeof file === "string") {
      // Caso o arquivo seja uma URL (string)
      setPreviewUrl(file);
      setShowModal(true);
    } else if (file.blobFile) {
      // Caso seja um Blob, converte para uma URL
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file.blobFile);
    } else {
      // Caso não seja string nem Blob, lida com o objeto File diretamente
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const fetchDocumentos = async (cpf) => {
    try {
      // Faz a requisição para buscar os documentos da pessoa
      const response = await fetchWithAuth(`/pessoa/documentos/${cpf}`, {
        method: "GET",
      });

      if (response.error) {
        showError("Erro ao buscar os documentos: ", response.message);
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      response.arquivos.forEach((arquivo, index) => {
        // Converte o base64 de volta para um Blob
        const byteCharacters = atob(arquivo.conteudo);

        const byteNumbers = new Array(byteCharacters.length)
          .fill()
          .map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: arquivo.tipo });

        const url = URL.createObjectURL(blob);

        // Exibe a imagem no frontend
        setDocumentos((prevList) => [
          ...prevList,
          {
            tipo: arquivo.tipo,
            imagemDocumento: url,
            dbPathName: arquivo.dbPathName,
          },
        ]);
      });
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  const openModalDocumentos = (pessoaIndex) => {
    setDocumentos([]);
    fetchDocumentos(pessoaIndex.cpf); // Busca documentos pelo CPF
    setPessoa(pessoaIndex);
    setShowModalProp(true);
  };

  const addDocumento = () => {
    validateAndAddToList(
      "documentos",
      {
        tipo: pessoa.documentoTipoDocumento,
        imagemDocumento: pessoa.imagemDocumento[0],
      },
      {
        documentoTipoDocumento: "",
        imagemDocumento: "",
      }
    );
  };

  const removeDocumento = (index) => {
    const updatedDocumento = documentos.filter((_, i) => i !== index);
    setDocumentos(updatedDocumento);
  };

  const validateAndAddToList = (listName, newItem, clearFields) => {
    const emptyFields = Object.entries(newItem).filter(
      ([key, value]) => value === undefined || value === ""
    );

    if (emptyFields.length > 0) {
      const emptyFieldNames = emptyFields.map(([key]) => key).join(", ");
      showError(
        `Não pode incluir na lista, os seguintes campos estão vazios: ${emptyFieldNames}`
      );
      return;
    }

    formRefPessoa.current.checkForField(listName, (checkResult) => {
      if (checkResult && checkResult.hasError) {
        showError(
          `Não pode incluir na lista, por favor checar se a informação está dentro dos padrões para o campo: ${listName}.`
        );
      } else {
        setPessoa((prevPessoa) => {
          const updatedList = [...(prevPessoa[listName] || []), newItem];

          const newPessoa = {
            ...prevPessoa,
            [listName]: updatedList,
            ...clearFields,
          };
          return newPessoa;
        });
        setDocumentos((prevList) => [...prevList, newItem]);
        //Limpar o campo de arquivo
        setFileList([]);
      }
    });
  };

  const handleSubmitPessoa = async () => {
    formRefPessoa.current.check((isValid, formErrorPessoa) => {
      if (isValid) {
        // Verifica se existe ao menos um telefone na lista
        if (documentos.length > 0) {
          // Wrapping the async code inside an async IIFE
          (async () => {
            try {
              const formData = new FormData();

              // Adiciona listas (documentos) ao `formData`
              documentos.forEach((item, index) => {
                formData.append(`documentos[${index}][tipo]`, item.tipo);
                if (item.dbPathName) {
                  formData.append(
                    `documentos[${index}][imagemDocumento]`,
                    item.dbPathName
                  );
                } else {
                  formData.append(
                    `documentos[${index}][imagemDocumento]`,
                    item.imagemDocumento
                  );
                }
              });

              // Adiciona arquivos, verificando `blobFile` em `documentos`
              documentos.forEach((doc, index) => {
                const file = doc.imagemDocumento?.blobFile;
                if (file) {
                  formData.append("files", file);
                }
              });

              const response = await fetchWithAuth(`/pessoa/${pessoa.cpf}`, {
                method: "PUT",
                body: formData,
              });

              setPessoa(() => {
                const newPessoa = {
                  documentoTipoDocumento: "",
                };
                return newPessoa;
              });

              //Fechar o modal de adicionar proprietário
              setShowModalProp(false);

              //Remove todos os elementos da lista de documentos
              setDocumentos([]);

              showSuccess(response.pessoa.nome + " salvo com sucesso");
            } catch (error) {
              showError("Erro: " + error.message);
            }
          })();
        } else {
          showError("Por favor, adicione ao menos um item em todas as listas.");
        }
      } else {
        const errorFields = Object.keys(formErrorPessoa)
          .filter((key) => formErrorPessoa[key] !== undefined)
          .join(", ");
        showError(`Por favor, verifique os campos: ${errorFields}`);
      }
    });
  };

  return (
    <>
      <FlexboxGrid>
        <Modal open={showModalProp} onClose={() => setShowModalProp(false)}>
          <Form
            ref={formRefPessoa}
            className="subContent"
            formValue={pessoa}
            onChange={handleFormChangePessoa}
            onSubmit={handleSubmitPessoa}
            fluid
          >
            <Modal.Header>
              <Modal.Title className="modal-title">
                Gerenciar documentos
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item colspan={5}>
                  <Form.Group controlId="documentoTipoDocumento">
                    <Form.Control
                      name="documentoTipoDocumento"
                      data={documentoTipoData}
                      searchable={false}
                      style={{ width: 224 }}
                      accepter={SelectPicker}
                      placeholder="Tipo Documento"
                    />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={8}>
                  <Form.Group controlId="imagemDocumento">
                    <Form.Control
                      name="imagemDocumento"
                      fileList={fileList}
                      maxFiles={1}
                      onPreview={handlePreview}
                      autoUpload={false} // Prevents automatic upload
                      listType="picture-text"
                      onChange={handleImageChange}
                      renderFileInfo={(file, fileElement) => {
                        return (
                          <div onClick={() => handlePreview(file)}>
                            <span>File Name: {file.name}</span>
                            <p>File URL: {file.url}</p>
                          </div>
                        );
                      }}
                      accepter={Uploader}
                    >
                      <Button color="primary" size="md">
                        Selecione o arquivo
                      </Button>
                    </Form.Control>
                    <Modal open={showModal} onClose={() => setShowModal(false)}>
                      <Modal.Header>
                        <Modal.Title>Pré-visualização</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {previewUrl && (
                          <img
                            src={previewUrl}
                            alt="Preview"
                            style={{ width: "100%" }}
                          />
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button onClick={() => setShowModal(false)}>
                          Fechar
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={3}>
                  <Button color="primary" size="md" onClick={addDocumento}>
                    +
                  </Button>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={24}>
                  <hr />
                  <List size="lg" autoScroll bordered hover>
                    {documentos &&
                      documentos.map((doc, index) => (
                        <List.Item key={index} index={index}>
                          <FlexboxGrid align="middle">
                            <FlexboxGrid.Item colspan={10}>
                              {doc.tipo}
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={10}>
                              {" "}
                              <div
                                onClick={() =>
                                  handlePreview(doc.imagemDocumento)
                                }
                              >
                                <img
                                  src={
                                    // Verifica se o retorno é uma URL (exemplo 1)
                                    typeof doc.imagemDocumento === "string"
                                      ? doc.imagemDocumento
                                      : // Verifica se é um objeto com blobFile (exemplo 2)
                                      doc.imagemDocumento.blobFile
                                      ? URL.createObjectURL(
                                          doc.imagemDocumento.blobFile
                                        )
                                      : null
                                  }
                                  alt="thumbnail"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={4}>
                              <Button
                                color="red"
                                size="md"
                                onClick={() => removeDocumento(index)}
                              >
                                🗑️
                              </Button>
                            </FlexboxGrid.Item>
                          </FlexboxGrid>
                        </List.Item>
                      ))}
                  </List>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="lg"
                appearance="ghost"
                onClick={() => setShowModalProp(false)}
              >
                Cancelar
              </Button>
              <Button size="lg" appearance="primary" type="submit">
                Salvar Documento
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <FlexboxGrid.Item colspan={18}>
          <Panel className="imovel-panel" shaded>
            <>
              <Text bold className="imovel-titulo">
                Endereço do Imóvel:
              </Text>
              <Text block className="imovel-info">
                Tipo imóvel:{" "}
                {tipoImovelInfo
                  ? `${tipoImovelInfo.tipoImovel} - ${tipoImovelInfo.label}`
                  : "Tipo não encontrado"}
              </Text>
              <Text block className="imovel-info">
                Rua: {aprovacaoLocatario.imovel.endereco.rua},{" "}
                {aprovacaoLocatario.imovel.endereco.numero}
              </Text>
              <Text block className="imovel-info">
                Bairro: {aprovacaoLocatario.imovel.endereco.bairro}
              </Text>
              <Text block className="imovel-info">
                Cidade: {aprovacaoLocatario.imovel.endereco.cidade}, Estado:{" "}
                {aprovacaoLocatario.imovel.endereco.estado}, CEP:{" "}
                {aprovacaoLocatario.imovel.endereco.cep}
              </Text>
              <Text block className="imovel-info">
                País: {aprovacaoLocatario.imovel.endereco.pais}
              </Text>
              {aprovacaoLocatario.imovel.endereco.complemento && (
                <Text block className="imovel-info">
                  Complemento: {aprovacaoLocatario.imovel.endereco.complemento}
                </Text>
              )}
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} align="middle">
          <Form.Group controlId="imovelAprovado">
            <Form.ControlLabel>Imóvel aprovado?</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="imovelAprovado"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <Divider />
        <FlexboxGrid.Item colspan={18}>
          <Heading level={3} className="titulo-fiador">
            Locatário(s)
          </Heading>
          <List size="lg" autoScroll bordered hover>
            {aprovacaoLocatario.locatarios &&
            aprovacaoLocatario?.locatarios.length === 0 ? (
              <List.Item>Sem locatários associados no momento</List.Item>
            ) : (
              aprovacaoLocatario?.locatarios?.map((doc, index) => (
                <List.Item key={index} index={index}>
                  <FlexboxGrid align="middle">
                    <FlexboxGrid.Item colspan={8}>{doc.nome}</FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>{doc.cpf}</FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>
                      <Button
                        size="lg"
                        appearance="ghost"
                        onClick={() => openModalDocumentos(doc)}
                      >
                        Ver documentos
                      </Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </List.Item>
              ))
            )}
          </List>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} align="middle">
          <Form.Group controlId="locatarioAprovado">
            <Form.ControlLabel>Locatário aprovado?</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="locatarioAprovado"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <Divider />
        <FlexboxGrid.Item colspan={18}>
          <Heading level={3} className="titulo-fiador">
            Fiador(es)
          </Heading>
          <List size="lg" autoScroll bordered hover>
            {aprovacaoLocatario.fiadores &&
            aprovacaoLocatario?.fiadores.length === 0 ? (
              <List.Item>Sem fiadores associados no momento</List.Item>
            ) : (
              aprovacaoLocatario?.fiadores?.map((doc, index) => (
                <List.Item key={index} index={index}>
                  <FlexboxGrid align="middle">
                    <FlexboxGrid.Item colspan={8}>{doc.nome}</FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>{doc.cpf}</FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>
                      <Button
                        size="lg"
                        appearance="ghost"
                        onClick={() => openModalDocumentos(doc)}
                      >
                        Ver documentos
                      </Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </List.Item>
              ))
            )}
          </List>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} align="middle">
          <Form.Group controlId="fiadorAprovado">
            <Form.ControlLabel>Fiador aprovado?</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="fiadorAprovado"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <Divider />
        <FlexboxGrid.Item colspan={18}>
          <Heading level={3} className="titulo-fiador">
            Proprietário(s)
          </Heading>
          <List size="lg" autoScroll bordered hover>
            {aprovacaoLocatario.imovel.proprietario &&
            aprovacaoLocatario?.imovel.proprietario.length === 0 ? (
              <List.Item>Sem proprietarios associados no momento</List.Item>
            ) : (
              aprovacaoLocatario?.imovel?.proprietario?.map((doc, index) => (
                <List.Item key={index} index={index}>
                  <FlexboxGrid align="middle">
                    <FlexboxGrid.Item colspan={8}>
                      {doc.proprietarioId.nome}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>
                      {doc.proprietarioId.cpf}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>
                      <Button
                        size="lg"
                        appearance="ghost"
                        onClick={() => openModalDocumentos(doc.proprietarioId)}
                      >
                        Ver documentos
                      </Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </List.Item>
              ))
            )}
          </List>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} align="middle">
          <Form.Group controlId="proprietarioAprovado">
            <Form.ControlLabel>Proprietário aprovado?</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="proprietarioAprovado"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Documentos;
