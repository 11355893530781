import React, { createContext, useState } from "react";
// arquivo onde você está utilizando validateCurrentStep
import { showError } from "../../components/Utils/toastUtils";

// Cria o contexto
export const AprovacaoLocatarioContext = createContext();

// Provedor do contexto
export const AprovacaoLocatarioProvider = ({ children }) => {
  const [aprovacaoLocatario, setAprovacaoLocatario] = useState({
    imovel: null,
    locatarios: [],
    moradores: [],
    fiadores: [],
  });

  const [stepData, setStepData] = useState({});

  const handleStepChange = (newData, step) => {
    let filteredData = null;
    switch (step) {
      case 1:
        filteredData = filterStep1Data(aprovacaoLocatario);
        break;
      case 2:
        filteredData = filterStep2Data(aprovacaoLocatario);
        break;
      case 3:
        filteredData = filterStep3Data(aprovacaoLocatario);
        break;
      case 4:
        filteredData = filterStep4Data(aprovacaoLocatario);
        break;
      case 5:
        filteredData = filterStep5Data(aprovacaoLocatario);
        break;
      case 6:
        filteredData = filterStep6Data(aprovacaoLocatario);
        break;
      default:
        // Para outros steps, siga o comportamento padrão

        break;
    }
    setStepData((prevData) => ({
      ...prevData,
      [`step${step}`]: filteredData, // Atualiza o step1 com os dados filtrados
    }));
  };

  const filterStep1Data = (aprovacaoLocatario) => {
    const {
      garantia,
      imovel,
      locatarios,
      moradores,
      valorAluguel,
      valorCondominio,
      valorIptu,
    } = aprovacaoLocatario;

    return {
      garantia,
      imovel,
      locatarios,
      moradores,
      valorAluguel,
      valorCondominio,
      valorIptu,
    };
  };

  const filterStep2Data = (aprovacaoLocatario) => {
    const { corretora, aprovado, valorParcela, seguradora, formaPagamento } =
      aprovacaoLocatario;

    return {
      corretora,
      aprovado,
      valorParcela,
      seguradora,
      formaPagamento,
    };
  };

  const filterStep3Data = (aprovacaoLocatario) => {
    const { fiadores, tipo, endereco, valor, numMatricula, numIptu } =
      aprovacaoLocatario;

    return {
      fiadores,
      tipo,
      endereco,
      valor,
      numMatricula,
      numIptu,
    };
  };

  const filterStep4Data = (aprovacaoLocatario) => {
    const {
      proprietarioAprovado,
      fiadorAprovado,
      locatarioAprovado,
      imovelAprovado,
    } = aprovacaoLocatario;

    return {
      proprietarioAprovado,
      fiadorAprovado,
      locatarioAprovado,
      imovelAprovado,
    };
  };

  const filterStep5Data = (aprovacaoLocatario) => {
    const {
      rateioObs,
      rateioAgua,
      rateioEnergia,
      valorAluguel,
      corretora,
      diaVencimentoAluguel,
      seguradora,
      valorTaxaLocacao,
      valorParcela,
      qtdParcelaTaxaLocacao,
      formaPagamento,
      percentualTaxaAdmMensal,
      valorCondominio,
      valorIptu,
      informacaoAdicional,
    } = aprovacaoLocatario;

    return {
      rateioObs,
      rateioAgua,
      rateioEnergia,
      valorAluguel,
      corretora,
      diaVencimentoAluguel,
      seguradora,
      valorTaxaLocacao,
      valorParcela,
      qtdParcelaTaxaLocacao,
      formaPagamento,
      percentualTaxaAdmMensal,
      valorCondominio,
      valorIptu,
      informacaoAdicional,
    };
  };

  const filterStep6Data = (aprovacaoLocatario) => {
    const {
      locatarioAvisado,
      cobrancaCondominio,
      proprietarioAvisado,
      responsavelFinanceiroCondominio,
      locacaoAprovada,
      cobrancaIptu,
      responsavelFinanceiroIptu,
      motivoDesaprovado,
      status,
    } = aprovacaoLocatario;

    return {
      locatarioAvisado,
      cobrancaCondominio,
      proprietarioAvisado,
      responsavelFinanceiroCondominio,
      locacaoAprovada,
      cobrancaIptu,
      responsavelFinanceiroIptu,
      motivoDesaprovado,
      status,
    };
  };

  const validateCurrentStep = (step, formRef) => {
    if (!formRef?.current) return false;

    // Executa a validação usando o ref do formulário
    const isValid = formRef.current.check();

    if (!isValid) return false;

    //Validações adicionais, opicional
    switch (step) {
      case 1:
        const { imovel, locatarios } = aprovacaoLocatario;
        // Verifica campos individuais
        if (imovel === null || locatarios.length === 0) {
          showError("Por favor preencha ao menos 1 item para cada lista.");
          return false; // Retorna falso se qualquer campo estiver inválido
        }
        break;
      case 3:
        const { fiadores } = aprovacaoLocatario;
        // Verifica campos individuais
        if (fiadores.length === 0) {
          showError("Por favor preencha ao menos 1 item para cada lista.");
          return false; // Retorna falso se qualquer campo estiver inválido
        }
        break;
      default:
    }

    return true; // Retorna true se não houver erros
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Aberto":
        return "status-aberto";
      case "Ganhou":
        return "status-ganhou";
      case "Perdeu":
        return "status-perdeu";
      case "Andamento":
        return "status-andamento";
      default:
        return "";
    }
  };

  return (
    <AprovacaoLocatarioContext.Provider
      value={{
        stepData,
        handleStepChange,
        validateCurrentStep,
        aprovacaoLocatario,
        setAprovacaoLocatario,
        filterStep1Data,
        filterStep2Data,
        filterStep3Data,
        filterStep4Data,
        filterStep5Data,
        filterStep6Data,
        getStatusClass,
      }}
    >
      {children}
    </AprovacaoLocatarioContext.Provider>
  );
};
