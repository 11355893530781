import React, { useContext } from "react";
import {
  FlexboxGrid,
  Radio,
  RadioGroup,
  Input,
  Form,
  InputGroup,
  DatePicker,
} from "rsuite";
import { ContratoLocacaoContext } from "./ContratoLocacaoContext";
import "./style.css";

const Vistoria = ({ handleFormChange }) => {
  const { contratoLocacao } = useContext(ContratoLocacaoContext); // Use o contexto correto

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="vistoriaLancada">
            <Form.ControlLabel>Vistoria lançada?</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="vistoriaLancada"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="dataVistoria">
            <Form.ControlLabel>Data Vistoria</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="dataVistoria"
              searchable={false}
              style={{ width: "100%" }}
              accepter={DatePicker}
              value={
                contratoLocacao.dataVistoria
                  ? new Date(contratoLocacao.dataVistoria)
                  : null
              }
              format="dd/MM/yyyy"
              placeholder="Data Vistoria"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4.5}>
          <Form.Group controlId="vistoriador">
            <Form.ControlLabel>Vistoriador</Form.ControlLabel>
            <InputGroup>
              <Form.Control
                size="lg"
                name="vistoriador"
                placeholder="Vistoriador"
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24}>
          <Form.Group controlId="obsVistoria">
            <Form.ControlLabel>Observações Vistoria</Form.ControlLabel>
            <Input
              as="textarea"
              rows={5}
              size="lg"
              name="obsVistoria"
              placeholder="Observações Vistoria"
              value={contratoLocacao.obsVistoria || ""} // Vincula o valor ao rateioObs
              onChange={(event) => {
                handleFormChange({
                  ...contratoLocacao,
                  obsVistoria: event,
                });
              }}
            />
          </Form.Group>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Vistoria;
