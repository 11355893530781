const types = {
  ALL_PESSOA: "@pessoa/ALL",
  UPDATE_PESSOA: "@pessoa/UPDATE",
  FILTER_PESSOAS: "@pessoa/FILTER",
  ADD_PESSOA: "@pessoa/ADD",
  SAVE_PESSOA: "@pessoa/SAVE",
  RESET_PESSOA: "@pessoa/RESET",
  UNLINK_PESSOA: "@pessoa/UNLINK",
};
export default types;
