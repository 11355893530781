const situacaoEscrituraData = [
  "Compra e venda",
  "Divisão amigável",
  "Usufruto",
  "Não possui",
  "Fazer Inventario",
].map((item) => ({
  label: item,
  value: item,
}));

module.exports = { situacaoEscrituraData };
