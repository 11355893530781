import {
  LOGIN_REQUEST,
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_FAILURE,
  LOGOUT,
} from "./types";

export const loginRequest = (payload) => ({
  type: LOGIN_REQUEST,
  payload,
});

export const authenticateSuccess = () => ({
  type: AUTHENTICATE_SUCCESS,
});

export const authenticateFailure = (error) => ({
  type: AUTHENTICATE_FAILURE,
  payload: error,
});

export const logout = () => ({
  type: LOGOUT,
});
