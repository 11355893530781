import { produce } from "immer";

import types from "./types";

const INITIAL_STATE = {
  behavior: "create",
  components: {
    drawer: false,
    confirmDelete: false,
  },
  form: {
    filtering: false,
    disabled: true,
    saving: false,
  },
  pessoas: [],
  pessoa: {
    nome: "",
    cpf: "",
    dataNascimento: null,
    email: "",
    pessoaTipoDocumento: "",
    pessoaTelefone: "",
    tipoTelefone: "",
    rua: "",
    pais: "",
    estado: "",
    cep: "",
    numero: "",
    cidade: "",
    bairro: "",
    complemento: "",

    // Trabalho
    profissao: "",
    salario: "",
    vinculoTrabalhista: "",
    enderecoLocalTrabalho: "",
    telefoneTrabalho: "",

    // Dados Bancários
    agencia: "",
    conta: "",
    tipoConta: "",
    favorecido: "",
    dadoBanCpf: "",

    // Referência e Conjuge
    conjuge: {
      cpf: "",
      rg: "",
      telefone: "",
    },

    refTipo: "",
    endereco: "",
    refTelefone: "",

    // Matrículas
    orgao: "",
    matricula: "",
    especie: "",
    senha: "",
    renda: null,

    // Documentos
    docTipo: "",
    imagemDocumento: "",

    // Fiscal
    issqn: "",
    descontoSimplificado: "",
  },
};

function pessoa(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_PESSOA: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_PESSOA: {
      return produce(state, (draft) => {
        draft.pessoa = INITIAL_STATE.pessoa;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default pessoa;
