import { Icon } from "@rsuite/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { InputPicker } from "rsuite";
import { updatePessoa } from "../../store/modules/pessoa/actions";
import { showError } from "../Utils/toastUtils"; // ajuste o caminho conforme necessário

const SearchFieldPessoas = ({ setPessoa, pessoasAssociadas = [] }) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null); // Estado para o valor selecionado

  const styles = { width: "100%" };

  const getUsers = (word) => {
    fetch(`${process.env.REACT_APP_API_URL}/pessoa/search?query=${word}`)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        // Filtra os itens que já estão na lista de locatários
        const filteredItems = data.filter(
          (item) =>
            !pessoasAssociadas.some((locatario) => locatario.cpf === item.cpf)
        );
        setItems(filteredItems);
      })
      .catch((e) => {
        console.log("Oops, error", e);
        setLoading(false);
      });
  };

  const handleSearch = (word) => {
    if (!word) {
      return;
    }
    setLoading(true);
    getUsers(word);
  };

  const dispatch = useDispatch();

  const handleBehaviorUpdate = (newBehavior) => {
    dispatch(updatePessoa({ behavior: newBehavior }));
  };

  const handleSelect = (value, item) => {
    if (pessoasAssociadas.some((locatario) => locatario.cpf === item.cpf)) {
      showError("Esta pessoa já está na lista.");
      return;
    }

    // Use the CPF value to fetch data from the backend
    axios
      .post(`${process.env.REACT_APP_API_URL}/pessoa/filter`, { cpf: item.cpf })
      .then((response) => {
        const pessoaData = response.data.pessoa;
        // console.log(pessoaData);
        // Dispatch the action to update the Redux state
        setPessoa(pessoaData);
        handleBehaviorUpdate("update");
      })
      .catch((error) => {
        console.error("Error fetching filtered data:", error);
      });
  };

  useEffect(() => {
    getUsers(""); // Initial fetch
  }, []);

  return (
    <InputPicker
      data={items}
      style={styles}
      labelKey="nome"
      valueKey="cpf"
      size="lg"
      placeholder="Selecione"
      locale={{
        noResultsText: "Não encontrado",
      }}
      onSearch={handleSearch}
      onSelect={handleSelect}
      value={selectedValue} // Controla o valor do InputPicker
      onChange={(value) => {
        setSelectedValue(value); // Atualiza o estado do valor selecionado
      }}
      renderMenu={(menu) => {
        if (loading) {
          return (
            <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
              <Icon icon="spinner" spin /> Carregando...
            </p>
          );
        }
        return menu;
      }}
    />
  );
};

export default SearchFieldPessoas;
