import React from "react";
import { Steps } from "rsuite";

const MySteps = ({ step, setStep }) => {
  return (
    <Steps current={step - 1} onChange={setStep}>
      <Steps.Item title="Início" />
      <Steps.Item title="Vistoria" />
      <Steps.Item title="Etapa Final" />
      <Steps.Item title="Cancelamento Seguros" />
      <Steps.Item title="Aguardando Reembolso" />
      <Steps.Item title="Repasse Final" />
    </Steps>
  );
};

export default MySteps;
