import { Icon } from "@rsuite/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { InputPicker } from "rsuite";
import { updateImovel } from "../../store/modules/imovel/actions";

const SearchFieldImoveis = ({ setImovel }) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const styles = { width: "100%" };

  const getImoveis = (word) => {
    fetch(`${process.env.REACT_APP_API_URL}/imovel/search?query=${word}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setLoading(false);
        setItems(data);
      })
      .catch((e) => {
        console.log("Oops, error", e);
        setLoading(false);
      });
  };

  const handleSearch = (word) => {
    if (!word) {
      return;
    }
    setLoading(true);
    getImoveis(word);
  };

  const dispatch = useDispatch();

  const handleBehaviorUpdate = (newBehavior) => {
    dispatch(updateImovel({ behavior: newBehavior }));
  };

  const handleSelect = (value, item) => {
    // Use o valor ID para buscar dados do backend
    axios
      .post(`${process.env.REACT_APP_API_URL}/imovel/filter`, { _id: item._id })
      .then((response) => {
        const imovelData = response.data.imovel;
        // console.log(imovelData);
        // Dispatch the action to update the Redux state
        setImovel(imovelData);
        handleBehaviorUpdate("update");
      })
      .catch((error) => {
        console.error("Error fetching filtered data:", error);
      });
  };

  useEffect(() => {
    getImoveis(""); // Initial fetch
  }, []);

  return (
    <InputPicker
      data={items}
      style={styles}
      labelKey="nome"
      valueKey="nome"
      size="lg"
      placeholder="Selecione"
      locale={{
        noResultsText: "Não encontrado",
      }}
      onSearch={handleSearch}
      onSelect={handleSelect}
      renderMenu={(menu) => {
        if (loading) {
          return (
            <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
              <Icon icon="spinner" spin /> Carregando...
            </p>
          );
        }
        return menu;
      }}
    />
  );
};

export default SearchFieldImoveis;
