const comodidadesData = [
  { name: "alarme", label: "Alarme" },
  { name: "arCondicionado", label: "Ar Condicionado" },
  { name: "elevadorPrivativo", label: "Elevador Privativo" },
  { name: "copa", label: "Copa" },
  { name: "cozinha", label: "Cozinha" },
  { name: "despensa", label: "Despensa" },
  { name: "lavanderia", label: "Lavanderia" },
  { name: "salaDeEstar", label: "Sala de Estar" },
  { name: "salaDeJantar", label: "Sala de Jantar" },
  { name: "salaDeTv", label: "Sala de TV" },
  { name: "escritorio", label: "Escritório" },
  { name: "moveisPlanejados", label: "Móveis Planejados" },
  { name: "dormitoriosComArmario", label: "Dormitórios com Armário" },
  { name: "quartoDeServico", label: "Quarto de Serviço" },
  { name: "mezanino", label: "Mezanino" },
  { name: "sacada", label: "Sacada" },
  { name: "areaGourmet", label: "Área Gourmet" },
];

module.exports = { comodidadesData };
