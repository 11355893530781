import React, { useContext, useState } from "react";
import {
  FlexboxGrid,
  Button,
  Radio,
  DatePicker,
  RadioGroup,
  Panel,
  Form,
  Modal,
  Uploader,
  InputGroup,
  Divider,
} from "rsuite";
import { ContratoLocacaoContext } from "./ContratoLocacaoContext";
import "./style.css";

const EntregaChaves = ({ handleGanhouClick }) => {
  const { contratoLocacao, setContratoLocacao } = useContext(
    ContratoLocacaoContext
  ); // Use o contexto correto

  const [showModal, setShowModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handlePreview = (file) => {
    const reader = new FileReader();
    if (typeof file === "string") {
      // Quando o arquivo for uma URL (string)
      setPreviewUrl(file);
      setShowModal(true);
    } else {
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file.blobFile);
    }
  };

  const getFileList = (tipoDocumento) => {
    return contratoLocacao[tipoDocumento]
      ? [contratoLocacao[tipoDocumento]]
      : [];
  };

  const handleImageChange = (files, tipoDocumento) => {
    if (files.length > 1) {
      files = [files[files.length - 1]]; // Mantém apenas o último arquivo
    }

    setContratoLocacao((prev) => ({
      ...prev,
      [tipoDocumento]: files[0] || null, // Armazena apenas o arquivo atual ou null
    }));
  };

  const styleButton = { alignSelf: "center" };

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="condominioContratoEnviadoAdministradora">
            <Form.ControlLabel>
              Caso haja Condomínio, Contrato de Locação enviado a
              Administradora?{" "}
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="condominioContratoEnviadoAdministradora"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="termoEntregaChaves">
            <Form.ControlLabel>
              Termo de entrega de chaves assinado?{" "}
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="termoEntregaChaves"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="chavesEntregues">
            <Form.ControlLabel>Chaves entregues?</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="chavesEntregues"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Modal.Header>
            <Modal.Title>Pré-visualização</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {previewUrl && (
              <img src={previewUrl} alt="Preview" style={{ width: "100%" }} />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button size="lg" onClick={() => setShowModal(false)}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
        <FlexboxGrid.Item colspan={12} align="center">
          <Panel className="imovel-panel" shaded>
            <>
              <Form.Group controlId="imagemTermoEntregaAssinadoField">
                <Form.ControlLabel>Termo de Entrega assinado</Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="imagemTermoEntregaAssinadoField"
                  fileList={getFileList("imagemTermoEntregaAssinadoField")}
                  maxFiles={1}
                  onPreview={handlePreview}
                  autoUpload={false}
                  listType="picture-text"
                  onChange={(files) =>
                    handleImageChange(files, "imagemTermoEntregaAssinadoField")
                  }
                  renderFileInfo={(file, fileElement) => {
                    return <div onClick={() => handlePreview(file)}></div>;
                  }}
                  accepter={Uploader}
                >
                  <Button appearance="primary" size="lg">
                    Selecione o arquivo
                  </Button>
                </Form.Control>
              </Form.Group>
            </>
          </Panel>
        </FlexboxGrid.Item>
        <Divider />
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="lancamentoSuperlogica">
            <Form.ControlLabel>Data de entrega de chaves </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="dataEntregaChaves"
              searchable={false}
              style={{ width: "100%" }}
              accepter={DatePicker}
              format="dd/MM/yyyy"
              value={
                contratoLocacao.dataEntregaChaves
                  ? new Date(contratoLocacao.dataEntregaChaves)
                  : null
              }
              placeholder="Data de entrega de chaves"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="lancamentoSuperlogica">
            <Form.ControlLabel>
              Lançamento no Superlógica conferidos?{" "}
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="lancamentoSuperlogica"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={4.5}>
          <Form.Group controlId="codigoContrato">
            <Form.ControlLabel>Código Contrato ADM</Form.ControlLabel>
            <InputGroup>
              <Form.Control
                size="lg"
                name="codigoContrato"
                placeholder="Código Contrato ADM"
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} />
        <FlexboxGrid.Item colspan={8} style={styleButton}>
          <Button
            size="lg"
            appearance="primary"
            color="green"
            className="button-spacing" // Adiciona a classe de estilo e espaçamento
            onClick={handleGanhouClick}
          >
            Ganhou
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default EntregaChaves;
