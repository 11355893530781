import types from "./types";

export function allPessoa() {
  return { type: types.ALL_PESSOA };
}

export function updatePessoa(payload) {
  return { type: types.UPDATE_PESSOA, payload };
}

export function filterPessoas() {
  return { type: types.FILTER_PESSOAS };
}

export function addPessoa() {
  return { type: types.ADD_PESSOA };
}

export function savePessoa() {
  return { type: types.SAVE_PESSOA };
}

export function resetPessoa() {
  return { type: types.RESET_PESSOA };
}

export function unlinkPessoa() {
  return { type: types.UNLINK_PESSOA };
}
