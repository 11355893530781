const baseURL = process.env.REACT_APP_API_URL; // URL base da sua API

// Função para recuperar o token do localStorage
const getToken = () => {
  return localStorage.getItem("token");
};

// Função fetch com autenticação automática
const fetchWithAuth = async (url, options = {}) => {
  const token = getToken();

  const headers = {
    ...options.headers,
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`; // Inclui o token no cabeçalho Authorization
  }

  const response = await fetch(`${baseURL}${url}`, {
    ...options,
    headers,
  });

  // Trata erros de resposta
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "An error occurred");
  }

  return response.json(); // Retorna o JSON da resposta
};

export { fetchWithAuth };
