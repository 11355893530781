import React, { useState, useContext } from "react";
import {
  FlexboxGrid,
  SelectPicker,
  Input,
  Panel,
  RadioGroup,
  Form,
  Radio,
  Button,
  Uploader,
} from "rsuite";
import { RescisaoContratoContext } from "./RescisaoContratoContext";
import { situacaoCpflDaeData } from "../../enums/situacaoCpflDaeData";

const EtapaFinal = ({ handleFormChange }) => {
  const { rescisaoContrato, setRescisaoContrato } = useContext(
    RescisaoContratoContext
  ); // Use o contexto correto
  const [showModal, setShowModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handlePreview = (file) => {
    const reader = new FileReader();
    if (typeof file === "string") {
      // Quando o arquivo for uma URL (string)
      setPreviewUrl(file);
      setShowModal(true);
    } else if (file.blobFile) {
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file.blobFile);
    } else {
      console.error("Arquivo inválido para preview:", file);
    }
  };

  const getFileList = (tipoDocumento) => {
    const fileList = rescisaoContrato[tipoDocumento]
      ? [rescisaoContrato[tipoDocumento]]
      : [];

    return fileList;
  };

  const handleImageChange = (files, tipoDocumento) => {
    if (!Array.isArray(files)) {
      console.error("Arquivos inválidos recebidos:", files);
      return;
    }

    if (files.length > 1) {
      files = [files[files.length - 1]]; // Mantém apenas o último arquivo
    }

    const file = files[0] || null;

    setRescisaoContrato((prev) => {
      const updatedState = {
        ...prev,
        [tipoDocumento]: file,
        [tipoDocumento.replace("Field", "")]: file.name,
      };

      return updatedState;
    });
  };

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={12}>
          <Form.Group controlId="situacaoCpfl">
            <Form.ControlLabel>CPFL</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="situacaoCpfl"
              data={situacaoCpflDaeData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="CPFL"
            />
          </Form.Group>
          <Form.Group controlId="situacaoDae">
            <Form.ControlLabel>DAE</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="situacaoDae"
              data={situacaoCpflDaeData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="DAE"
            />
          </Form.Group>
          <Form.ControlLabel>
            Ficaram débitos pendentes a receber?
          </Form.ControlLabel>
          <Form.Control
            size="lg"
            name="debitosPendentes"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
          <Form.ControlLabel>
            Declaração de Adimplência foi enviada para Seguradora?
          </Form.ControlLabel>
          <Form.Control
            size="lg"
            name="declaracaoEnviadaSeguradora"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
          <Form.ControlLabel>
            Enviados Débitos Finais em caso de Sinistro?
          </Form.ControlLabel>
          <Form.Control
            size="lg"
            name="debitosEnviadosSinistro"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12}>
          <Form.Group controlId="problemaTransferencia">
            <Form.ControlLabel>
              Problema para efetuar a transferência? Relate aqui:
            </Form.ControlLabel>
            <Input
              as="textarea"
              rows={15}
              size="lg"
              name="problemaTransferencia"
              placeholder="Problema para efetuar a transferência? Relate aqui"
              value={rescisaoContrato.problemaTransferencia || ""}
              onChange={(event) => {
                handleFormChange({
                  ...rescisaoContrato,
                  problemaTransferencia: event,
                });
              }}
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Panel className="imovel-panel" shaded>
            <>
              <Form.Group controlId="imagemDistratoField">
                <Form.ControlLabel>Distrato</Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="imagemDistratoField"
                  fileList={getFileList("imagemDistratoField")}
                  maxFiles={1}
                  onPreview={handlePreview}
                  autoUpload={false}
                  listType="picture-text"
                  onChange={(files) =>
                    handleImageChange(files, "imagemDistratoField")
                  }
                  renderFileInfo={(file, fileElement) => {
                    return <div onClick={() => handlePreview(file)}></div>;
                  }}
                  accepter={Uploader}
                >
                  <Button appearance="primary" size="lg">
                    Selecione o arquivo
                  </Button>
                </Form.Control>
              </Form.Group>
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Panel className="imovel-panel" shaded>
            <>
              <Form.Group controlId="imagemTermoConfissaoField">
                <Form.ControlLabel>
                  Termo de Confissão de Dívida
                </Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="imagemTermoConfissaoField"
                  fileList={getFileList("imagemTermoConfissaoField")}
                  maxFiles={1}
                  onPreview={handlePreview}
                  autoUpload={false}
                  listType="picture-text"
                  onChange={(files) =>
                    handleImageChange(files, "imagemTermoConfissaoField")
                  }
                  renderFileInfo={(file, fileElement) => {
                    return <div onClick={() => handlePreview(file)}></div>;
                  }}
                  accepter={Uploader}
                >
                  <Button appearance="primary" size="lg">
                    Selecione o arquivo
                  </Button>
                </Form.Control>
              </Form.Group>
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Panel className="imovel-panel" shaded>
            <>
              <Form.Group controlId="imagemAcordoParcelamentoField">
                <Form.ControlLabel>Acordo Parcelamento</Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="imagemAcordoParcelamentoField"
                  fileList={getFileList("imagemAcordoParcelamentoField")}
                  maxFiles={1}
                  onPreview={handlePreview}
                  autoUpload={false}
                  listType="picture-text"
                  onChange={(files) =>
                    handleImageChange(files, "imagemAcordoParcelamentoField")
                  }
                  renderFileInfo={(file, fileElement) => {
                    return <div onClick={() => handlePreview(file)}></div>;
                  }}
                  accepter={Uploader}
                >
                  <Button appearance="primary" size="lg">
                    Selecione o arquivo
                  </Button>
                </Form.Control>
              </Form.Group>
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Panel className="imovel-panel" shaded>
            <>
              <Form.Group controlId="imagemTermoChavesField">
                <Form.ControlLabel>
                  Termo de entrega de Chaves ou abandono
                </Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="imagemTermoChavesField"
                  fileList={getFileList("imagemTermoChavesField")}
                  maxFiles={1}
                  onPreview={handlePreview}
                  autoUpload={false}
                  listType="picture-text"
                  onChange={(files) =>
                    handleImageChange(files, "imagemTermoChavesField")
                  }
                  renderFileInfo={(file, fileElement) => {
                    return <div onClick={() => handlePreview(file)}></div>;
                  }}
                  accepter={Uploader}
                >
                  <Button appearance="primary" size="lg">
                    Selecione o arquivo
                  </Button>
                </Form.Control>
              </Form.Group>
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6}>
          <hr />
          <Form.ControlLabel>Proprietário assinou distrato?</Form.ControlLabel>
          <Form.Control
            size="lg"
            name="propAssinouDistrato"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
          <Form.ControlLabel>Locatário assinou distrato?</Form.ControlLabel>
          <Form.Control
            size="lg"
            name="locAssinouDistrato"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default EtapaFinal;
