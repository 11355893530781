import React, { useEffect, useState, useContext } from "react";
import {
  FlexboxGrid,
  Heading,
  Table,
  Input,
  Button,
  Content,
  ButtonGroup,
} from "rsuite";
import { fetchWithAuth } from "../../services/apiNoState";
import { showError } from "../../components/Utils/toastUtils";
import { useNavigate } from "react-router-dom";
import EditIcon from "@rsuite/icons/Edit";
import { TransferenciaContasContext } from "./TransferenciaContasContext";
import { contasTransferencia } from "../../enums/contasTransferencia";
import SearchFieldContrato from "../../components/Search/SearchFieldContrato";

const { Column, HeaderCell, Cell } = Table;

const ListaTransferenciaContas = () => {
  const [transferencias, setTransferencias] = useState([]);
  const [filteredTransferencias, setFilteredTransferencias] = useState([]);
  const [activeFilter, setActiveFilter] = useState("mostrarTodos");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [contratoSelecionado, setContratoSelecionado] = useState([]);

  const { getStatusClass } = useContext(TransferenciaContasContext);

  // Função para carregar transferências
  useEffect(() => {
    const fetchTransferencias = async () => {
      try {
        const response = await fetchWithAuth("/transferenciaContas", {
          method: "GET",
        });
        if (response.error) {
          showError("Erro ao carregar transferências:", response.message);
        } else {
          setTransferencias(response.transferenciaContas);
          setFilteredTransferencias(response.transferenciaContas);
        }
      } catch (error) {
        showError("Erro ao buscar transferências: " + error);
      }
    };
    fetchTransferencias();
  }, []);

  const handleFilterByConta = (contaLabel) => {
    const filtered = transferencias.filter((transferencia) =>
      transferencia.detalhesTransferencia.some(
        (detalhe) => detalhe.conta === contaLabel && detalhe.status === "Não"
      )
    );
    setFilteredTransferencias(filtered);
    setActiveFilter(contaLabel);
  };

  const handleShowAll = () => {
    setFilteredTransferencias(transferencias);
    setSearch(""); // Reseta o campo de busca
    setActiveFilter("mostrarTodos");
  };

  const contas = Array.from(
    new Set(contasTransferencia.map((conta) => conta.label))
  );

  const handleSetContrato = (contratoData) => {
    setContratoSelecionado(contratoData);
  };

  return (
    <Content className="m-2">
      <Heading level={3} className="titulo-imovel">
        Lista de Transferências
      </Heading>
      <Input
        placeholder="Buscar por endereço"
        value={search}
        onChange={setSearch}
        style={{ marginBottom: 10, width: 300 }}
      />

      {/* Botões de filtro */}
      <ButtonGroup style={{ marginBottom: 10 }}>
        {contas.map((contaLabel) => (
          <Button
            key={contaLabel}
            appearance={activeFilter === contaLabel ? "primary" : "default"}
            onClick={() => handleFilterByConta(contaLabel)}
          >
            {`Filtrar ${contaLabel}`}
          </Button>
        ))}
        <Button
          appearance={activeFilter === "mostrarTodos" ? "primary" : "default"}
          onClick={handleShowAll}
        >
          Mostrar Todos
        </Button>
      </ButtonGroup>

      <Table
        height={400}
        data={filteredTransferencias}
        rowHeight={60}
        loading={false}
      >
        <Column width={200} align="center" sortable>
          <HeaderCell>Rua e Número</HeaderCell>
          <Cell>
            {(rowData) => {
              const endereco = rowData.contratoId.imovel?.endereco || {};
              return `${endereco.rua || "Sem informação"} - ${
                endereco.numero || ""
              }`.trim();
            }}
          </Cell>
        </Column>

        <Column width={200} align="center" sortable>
          <HeaderCell>Bairro</HeaderCell>
          <Cell>
            {(rowData) =>
              rowData.contratoId.imovel?.endereco?.bairro || "Sem informação"
            }
          </Cell>
        </Column>

        <Column width={200} align="center" sortable>
          <HeaderCell>Status</HeaderCell>
          <Cell>
            {(rowData) => (
              <span
                className={`status-value ${getStatusClass(rowData.status)}`}
              >
                {rowData.status || "Sem informação"}
              </span>
            )}
          </Cell>
        </Column>

        <Column width={200} fixed="right">
          <HeaderCell>Ação</HeaderCell>
          <Cell>
            {(rowData) => (
              <EditIcon
                appearance="link"
                className="icon-big icon-link"
                onClick={() =>
                  navigate("/transferenciaContas", {
                    state: { transferencia: rowData },
                  })
                }
              />
            )}
          </Cell>
        </Column>
      </Table>
      <FlexboxGrid.Item colspan={12}>
        <Heading level={3} className="titulo-imovel">
          Selecione um Contrato
        </Heading>
        <SearchFieldContrato setContrato={handleSetContrato} />
      </FlexboxGrid.Item>
      <Button
        appearance="primary"
        onClick={() =>
          navigate("/transferenciaContas", {
            state: { contrato: contratoSelecionado },
          })
        }
      >
        Cadastrar Nova Transferência
      </Button>
    </Content>
  );
};

export default ListaTransferenciaContas;
