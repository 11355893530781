export const contasTransferencia = [
  { label: "Água", value: "agua_transferencia", group: "Transferência" },
  {
    label: "Energia",
    value: "energia_transferencia",
    group: "Transferência",
  },
  { label: "Gás", value: "gas_transferencia", group: "Transferência" },
  { label: "Água", value: "agua_imputacao", group: "Imputação" },
  { label: "Energia", value: "energia_imputacao", group: "Imputação" },
  { label: "Gás", value: "gas_imputacao", group: "Imputação" },
  { label: "Água", value: "agua_desligamento", group: "Desligamento" },
  { label: "Energia", value: "energia_desligamento", group: "Desligamento" },
  { label: "Gás", value: "gas_desligamento", group: "Desligamento" },
];
