const matriculaOrgaoData = [
  "Publico",
  "Estadual",
  "Federal",
  "Municipal",
  "INSS",
].map((item) => ({
  label: item,
  value: item,
}));

module.exports = { matriculaOrgaoData };
