import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { logout } from "../../store/modules/auth/actions"; // Importe a ação de logout

const useIdleTimer = (timeout = 180000) => {
  // timeout em milissegundos, 180000ms = 3 minutos
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState(timeout);

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setTimeLeft(timeout);
    timerRef.current = setTimeout(() => {
      dispatch(logout());
    }, timeout);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prev) => prev - 1000);
    }, 1000);

    const handleUserActivity = () => {
      resetTimer();
    };

    //window.addEventListener("mousemove", handleUserActivity);
    //window.addEventListener("keydown", handleUserActivity);
    //window.addEventListener("scroll", handleUserActivity);
    window.addEventListener("click", handleUserActivity);

    resetTimer(); // Inicializa o timer quando o componente monta

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      clearInterval(interval);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("scroll", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
    };
  }, [dispatch, timeout]);

  return timeLeft;
};

export default useIdleTimer;
