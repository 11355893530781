import { Schema } from "rsuite";

import { contaBancariaData } from "../../enums/contaBancariaData";
import { garantidoData } from "../../enums/garantidoData";
import { modalidadeData } from "../../enums/modalidadeData";
import { prazoRepasseData } from "../../enums/prazoRepasseData";

const { StringType, NumberType, ObjectType, DateType, ArrayType } =
  Schema.Types;

export const step1Schema = Schema.Model({
  rateioAgua: StringType()
    .isRequired("Rateio de água é obrigatório")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'"
    ),

  rateioEnergia: StringType()
    .isRequired("Rateio de energia é obrigatório")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'"
    ),

  valorAluguel: NumberType()
    .isRequired("Valor do aluguel é obrigatório")
    .min(0, "O valor deve ser positivo"),

  corretora: StringType()
    .isRequired("Corretora é obrigatória")
    .addRule(
      (value) => ["Corretora1", "Corretora2"].includes(value),
      "Escolha uma corretora válida"
    ),

  diaVencimentoAluguel: StringType().isRequired(
    "Dia de vencimento é obrigatório"
  ),

  seguradora: StringType()
    .isRequired("Seguradora é obrigatória")
    .addRule(
      (value) => ["Seguradora1", "Seguradora2"].includes(value),
      "Escolha uma seguradora válida"
    ),

  valorTaxaLocacao: NumberType()
    .isRequired("Valor da taxa de locação é obrigatório")
    .min(0, "O valor deve ser positivo"),

  valorParcelaSeguro: NumberType()
    .isRequired("Valor da parcela do seguro é obrigatório")
    .min(0, "O valor deve ser positivo"),

  qtdParcelaTaxaLocacao: NumberType()
    .isRequired("Quantidade de parcelas da taxa de locação é obrigatória")
    .min(1, "Deve haver pelo menos 1 parcela"),

  formaPagamento: StringType()
    .isRequired("Forma de pagamento é obrigatória")
    .addRule(
      (value) => ["Crédito", "Débito"].includes(value),
      "Escolha entre 'Crédito' ou 'Débito'"
    ),

  percentualTaxaAdmMensal: NumberType()
    .isRequired("Percentual da taxa de administração é obrigatório")
    .min(0, "O valor deve ser positivo"),

  valorCondominio: NumberType()
    .isRequired("Valor do condomínio é obrigatório")
    .min(0, "O valor deve ser positivo"),

  valorIptu: NumberType()
    .isRequired("Valor do IPTU é obrigatório")
    .min(0, "O valor deve ser positivo"),

  cobrancaCondominio: StringType()
    .isRequired("Cobrança de condomínio é obrigatória")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'"
    ),

  responsavelFinanceiroCondominio: StringType()
    .isRequired("Responsável financeiro pelo condomínio é obrigatório")
    .addRule(
      (value) => ["Não há", "Imobiliária", "Sindíco"].includes(value),
      "Escolha uma opção válida"
    ),

  cobrancaIptu: StringType()
    .isRequired("Cobrança de IPTU é obrigatória")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'"
    ),

  responsavelFinanceiroIptu: StringType()
    .isRequired("Responsável financeiro pelo IPTU é obrigatório")
    .addRule(
      (value) => ["Não há", "Imobiliária", "Sindíco"].includes(value),
      "Escolha uma opção válida"
    ),
});

export const step2Schema = Schema.Model({
  dadosCadastraisConferidos: StringType()
    .isRequired("O campo 'Dados Cadastrais Conferidos' é obrigatório.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'"
    ),
  contasConsumoConferido: StringType()
    .isRequired("O campo 'Contas Consumo Conferido' é obrigatório.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'"
    ),
  imagemCpflField: ObjectType().isRequired(
    "O arquivo de imagem CPFL é obrigatório."
  ),
  imagemDaeField: ObjectType().isRequired(
    "O arquivo de imagem DAE é obrigatório."
  ),
  imagemCondominioField: ObjectType().isRequired(
    "O arquivo de imagem do Condomínio é obrigatório."
  ),
  imagemGasField: ObjectType().isRequired(
    "O arquivo de imagem de Gás é obrigatório."
  ),
  entraDimob: StringType()
    .isRequired("O campo 'Entra Dimob' é obrigatório.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'"
    ),
  cadastradoSuperlogica: StringType()
    .isRequired("O campo 'Cadastrado no Superlógica' é obrigatório.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'"
    ),
});

export const step3Schema = Schema.Model({
  inicioContrato: DateType()
    .isRequired("A data de início do contrato é obrigatória.")
    .min(new Date("1900-01-01"), "A data deve ser válida."),

  validadeContrato: NumberType()
    .isRequired("A prazo do Contrato é obrigatória.")
    .min(0, "O valor deve ser positivo."),

  valorTaxaLocacao: NumberType()
    .isRequired("O valor da taxa de locação é obrigatório.")
    .min(0, "O valor deve ser positivo."),

  isentoMultaRescisoria: StringType()
    .isRequired("Informe se há isenção de multa rescisória.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'."
    ),

  indiceReajuste: StringType()
    .isRequired("O índice de reajuste é obrigatório.")
    .addRule(
      (value) => ["IGPM", "IPCA", "Outros"].includes(value),
      "Escolha um índice válido (IGPM, IPCA ou Outros)."
    ),

  isentoApos: NumberType()
    .isRequired("Informe o número de meses para isenção.")
    .min(0, "O valor deve ser positivo ou zero."),

  apoliceGerada: StringType()
    .isRequired("Informe se a apólice foi gerada.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'."
    ),

  modalidade: StringType()
    .isRequired("O modalidade é obrigatória.")
    .addRule(
      (value) => modalidadeData.some((item) => item.value === value),
      "Valor inválido para modalidade."
    ),

  garantido: StringType()
    .isRequired("O garantido é obrigatória.")
    .addRule(
      (value) => garantidoData.some((item) => item.value === value),
      "Valor inválido para garantido."
    ),

  contaBancaria: StringType()
    .isRequired("A conta bancaria é obrigatória.")
    .addRule(
      (value) => contaBancariaData.some((item) => item.value === value),
      "Valor inválido para conta bancaria."
    ),
  prazoRepasse: StringType()
    .isRequired("O prazo repasse é obrigatório.")
    .addRule(
      (value) => prazoRepasseData.some((item) => item.value === value),
      "Valor inválido para prazo repasse."
    ),
  contratoLocacaoGerado: StringType()
    .isRequired("Informe se o contrato de locação foi gerado.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'."
    ),

  contratoAdmGerado: StringType()
    .isRequired("Informe se o contrato de administração foi gerado.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'."
    ),

  clausulaEspecial: StringType()
    .isRequired("Informe se há cláusula especial.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'."
    ),

  clausulaEspecialObs: StringType().addRule(
    (value, data) => (data.clausulaEspecial === "Sim" ? !!value?.trim() : true),
    "As observações são obrigatórias se houver cláusula especial."
  ),

  obsContrato: StringType().maxLength(
    500,
    "As observações devem ter no máximo 500 caracteres."
  ),
});

export const step4Schema = Schema.Model({
  vistoriaLancada: StringType()
    .isRequired("Informe se a vistoria foi lançada.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'."
    ),

  dataVistoria: DateType()
    .isRequired("A data da vistoria é obrigatória.")
    .min(new Date("1900-01-01"), "A data deve ser válida."),

  vistoriador: StringType()
    .isRequired("O nome do vistoriador é obrigatório.")
    .minLength(3, "O nome deve ter pelo menos 3 caracteres.")
    .maxLength(100, "O nome deve ter no máximo 100 caracteres."),

  obsVistoria: StringType().maxLength(
    500,
    "As observações devem ter no máximo 500 caracteres."
  ),
});

export const step5Schema = Schema.Model({
  seguradoraInc: StringType()
    .isRequired("A seguradora é obrigatória.")
    .minLength(3, "A seguradora deve ter pelo menos 3 caracteres."),

  apoliceSeguroIncendioGerada: StringType()
    .isRequired("Informe se a apólice foi gerada.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'."
    ),

  imagemSeguroIncendioField: ObjectType().isRequired(
    "A imagem do seguro é obrigatória."
  ),

  apoliceSeguroIncendio: StringType()
    .isRequired("O número da apólice é obrigatório.")
    .minLength(5, "O número da apólice deve ter pelo menos 5 caracteres."),

  dataSeguroIncendio: DateType()
    .isRequired("A data do seguro é obrigatória.")
    .range(new Date("2000-01-01"), new Date("2100-12-31"), "Data inválida."),

  valorSeguroIncendio: NumberType()
    .isRequired("O valor do seguro é obrigatório.")
    .min(0, "O valor deve ser positivo."),

  vencimentoSeguroIncendio: DateType()
    .isRequired("A data de vencimento é obrigatória.")
    .addRule(
      (value, formData) =>
        new Date(value) > new Date(formData.dataSeguroIncendio),
      "A data de vencimento deve ser após a data do seguro."
    ),

  obsSeguroIncendio: StringType().maxLength(
    500,
    "As observações devem ter no máximo 500 caracteres."
  ),
});

export const step6Schema = Schema.Model({
  modeloAssinatura: StringType()
    .isRequired("O modelo de assinatura é obrigatório.")
    .addRule(
      (value) => ["Contrato Digital", "Contrato Físico"].includes(value),
      "Escolha entre 'Contrato Digital' ou 'Contrato Físico'."
    ),

  situacaoDocumentos: ArrayType()
    .isRequired("A situação dos documentos é obrigatória.")
    .addRule((value) => {
      const validSituations = [
        "Baixados",
        "Enviados",
        "Assinados",
        "Conferida",
      ];
      return (
        Array.isArray(value) &&
        value.every((item) => validSituations.includes(item))
      );
    }, "Os valores devem ser 'baixados', 'enviados', 'assinados' ou 'conferida'."),

  obsContrato: StringType().maxLength(
    500,
    "As observações devem ter no máximo 500 caracteres."
  ),

  imagemLocacaoField: ObjectType().isRequired(
    "A imagem da locação é obrigatória."
  ),
  imagemVistoriaField: ObjectType().isRequired(
    "A imagem da vistoria é obrigatória."
  ),
  imagemAdministracaoField: ObjectType().isRequired(
    "A imagem da administração é obrigatória."
  ),
});

export const step7Schema = Schema.Model({
  condominioContratoEnviadoAdministradora: StringType()
    .isRequired(
      "Informe se o Caso haja Condomínio, Contrato de Locação enviado a Administradora? foi lançada."
    )

    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'."
    ),
  termoEntregaChaves: StringType()
    .isRequired(
      "Informe se o Termo de entrega de chaves assinado? foi lançada."
    )
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'."
    ),
  chavesEntregues: StringType()
    .isRequired("Informe se o Chaves entregues? foi lançada.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'."
    ),
  imagemTermoEntregaAssinadoField: ObjectType().isRequired(
    "A imagem do termo de entrega assinado é obrigatória"
  ),
  lancamentoSuperlogica: StringType()
    .isRequired(
      "Informe se o Lançamento no Superlógica conferidos? foi lançada."
    )
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "Escolha entre 'Sim' ou 'Não'."
    ),
  codigoContrato: StringType().isRequired(
    "Informe se o Código Contrato ADM foi lançada."
  ),
});
