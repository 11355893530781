import React, { useContext, useState } from "react";
import {
  FlexboxGrid,
  RadioGroup,
  Form,
  Radio,
  SelectPicker,
  Modal,
  Button,
} from "rsuite";
import { AprovacaoLocatarioContext } from "./AprovacaoLocatarioContext";

const Aprovacao = ({ handleSubmit, setFormDisabled }) => {
  const { aprovacaoLocatario, setAprovacaoLocatario } = useContext(
    AprovacaoLocatarioContext
  ); // Use o contexto correto

  const [motivoDesaprovadoPerdeu, setMotivoDesaprovadoPerdeu] = useState(
    aprovacaoLocatario?.aprovacaoId?.motivoDesaprovado || null
  );

  const [showModalGanhou, setShowModalGanhou] = useState(false);
  const [showModalPerdeu, setShowModalPerdeu] = useState(false);
  const [locacaoAprovada, setLocacaoAprovada] = useState(
    aprovacaoLocatario?.aprovacaoId?.locacaoAprovada || ""
  ); // Estado para guardar a seleção

  const responsavelFinanceiroCondominioData = [
    "Não há",
    "Imobiliária",
    "Sindíco",
  ].map((item) => ({
    label: item,
    value: item,
  }));

  const responsavelFinanceiroIptuData = [
    "Não há",
    "Imobiliária",
    "Sindíco",
  ].map((item) => ({
    label: item,
    value: item,
  }));

  const motivoDesaprovadoData = [
    "Imóvel locado",
    "Desistencia proprietário",
    "Desistencia locatário",
  ].map((item) => ({
    label: item,
    value: item,
  }));

  const handleRadioChange = (value) => {
    if (value === "Sim") {
      setShowModalGanhou(true); // Abre o modal de "Ganhou"
    } else if (value === "Não") {
      setShowModalPerdeu(true); // Abre o modal de "Perdeu"
    }
    //Volta o status anterior, pois o status só mudará caso o usuário confirme a mudança
    setLocacaoAprovada((prev) => prev);
  };

  const handleConfirmGanhou = async () => {
    const submitSuccess = await handleSubmit("Ganhou"); // Aguarda a finalização do submit
    if (submitSuccess) {
      setLocacaoAprovada("Sim");
      setShowModalGanhou(false); // Fecha o modal
      setFormDisabled(true);
    }
  };

  const handleConfirmPerdeu = async () => {
    const submitSuccess = await handleSubmit("Perdeu"); // Aguarda a finalização do submit
    if (submitSuccess) {
      setLocacaoAprovada("Não");
      setShowModalPerdeu(false); // Fecha o modal
      setFormDisabled(true);
    }
  };

  return (
    <>
      <FlexboxGrid>
        <Modal open={showModalGanhou} onClose={() => setShowModalGanhou(false)}>
          <Modal.Header>
            <Modal.Title>Alterar status para "Ganhou"</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Tem certeza que deseja alterar o status para "Ganhou"?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="lg"
              appearance="ghost"
              onClick={() => setShowModalGanhou(false)}
            >
              Cancelar
            </Button>
            <Button
              size="lg"
              appearance="primary"
              onClick={handleConfirmGanhou}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal de confirmação de "Perdeu" */}
        <Modal open={showModalPerdeu} onClose={() => setShowModalPerdeu(false)}>
          <Modal.Header>
            <Modal.Title className="modal-title">
              Alterar status para "Perdeu"
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="modal-message">
              Tem certeza que deseja alterar o status para "Perdeu"?
            </p>
            <hr />
            {/* Campo de Motivo Desaprovado para "Perdeu" */}
            <Form fluid>
              <Form.Group controlId="motivoDesaprovado">
                <Form.ControlLabel>Motivo Desaprovado</Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="motivoDesaprovado"
                  data={motivoDesaprovadoData}
                  accepter={SelectPicker}
                  searchable={false}
                  style={{ width: "100%" }}
                  placeholder="Motivo Desaprovado"
                  value={motivoDesaprovadoPerdeu}
                  onChange={setMotivoDesaprovadoPerdeu} // Atualiza o estado do motivo de "Perdeu"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              appearance="ghost"
              onClick={() => setShowModalPerdeu(false)}
            >
              Cancelar
            </Button>
            <Button appearance="primary" onClick={handleConfirmPerdeu}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>

        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="locatarioAvisado">
            <Form.ControlLabel>
              Locatário foi avisado e continua com a locação?
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="locatarioAvisado"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="cobrancaCondominio">
            <Form.ControlLabel>
              Vai cobrar condominio do locatário?
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="cobrancaCondominio"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8} />
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="proprietarioAvisado">
            <Form.ControlLabel>
              Proprietário foi avisado sobre a aprovação?
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="proprietarioAvisado"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="responsavelFinanceiroCondominio">
            <Form.ControlLabel>
              Quem é o responsável financeiro pelo condomínio?
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="responsavelFinanceiroCondominio"
              data={responsavelFinanceiroCondominioData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Responsavel Financeiro"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="locacaoAprovada">
            <Form.ControlLabel>A locação está aprovada?</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="locacaoAprovada"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
              value={locacaoAprovada}
              onChange={handleRadioChange}
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="cobrancaIptu">
            <Form.ControlLabel>Vai cobrar IPTU do locatário?</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="cobrancaIptu"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="responsavelFinanceiroIptu">
            <Form.ControlLabel>
              Quem é o responsável financeiro pelo IPTU?
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="responsavelFinanceiroIptu"
              data={responsavelFinanceiroIptuData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Responsavel Financeiro IPTU"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="motivoDesaprovado">
            <Form.ControlLabel>Motivo desaprovado</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="motivoDesaprovado"
              data={motivoDesaprovadoData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Motivo Desaprovado"
              value={motivoDesaprovadoPerdeu}
              onChange={setMotivoDesaprovadoPerdeu} // Atualiza o estado do motivo de "Perdeu"
            />
          </Form.Group>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Aprovacao;
