const tipoSinistroData = [
  "Aluguel Atrasado",
  "Débitos Finais",
  "Rescisão Contratual",
].map((item) => ({
  label: item,
  value: item,
}));

module.exports = { tipoSinistroData };
