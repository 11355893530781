import {
  FlexboxGrid,
  Form,
  Button,
  Divider,
  ButtonGroup,
  Content,
  Text,
  Modal,
  SelectPicker,
} from "rsuite";
import { useRef, useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MySteps from "./MySteps";
import MyComponent from "./MyComponent";
import { AprovacaoLocatarioContext } from "./AprovacaoLocatarioContext";
import { fetchWithAuth } from "../../services/apiNoState";
import {
  step1Schema,
  step2Schema,
  step3Schema,
  step4Schema,
  step5Schema,
  step6Schema,
} from "./validacao";
import { showError, showSuccess } from "../../components/Utils/toastUtils";
import { useLocation } from "react-router-dom";

const AprovacaoLocatario = () => {
  const {
    aprovacaoLocatario,
    setAprovacaoLocatario,
    validateCurrentStep,
    stepData,
    handleStepChange,
    filterStep1Data,
    filterStep2Data,
    filterStep3Data,
    filterStep4Data,
    filterStep5Data,
    filterStep6Data,
    getStatusClass,
  } = useContext(AprovacaoLocatarioContext);

  const [step, setStep] = useState(1);

  const [formError, setFormError] = useState({});
  const { behavior } = useSelector((state) => state.aprovacaoLocatario);
  const [formDisabled, setFormDisabled] = useState(false); // Estado para controlar o formulário

  const location = useLocation();
  const aprovacaoParam = location.state?.aprovacao; // Acessa 'aprovacao' de 'location.state'
  const [showModalPerdeu, setShowModalPerdeu] = useState(false);
  const [motivoDesaprovadoPerdeu, setMotivoDesaprovadoPerdeu] = useState(null);

  const formRef = useRef();

  // Bloquear o step com base na seleção da garantia
  const isFiadorSelected = aprovacaoLocatario?.garantia === "Fiador";
  const isSeguroSelected = aprovacaoLocatario?.garantia === "Seguro fiança";

  const motivoDesaprovadoData = [
    "Imóvel locado",
    "Desistencia proprietário",
    "Desistencia locatário",
  ].map((item) => ({
    label: item,
    value: item,
  }));

  useEffect(() => {
    const carregarAprovacaoLocatario = async () => {
      if (aprovacaoParam) {
        // Atualiza o estado com os dados vindos de `aprovacaoParam`
        setAprovacaoLocatario((prev) => {
          let updates = {
            ...aprovacaoParam,
            locatarios: aprovacaoParam.locatario
              ? aprovacaoParam.locatario
              : [],
            moradores: aprovacaoParam.morador ? aprovacaoParam.morador : [],
            fiadores: aprovacaoParam.fiador ? aprovacaoParam.fiador : [],
            proprietarios: aprovacaoParam.proprietario
              ? aprovacaoParam.proprietario
              : [],
          };

          // Valida e faz o spread das informações de seguradoraId, se existir
          if (aprovacaoParam.seguradoraId) {
            updates = { ...aprovacaoParam.seguradoraId, ...updates };
          }

          // Valida e faz o spread das informações de imovelGarantiaId, se existir
          if (aprovacaoParam.imovelGarantiaId) {
            updates = { ...aprovacaoParam.imovelGarantiaId, ...updates };
          }

          // Valida e faz o spread das informações de aprovacaoId, se existir
          if (aprovacaoParam.aprovacaoId) {
            updates = { ...aprovacaoParam.aprovacaoId, ...updates };
          }

          //Valida se existe um motivoDesaprovado, e se o mesmo está vazio
          if (aprovacaoParam.status === "Perdeu") {
            setFormPerdeu();
          } else if (aprovacaoParam.status === "Ganhou") {
            setFormGanhou();
          } else {
            handleAndamentoClick(); // Se nenhuma das condições acima for atendida
          }

          return updates;
        });

        // Filtra os dados de step1
        const filteredData = filterStep1Data(aprovacaoParam); // Usa aprovacaoParam para filtrar corretamente

        // Atualiza o step data com os dados filtrados
        handleStepChange(filteredData, step);
      }
    };

    carregarAprovacaoLocatario();
  }, []);

  const handleFormChange = (formValue) => {
    setAprovacaoLocatario((prevState) => ({
      ...prevState,
      ...formValue,
    }));
  };

  const handleSubmit = async (status = aprovacaoLocatario.status) => {
    if (validateCurrentStep(step, formRef)) {
      if (!aprovacaoLocatario._id) {
        handleAndamentoClick();

        try {
          // Salva a aprovação caso o ID não exista
          const savedAprovacao = await saveAprovacaoLocatario(
            aprovacaoLocatario
          );
          // Atualiza o aprovacaoLocatario com o ID retornado
          setAprovacaoLocatario((prev) => ({
            ...prev,
            _id: savedAprovacao.id, // ou o campo correspondente retornado pela função
          }));
          return true;
        } catch (error) {
          console.error("Erro ao salvar a aprovação do locatário:", error);
          return false; // Interrompe se houver erro ao salvar
        }
      } else {
        checkForChanges(step, status); // Verifica se houve alterações
        return true;
      }
    } else {
      return false;
    }
  };

  const checkForChanges = async (step, status) => {
    let currentData;

    switch (step) {
      case 1:
        currentData = filterStep1Data(aprovacaoLocatario); // Filtra os dados relevantes do step 1
        break;
      case 2:
        currentData = filterStep2Data(aprovacaoLocatario); // Filtra os dados relevantes do step 2
        break;
      case 3:
        currentData = filterStep3Data(aprovacaoLocatario); // Filtra os dados relevantes do step 3
        break;
      case 4:
        currentData = filterStep4Data(aprovacaoLocatario); // Filtra os dados relevantes do step 4
        break;
      case 5:
        currentData = filterStep5Data(aprovacaoLocatario); // Filtra os dados relevantes do step 5
        break;
      case 6:
        currentData = filterStep6Data(aprovacaoLocatario); // Filtra os dados relevantes do step 6
        break;
      default:
        currentData = aprovacaoLocatario[`step${step}`]; // Use os dados completos para outros steps
    }

    const savedData = stepData[`step${step}`]; // Dados salvos para o step atual

    // Compara os dados salvos com os dados atuais
    const hasChanges =
      JSON.stringify(currentData) !== JSON.stringify(savedData);
    if (hasChanges) {
      await atualizarAprovacaoLocatario(status); // Chama o método separado
      // Se houver mudanças, salva as alterações no stepData
      handleStepChange(aprovacaoLocatario, step);
    }
  };

  const saveAprovacaoLocatario = async (aprovacaoLocatario) => {
    try {
      const response = await fetchWithAuth("/aprovacaoLocatario", {
        method: "POST",
        body: JSON.stringify(aprovacaoLocatario), // Salva os dados
        headers: {
          "Content-Type": "application/json", // Especifica o tipo de conteúdo
        },
      });

      if (response.error) {
        showError(
          "Erro ao salvar a aprovação do locatário: ",
          response.message
        );
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Aprovação de locatário parcial salvo");
      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao salvar a aprovação do locatário: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  const atualizarAprovacaoLocatario = async (status) => {
    try {
      aprovacaoLocatario.step = step;
      //Caso o handleSubmit seja chamado pelo aprovação está aprovada?, então alterar o status
      if (status === "Perdeu" || status === "Ganhou") {
        aprovacaoLocatario.status = status;
      }
      const response = await fetchWithAuth(
        `/aprovacaoLocatario/${aprovacaoLocatario._id}`,
        {
          method: "PUT",
          body: JSON.stringify(aprovacaoLocatario), // Salva os dados
          headers: {
            "Content-Type": "application/json", // Especifica o tipo de conteúdo
          },
        }
      );

      if (response.error) {
        showError(
          "Erro ao alterar a aprovação do locatário: ",
          response.message
        );
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Aprovação de locatário parcial alterado");
      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao salvar a aprovação do locatário: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  const onNext = async () => {
    if (
      aprovacaoLocatario.status === "Perdeu" ||
      aprovacaoLocatario.status === "Ganhou"
    ) {
      goNextStep();
      return;
    }

    if (validateCurrentStep(step, formRef)) {
      // Verifica se o ID da aprovação já existe, caso não, significa que preciso ter a primeira inclusão da aprovação do locatário no banco
      if (!aprovacaoLocatario._id) {
        handleAndamentoClick();
        try {
          // Salva a aprovação caso o ID não exista
          const savedAprovacao = await saveAprovacaoLocatario(
            aprovacaoLocatario
          );

          // Atualiza o aprovacaoLocatario com o ID retornado
          setAprovacaoLocatario((prev) => ({
            ...prev,
            _id: savedAprovacao.id, // ou o campo correspondente retornado pela função
          }));
        } catch (error) {
          console.error("Erro ao salvar a aprovação do locatário:", error);
          return; // Interrompe se houver erro ao salvar
        }
      } else {
        checkForChanges(step); // Verifica se houve alterações
      }
      goNextStep();
    } else {
      console.error("Validation failed");
    }
  };

  const goNextStep = () => {
    // Valida os campos do step atual
    try {
      // Avança para o próximo step
      if (step === 1 && isFiadorSelected) {
        setStep(3); // Pular para o próximo step não bloqueado
      } else if (step === 2 && isSeguroSelected) {
        setStep(4); // Pular para o próximo step não bloqueado
      } else {
        setStep(step + 1);
      }
    } catch (error) {
      console.error("Error saving step data:", error);
    }
  };

  const onPrevious = () => {
    let prevStep = step - 1;
    //Verificar com Cristiano se salvará se der um "Anterior"
    // checkForChanges(step); // Verifica alterações no step atual antes de voltar

    // Pula o step "Fiador" se "Seguro fiança" estiver selecionado
    if (prevStep === 3 && isSeguroSelected) {
      prevStep = 2; // Volta para o step "Seguro fiança"
    }
    // Pula o step "Seguro fiança" se "Fiador" estiver selecionado
    if (prevStep === 2 && isFiadorSelected) {
      prevStep = 1; // Volta para o step "Caixa de Entrada"
    }
    setStep(prevStep);
  };

  const getSchemaForStep = (step) => {
    switch (step) {
      case 1:
        return step1Schema;
      case 2:
        return step2Schema;
      case 3:
        return step3Schema;
      case 4:
        return step4Schema;
      case 5:
        return step5Schema(isSeguroSelected);
      case 6:
        return step6Schema;
      default:
    }
  };

  const handlePerdeuClick = () => {
    setShowModalPerdeu(true); // Abrir a modal de "Perdeu"
  };

  const handleSubmitPerdeu = async () => {
    // Ações ao confirmar a mudança para "Perdeu"
    try {
      aprovacaoLocatario.step = step;
      const response = await fetchWithAuth(
        `/aprovacaoLocatario/perdeu/${aprovacaoLocatario._id}`,
        {
          method: "PUT",
          body: JSON.stringify({ motivoDesaprovadoPerdeu }), // Salva os dados
          headers: {
            "Content-Type": "application/json", // Especifica o tipo de conteúdo
          },
        }
      );

      if (response.error) {
        showError(
          "Erro interno ao alterar o status para perdeu: ",
          response.message
        );
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Status alterado para perdeu com sucesso");

      setFormPerdeu();
      setShowModalPerdeu(false); // Fecha a modal de "Perdeu"

      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao alterar o status para perdeu: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  //Alterando o formulário para perdeu
  const setFormPerdeu = () => {
    setFormDisabled(true); // Desabilita o formulário
    setAprovacaoLocatario((prevState) => ({
      ...prevState,
      status: "Perdeu",
    }));
  };

  //Alterando o formulário para ganhou
  const setFormGanhou = () => {
    setFormDisabled(true); // Desabilita o formulário
    setAprovacaoLocatario((prevState) => ({
      ...prevState,
      status: "Ganhou",
    }));
  };

  const handleAndamentoClick = () => {
    aprovacaoLocatario.status = "Andamento";
  };

  return (
    <Content className="m-2">
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          <Form
            ref={formRef}
            className="subContent formSpacing"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            model={getSchemaForStep(step)} // Esquema dinâmico com base no step atual
            formValue={aprovacaoLocatario}
            onChange={handleFormChange}
            onCheck={(formError) => setFormError(formError)}
            onSubmit={handleSubmit}
            disabled={formDisabled} // Desabilita o formulário com base no estado formDisabled
            fluid
          >
            {/* Modal de confirmação de "Perdeu" */}
            <Modal
              open={showModalPerdeu}
              onClose={() => setShowModalPerdeu(false)}
            >
              <Modal.Header>
                <Modal.Title className="modal-title">
                  Alterar status para "Perdeu"
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="modal-message">
                  Tem certeza que deseja alterar o status para "Perdeu"?
                </p>
                <hr />
                {/* Campo de Motivo Desaprovado para "Perdeu" */}
                <Form fluid>
                  <Form.Group controlId="motivoDesaprovadoPerdeu">
                    <Form.ControlLabel>Motivo Desaprovado</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      name="motivoDesaprovadoPerdeu"
                      data={motivoDesaprovadoData}
                      accepter={SelectPicker}
                      searchable={false}
                      style={{ width: "100%" }}
                      placeholder="Motivo Desaprovado"
                      onChange={setMotivoDesaprovadoPerdeu} // Atualiza o estado do motivo de "Perdeu"
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  appearance="ghost"
                  onClick={() => setShowModalPerdeu(false)}
                >
                  Cancelar
                </Button>
                <Button appearance="primary" onClick={handleSubmitPerdeu}>
                  Confirmar
                </Button>
              </Modal.Footer>
            </Modal>
            <MySteps
              step={step}
              setStep={setStep}
              isFiadorSelected={isFiadorSelected}
              isSeguroSelected={isSeguroSelected}
            />
            <FlexboxGrid.Item colspan={24} align="right">
              <Text bold className="status-label">
                Status:
                <span
                  className={`status-value ${getStatusClass(
                    aprovacaoLocatario.status || "Aberto"
                  )}`}
                >
                  {aprovacaoLocatario.status || "Aberto"}
                </span>
              </Text>
            </FlexboxGrid.Item>
            <MyComponent
              step={step}
              handleFormChange={handleFormChange}
              isSeguroSelected={isSeguroSelected}
              handleSubmit={handleSubmit}
              setFormDisabled={setFormDisabled}
            />
            <FlexboxGrid
              className="d-flex justify-content-start"
              style={{ marginTop: "auto" }}
            >
              <Divider />
              <FlexboxGrid.Item colspan={12} className="justify-content-start">
                <ButtonGroup>
                  <Button onClick={onPrevious} disabled={step === 1}>
                    Anterior
                  </Button>
                  <Button onClick={onNext} disabled={step === 6}>
                    Próximo
                  </Button>
                </ButtonGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item
                colspan={12}
                className="d-flex justify-content-end"
              >
                <Button
                  size="lg"
                  appearance="primary"
                  color="red"
                  className="button-spacing" // Adiciona a classe de estilo e espaçamento
                  onClick={handlePerdeuClick}
                >
                  Perdeu
                </Button>
                <Button
                  size="lg"
                  appearance="primary"
                  type="submit"
                  className="button-spacing"
                >
                  {behavior === "create" ? "Salvar" : "Atualizar"} Aprovação
                </Button>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Form>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Content>
  );
};

export default AprovacaoLocatario;
