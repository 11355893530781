import React, { useContext } from "react";

import CaixaDeEntrada from "./CaixaDeEntrada";
import CadastroInicial from "./CadastroInicial";
import Contrato from "./Contrato";
import Vistoria from "./Vistoria";
import SeguroIncendio from "./SeguroIncendio";
import Assinaturas from "./Assinaturas";
import EntregaChaves from "./EntregaChaves";
import { ContratoLocacaoContext } from "./ContratoLocacaoContext";

const MyComponent = ({ step, handleFormChange, handleGanhouClick }) => {
  const { stepData, handleStepChange } = useContext(ContratoLocacaoContext);

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <CaixaDeEntrada
            formData={stepData.step1}
            onChange={(data) => handleStepChange(1)}
            handleFormChange={handleFormChange}
          />
        );
      case 2:
        return (
          <CadastroInicial
            formData={stepData.step2}
            onChange={(data) => handleStepChange(2)}
          />
        );
      case 3:
        return (
          <Contrato
            formData={stepData.step3}
            onChange={(data) => handleStepChange(3)}
            handleFormChange={handleFormChange}
          />
        );
      case 4:
        return (
          <Vistoria
            formData={stepData.step4}
            onChange={(data) => handleStepChange(4)}
            handleFormChange={handleFormChange}
          />
        );
      case 5:
        return (
          <SeguroIncendio
            formData={stepData.step5}
            onChange={(data) => handleStepChange(5)}
            handleFormChange={handleFormChange}
          />
        );
      case 6:
        return (
          <Assinaturas
            formData={stepData.step6}
            onChange={(data) => handleStepChange(6)}
            handleFormChange={handleFormChange}
          />
        );
      case 7:
        return (
          <EntregaChaves
            formData={stepData.step7}
            onChange={(data) => handleStepChange(7)}
            handleGanhouClick={handleGanhouClick}
          />
        );
      default:
        return <div>Selecione uma etapa</div>;
    }
  };

  return (
    <>
      <hr />
      {renderContent()}
    </>
  );
};

export default MyComponent;
