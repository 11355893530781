import React, { createContext, useState } from "react";
import { fetchWithAuth } from "../../services/apiNoState";
// arquivo onde você está utilizando validateCurrentStep

// Cria o contexto
export const RescisaoContratoContext = createContext();

// Provedor do contexto
export const RescisaoContratoProvider = ({ children }) => {
  const [usuarioLogado, setUsuarioLogado] = useState(null);
  const [rescisaoContrato, setRescisaoContrato] = useState({});
  const [contratoLocacao, setContratoLocacao] = useState({});

  const [stepData, setStepData] = useState({});

  // Função para buscar o usuário logado
  const carregarUsuarioLogado = async () => {
    try {
      const response = await fetchWithAuth(`/auth/usuario`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // Especifica o tipo de conteúdo
        },
      });
      setUsuarioLogado(response.data);
    } catch (error) {
      console.error("Erro ao carregar usuário logado:", error);
    }
  };

  const handleStepChange = (step) => {
    let filteredData = null;
    switch (step) {
      case 1:
        filteredData = filterStep1Data(rescisaoContrato);
        break;
      case 2:
        filteredData = filterStep2Data(rescisaoContrato);
        break;
      case 3:
        filteredData = filterStep3Data();
        break;
      case 4:
        filteredData = filterStep4Data(rescisaoContrato);
        break;
      case 5:
        filteredData = filterStep5Data(rescisaoContrato);
        break;
      case 6:
        filteredData = filterStep6Data(rescisaoContrato);
        break;
      default:
        // Para outros steps, siga o comportamento padrão

        break;
    }
    setStepData((prevData) => ({
      ...prevData,
      [`step${step}`]: JSON.parse(JSON.stringify(filteredData)), // Garante cópia profunda
    }));
  };

  const preencherSteps = (rescisaoParam) => {
    // Objeto com os filtros correspondentes para cada step
    const filtros = {
      1: filterStep1Data,
      2: filterStep2Data,
      3: filterStep3Data,
      4: filterStep4Data,
      5: filterStep5Data,
      6: filterStep6Data,
    };

    // Inicializa o stepData atualizado
    const novoStepData = {};

    // Itera sobre os steps definidos no filtro
    Object.keys(filtros).forEach((step) => {
      const stepNum = parseInt(step, 10); // Converte para número
      if (rescisaoParam) {
        // Aplica o filtro correspondente ao step atual
        const dadosFiltrados = filtros[stepNum](rescisaoParam);
        novoStepData[`step${stepNum}`] = dadosFiltrados;
      }
    });
    // Atualiza o stepData no contexto
    setStepData((prevData) => ({
      ...prevData,
      ...novoStepData, // Mescla os contratoParam novos
    }));
  };

  const filterStep1Data = (rescisaoContrato) => {
    const {
      dataPrevistaDesocupacao,
      motivoRescisao,
      avisouProprietario,
      imagemDeclaracaoAdimplencia,
      possuiDebitoAguaEnergia,
      temSinistroAberto,
      checklist,
      informacoesDebitosRescisao,
    } = rescisaoContrato;

    return {
      dataPrevistaDesocupacao,
      motivoRescisao,
      avisouProprietario,
      imagemDeclaracaoAdimplencia,
      possuiDebitoAguaEnergia,
      temSinistroAberto,
      checklist,
      informacoesDebitosRescisao,
    };
  };

  const filterStep2Data = (rescisaoContrato) => {
    const {
      realizarBenfeitoria,
      custearBenfeitoria,
      proprietarioAprovou,
      dataAprovacaoProprietario,
      vistoriaConcluida,
      dataServicoExecutado,
      vistoriador,
      lancadaAplicativo,
      dataVistoria,
      fornecedor,
      anotacoesVistoria,
      documentosOrcamento,
      listaOrcamento,
    } = rescisaoContrato;

    return {
      realizarBenfeitoria,
      custearBenfeitoria,
      proprietarioAprovou,
      dataAprovacaoProprietario,
      vistoriaConcluida,
      dataServicoExecutado,
      vistoriador,
      lancadaAplicativo,
      dataVistoria,
      fornecedor,
      anotacoesVistoria,
      documentosOrcamento,
      listaOrcamento,
    };
  };

  const filterStep3Data = (newData = null) => {
    const {
      situacaoCpfl,
      situacaoDae,
      debitosPendentes,
      declaracaoEnviadaSeguradora,
      debitosEnviadosSinistro,
      problemaTransferencia,
      imagemDistrato,
      imagemTermoConfissao,
      imagemAcordoParcelamento,
      imagemTermoChaves,
      propAssinouDistrato,
      locAssinouDistrato,
    } = newData ? newData : rescisaoContrato;

    return {
      situacaoCpfl,
      situacaoDae,
      debitosPendentes,
      declaracaoEnviadaSeguradora,
      debitosEnviadosSinistro,
      problemaTransferencia,
      imagemDistrato,
      imagemTermoConfissao,
      imagemAcordoParcelamento,
      imagemTermoChaves,
      propAssinouDistrato,
      locAssinouDistrato,
    };
  };

  const filterStep4Data = (rescisaoContrato) => {
    const { viseguroFiancaCancelado, seguroIncendioCancelado } =
      rescisaoContrato;

    return {
      viseguroFiancaCancelado,
      seguroIncendioCancelado,
    };
  };

  const filterStep5Data = (rescisaoContrato) => {
    const { listaComunicacao } = rescisaoContrato;

    return { listaComunicacao };
  };

  const filterStep6Data = (rescisaoContrato) => {
    const {
      detalheRepasse,
      dataRepasse,
      repasseLiquidado,
      multaRescisoria,
      ajustesRepasse,
    } = rescisaoContrato;

    return {
      detalheRepasse,
      dataRepasse,
      repasseLiquidado,
      multaRescisoria,
      ajustesRepasse,
    };
  };

  const validateCurrentStep = (step, formRef) => {
    if (!formRef?.current) return false;

    // Executa a validação usando o ref do formulário
    const isValid = formRef.current.check();

    if (!isValid) return false;

    return true; // Retorna true se não houver erros
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Aberto":
        return "status-aberto";
      case "Ganhou":
        return "status-ganhou";
      case "Perdeu":
        return "status-perdeu";
      case "Andamento":
        return "status-andamento";
      default:
        return "";
    }
  };

  carregarUsuarioLogado();

  return (
    <RescisaoContratoContext.Provider
      value={{
        stepData,
        preencherSteps,
        handleStepChange,
        validateCurrentStep,
        contratoLocacao,
        setContratoLocacao,
        rescisaoContrato,
        setRescisaoContrato,
        filterStep1Data,
        filterStep2Data,
        filterStep3Data,
        filterStep4Data,
        filterStep5Data,
        filterStep6Data,
        getStatusClass,
        usuarioLogado,
      }}
    >
      {children}
    </RescisaoContratoContext.Provider>
  );
};
