import { all, call, put, select, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import {
  allImovel as allImovelAction,
  resetImovel,
  updateImovel,
} from "./actions";
import types from "./types";

export function* allImovel() {
  const { form } = yield select((state) => state.imovel);

  try {
    yield put(updateImovel({ form: { ...form, filtering: true } }));
    const { data: res } = yield call(api.get, `/imovel/imoveis/`);

    yield put(updateImovel({ form: { ...form, filtering: false } }));

    if (res.error) {
      alert(res.message);
      return false;
    }

    yield put(updateImovel({ imoveis: res.imoveis }));
  } catch (err) {
    yield put(updateImovel({ form: { ...form, filtering: false } }));
    alert(err.message);
  }
}

export function* filterImoveis() {
  const { form, imovel } = yield select((state) => state.imovel);

  try {
    yield put(updateImovel({ form: { ...form, filtering: true } }));
    const { data: res } = yield call(api.post, `/imovel/filter/`, {
      filters: {
        email: imovel.email,
      },
    });

    yield put(updateImovel({ form: { ...form, filtering: false } }));

    if (res.error) {
      alert(res.message);
      return false;
    }

    if (res.imoveis.length > 0) {
      yield put(
        updateImovel({
          imovel: res.imoveis[0],
          form: { ...form, filtering: false, disabled: true },
        })
      );
    } else {
      yield put(
        updateImovel({
          form: { ...form, disabled: false },
        })
      );
    }
  } catch (err) {
    yield put(updateImovel({ form: { ...form, filtering: false } }));
    alert(err.message);
  }
}

// const showError = (message) => {
//   toaster.push(
//     <Message type="error" showIcon>
//       {message}
//     </Message>,
//     {
//       placement: "topEnd",
//       duration: 5000,
//     }
//   );
// };

// const showSuccess = (message) => {
//   toaster.push(
//     <Message type="success" showIcon>
//       {message}
//     </Message>,
//     {
//       placement: "topEnd",
//       duration: 5000,
//     }
//   );
// };

function appendFormData(formData, data, parentKey = "") {
  if (data instanceof Array) {
    data.forEach((item, index) => {
      appendFormData(formData, item, `${parentKey}[${index}]`);
    });
  } else if (data instanceof Object && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      appendFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}.${key}` : key
      );
    });
  } else {
    formData.append(parentKey, data);
  }
}

export function* addImovel() {
  const { form, imovel, components } = yield select((state) => state.imovel);
  try {
    yield put(updateImovel({ form: { ...form, saving: true } }));
    const formData = new FormData();
    console.log("imovel", imovel);
    appendFormData(formData, imovel);
    console.log("formData", formData);

    const { data: res } = yield call(api.post, `/imovel`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    yield put(updateImovel({ form: { ...form, saving: false } }));

    if (res.error) {
      // showError(res.message);
      return false;
    }

    // showSuccess("Imovel adicionada com sucesso.");

    yield put(updateImovel({ components: { ...components, drawer: false } }));
    yield put(resetImovel());
  } catch (err) {
    yield put(updateImovel({ form: { ...form, saving: false } }));

    if (err.response && err.response.status === 400) {
      // showError(err.response.data.message || "Erro ao adicionar imovel.");
    } else {
      // showError(err.message || "Erro desconhecido.");
    }
  }
}

export function* saveImovel() {
  const { imovel, form, components } = yield select((state) => state.imovel);

  try {
    yield put(updateImovel({ form: { ...form, saving: true } }));

    const formData = new FormData();
    // console.log(imovel);
    appendFormData(formData, imovel);
    // console.log(formData);
    const { data: res } = yield call(
      api.put,
      `/imovel/${formData.get("cpf")}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    yield put(updateImovel({ form: { ...form, saving: false } }));

    if (res.error) {
      // showError(res.message);
      return false;
    }

    yield put(allImovelAction());
    yield put(updateImovel({ components: { ...components, drawer: false } }));
    yield put(resetImovel());
  } catch (err) {
    // COLOCAR AQUI O ALERT DO RSUITE
    yield put(updateImovel({ form: { ...form, saving: false } }));
    // showError(err.message);
  }
}

export function* unlinkImovel() {
  const { form, imovel, components } = yield select((state) => state.imovel);

  try {
    yield put(updateImovel({ form: { ...form, saving: true } }));
    const { data: res } = yield call(api.delete, `/imovel/${imovel._id}`);

    yield put(
      updateImovel({
        form: { ...form, saving: false },
      })
    );

    if (res.error) {
      alert(res.message);
      return false;
    }

    yield put(allImovelAction());
    yield put(
      updateImovel({
        components: { ...components, drawer: false, confirmDelete: false },
      })
    );
    yield put(resetImovel());
  } catch (err) {
    yield put(updateImovel({ form: { ...form, saving: false } }));
    alert(err.message);
  }
}

export default all([
  takeLatest(types.ALL_IMOVEL, allImovel),
  takeLatest(types.FILTER_IMOVEIS, filterImoveis),
  takeLatest(types.ADD_IMOVEL, addImovel),
  takeLatest(types.SAVE_IMOVEL, saveImovel),
  takeLatest(types.UNLINK_IMOVEL, unlinkImovel),
]);
