import { useContext } from "react";
import { Button, FlexboxGrid, Form, Input, Radio, RadioGroup } from "rsuite";
import { contasTransferencia } from "../../enums/contasTransferencia";
import { TransferenciaContasContext } from "./TransferenciaContasContext";

const Transferindo = ({ handleConcluirClick }) => {
  const {
    transferenciaContas,
    setTransferenciaContas,
    fieldsState,
    setFieldsState,
  } = useContext(TransferenciaContasContext);

  // Obter os labels únicos dinamicamente
  const contas = Array.from(
    new Set(contasTransferencia.map((conta) => conta.label))
  );

  const handleRadioChange = (campo, value) => {
    setTransferenciaContas((prev) => {
      // Atualiza ou cria o item dentro de detalhesTransferencia
      const detalhesAtualizados = prev.detalhesTransferencia.map((detalhe) =>
        detalhe.conta.toLowerCase() === campo.toLowerCase()
          ? { ...detalhe, status: value } // Atualiza o status do campo correspondente
          : detalhe
      );

      // Adiciona um novo item se o campo não existe
      if (
        !detalhesAtualizados.some(
          (detalhe) => detalhe.conta.toLowerCase() === campo.toLowerCase()
        )
      ) {
        detalhesAtualizados.push({ conta: campo, status: value });
      }

      return {
        ...prev,
        detalhesTransferencia: detalhesAtualizados, // Atualiza o array com os dados organizados
      };
    });

    // Atualiza o controle de exibição de campos adicionais (se necessário)
    setFieldsState((prev) => ({
      ...prev,
      [campo]: value,
    }));
  };

  const handleInputChange = (campo, value) => {
    setTransferenciaContas((prev) => {
      // Certifique-se de não sobrescrever o estado anterior
      const detalhesAtualizados = prev.detalhesTransferencia.map((detalhe) => {
        if (
          campo.includes("Observacao") &&
          detalhe.conta.toLowerCase() ===
            campo.replace("Observacao", "").toLowerCase()
        ) {
          return { ...detalhe, observacao: value };
        } else if (
          campo.includes("Protocolo") &&
          detalhe.conta.toLowerCase() ===
            campo.replace("Protocolo", "").toLowerCase()
        ) {
          return { ...detalhe, protocolo: value };
        }
        return detalhe;
      });

      return {
        ...prev,
        detalhesTransferencia: detalhesAtualizados,
      };
    });
  };

  const styleButton = { alignSelf: "center" };

  return (
    <FlexboxGrid>
      {contas.map((conta) => (
        <FlexboxGrid.Item key={conta} colspan={24}>
          <FlexboxGrid>
            {/* Campo principal */}
            <FlexboxGrid.Item colspan={12}>
              <Form.ControlLabel>{`${conta} transferido?`}</Form.ControlLabel>
              <Form.Control
                size="lg"
                name={`${conta.toLowerCase()}Transferido`} // Nome dinâmico baseado no label
                style={{ backgroundColor: "white" }}
                appearance="picker"
                accepter={RadioGroup}
                value={
                  transferenciaContas.detalhesTransferencia.find(
                    (detalhe) =>
                      detalhe.conta.toLowerCase() === conta.toLowerCase()
                  )?.status || ""
                }
                inline
                onChange={(value) => handleRadioChange(conta, value)} // Atualiza o estado
              >
                <Radio value="Sim">Sim</Radio>
                <Radio value="Não">Não</Radio>
                <Radio value="Não se aplica">Não se aplica</Radio>
              </Form.Control>
            </FlexboxGrid.Item>

            {/* Campos adicionais */}
            <FlexboxGrid.Item colspan={12}>
              {fieldsState[conta.toLowerCase()] === "Sim" && (
                <Form.Group>
                  <Form.ControlLabel>{`Protocolo para ${conta}:`}</Form.ControlLabel>
                  <Input
                    size="lg"
                    name={`${conta.toLowerCase()}Protocolo`} // Nome dinâmico
                    placeholder={`Digite o protocolo para ${conta}`}
                    value={
                      transferenciaContas.detalhesTransferencia.find(
                        (detalhe) =>
                          detalhe.conta.toLowerCase() === conta.toLowerCase()
                      )?.protocolo || ""
                    } // Busca o valor do protocolo no estado
                    onChange={(value) =>
                      handleInputChange(
                        `${conta.toLowerCase()}Protocolo`,
                        value
                      )
                    }
                  />
                </Form.Group>
              )}
              {fieldsState[conta.toLowerCase()] === "Não" && (
                <Form.Group>
                  <Form.ControlLabel>{`Observação para ${conta}:`}</Form.ControlLabel>
                  <Input
                    as="textarea"
                    rows={3}
                    size="lg"
                    name={`${conta.toLowerCase()}Observacao`} // Nome dinâmico
                    placeholder={`Digite uma observação para ${conta}`}
                    value={
                      transferenciaContas.detalhesTransferencia.find(
                        (detalhe) =>
                          detalhe.conta.toLowerCase() === conta.toLowerCase()
                      )?.observacao || ""
                    } // Busca o valor da observação no estado
                    onChange={(value) => {
                      handleInputChange(
                        `${conta.toLowerCase()}Observacao`,
                        value
                      );
                    }}
                  />
                </Form.Group>
              )}
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </FlexboxGrid.Item>
      ))}

      {/* Outros campos específicos */}
      {["iptuLancado", "condominioLancado", "rateioLancado"].map((campo) => (
        <FlexboxGrid.Item key={campo} colspan={24}>
          <FlexboxGrid>
            {/* Campo principal */}
            <FlexboxGrid.Item colspan={12}>
              <Form.ControlLabel>
                {`${campo.replace(/Lancado/, "")} está lançado corretamente?`}
              </Form.ControlLabel>
              <Form.Control
                size="lg"
                name={campo}
                style={{ backgroundColor: "white" }}
                appearance="picker"
                accepter={RadioGroup}
                value={
                  transferenciaContas.detalhesTransferencia.find(
                    (detalhe) =>
                      detalhe.conta.toLowerCase() === campo.toLowerCase()
                  )?.status || ""
                }
                inline
                onChange={(value) => handleRadioChange(campo, value)}
              >
                <Radio value="Sim">Sim</Radio>
                <Radio value="Não">Não</Radio>
                <Radio value="Não se aplica">Não se aplica</Radio>
              </Form.Control>
            </FlexboxGrid.Item>

            {/* Campos adicionais */}
            <FlexboxGrid.Item colspan={12}>
              {fieldsState[campo.toLowerCase()] === "Sim" && (
                <Form.Group>
                  <Form.ControlLabel>{`Protocolo para ${campo}:`}</Form.ControlLabel>
                  <Input
                    size="lg"
                    name={`${campo}Protocolo`}
                    placeholder={`Digite o protocolo para ${campo}`}
                    value={
                      transferenciaContas.detalhesTransferencia.find(
                        (detalhe) =>
                          detalhe.conta.toLowerCase() === campo.toLowerCase()
                      )?.protocolo || ""
                    }
                    onChange={(value) =>
                      handleInputChange(`${campo}Protocolo`, value)
                    }
                  />
                </Form.Group>
              )}
              {fieldsState[campo.toLowerCase()] === "Não" && (
                <Form.Group>
                  <Form.ControlLabel>{`Observação para ${campo}:`}</Form.ControlLabel>
                  <Input
                    as="textarea"
                    rows={3}
                    size="lg"
                    name={`${campo}Observacao`}
                    placeholder={`Digite uma observação para ${campo}`}
                    value={
                      transferenciaContas.detalhesTransferencia.find(
                        (detalhe) =>
                          detalhe.conta.toLowerCase() === campo.toLowerCase()
                      )?.observacao || ""
                    }
                    onChange={(value) =>
                      handleInputChange(`${campo}Observacao`, value)
                    }
                  />
                </Form.Group>
              )}
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </FlexboxGrid.Item>
      ))}

      <FlexboxGrid.Item colspan={6} style={styleButton}>
        <hr />
        <Button
          size="lg"
          appearance="primary"
          color="green"
          className="button-spacing"
          onClick={handleConcluirClick}
        >
          Concluir
        </Button>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};
export default Transferindo;
