import { Schema } from "rsuite";

const { StringType, NumberType } = Schema.Types;

export const step1Schema = Schema.Model({
  garantia: StringType().isRequired("Garantia é obrigatória"),
  valorAluguel: NumberType()
    .isRequired("O valor do aluguel é obrigatório")
    .min(0, "O valor do aluguel deve ser positivo"),
  valorIptu: NumberType()
    .isRequired("O valor do IPTU é obrigatório")
    .min(0, "O valor do IPTU deve ser positivo"),
});

export const step2Schema = Schema.Model({
  corretora: StringType().isRequired("A corretora é obrigatória"),
  aprovado: StringType().isRequired(
    "É obrigatório informar se o seguro foi aprovado"
  ),
  valorParcela: NumberType()
    .isRequired("O valor da parcela do seguro é obrigatório")
    .min(0, "O valor da parcela do seguro deve ser positivo"),
  seguradora: StringType().isRequired("A seguradora é obrigatória"),
  formaPagamento: StringType().isRequired("A forma de pagamento é obrigatória"),
});

export const step3Schema = Schema.Model({
  numIptu: StringType().isRequired("O número do IPTU é obrigatório"),
  numMatricula: StringType().isRequired("O número da matrícula é obrigatório"),
  valor: NumberType()
    .isRequired("O valor do imóvel é obrigatório")
    .min(0, "O valor do imóvel deve ser positivo"),
  tipo: StringType().isRequired("O tipo do imóvel é obrigatório"),
});

export const step4Schema = Schema.Model({
  proprietarioAprovado: StringType().isRequired(
    "É obrigatório informar se o proprietário foi aprovado"
  ),
  fiadorAprovado: StringType().isRequired(
    "É obrigatório informar se o fiador foi aprovado"
  ),
  locatarioAprovado: StringType().isRequired(
    "É obrigatório informar se o locatário foi aprovado"
  ),
  imovelAprovado: StringType().isRequired(
    "É obrigatório informar se o imóvel foi aprovado"
  ),
});

export const step5Schema = (isSeguroSelected) =>
  Schema.Model({
    rateioAgua: StringType().isRequired("O rateio da água é obrigatório"),
    rateioEnergia: StringType().isRequired("O rateio de energia é obrigatório"),
    valorAluguel: NumberType()
      .isRequired("O valor do aluguel é obrigatório")
      .min(0, "O valor do aluguel deve ser positivo"),
    corretora: isSeguroSelected
      ? StringType().isRequired("A corretora é obrigatória")
      : StringType(),
    diaVencimentoAluguel: StringType()
      .isRequired("O dia de vencimento do aluguel é obrigatório.")
      .addRule(
        (value) =>
          /^\d{1,2}$/.test(value) && Number(value) >= 1 && Number(value) <= 31,
        "O dia de vencimento deve ser um número entre 1 e 31."
      ),

    seguradora: isSeguroSelected
      ? StringType().isRequired("A seguradora é obrigatória")
      : StringType(),
    valorTaxaLocacao: NumberType()
      .isRequired("O valor da taxa de locação é obrigatório")
      .min(0, "O valor da taxa de locação deve ser positivo"),
    valorParcela: isSeguroSelected
      ? NumberType()
          .isRequired("O valor da parcela do seguro é obrigatório")
          .min(0, "O valor da parcela do seguro deve ser positivo")
      : StringType(),
    qtdParcelaTaxaLocacao: NumberType()
      .isRequired("A quantidade de parcelas da taxa de locação é obrigatória")
      .min(0, "A quantidade de parcelas deve ser positiva"),
    percentualTaxaAdmMensal: NumberType()
      .isRequired("O percentual da taxa de administração mensal é obrigatório")
      .min(0, "O percentual deve ser positivo"),
    valorIptu: NumberType()
      .isRequired("O valor do IPTU é obrigatório")
      .min(0, "O valor do IPTU deve ser positivo"),
  });

export const step6Schema = Schema.Model({
  locatarioAvisado: StringType().isRequired(
    "É obrigatório informar se o locatário foi avisado"
  ),
  cobrancaCondominio: StringType().isRequired(
    "É obrigatório informar se o condomínio será cobrado do locatário"
  ),
  proprietarioAvisado: StringType().isRequired(
    "É obrigatório informar se o proprietário foi avisado sobre a aprovação"
  ),
  responsavelFinanceiroCondominio: StringType().isRequired(
    "O responsável financeiro pelo condomínio é obrigatório"
  ),
  locacaoAprovada: StringType().isRequired(
    "É obrigatório informar se a locação foi aprovada"
  ),
  cobrancaIptu: StringType().isRequired(
    "É obrigatório informar se o IPTU será cobrado do locatário"
  ),
  responsavelFinanceiroIptu: StringType().isRequired(
    "O responsável financeiro pelo IPTU é obrigatório"
  ),
});
