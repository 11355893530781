import { format } from "date-fns";

import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Content,
  Divider,
  FlexboxGrid,
  Form,
  Modal,
  SelectPicker,
  Table,
  Text,
} from "rsuite";

import { showError, showSuccess } from "../../components/Utils/toastUtils";
import { fetchWithAuth } from "../../services/apiNoState";
import MyComponent from "./MyComponent";
import MySteps from "./MySteps";
import { TransferenciaContasContext } from "./TransferenciaContasContext";
import { step2Schema, step3Schema } from "./validacao";

/**
 * Componente principal do contrato de locação.
 * Este componente gerencia o estado do contrato, a navegação entre etapas e a validação do formulário.
 */
const TransferenciaContas = () => {
  const {
    setContratoLocacao,
    transferenciaContas,
    setTransferenciaContas,
    validateCurrentStep,
    stepData,
    handleStepChange,
    preencherSteps,
    filterStep2Data,
    filterStep3Data,
    getStatusClass,
    usuarioLogado,
    setFieldsState,
  } = useContext(TransferenciaContasContext);

  const { Column, HeaderCell, Cell } = Table;

  const [step, setStep] = useState(1);

  const [formError, setFormError] = useState({});
  const [formDisabled, setFormDisabled] = useState(false); // Estado para controlar o formulário

  const location = useLocation();
  const contratoParam = location.state?.contrato; // Acessa 'contrato' de 'location.state'
  const transferenciaParam = location.state?.transferencia; // Acessa 'transferencia' de 'location.state'

  const [showModalComunicacao, setShowModalComunicacao] = useState(false);
  const [showModalConcluir, setShowModalConcluir] = useState(false);
  const [showModalAdicionarRegistro, setShowModalAdicionarRegistro] =
    useState(false);

  const formRef = useRef();

  const quemData = ["Seguradora", "Locatário", "Proprietário", "Interna"].map(
    (item) => ({
      label: item,
      value: item,
    })
  );

  /**
   * Efeito colateral que carrega os dados do contrato de locação ao montar o componente.
   * Atualiza o estado com os dados do contrato se o parâmetro de contrato estiver presente na localização.
   */
  useEffect(() => {
    const carregarTransferenciaContas = async () => {
      if (contratoParam) {
        setContratoLocacao(contratoParam);
        transferenciaContas.origem = "Manual";
      }

      if (transferenciaParam) {
        setContratoLocacao(transferenciaParam.contratoId);

        // Atualiza o estado com os dados vindos de `contratoParam`
        setTransferenciaContas(() => {
          let updates = {
            ...transferenciaParam,
          };

          if (transferenciaParam.status === "Concluído") {
            setFormConcluir();
          } else {
            handleAndamentoClick(); // Se nenhuma das condições acima for atendida
          }

          preencherCamposCheckBox(updates.detalhesTransferencia);

          return updates;
        });

        const preencherCamposCheckBox = (detalhesTransferencia) => {
          // Objeto para armazenar os status dos radios
          const novosFieldsState = {};
          // Objeto para acumular as mudanças dos campos adicionais
          const formChanges = {};

          detalhesTransferencia.forEach((detalhe) => {
            // Converte o nome da conta para minúsculas para padronização
            const campo = detalhe.conta.toLowerCase();

            // Atualiza o status no fieldsState
            novosFieldsState[campo] = detalhe.status;

            // Dependendo do status, atualiza os campos extras:
            if (detalhe.status === "Sim") {
              formChanges[`${campo}Protocolo`] = detalhe.protocolo || "";
              // Opcional: se quiser limpar o campo observação quando for "Sim"
              formChanges[`${campo}Observacao`] = "";
            } else if (detalhe.status === "Não") {
              formChanges[`${campo}Observacao`] = detalhe.observacao || "";
              // Opcional: se quiser limpar o campo protocolo quando for "Não"
              formChanges[`${campo}Protocolo`] = "";
            } else {
              // Para "Não se aplica", limpa ambos os campos adicionais (ou mantenha conforme necessário)
              formChanges[`${campo}Protocolo`] = "";
              formChanges[`${campo}Observacao`] = "";
            }
          });

          // Atualiza os campos do formulário de uma única vez
          handleFormChange(formChanges);

          // Atualiza o estado do fieldsState com os novos status
          setFieldsState((prev) => ({
            ...prev,
            ...novosFieldsState,
          }));
        };

        preencherSteps(transferenciaParam);
      }
    };

    carregarTransferenciaContas();
  }, []);

  /**
   * Manipula as mudanças no formulário e atualiza o estado do contrato de locação.
   * @param {Object} formValue - Os valores do formulário que foram alterados.
   */
  const handleFormChange = (formValue) => {
    setTransferenciaContas((prevState) => ({
      ...prevState,
      ...formValue,
    }));
  };

  /**
   * Envia os dados do formulário para o backend.
   * Valida o passo atual e verifica se houve alterações antes de enviar os dados.
   * @param {string} status - O status atual do contrato.
   */
  const handleSubmit = async (status = transferenciaContas.status) => {
    if (validateCurrentStep(formRef)) {
      // if (transferenciaContas.status === "Aberto") {
      //   await handleAndamentoClick();
      // }

      //Verifica se tem id, caso não, significa que preciso fazer um post ao invés de PUT
      if (!transferenciaContas._id) {
        await handleAndamentoClick();
        try {
          // Salva a aprovação caso o ID não exista
          const savedRescisao = await saveTransferenciaContas(
            transferenciaContas
          );

          // Atualiza o transferenciaContas com o ID retornado
          setTransferenciaContas((prev) => ({
            ...prev,
            _id: savedRescisao.id, // ou o campo correspondente retornado pela função
          }));

          // Se houver mudanças, salva as alterações no stepData
          handleStepChange(step);
        } catch (error) {
          console.error("Erro ao salvar a transferência de Contas:", error);
          return; // Interrompe se houver erro ao salvar
        }

        // Verifica se houve alterações
      } else if (await hasAnyChanges(step)) {
        //Chama o backend e atualiza todos os campos que tiveram mudança
        await atualizarTransferenciaContas(status);
        // Se houver mudanças, salva as alterações no stepData
        handleStepChange(step);
      }
    } else {
      showError(
        "Falha na validação, por favor valide todos os campos obrigatórios"
      );
      return false;
    }
  };

  /**
   * Verifica se houve alterações nos dados do formulário.
   * Compara os dados atuais com os dados salvos para determinar se houve alguma mudança.
   * @param {number} step - O número do passo atual do formulário.
   * @returns {boolean} - Retorna true se houver alterações, caso contrário false.
   */
  const hasAnyChanges = async (step) => {
    let currentData;

    switch (step) {
      case 2:
        currentData = filterStep2Data(transferenciaContas); // Filtra os dados relevantes do step 2
        break;
      case 3:
        currentData = filterStep3Data(); // Filtra os dados relevantes do step 3
        break;
      default:
        return false;
    }

    const savedData = stepData[`step${step}`]; // Dados salvos para o step atual

    // Comparação dos dados
    const hasChanges = Object.keys(currentData).some((key) => {
      const currentValue = currentData[key]
        ? JSON.parse(JSON.stringify(currentData[key]))
        : null;

      const savedValue =
        savedData && savedData[key]
          ? JSON.parse(JSON.stringify(savedData[key]))
          : null;

      // Comparação padrão para outros campos
      return JSON.stringify(currentValue) !== JSON.stringify(savedValue);
    });

    return hasChanges;
  };

  /**
   * Atualiza os dados da transferencia no backend.
   * Envia uma requisição PUT para atualizar a transferencia com os dados atuais.
   * @param {string} status - O status atual da transferencia.
   */
  const atualizarTransferenciaContas = async (status) => {
    try {
      transferenciaContas.step = step;
      //Caso o handleSubmit seja chamado pelo aprovação está aprovada?, então alterar o status
      if (status === "Concluído") {
        transferenciaContas.status = status;
      }

      const response = await fetchWithAuth(
        `/transferenciaContas/${transferenciaContas._id}`,
        {
          method: "PUT",
          body: JSON.stringify(transferenciaContas), // Salva os dados
          headers: {
            "Content-Type": "application/json", // Especifica o tipo de conteúdo
          },
        }
      );

      if (response.error) {
        showError("Erro ao alterar a transferencia: ", response.message);
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Transferencia parcial alterado");
      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao salvar a transferencia: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  const saveTransferenciaContas = async (transferenciaContas) => {
    try {
      const response = await fetchWithAuth("/transferenciaContas", {
        method: "POST",
        body: JSON.stringify({
          ...transferenciaContas,
          contratoId: contratoParam._id,
        }), // Salva os dados
        headers: {
          "Content-Type": "application/json", // Especifica o tipo de conteúdo
        },
      });

      if (response.error) {
        showError(
          "Erro ao salvar a transferência de contas: ",
          response.message
        );
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Transferência de Contas parcial salvo");
      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao salvar a transferência de contas: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  /**
   * Navega para o próximo passo do formulário.
   * Valida o passo atual e, se tudo estiver correto, avança para o próximo passo.
   */
  const onNext = async () => {
    const status = transferenciaContas.status;
    if (status === "Concluído") {
      goNextStep();
      return;
    }

    if (validateCurrentStep(formRef)) {
      // Verifica se o ID da aprovação já existe, caso não, significa que preciso ter a primeira inclusão da contrato no banco
      if (status === "Aberto") {
        await handleAndamentoClick();
      }

      //Verifica se tem id, caso não, significa que preciso fazer um post ao invés de PUT
      if (!transferenciaContas._id) {
        await handleAndamentoClick();
        try {
          // Salva a aprovação caso o ID não exista
          const savedRescisao = await saveTransferenciaContas(
            transferenciaContas
          );

          // Atualiza o transferenciaContas com o ID retornado
          setTransferenciaContas((prev) => ({
            ...prev,
            _id: savedRescisao.id, // ou o campo correspondente retornado pela função
          }));

          // Se houver mudanças, salva as alterações no stepData
          handleStepChange(step);
        } catch (error) {
          console.error("Erro ao salvar a transferência de Contas:", error);
          return; // Interrompe se houver erro ao salvar
        }

        // Verifica se houve alterações
      } else if (await hasAnyChanges(step)) {
        //Chama o backend e atualiza todos os campos que tiveram mudança
        await atualizarTransferenciaContas(status); // Chama o método separado
        // Se houver mudanças, salva as alterações no stepData
        handleStepChange(step);
      }
      goNextStep();
    } else {
      showError(
        "Falha na validação, por favor valide todos os campos obrigatórios"
      );
    }
  };

  const goNextStep = () => {
    // Valida os campos do step atual
    try {
      setStep(step + 1);
    } catch (error) {
      console.error("Error saving step data:", error);
    }
  };

  /**
   * Navega para o passo anterior do formulário.
   * Atualiza o estado para o passo anterior.
   */
  const onPrevious = () => {
    let prevStep = step - 1;

    setStep(prevStep);
  };

  /**
   * Obtém o esquema de validação para o passo atual.
   * Retorna o esquema de validação correspondente ao passo atual.
   * @param {number} step - O número do passo atual.
   * @returns {Object} - O esquema de validação para o passo atual.
   */
  const getSchemaForStep = (step) => {
    switch (step) {
      case 2:
        return step2Schema;
      case 3:
        return step3Schema;
      default:
    }
  };

  const handleComunicacaoClick = () => {
    setShowModalComunicacao(true); // Abrir a modal de "Comunicacao"
  };

  /**
   * Manipula a ação de clicar no botão "Concluir".
   * Abre a modal de confirmação para alterar o status para "Concluir".
   */
  const handleConcluirClick = () => {
    setShowModalConcluir(true); // Abrir a modal de "Concluir"
  };

  const handleAdicionarRegistroComunicacaoClick = () => {
    setShowModalAdicionarRegistro(true);
  };

  const handleSubmitComunicacao = async () => {
    // Ações ao confirmar a Comunicação
    try {
      transferenciaContas.step = step;
      const response = await fetchWithAuth(
        `/transferenciaContas/comunicacao/${transferenciaContas._id}`,
        {
          method: "PUT",
          body: JSON.stringify(transferenciaContas.listaComunicacao), // Salva os dados
          headers: {
            "Content-Type": "application/json", // Especifica o tipo de conteúdo
          },
        }
      );

      if (response.error) {
        showError(
          "Erro interno ao atualizar a comunicação: ",
          response.message
        );
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Comunicação atualizada com sucesso!");

      setShowModalComunicacao(false);

      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao atualizar a comunicação: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  const handleSubmitAdicionarRegistro = async () => {
    handleAddRecord();

    // Ação de confirmar a adção de Registro
    showSuccess("Registro adicionado com sucesso");

    setShowModalAdicionarRegistro(false); // Fecha a modal de Adicionar Registro
  };

  /**
   * Envia os dados para alterar o status do contrato para "Concluir".
   * Envia uma requisição para atualizar o status do contrato no backend.
   */
  const handleSubmitConcluir = async () => {
    // Ações ao confirmar a mudança para "Concluir"
    try {
      transferenciaContas.step = step;
      const response = await fetchWithAuth(
        `/transferenciaContas/concluir/${transferenciaContas._id}`,
        {
          method: "PUT",
        }
      );

      if (response.error) {
        showError(
          "Erro interno ao alterar o status para concluir: ",
          response.message
        );
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Status alterado para concluir com sucesso");

      setFormConcluir();
      setShowModalConcluir(false); // Fecha a modal de "Concluir"

      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao alterar o status para concluir: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  /**
   * Altera o formulário para o estado "Concluir".
   * Desabilita o formulário e atualiza o status para "Concluir".
   */
  const setFormConcluir = () => {
    setFormDisabled(true); // Desabilita o formulário
    setTransferenciaContas((prevState) => ({
      ...prevState,
      status: "Concluído",
    }));
  };

  /**
   * Manipula a ação de clicar no botão de andamento.
   * Atualiza o status do contrato para "Andamento".
   */
  const handleAndamentoClick = async () => {
    transferenciaContas.status = "Andamento";
  };

  /*Informações referente a manipulação da tabela dinamica */
  // Adicionar um novo registro
  const handleAddRecord = () => {
    const newRecord = {
      quem: transferenciaContas.quem,
      descricao: transferenciaContas.descricao,
      criadoEm: format(new Date(), "dd/MM/yyyy HH:mm"), // Formato personalizado
      criadoPor: usuarioLogado?.name || "Desconhecido", // Preenche com o nome do usuário
    };
    setTransferenciaContas((prev) => ({
      ...prev,
      listaComunicacao: [...(prev.listaComunicacao || []), newRecord],
    }));
  };
  /*Fim das informações da tabela dinamica */

  /**
   * Manipula a ação de clicar no botão de andamento.
   * Atualiza o status do contrato para "Andamento".
   */
  return (
    <Content className="m-2">
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          <Form
            ref={formRef}
            className="subContent formSpacing"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            model={getSchemaForStep(step)} // Esquema dinâmico com base no step atual
            formValue={transferenciaContas}
            onChange={handleFormChange}
            onCheck={(formError) => setFormError(formError)}
            onSubmit={handleSubmit}
            disabled={formDisabled} // Desabilita o formulário com base no estado formDisabled
            fluid
          >
            {/* Modal para adicionar Comunicacao */}
            <Modal
              open={showModalAdicionarRegistro}
              onClose={() => setShowModalAdicionarRegistro(false)}
              backdrop="static"
            >
              <Modal.Header>
                <Modal.Title>Adicionar registro de comunicação</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FlexboxGrid justify="space-between">
                  <FlexboxGrid.Item colspan={12}>
                    <Form.Group controlId="quem">
                      <Form.ControlLabel>Quem</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="quem"
                        data={quemData}
                        searchable={false}
                        style={{ width: 224 }}
                        accepter={SelectPicker}
                        placeholder="Quem"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={12}>
                    <Form.Group controlId="descricao">
                      <Form.ControlLabel>Descrição</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="descricao"
                        placeholder="Descrição"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>

                  <Divider />
                </FlexboxGrid>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="lg"
                  appearance="ghost"
                  onClick={() => setShowModalAdicionarRegistro(false)}
                >
                  Cancelar
                </Button>
                <Button
                  size="lg"
                  appearance="primary"
                  onClick={handleSubmitAdicionarRegistro}
                >
                  Adicionar Registro Novo
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Fim Modal para adicionar Comunicacao */}

            {/* Modal de Comunicacao */}
            <Modal
              size="lg"
              open={showModalComunicacao}
              onClose={() => setShowModalComunicacao(false)}
            >
              <Modal.Header>
                <Modal.Title className="modal-title">Comunicação</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="modal-message">
                  Gerenciamento de comunicações internas
                </p>
                <hr />
                <Form fluid>
                  <FlexboxGrid.Item colspan={24}>
                    <Button
                      size="lg"
                      appearance="primary"
                      color="gray"
                      className="button-spacing" // Adiciona a classe de estilo e espaçamento
                      onClick={handleAdicionarRegistroComunicacaoClick}
                    >
                      Adicionar Registro de Comunicação
                    </Button>
                    <hr />
                    <Table
                      data={transferenciaContas.listaComunicacao || []}
                      height={200}
                      bordered
                      cellBordered
                      wordWrap
                      autoHeight
                    >
                      {/* Quem */}
                      <Column flexGrow={1}>
                        <HeaderCell>Quem</HeaderCell>
                        <Cell>{(rowData) => <span>{rowData.quem}</span>}</Cell>
                      </Column>

                      {/* Descrição */}
                      <Column flexGrow={1}>
                        <HeaderCell>Descrição</HeaderCell>
                        <Cell>
                          {(rowData) => <span>{rowData.descricao}</span>}
                        </Cell>
                      </Column>

                      {/* Criado em */}
                      <Column flexGrow={1}>
                        <HeaderCell>Criado em</HeaderCell>
                        <Cell>
                          {(rowData) => <span>{rowData.criadoEm}</span>}
                        </Cell>
                      </Column>

                      {/* Criado por */}
                      <Column flexGrow={1}>
                        <HeaderCell>Criado por</HeaderCell>
                        <Cell>
                          {(rowData) => <span>{rowData.criadoPor}</span>}
                        </Cell>
                      </Column>
                    </Table>
                  </FlexboxGrid.Item>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  appearance="ghost"
                  onClick={() => setShowModalComunicacao(false)}
                >
                  Cancelar
                </Button>
                <Button appearance="primary" onClick={handleSubmitComunicacao}>
                  Confirmar
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Modal de confirmação de "Concluir" */}
            <Modal
              open={showModalConcluir}
              onClose={() => setShowModalConcluir(false)}
            >
              <Modal.Header>
                <Modal.Title className="modal-title">
                  Alterar status para "Concluído"
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="modal-message">
                  Tem certeza que deseja alterar o status para "Concluído"?
                </p>
                <hr />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  appearance="ghost"
                  onClick={() => setShowModalConcluir(false)}
                >
                  Cancelar
                </Button>
                <Button appearance="primary" onClick={handleSubmitConcluir}>
                  Confirmar
                </Button>
              </Modal.Footer>
            </Modal>
            <MySteps step={step} setStep={setStep} />
            <FlexboxGrid.Item colspan={24} align="right">
              <Text bold className="status-label">
                Status:
                <span
                  className={`status-value ${getStatusClass(
                    transferenciaContas.status || "Aberto"
                  )}`}
                >
                  {transferenciaContas.status || "Aberto"}
                </span>
              </Text>
            </FlexboxGrid.Item>
            <MyComponent
              step={step}
              handleFormChange={handleFormChange}
              handleConcluirClick={handleConcluirClick}
              handleSubmitComunicacao={handleSubmitComunicacao}
            />
            <FlexboxGrid
              className="d-flex justify-content-start"
              style={{ marginTop: "auto" }}
            >
              <Divider />
              <FlexboxGrid.Item colspan={12} className="justify-content-start">
                <ButtonGroup>
                  <Button onClick={onPrevious} disabled={step === 1}>
                    Anterior
                  </Button>
                  <Button onClick={onNext} disabled={step === 3}>
                    Próximo
                  </Button>
                </ButtonGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item
                colspan={12}
                className="d-flex justify-content-end"
              >
                <Button
                  size="lg"
                  appearance="primary"
                  color="gray"
                  className="button-spacing" // Adiciona a classe de estilo e espaçamento
                  onClick={handleComunicacaoClick}
                >
                  Comunicação
                </Button>
                <Button
                  size="lg"
                  appearance="primary"
                  type="submit"
                  className="button-spacing"
                >
                  Salvar Transferência
                </Button>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Form>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Content>
  );
};

export default TransferenciaContas;
