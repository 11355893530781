import { produce } from "immer";

import types from "./types";

const INITIAL_STATE = {
  behavior: "create",
  components: {
    drawer: false,
    confirmDelete: false,
  },
  form: {
    filtering: false,
    disabled: true,
    saving: false,
  },
  imoveis: [],
  imovel: {
    documentos: [
      {
        tipo: "", // "RG", "CPF", "Certidão de casamento"
        documento: "",
      },
    ],
    endereco: {
      ruaId: null, // mongoose.Types.ObjectId
      numero: null,
      complemento: "",
    },
    proprietario: [
      {
        cpf: "", // Deve referenciar "Pessoa"
        percentual: null,
      },
    ],
    contratoId: [], // Lista de mongoose.Types.ObjectId
    orcamentoId: [], // Lista de mongoose.Types.ObjectId
    taxaAdministracao: null,
    repasse: "",
    indicadoPor: "",
    emailAdmCondominio: "",
    telefoneAdmCondominio: "",
    admCondominio: "",
    valorCondominio: null,
    valorVenda: null,
    valorAluguel: null,
    valorIptu: null,
    matricula: "",
    localizacao: "",
    inscricaoMunicipal: "",
    cartorio: "",
    imovelCodigo: null, // mongoose.Types.ObjectId
    observacao: "",
    hobbyBox: "",
    tipoImovel: "", // "Casa", "Apartamento"
    qtdVagaGaragem: null,
    qtdDormitorio: null,
    qtdBanheiro: null,
    metragemTerreno: null,
    metragemConstrucao: null,
    status: "", // "Ativo", "Inativo"
    disponibilidade: "", // "Disponível", "Vendido", "Locado"
    ocupacao: "", // "Sim", "Não"
    ocupadoPor: "", // "Inquilino", "Proprietário"
  },
};

function imovel(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_IMOVEL: {
      return produce(state, (draft) => {
        draft = { ...draft, ...action.payload };
        return draft;
      });
    }
    case types.RESET_IMOVEL: {
      return produce(state, (draft) => {
        draft.imovel = INITIAL_STATE.imovel;
        return draft;
      });
    }
    default:
      return state;
  }
}

export default imovel;
