import BarLineChartIcon from "@rsuite/icons/BarLineChart";
import EmailFillIcon from "@rsuite/icons/EmailFill";
import GrowthIcon from "@rsuite/icons/Growth";
import ListIcon from "@rsuite/icons/List";
import PeoplesIcon from "@rsuite/icons/Peoples";
import PhoneFillIcon from "@rsuite/icons/PhoneFill";
import TextImageIcon from "@rsuite/icons/TextImage";
import UserBadgeIcon from "@rsuite/icons/UserBadge";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Content,
  DatePicker,
  Divider,
  FlexboxGrid,
  Form,
  InputGroup,
  List,
  Modal,
  SelectPicker,
  Tabs,
  Text,
  Uploader,
} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import SearchFieldPessoas from "../../components/Search/SearchFieldPessoas";
import { showError, showSuccess } from "../../components/Utils/toastUtils";
import { documentoPessoaTipoData } from "../../enums/documentoPessoaTipoData";
import { fiscalData } from "../../enums/fiscalData";
import { matriculaOrgaoData } from "../../enums/matriculaOrgaoData";
import { referenciaTipoData } from "../../enums/referenciaTipoData";
import { simNaoData } from "../../enums/simNaoData";
import { tipoContaData } from "../../enums/tipoContaData";
import { tipoDocumentoPessoaData } from "../../enums/tipoDocumentoPessoaData";
import { tipoTelefoneData } from "../../enums/tipoTelefoneData";
import { vinculoTrabalhistaData } from "../../enums/vinculoTrabalhistaData";
import { fetchWithAuth } from "../../services/apiNoState";
import { fazerUpload, fetchDocumentos } from "../../services/manageUploads";
import validacao from "./validacao";

const Pessoa = () => {
  const [pessoa, setPessoa] = useState();

  // Outras listas de itens
  const [telefones, setTelefones] = useState([]);
  const [dadosBancarios, setDadosBancarios] = useState([]);
  const [referencias, setReferencias] = useState([]);
  const [matriculas, setMatriculas] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [dadosUltimoSalvo, setDadosUltimoSalvo] = useState(null);

  const { behavior } = useSelector((state) => state.pessoa);

  const [formError, setFormError] = useState({});
  const [fileList, setFileList] = useState([]);
  const formRef = useRef();
  const [formData] = useState(new FormData());
  const [showModal, setShowModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const styleButton = { alignSelf: "center" };

  const matriculaEspecieData = ["especie1", "especie2"].map((item) => ({
    label: item,
    value: item,
  }));

  const handlePreview = (file) => {
    const reader = new FileReader();

    if (typeof file === "string") {
      // Caso o arquivo seja uma URL (string)
      setPreviewUrl(file);
      setShowModal(true);
    } else if (file.blobFile) {
      // Caso seja um Blob, converte para uma URL
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file.blobFile);
    } else {
      // Caso não seja string nem Blob, lida com o objeto File diretamente
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageChange = (files) => {
    if (files.length > 1) {
      files = [files[files.length - 1]];
    }
    setFileList(files);
  };

  const handleFormChange = (formValue) => {
    setPessoa(formValue);
    for (const key in formValue) {
      formData.set(key, formValue[key]);
    }
  };

  const validateAndAddToList = (listName, newItem, clearFields) => {
    const emptyFields = Object.entries(newItem).filter(
      ([key, value]) => value === undefined || value === ""
    );

    if (emptyFields.length > 0) {
      const emptyFieldNames = emptyFields.map(([key]) => key).join(", ");
      showError(
        `Não pode incluir na lista, os seguintes campos estão vazios: ${emptyFieldNames}`
      );
      return;
    }

    formRef.current.checkForField(listName, (checkResult) => {
      if (checkResult && checkResult.hasError) {
        showError(
          `Não pode incluir na lista, por favor checar se a informação está dentro dos padrões para o campo: ${listName}.`
        );
      } else {
        setPessoa((prevPessoa) => {
          const updatedList = [...(prevPessoa[listName] || []), newItem];

          const newPessoa = {
            ...prevPessoa,
            [listName]: updatedList,
            ...clearFields,
          };
          return newPessoa;
        });

        updateStateList(listName, newItem);
      }
    });
  };

  const updateStateList = (listName, newItem) => {
    switch (listName) {
      case "telefones":
        setTelefones((prevList) => [...prevList, newItem]);
        break;
      case "dadosBancarios":
        setDadosBancarios((prevList) => [...prevList, newItem]);
        break;
      case "referencias":
        setReferencias((prevList) => [...prevList, newItem]);
        break;
      case "matriculas":
        setMatriculas((prevList) => [...prevList, newItem]);
        break;
      case "documentos":
        setDocumentos((prevList) => [...prevList, newItem]);
        //Limpar o campo de arquivo
        setFileList([]);
        break;
      default:
        console.warn(`List name ${listName} is not recognized.`);
    }
  };

  const addTelefone = () => {
    validateAndAddToList(
      "telefones",
      { telefone: pessoa.pessoaTelefone, tipo: pessoa.tipoTelefone },
      {
        pessoaTelefone: "",
        tipoTelefone: "",
      }
    );
  };

  const addDadosBancarios = () => {
    if (
      !pessoa?.agencia ||
      !pessoa?.conta ||
      !pessoa?.tipoConta ||
      !pessoa?.favorecido ||
      !pessoa?.dadoBanCpf
    ) {
      showError("Todos os campos de Dados Bancários são obrigatórios.");
      return;
    }

    const novoDadoBancario = {
      agencia: pessoa.agencia,
      conta: pessoa.conta,
      tipoConta: pessoa.tipoConta,
      favorecido: pessoa.favorecido,
      cpf: pessoa.dadoBanCpf,
    };

    setPessoa((prevPessoa) => ({
      ...prevPessoa,
      dadosBancarios: [...(prevPessoa?.dadosBancarios || []), novoDadoBancario],
      agencia: "",
      conta: "",
      tipoConta: "",
      favorecido: "",
      dadoBanCpf: "",
    }));
  };

  const addReferencia = () => {
    validateAndAddToList(
      "referencias",
      {
        nome: pessoa.referencia.nome,
        tipo: pessoa.referencia.tipo,
        endereco: pessoa.referencia.endereco,
        telefone: pessoa.referencia.telefone,
      },
      {
        referencia: {
          nome: "",
          tipo: "",
          endereco: "",
          telefone: "",
        },
      }
    );
  };

  const addMatriculas = () => {
    validateAndAddToList(
      "matriculas",
      {
        orgao: pessoa.orgao,
        matricula: pessoa.matricula,
        especie: pessoa.especie,
        senha: pessoa.senha,
        renda: pessoa.renda,
      },
      {
        orgao: "",
        matricula: "",
        especie: "",
        senha: "",
        renda: "",
      }
    );
  };

  const addDocumento = () => {
    if (!pessoa?.documentoTipoDocumento || !pessoa?.imagemDocumento?.[0]) {
      showError(
        "Os campos 'Tipo do Documento' e 'Imagem do Documento' são obrigatórios."
      );
      return;
    }

    const novoDocumento = {
      tipo: pessoa.documentoTipoDocumento,
      imagemDocumento: pessoa.imagemDocumento[0],
    };

    setPessoa((prevPessoa) => ({
      ...prevPessoa,
      documentos: [...(prevPessoa?.documentos || []), novoDocumento],
      documentoTipoDocumento: "", // Limpa o campo após adicionar
      imagemDocumento: [], // Limpa o campo após adicionar
    }));
  };

  const handleSubmit = async () => {
    formRef.current.check(async (isValid, formError) => {
      if (!isValid) {
        const errorFields = Object.keys(formError)
          .filter((key) => formError[key] !== undefined)
          .join(", ");
        showError(`Por favor, verifique os campos: ${errorFields}`);
        return;
      }

      try {
        // Verifica se houve mudanças antes de enviar
        if (await hasAnyChanges()) {
          // Garante que as imagens foram validadas e enviadas antes de continuar
          await validaEUploadImagens();

          const payload = {
            ...pessoa,
            documentos: pessoa.documentos?.map((doc) => ({
              tipo: doc.tipo,
              imagemDocumento: doc.nome || doc.imagemDocumento,
            })),
          };

          const url =
            behavior === "create" ? "/pessoa" : `/pessoa/${pessoa.cpf}`;
          const method = behavior === "create" ? "POST" : "PUT";

          const response = await fetchWithAuth(url, {
            method,
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          });

          if (response.error) {
            showError(`Erro ao tentar salvar os dados: ${response.message}`);
          } else {
            showSuccess(
              `${pessoa.nome} ${
                behavior === "create" ? "salvo" : "alterado"
              } com sucesso`
            );
            // Atualiza os dados após o salvamento bem-sucedido
            setDadosUltimoSalvo(payload);
          }
        }
      } catch (error) {
        showError(`Erro: ${error.message}`);
      }
    });
  };

  const validaEUploadImagens = async () => {
    const arquivosParaUpload = [];

    if (pessoa.documentos !== undefined) {
      pessoa.documentos.forEach((doc, index) => {
        const file = doc.imagemDocumento?.blobFile;
        if (file) {
          arquivosParaUpload.push({
            arquivo: file,
            contexto: {
              field: "documentos",
              subField: "imagemDocumento",
              index,
            },
          });
        }
      });

      if (arquivosParaUpload.length === 0) return;

      // Faz upload das imagens
      const resultado = await fazerUpload(arquivosParaUpload);

      // Atualiza os documentos com os novos caminhos das imagens
      if (resultado) {
        resultado.forEach((file) => {
          const { index } = file.contexto;
          pessoa.documentos[index].imagemDocumento = file.fileName;
        });
      }
    }
  };

  const hasAnyChanges = async () => {
    const savedData = dadosUltimoSalvo || {};
    const hasChanges = JSON.stringify(pessoa) !== JSON.stringify(savedData);
    return hasChanges;
  };

  const removeDadosBancarios = (index) => {
    const updatedDadosBancarios =
      pessoa.dadosBancarios?.filter((_, i) => i !== index) || [];
    setPessoa({ ...pessoa, dadosBancarios: updatedDadosBancarios });
  };

  const removeReferencia = (index) => {
    const updatedReferencia = referencias.filter((_, i) => i !== index);
    setReferencias(updatedReferencia);
  };

  const removeMatriculas = (index) => {
    const updatedMatriculas = matriculas.filter((_, i) => i !== index);
    setMatriculas(updatedMatriculas);
  };

  const removeTelefone = (index) => {
    const updatedTelefone = telefones.filter((_, i) => i !== index);
    setTelefones(updatedTelefone);
  };

  const removeDocumento = (index) => {
    const updatedDocumentos =
      pessoa.documentos?.filter((_, i) => i !== index) || [];
    setPessoa({ ...pessoa, documentos: updatedDocumentos });
  };

  const handleSetPessoa = (pessoaData) => {
    setReferencias(pessoaData.referencias || []);
    setMatriculas(pessoaData.matriculas || []);
    setTelefones(pessoaData.telefones || []);

    setPessoa(() => ({
      ...pessoaData,
      documentos: combinarDocumentos(
        fetchDocumentos(pessoaData.documentos || []),
        pessoaData.documentos || []
      ),
    }));
  };

  // Função para combinar documentos existentes com as informações adicionais de pessoaData.documentos
  const combinarDocumentos = (documentosExistentes, documentosPessoa) => {
    return documentosExistentes.map((documento, index) => {
      const infoAdicional = documentosPessoa[index];
      return {
        ...documento,
        tipo: infoAdicional?.tipo || null,
        nome: infoAdicional?.nome || null,
        conteudo: infoAdicional?.conteudo || null,
      };
    });
  };

  // Função para validar o formato do CEP
  const validarCep = (cep) => {
    const regex = /^[0-9]{5}-?[0-9]{3}$/;
    return regex.test(cep);
  };
  const [loading, setLoading] = useState(false);
  // Função para buscar o endereço pelo CEP
  const buscarCep = async () => {
    if (!validarCep(pessoa.endereco.cep)) {
      showError(
        "CEP inválido. Por favor, insira um CEP no formato XXXXX-XXX ou XXXXXXXX."
      );
      return;
    }

    try {
      setLoading(true);

      const response = await fetchWithAuth(
        `/endereco/buscar-cep/${pessoa.endereco.cep}`
      );
      if (!response.error) {
        pessoa.endereco.rua = response.rua;
        pessoa.endereco.bairro = response.bairro;
        pessoa.endereco.cidade = response.cidade;
        pessoa.endereco.estado = response.estado;
        pessoa.endereco.pais = response.pais;
        showSuccess("Endereço encontrado com sucesso!");
      } else {
        showError("CEP não encontrado.");
      }
    } catch (error) {
      showError("Erro ao buscar o CEP.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Content className="m-2">
        <FlexboxGrid>
          <FlexboxGrid.Item colspan={24}>
            <FlexboxGrid className="contentHeader" align="middle">
              <FlexboxGrid.Item colspan={8}>
                <SearchFieldPessoas setPessoa={handleSetPessoa} />
              </FlexboxGrid.Item>

              <FlexboxGrid.Item colspan={8} align="middle">
                <Text size="xxl" color="blue" weight="semibold">
                  Gerenciar Pessoa
                </Text>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <Form
              ref={formRef}
              className="subContent formSpacing"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              model={validacao}
              formValue={pessoa}
              onChange={handleFormChange}
              onCheck={(formError) => setFormError(formError)}
              onSubmit={handleSubmit}
              fluid
            >
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={6}>
                  <Form.Group controlId="nome">
                    <Form.ControlLabel>Nome</Form.ControlLabel>
                    <Form.Control size="lg" name="nome" placeholder="Nome" />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6}>
                  <Form.Group controlId="cpf">
                    <Form.ControlLabel>CPF</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      readOnly={behavior !== "create"}
                      name="cpf"
                      placeholder="CPF"
                    />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6}>
                  <Form.Group controlId="dataNascimento">
                    <Form.ControlLabel>Data Nascimento</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      name="dataNascimento"
                      searchable={false}
                      style={{ width: "100%" }}
                      accepter={DatePicker}
                      value={
                        pessoa?.dataNascimento
                          ? new Date(pessoa.dataNascimento)
                          : null
                      }
                      format="dd/MM/yyyy"
                      placeholder="Data Nascimento"
                    />

                    {/* <Form.Control size="lg" name="dataNascimento" type="date" /> */}
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6}>
                  <Form.Group controlId="falecido">
                    <Form.ControlLabel>Falecido</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      name="falecido"
                      data={simNaoData}
                      searchable={false}
                      style={{ width: "100%" }}
                      accepter={SelectPicker}
                      placeholder="Falecido"
                    />
                  </Form.Group>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={6}>
                  <Form.Group controlId="tipoDocumento">
                    <Form.ControlLabel>Tipo Documento</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      name="tipoDocumento"
                      data={tipoDocumentoPessoaData}
                      searchable={false}
                      style={{ width: "100%" }}
                      accepter={SelectPicker}
                      placeholder="Tipo Documento"
                    />
                  </Form.Group>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={6}>
                  <Form.Group controlId="documento">
                    <Form.ControlLabel>Número do Documento</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      name="documento"
                      placeholder="Documento"
                    />
                  </Form.Group>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={6}>
                  <Form.Group controlId="tipoTelefone">
                    <Form.ControlLabel>Tipo Telefone</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      name="tipoTelefone"
                      data={tipoTelefoneData}
                      searchable={false}
                      style={{ width: "100%" }}
                      accepter={SelectPicker}
                      placeholder="Tipo Telefone"
                    />
                  </Form.Group>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={5}>
                  <Form.Group controlId="pessoaTelefone">
                    <Form.ControlLabel>Telefone</Form.ControlLabel>
                    <InputGroup>
                      <InputGroup.Addon>
                        <PhoneFillIcon />
                      </InputGroup.Addon>
                      <Form.Control
                        size="lg"
                        name="pessoaTelefone"
                        placeholder="Telefone"
                      />
                    </InputGroup>
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={1} style={styleButton}>
                  <Button size="lg" color="primary" onClick={addTelefone}>
                    +
                  </Button>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6}>
                  <Form.Group controlId="email">
                    <Form.ControlLabel>Email</Form.ControlLabel>
                    <InputGroup>
                      <InputGroup.Addon>
                        <EmailFillIcon />
                      </InputGroup.Addon>
                      <Form.Control
                        size="lg"
                        name="email"
                        type="email"
                        placeholder="Email"
                      />
                    </InputGroup>
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                <FlexboxGrid.Item
                  colspan={12}
                  className="mt-3"
                  style={{ height: "60px", overflowY: "auto" }}
                >
                  <List size="lg" autoScroll bordered hover>
                    {telefones.map((doc, index) => (
                      <List.Item key={index} index={index}>
                        <div className="d-flex align-items-center">
                          <div className="form-group col-5">{doc.tipo}</div>
                          <div className="form-group col-5">{doc.telefone}</div>
                          <Button
                            color="red"
                            size="lg"
                            onClick={() => removeTelefone(index)}
                          >
                            🗑️
                          </Button>
                        </div>
                      </List.Item>
                    ))}
                  </List>
                </FlexboxGrid.Item>
                <Divider />
                <FlexboxGrid.Item colspan={12}>
                  <Form.Group controlId="endereco.rua">
                    <Form.ControlLabel>Rua</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      placeholder="Rua"
                      value={pessoa?.endereco?.rua || ""}
                      onChange={(value) => {
                        setPessoa((prevPessoa) => ({
                          ...prevPessoa,
                          endereco: {
                            ...prevPessoa.endereco,
                            rua: value,
                          },
                        }));
                      }}
                    />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6}>
                  <Form.Group controlId="endereco.cep">
                    <Form.ControlLabel>CEP</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      placeholder="CEP"
                      value={pessoa?.endereco?.cep || ""}
                      onChange={(value) => {
                        setPessoa((prevPessoa) => ({
                          ...prevPessoa,
                          endereco: {
                            ...prevPessoa.endereco,
                            cep: value,
                          },
                        }));
                      }}
                    />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item style={styleButton} colspan={6}>
                  <Button
                    size="lg"
                    appearance="primary"
                    onClick={buscarCep}
                    loading={loading}
                  >
                    Buscar Endereço
                  </Button>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6}>
                  <Form.Group controlId="endereco.numero">
                    <Form.ControlLabel>Número</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      placeholder="Número"
                      value={pessoa?.endereco?.numero || ""}
                      onChange={(value) => {
                        setPessoa((prevPessoa) => ({
                          ...prevPessoa,
                          endereco: {
                            ...prevPessoa.endereco,
                            numero: value,
                          },
                        }));
                      }}
                    />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6}>
                  <Form.Group controlId="endereco.pais">
                    <Form.ControlLabel>País</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      placeholder="País"
                      value={pessoa?.endereco?.pais || ""}
                      onChange={(value) => {
                        setPessoa((prevPessoa) => ({
                          ...prevPessoa,
                          endereco: {
                            ...prevPessoa.endereco,
                            pais: value,
                          },
                        }));
                      }}
                    />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6}>
                  <Form.Group controlId="endereco.estado">
                    <Form.ControlLabel>Estado</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      placeholder="Estado"
                      value={pessoa?.endereco?.estado || ""}
                      onChange={(value) => {
                        setPessoa((prevPessoa) => ({
                          ...prevPessoa,
                          endereco: {
                            ...prevPessoa.endereco,
                            estado: value,
                          },
                        }));
                      }}
                    />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6}>
                  <Form.Group controlId="endereco.cidade">
                    <Form.ControlLabel>Cidade</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      placeholder="Cidade"
                      value={pessoa?.endereco?.cidade || ""}
                      onChange={(value) => {
                        setPessoa((prevPessoa) => ({
                          ...prevPessoa,
                          endereco: {
                            ...prevPessoa.endereco,
                            cidade: value,
                          },
                        }));
                      }}
                    />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6}>
                  <Form.Group controlId="endereco.bairro">
                    <Form.ControlLabel>Bairro</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      placeholder="Bairro"
                      value={pessoa?.endereco?.bairro || ""}
                      onChange={(value) => {
                        setPessoa((prevPessoa) => ({
                          ...prevPessoa,
                          endereco: {
                            ...prevPessoa.endereco,
                            bairro: value,
                          },
                        }));
                      }}
                    />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6} className="mb-4">
                  <Form.Group controlId="endereco.complemento">
                    <Form.ControlLabel>Complemento</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      placeholder="Complemento"
                      value={pessoa?.endereco?.complemento || ""}
                      onChange={(value) => {
                        setPessoa((prevPessoa) => ({
                          ...prevPessoa,
                          endereco: {
                            ...prevPessoa.endereco,
                            complemento: value,
                          },
                        }));
                      }}
                    />
                  </Form.Group>
                </FlexboxGrid.Item>
              </FlexboxGrid>

              <Divider />

              <Tabs defaultActiveKey="1">
                <Tabs.Tab
                  eventKey="1"
                  title={
                    <div>
                      <UserBadgeIcon style={{ marginRight: 8 }} /> Trabalho
                    </div>
                  }
                >
                  <FlexboxGrid>
                    <FlexboxGrid.Item colspan={5}>
                      <Form.Group controlId="pessoaTrabalho.profissao">
                        <Form.ControlLabel>Profissão</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          placeholder="Profissão"
                          value={pessoa?.pessoaTrabalho?.profissao || ""}
                          onChange={(value) => {
                            setPessoa((prevPessoa) => ({
                              ...prevPessoa,
                              pessoaTrabalho: {
                                ...prevPessoa.pessoaTrabalho,
                                profissao: value,
                              },
                            }));
                          }}
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={3}>
                      <Form.Group controlId="pessoaTrabalho.salario">
                        <Form.ControlLabel>Salário</Form.ControlLabel>
                        <InputGroup>
                          <InputGroup.Addon>R$</InputGroup.Addon>
                          <Form.Control
                            size="lg"
                            placeholder="Salário"
                            value={pessoa?.pessoaTrabalho?.salario || ""}
                            onChange={(value) => {
                              setPessoa((prevPessoa) => ({
                                ...prevPessoa,
                                pessoaTrabalho: {
                                  ...prevPessoa.pessoaTrabalho,
                                  salario: value,
                                },
                              }));
                            }}
                          />
                        </InputGroup>
                      </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item colspan={5}>
                      <Form.Group controlId="pessoaTrabalho.vinculoTrabalhista">
                        <Form.ControlLabel>
                          Vínculo Trabalhista
                        </Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          data={vinculoTrabalhistaData}
                          searchable={false}
                          style={{ width: 224 }}
                          accepter={SelectPicker}
                          placeholder="Vínculo Trabalhista"
                          value={
                            pessoa?.pessoaTrabalho?.vinculoTrabalhista || ""
                          }
                          onChange={(value) => {
                            setPessoa((prevPessoa) => ({
                              ...prevPessoa,
                              pessoaTrabalho: {
                                ...prevPessoa.pessoaTrabalho,
                                vinculoTrabalhista: value,
                              },
                            }));
                          }}
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={5}>
                      <Form.Group controlId="pessoaTrabalho.enderecoLocalTrabalho">
                        <Form.ControlLabel>
                          Endereço Local Trabalho
                        </Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          placeholder="Endereço Local Trabalho"
                          value={
                            pessoa?.pessoaTrabalho?.enderecoLocalTrabalho || ""
                          }
                          onChange={(value) => {
                            setPessoa((prevPessoa) => ({
                              ...prevPessoa,
                              pessoaTrabalho: {
                                ...prevPessoa.pessoaTrabalho,
                                enderecoLocalTrabalho: value,
                              },
                            }));
                          }}
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={5}>
                      <Form.Group controlId="pessoaTrabalho.telefoneTrabalho">
                        <Form.ControlLabel>Telefone Trabalho</Form.ControlLabel>
                        <InputGroup>
                          <InputGroup.Addon>
                            <PhoneFillIcon />
                          </InputGroup.Addon>
                          <Form.Control
                            size="lg"
                            placeholder="Telefone Trabalho"
                            value={
                              pessoa?.pessoaTrabalho?.telefoneTrabalho || ""
                            }
                            onChange={(value) => {
                              setPessoa((prevPessoa) => ({
                                ...prevPessoa,
                                pessoaTrabalho: {
                                  ...prevPessoa.pessoaTrabalho,
                                  telefoneTrabalho: value,
                                },
                              }));
                            }}
                          />
                        </InputGroup>
                      </Form.Group>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </Tabs.Tab>
                <Tabs.Tab
                  eventKey="2"
                  title={
                    <div>
                      <BarLineChartIcon style={{ marginRight: 8 }} />
                      Dados Bancários
                    </div>
                  }
                >
                  <FlexboxGrid>
                    <FlexboxGrid.Item colspan={4}>
                      <Form.Group controlId="agencia">
                        <Form.ControlLabel>Agência</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          name="agencia"
                          placeholder="Agência"
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4}>
                      <Form.Group controlId="conta">
                        <Form.ControlLabel>Conta</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          name="conta"
                          placeholder="Conta"
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={5}>
                      <Form.Group controlId="tipoConta">
                        <Form.ControlLabel>Tipo Conta</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          name="tipoConta"
                          data={tipoContaData}
                          searchable={false}
                          style={{ width: 224 }}
                          accepter={SelectPicker}
                          placeholder="Tipo Conta"
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4}>
                      <Form.Group controlId="favorecido">
                        <Form.ControlLabel>Favorecido</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          name="favorecido"
                          placeholder="Favorecido"
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4}>
                      <Form.Group controlId="dadoBanCpf">
                        <Form.ControlLabel>CPF</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          name="dadoBanCpf"
                          placeholder="CPF"
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={1} style={styleButton}>
                      <Button
                        color="primary"
                        size="lg"
                        onClick={addDadosBancarios}
                      >
                        +
                      </Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>

                  <List
                    size="lg"
                    bordered
                    hover
                    className="w-100 mt-3"
                    style={{ height: "100px", overflowY: "auto" }}
                  >
                    {pessoa?.dadosBancarios &&
                      pessoa?.dadosBancarios.map((doc, index) => (
                        <List.Item key={index} index={index}>
                          <div className="d-flex align-items-center">
                            <div className="form-group col-2">
                              {doc.agencia}
                            </div>
                            <div className="form-group col-2">{doc.conta}</div>
                            <div className="form-group col-2">
                              {doc.tipoConta}
                            </div>
                            <div className="form-group col-2">
                              {doc.favorecido}
                            </div>
                            <div className="form-group col-2">{doc.cpf}</div>
                            <Button
                              color="red"
                              size="lg"
                              onClick={() => removeDadosBancarios(index)}
                            >
                              🗑️
                            </Button>
                          </div>
                        </List.Item>
                      ))}
                  </List>
                </Tabs.Tab>
                <Tabs.Tab
                  eventKey="3"
                  title={
                    <div>
                      <PeoplesIcon style={{ marginRight: 8 }} />
                      Referência e Cônjuge
                    </div>
                  }
                >
                  <FlexboxGrid>
                    <FlexboxGrid.Item colspan={6}>
                      <Form.Group controlId="conjuge.nome">
                        <Form.ControlLabel>Cônjuge Nome</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          placeholder="Cônjuge Nome"
                          value={pessoa?.conjuge?.nome || ""}
                          onChange={(value) => {
                            setPessoa((prevPessoa) => ({
                              ...prevPessoa,
                              conjuge: {
                                ...prevPessoa.conjuge,
                                nome: value,
                              },
                            }));
                          }}
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6}>
                      <Form.Group controlId="conjuge.cpf">
                        <Form.ControlLabel>Cônjuge CPF</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          placeholder="Cônjuge CPF"
                          value={pessoa?.conjuge?.cpf || ""}
                          onChange={(value) => {
                            setPessoa((prevPessoa) => ({
                              ...prevPessoa,
                              conjuge: {
                                ...prevPessoa.conjuge,
                                cpf: value,
                              },
                            }));
                          }}
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6}>
                      <Form.Group controlId="conjuge.rg">
                        <Form.ControlLabel>Cônjuge RG</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          placeholder="Cônjuge RG"
                          value={pessoa?.conjuge?.rg || ""}
                          onChange={(value) => {
                            setPessoa((prevPessoa) => ({
                              ...prevPessoa,
                              conjuge: {
                                ...prevPessoa.conjuge,
                                rg: value,
                              },
                            }));
                          }}
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={5}>
                      <Form.Group controlId="conjuge.telefone">
                        <Form.ControlLabel>Cônjuge Telefone</Form.ControlLabel>
                        <InputGroup>
                          <InputGroup.Addon>
                            <PhoneFillIcon />
                          </InputGroup.Addon>
                          <Form.Control
                            size="lg"
                            placeholder="Cônjuge Telefone"
                            value={pessoa?.conjuge?.telefone || ""}
                            onChange={(value) => {
                              setPessoa((prevPessoa) => ({
                                ...prevPessoa,
                                conjuge: {
                                  ...prevPessoa.conjuge,
                                  telefone: value,
                                },
                              }));
                            }}
                          />
                        </InputGroup>
                      </Form.Group>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                  <FlexboxGrid>
                    <FlexboxGrid.Item colspan={6}>
                      <Form.Group controlId="referencia.nome">
                        <Form.ControlLabel>Referencia Nome</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          placeholder="Referencia Nome"
                          value={pessoa?.referencia?.nome || ""}
                          onChange={(value) => {
                            setPessoa((prevPessoa) => ({
                              ...prevPessoa,
                              referencia: {
                                ...prevPessoa.referencia,
                                nome: value,
                              },
                            }));
                          }}
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6}>
                      <Form.Group controlId="referencia.tipo">
                        <Form.ControlLabel>Referência Tipo</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          data={referenciaTipoData}
                          searchable={false}
                          style={{ width: 224 }}
                          accepter={SelectPicker}
                          placeholder="Referência Tipo"
                          value={pessoa?.referencia?.tipo || ""}
                          onChange={(value) => {
                            setPessoa((prevPessoa) => ({
                              ...prevPessoa,
                              referencia: {
                                ...prevPessoa.referencia,
                                tipo: value,
                              },
                            }));
                          }}
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6}>
                      <Form.Group controlId="referencia.endereco">
                        <Form.ControlLabel>
                          Referência Endereço
                        </Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          placeholder="Referência Endereço"
                          value={pessoa?.referencia?.endereco || ""}
                          onChange={(value) => {
                            setPessoa((prevPessoa) => ({
                              ...prevPessoa,
                              referencia: {
                                ...prevPessoa.referencia,
                                endereco: value,
                              },
                            }));
                          }}
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={5}>
                      <Form.Group controlId="referencia.telefone">
                        <Form.ControlLabel>
                          Referência Telefone
                        </Form.ControlLabel>
                        <InputGroup>
                          <InputGroup.Addon>
                            <PhoneFillIcon />
                          </InputGroup.Addon>
                          <Form.Control
                            size="lg"
                            placeholder="Referência Telefone"
                            value={pessoa?.referencia?.telefone || ""}
                            onChange={(value) => {
                              setPessoa((prevPessoa) => ({
                                ...prevPessoa,
                                referencia: {
                                  ...prevPessoa.referencia,
                                  telefone: value,
                                },
                              }));
                            }}
                          />
                        </InputGroup>
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={1} style={styleButton}>
                      <Button color="primary" size="lg" onClick={addReferencia}>
                        +
                      </Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>

                  <List
                    size="lg"
                    bordered
                    hover
                    className="w-100 mt-3"
                    style={{ height: "100px", overflowY: "auto" }}
                  >
                    {referencias.map((doc, index) => (
                      <List.Item key={index} index={index}>
                        <div className="d-flex align-items-center">
                          <div className="form-group col-3">{doc.nome}</div>
                          <div className="form-group col-3">{doc.tipo}</div>
                          <div className="form-group col-3">{doc.endereco}</div>
                          <div className="form-group col-2">{doc.telefone}</div>
                          <Button
                            color="red"
                            size="lg"
                            onClick={() => removeReferencia(index)}
                          >
                            🗑️
                          </Button>
                        </div>
                      </List.Item>
                    ))}
                  </List>
                </Tabs.Tab>
                <Tabs.Tab
                  eventKey="4"
                  title={
                    <div>
                      <TextImageIcon style={{ marginRight: 8 }} /> Matrícula
                    </div>
                  }
                >
                  <FlexboxGrid>
                    <FlexboxGrid.Item colspan={5}>
                      <Form.Group controlId="orgao">
                        <Form.ControlLabel>Matrícula Órgão</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          name="orgao"
                          data={matriculaOrgaoData}
                          searchable={false}
                          style={{ width: 224 }}
                          accepter={SelectPicker}
                          placeholder="Matrícula Órgão"
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4}>
                      <Form.Group controlId="matricula">
                        <Form.ControlLabel>Matrícula</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          name="matricula"
                          placeholder="Matrícula"
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={5}>
                      <Form.Group controlId="especie">
                        <Form.ControlLabel>Espécie</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          name="especie"
                          data={matriculaEspecieData}
                          searchable={false}
                          style={{ width: 224 }}
                          accepter={SelectPicker}
                          placeholder="Espécie"
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4}>
                      <Form.Group controlId="senha">
                        <Form.ControlLabel>Senha</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          name="senha"
                          placeholder="Senha"
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4}>
                      <Form.Group controlId="renda">
                        <Form.ControlLabel>Renda</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          name="renda"
                          placeholder="Renda"
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={1} style={styleButton}>
                      <Button color="primary" size="lg" onClick={addMatriculas}>
                        +
                      </Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>

                  <List
                    size="lg"
                    bordered
                    hover
                    className="w-100 mt-3"
                    style={{ height: "100px", overflowY: "auto" }}
                  >
                    {matriculas &&
                      matriculas.map((doc, index) => (
                        <List.Item key={index} index={index}>
                          <div className="d-flex align-items-center">
                            <div className="form-group col-2">{doc.orgao}</div>
                            <div className="form-group col-2">
                              {doc.matricula}
                            </div>
                            <div className="form-group col-2">
                              {doc.especie}
                            </div>
                            <div className="form-group col-2">{doc.senha}</div>
                            <div className="form-group col-3">{doc.renda}</div>
                            <Button
                              color="red"
                              size="lg"
                              onClick={() => removeMatriculas(index)}
                            >
                              🗑️
                            </Button>
                          </div>
                        </List.Item>
                      ))}
                  </List>
                </Tabs.Tab>
                <Tabs.Tab
                  eventKey="5"
                  title={
                    <div>
                      <ListIcon style={{ marginRight: 8 }} /> Lista de
                      Documentos
                    </div>
                  }
                >
                  <FlexboxGrid>
                    <FlexboxGrid.Item colspan={5}>
                      <Form.Group controlId="documentoTipoDocumento">
                        <Form.ControlLabel>Tipo Documento</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          name="documentoTipoDocumento"
                          data={documentoPessoaTipoData}
                          searchable={false}
                          style={{ width: 224 }}
                          accepter={SelectPicker}
                          placeholder="Tipo Documento"
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6} style={styleButton}>
                      <Form.Group controlId="imagemDocumento">
                        <Form.Control
                          size="lg"
                          name="imagemDocumento"
                          fileList={fileList}
                          onPreview={handlePreview}
                          autoUpload={false} // Prevents automatic upload
                          listType="picture-text"
                          onChange={handleImageChange}
                          renderFileInfo={(file, fileElement) => {
                            return (
                              <div onClick={() => handlePreview(file)}>
                                <span>File Name: {file.name}</span>
                                <p>File URL: {file.url}</p>
                              </div>
                            );
                          }}
                          accepter={Uploader}
                        >
                          <Button color="primary" size="lg">
                            Selecione o arquivo
                          </Button>
                        </Form.Control>
                        <Modal
                          open={showModal}
                          onClose={() => setShowModal(false)}
                        >
                          <Modal.Header>
                            <Modal.Title>Pré-visualização</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {previewUrl && (
                              <img
                                src={previewUrl}
                                alt="Preview"
                                style={{ width: "100%" }}
                              />
                            )}
                          </Modal.Body>
                          <Modal.Footer>
                            <Button onClick={() => setShowModal(false)}>
                              Fechar
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={3} style={styleButton}>
                      <Button color="primary" size="lg" onClick={addDocumento}>
                        +
                      </Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                  <List size="lg" autoScroll bordered hover>
                    {pessoa?.documentos &&
                      pessoa?.documentos.map((doc, index) => (
                        <List.Item key={index} index={index}>
                          <FlexboxGrid align="middle">
                            <FlexboxGrid.Item colspan={10}>
                              {doc.tipo}
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={10}>
                              {" "}
                              <div
                                onClick={() =>
                                  handlePreview(doc.imagemDocumento)
                                }
                              >
                                <img
                                  src={
                                    // Verifica se o retorno é uma URL (exemplo 1)
                                    typeof doc.imagemDocumento === "string"
                                      ? doc.imagemDocumento
                                      : // Verifica se é um objeto com blobFile (exemplo 2)
                                      doc.imagemDocumento.blobFile
                                      ? URL.createObjectURL(
                                          doc.imagemDocumento.blobFile
                                        )
                                      : null
                                  }
                                  alt="thumbnail"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={4}>
                              <Button
                                color="red"
                                size="lg"
                                onClick={() => removeDocumento(index)}
                              >
                                🗑️
                              </Button>
                            </FlexboxGrid.Item>
                          </FlexboxGrid>
                        </List.Item>
                      ))}
                  </List>
                </Tabs.Tab>
                <Tabs.Tab
                  eventKey="6"
                  title={
                    <div>
                      <GrowthIcon style={{ marginRight: 8 }} /> Fiscal
                    </div>
                  }
                >
                  <FlexboxGrid>
                    <FlexboxGrid.Item colspan={5}>
                      <Form.Group controlId="issqn">
                        <Form.ControlLabel>ISSQN</Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          name="issqn"
                          data={fiscalData}
                          searchable={false}
                          style={{ width: 224 }}
                          accepter={SelectPicker}
                          placeholder="ISSQN"
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={5}>
                      <Form.Group controlId="descontoSimplificado">
                        <Form.ControlLabel>
                          Desconto Simplificado
                        </Form.ControlLabel>
                        <Form.Control
                          size="lg"
                          name="descontoSimplificado"
                          data={fiscalData}
                          searchable={false}
                          style={{ width: 224 }}
                          accepter={SelectPicker}
                          placeholder="Desconto Simplificado"
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </Tabs.Tab>
              </Tabs>
              <Divider />
              <FlexboxGrid
                className="d-flex justify-content-start"
                style={{ marginTop: "auto" }}
              >
                <FlexboxGrid.Item
                  colspan={24}
                  className="d-flex justify-content-end"
                >
                  <Button size="lg" appearance="primary" type="submit">
                    {behavior === "create" ? "Salvar" : "Atualizar"} Pessoa
                  </Button>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Form>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </>
  );
};

export default Pessoa;
