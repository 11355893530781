const itensLazerData = [
  { name: "churrasqueira", label: "Churrasqueira" },
  { name: "piscina", label: "Piscina" },
  { name: "hidromassagem", label: "Hidromassagem" },
  { name: "ofuro", label: "Ofurô" },
  { name: "sauna", label: "Sauna" },
  { name: "jardimInverno", label: "Jardim de inverno" },
  { name: "lareira", label: "Lareira" },
  { name: "adega", label: "Adega" },
];

module.exports = { itensLazerData };
