import { all } from "redux-saga/effects";

import aprovacaoLocatario from "./modules/aprovacaoLocatario/saga";
import authSaga from "./modules/auth/saga"; // Importe as sagas de autenticação
import imovel from "./modules/imovel/saga";
import pessoas from "./modules/pessoa/saga";

export default function* rootSaga() {
  return yield all([authSaga(), pessoas, imovel, aprovacaoLocatario]);
}
