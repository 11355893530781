import { useEffect, useState } from "react";
import { FlexboxGrid, Header, Text } from "rsuite";
import { fetchWithAuth } from "../../services/apiNoState";
import useIdleTimer from "../hooks/useIdleTimer"; // Importe o hook personalizado

const TopHeader = () => {
  const [usuario, setUsuario] = useState(null);
  const timeLeft = useIdleTimer(1800000); // 180000ms = 30 minutos

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    const carregarUsuario = async () => {
      try {
        const response = await fetchWithAuth(`/auth/usuario`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json", // Especifica o tipo de conteúdo
          },
        });
        setUsuario(response);
      } catch (error) {
        console.error("Erro ao carregar usuário:", error);
      }
    };

    carregarUsuario();
  }, []);

  return (
    <Header className="page-header">
      <FlexboxGrid justify="end">
        <FlexboxGrid.Item colspan={4}>
          <Text className="text-white">
            Tempo restante: {formatTime(timeLeft)}
          </Text>
          <Text className="text-white">{usuario?.name || "Carregando..."}</Text>
          <Text className="text-white">Administrador</Text>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={2}>
          <img
            src={usuario?.picture || "https://www.gravatar.com/avatar/?d=mp"}
            style={{ borderRadius: "50%", width: 40, height: 40 }}
            alt="Foto de perfil"
          />
          <span className="mdi mdi-chevron-down text-white"></span>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Header>
  );
};
export default TopHeader;
