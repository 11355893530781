import {
  FlexboxGrid,
  Form,
  Button,
  Divider,
  ButtonGroup,
  Content,
  Text,
  Modal,
  SelectPicker,
} from "rsuite";
import { useRef, useContext, useState, useEffect } from "react";
import MySteps from "./MySteps";
import MyComponent from "./MyComponent";
import { ContratoLocacaoContext } from "./ContratoLocacaoContext";
import { fetchWithAuth } from "../../services/apiNoState";
import { fazerUpload, addFileToUploader } from "../../services/manageUploads";
import {
  step1Schema,
  step2Schema,
  step3Schema,
  step4Schema,
  step5Schema,
  step6Schema,
  step7Schema,
} from "./validacao";
import { showError, showSuccess } from "../../components/Utils/toastUtils";
import { useLocation } from "react-router-dom";
import { modalidadeData } from "../../enums/modalidadeData";

/**
 * Componente principal do contrato de locação.
 * Este componente gerencia o estado do contrato, a navegação entre etapas e a validação do formulário.
 */
const ContratoLocacao = () => {
  const {
    contratoLocacao,
    setContratoLocacao,
    validateCurrentStep,
    stepData,
    handleStepChange,
    preencherSteps,
    filterStep1Data,
    filterStep2Data,
    filterStep3Data,
    filterStep4Data,
    filterStep5Data,
    filterStep6Data,
    filterStep7Data,
    getStatusClass,
  } = useContext(ContratoLocacaoContext);

  const [step, setStep] = useState(1);

  const [formError, setFormError] = useState({});
  const [formDisabled, setFormDisabled] = useState(false); // Estado para controlar o formulário

  const location = useLocation();
  const contratoParam = location.state?.contrato; // Acessa 'contrato' de 'location.state'
  const [showModalPerdeu, setShowModalPerdeu] = useState(false);
  const [showModalGanhou, setShowModalGanhou] = useState(false);
  const [motivoDesaprovadoPerdeu, setMotivoDesaprovadoPerdeu] = useState(null);

  const formRef = useRef();

  const motivoDesaprovadoData = [
    "Desistencia proprietário",
    "Desistencia locatário",
    "Motivo força maior",
  ].map((item) => ({
    label: item,
    value: item,
  }));

  /**
   * Efeito colateral que carrega os dados do contrato de locação ao montar o componente.
   * Atualiza o estado com os dados do contrato se o parâmetro de contrato estiver presente na localização.
   */
  useEffect(() => {
    const carregarContratoLocacao = async () => {
      if (contratoParam) {
        // Atualiza o estado com os dados vindos de `contratoParam`
        setContratoLocacao(() => {
          let updates = {
            ...contratoParam,
            modalidade: contratoParam.modalidade || modalidadeData[0].value, // Valida e preenche com "Mês vencido" se vazio
            locatarios: contratoParam.locatario ? contratoParam.locatario : [],
            moradores: contratoParam.morador ? contratoParam.morador : [],
            fiadores: contratoParam.fiador ? contratoParam.fiador : [],
            proprietarios: contratoParam.proprietario
              ? contratoParam.proprietario
              : [],
          };

          // Verifica se existem as informações de imagemCpfl, imagemDae, imagemGas e imagemCondominio
          if (contratoParam.imagemCpflField) {
            updates.imagemCpflField = addFileToUploader(
              contratoParam.imagemCpflField.conteudo,
              contratoParam.imagemCpflField.nome
            );
          }

          if (contratoParam.imagemDaeField) {
            updates.imagemDaeField = addFileToUploader(
              contratoParam.imagemDaeField.conteudo,
              contratoParam.imagemDaeField.nome
            );
          }

          if (contratoParam.imagemGasField) {
            updates.imagemGasField = addFileToUploader(
              contratoParam.imagemGasField.conteudo,
              contratoParam.imagemGasField.nome
            );
          }

          if (contratoParam.imagemCondominioField) {
            updates.imagemCondominioField = addFileToUploader(
              contratoParam.imagemCondominioField.conteudo,
              contratoParam.imagemCondominioField.nome
            );
          }

          if (contratoParam.imagemSeguroIncendioField) {
            contratoParam.imagemSeguroIncendioField =
              updates.imagemSeguroIncendioField = addFileToUploader(
                contratoParam.imagemSeguroIncendioField.conteudo,
                contratoParam.imagemSeguroIncendioField.nome
              );
          }

          if (contratoParam.imagemLocacaoField) {
            updates.imagemLocacaoField = addFileToUploader(
              contratoParam.imagemLocacaoField.conteudo,
              contratoParam.imagemLocacaoField.nome
            );
          }

          if (contratoParam.imagemVistoriaField) {
            updates.imagemVistoriaField = addFileToUploader(
              contratoParam.imagemVistoriaField.conteudo,
              contratoParam.imagemVistoriaField.nome
            );
          }

          if (contratoParam.imagemAdministracaoField) {
            updates.imagemAdministracaoField = addFileToUploader(
              contratoParam.imagemAdministracaoField.conteudo,
              contratoParam.imagemAdministracaoField.nome
            );
          }

          if (contratoParam.imagemTermoEntregaAssinadoField) {
            updates.imagemTermoEntregaAssinadoField = addFileToUploader(
              contratoParam.imagemTermoEntregaAssinadoField.conteudo,
              contratoParam.imagemTermoEntregaAssinadoField.nome
            );
          }

          //Valida se existe um motivoDesaprovado, e se o mesmo está vazio
          if (contratoParam.status === "Perdeu") {
            setFormPerdeu();
          } else if (contratoParam.status === "Ganhou") {
            setFormGanhou();
          } else {
            handleAndamentoClick(); // Se nenhuma das condições acima for atendida
          }

          return updates;
        });
        preencherSteps(contratoParam);
      }
    };

    carregarContratoLocacao();
  }, []);

  /**
   * Manipula as mudanças no formulário e atualiza o estado do contrato de locação.
   * @param {Object} formValue - Os valores do formulário que foram alterados.
   */
  const handleFormChange = (formValue) => {
    setContratoLocacao((prevState) => ({
      ...prevState,
      ...formValue,
    }));
  };

  /**
   * Envia os dados do formulário para o backend.
   * Valida o passo atual e verifica se houve alterações antes de enviar os dados.
   * @param {string} status - O status atual do contrato.
   */
  const handleSubmit = async (status = contratoLocacao.status) => {
    if (validateCurrentStep(step, formRef)) {
      if (contratoLocacao.status === "Aberto") {
        await handleAndamentoClick();
      }

      // Verifica se houve alterações
      if (await hasAnyChanges(step)) {
        //Verifica se o step atual possui imagens
        await validaEUploadImagens();

        //Chama o backend e atualiza todos os campos que tiveram mudança
        await atualizarContratoLocacao(status);
        // Se houver mudanças, salva as alterações no stepData
        handleStepChange(step);
      }
    } else {
      return false;
    }
  };

  /**
   * Verifica se houve alterações nos dados do formulário.
   * Compara os dados atuais com os dados salvos para determinar se houve alguma mudança.
   * @param {number} step - O número do passo atual do formulário.
   * @returns {boolean} - Retorna true se houver alterações, caso contrário false.
   */
  const hasAnyChanges = async (step) => {
    let currentData;

    switch (step) {
      case 1:
        currentData = filterStep1Data(contratoLocacao); // Filtra os dados relevantes do step 1
        break;
      case 2:
        currentData = filterStep2Data(contratoLocacao); // Filtra os dados relevantes do step 2
        break;
      case 3:
        currentData = filterStep3Data(contratoLocacao); // Filtra os dados relevantes do step 3
        break;
      case 4:
        currentData = filterStep4Data(contratoLocacao); // Filtra os dados relevantes do step 4
        break;
      case 5:
        currentData = filterStep5Data(contratoLocacao); // Filtra os dados relevantes do step 5
        break;
      case 6:
        currentData = filterStep6Data(contratoLocacao); // Filtra os dados relevantes do step 6
        break;
      case 7:
        currentData = filterStep7Data(contratoLocacao); // Filtra os dados relevantes do step 6
        break;
      default:
        currentData = contratoLocacao[`step${step}`]; // Use os dados completos para outros steps
    }

    const savedData = stepData[`step${step}`]; // Dados salvos para o step atual

    // Função para verificar se um campo é de imagem
    const isImageField = (field) => field.startsWith("imagem");

    // Comparação dos dados
    const hasChanges = Object.keys(currentData).some((key) => {
      const currentValue = currentData[key]
        ? JSON.parse(JSON.stringify(currentData[key]))
        : null;

      const savedValue =
        savedData && savedData[key]
          ? JSON.parse(JSON.stringify(savedData[key]))
          : null;

      if (isImageField(key)) {
        // Comparação baseada apenas no atributo "name" para campos de imagem
        return currentValue?.name !== (savedValue?.name || savedValue?.nome);
      }

      // Comparação padrão para outros campos
      return JSON.stringify(currentValue) !== JSON.stringify(savedValue);
    });

    return hasChanges;
  };

  /**
   * Valida e faz upload das imagens se houver alterações.
   * Verifica quais arquivos de imagem foram alterados e os envia para o backend.
   */
  const validaEUploadImagens = async () => {
    // Map de campos relacionados ao step
    const stepFieldsMap = {
      2: [
        "imagemCpflField",
        "imagemDaeField",
        "imagemCondominioField",
        "imagemGasField",
      ],
      5: ["imagemSeguroIncendioField"],
      6: [
        "imagemLocacaoField",
        "imagemVistoriaField",
        "imagemAdministracaoField",
      ],
      7: ["imagemTermoEntregaAssinadoField"],
    };

    // Recupera os campos baseados no step atual
    const fieldsToValidate = stepFieldsMap[step];
    if (!fieldsToValidate) return; // Se não houver campos para o step, encerra.

    // Obter os campos do contratoLocacao e savedFields
    const savedFieldsForStep = stepData[`step${step}`];
    const arquivosParaUpload = fieldsToValidate
      .map((field) => {
        const currentField = contratoLocacao[field]?.blobFile;
        const savedField =
          savedFieldsForStep?.[field]?.nome ||
          savedFieldsForStep?.[field]?.name;

        // Verifica se há alterações
        if (currentField && currentField.name !== savedField) {
          return currentField; // Inclui no upload apenas se for diferente.
        }
        return null;
      })
      .filter(Boolean); // Remove valores nulos.

    if (arquivosParaUpload.length === 0) return; // Nada a ser feito se não houver alterações.

    // Faz o upload
    const resultado = await fazerUpload(arquivosParaUpload);

    // Atualiza o contratoLocacao com os nomes retornados
    if (resultado) {
      resultado.forEach((file) => {
        fieldsToValidate.forEach((field) => {
          const currentField = contratoLocacao[field]?.blobFile;
          if (currentField && currentField.name === file.originalName) {
            contratoLocacao[field.replace("Field", "")] = file.fileName; // Atualiza o campo correspondente.
          }
        });
      });
    }
  };

  /**
   * Atualiza os dados do contrato de locação no backend.
   * Envia uma requisição PUT para atualizar o contrato com os dados atuais.
   * @param {string} status - O status atual do contrato.
   */
  const atualizarContratoLocacao = async (status) => {
    try {
      contratoLocacao.step = step;
      //Caso o handleSubmit seja chamado pelo aprovação está aprovada?, então alterar o status
      if (status === "Perdeu" || status === "Ganhou") {
        contratoLocacao.status = status;
      }

      const response = await fetchWithAuth(
        `/contratoLocacao/${contratoLocacao._id}`,
        {
          method: "PUT",
          body: JSON.stringify(contratoLocacao), // Salva os dados
          headers: {
            "Content-Type": "application/json", // Especifica o tipo de conteúdo
          },
        }
      );

      if (response.error) {
        showError("Erro ao alterar a contrato: ", response.message);
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Contrato parcial alterado");
      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao salvar a contrato: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  /**
   * Navega para o próximo passo do formulário.
   * Valida o passo atual e, se tudo estiver correto, avança para o próximo passo.
   */
  const onNext = async () => {
    const status = contratoLocacao.status;
    if (status === "Perdeu" || status === "Ganhou") {
      goNextStep();
      return;
    }

    if (validateCurrentStep(step, formRef)) {
      // Verifica se o ID da aprovação já existe, caso não, significa que preciso ter a primeira inclusão da contrato no banco
      if (status === "Aberto") {
        await handleAndamentoClick();
      }

      // Verifica se houve alterações
      if (await hasAnyChanges(step)) {
        //Verifica se o step atual possui imagens
        await validaEUploadImagens();
        //Chama o backend e atualiza todos os campos que tiveram mudança
        await atualizarContratoLocacao(status); // Chama o método separado
        // Se houver mudanças, salva as alterações no stepData
        handleStepChange(step);
      }
      goNextStep();
    } else {
      showError(
        "Falha na validação, por favor valide todos os campos obrigatórios"
      );
    }
  };

  const goNextStep = () => {
    // Valida os campos do step atual
    try {
      setStep(step + 1);
    } catch (error) {
      console.error("Error saving step data:", error);
    }
  };

  /**
   * Navega para o passo anterior do formulário.
   * Atualiza o estado para o passo anterior.
   */
  const onPrevious = () => {
    let prevStep = step - 1;

    setStep(prevStep);
  };

  /**
   * Obtém o esquema de validação para o passo atual.
   * Retorna o esquema de validação correspondente ao passo atual.
   * @param {number} step - O número do passo atual.
   * @returns {Object} - O esquema de validação para o passo atual.
   */
  const getSchemaForStep = (step) => {
    switch (step) {
      case 1:
        return step1Schema;
      case 2:
        return step2Schema;
      case 3:
        return step3Schema;
      case 4:
        return step4Schema;
      case 5:
        return step5Schema;
      case 6:
        return step6Schema;
      case 7:
        return step7Schema;
      default:
    }
  };

  /**
   * Manipula a ação de clicar no botão "Perdeu".
   * Abre a modal de confirmação para alterar o status para "Perdeu".
   */
  const handlePerdeuClick = () => {
    setShowModalPerdeu(true); // Abrir a modal de "Perdeu"
  };

  /**
   * Manipula a ação de clicar no botão "Ganhou".
   * Abre a modal de confirmação para alterar o status para "Ganhou".
   */
  const handleGanhouClick = () => {
    setShowModalGanhou(true); // Abrir a modal de "Ganhou"
  };

  /**
   * Envia os dados para alterar o status do contrato para "Perdeu".
   * Envia uma requisição para atualizar o status do contrato no backend.
   */
  const handleSubmitPerdeu = async () => {
    // Ações ao confirmar a mudança para "Perdeu"
    try {
      contratoLocacao.step = step;
      const response = await fetchWithAuth(
        `/contratoLocacao/perdeu/${contratoLocacao._id}`,
        {
          method: "PUT",
          body: JSON.stringify({ motivoDesaprovadoPerdeu }), // Salva os dados
          headers: {
            "Content-Type": "application/json", // Especifica o tipo de conteúdo
          },
        }
      );

      if (response.error) {
        showError(
          "Erro interno ao alterar o status para perdeu: ",
          response.message
        );
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Status alterado para perdeu com sucesso");

      setFormPerdeu();
      setShowModalPerdeu(false); // Fecha a modal de "Perdeu"

      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao alterar o status para perdeu: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  /**
   * Envia os dados para alterar o status do contrato para "Ganhou".
   * Envia uma requisição para atualizar o status do contrato no backend.
   */
  const handleSubmitGanhou = async () => {
    // Ações ao confirmar a mudança para "Ganhou"
    try {
      contratoLocacao.step = step;
      const response = await fetchWithAuth(
        `/contratoLocacao/ganhou/${contratoLocacao._id}`,
        {
          method: "PUT",
        }
      );

      if (response.error) {
        showError(
          "Erro interno ao alterar o status para ganhou: ",
          response.message
        );
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Status alterado para ganhou com sucesso");

      setFormGanhou();
      setShowModalGanhou(false); // Fecha a modal de "Ganhou"

      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao alterar o status para ganhou: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  /**
   * Altera o formulário para o estado "Perdeu".
   * Desabilita o formulário e atualiza o status para "Perdeu".
   */
  const setFormPerdeu = () => {
    setFormDisabled(true); // Desabilita o formulário
    setContratoLocacao((prevState) => ({
      ...prevState,
      status: "Perdeu",
    }));
  };

  /**
   * Altera o formulário para o estado "Ganhou".
   * Desabilita o formulário e atualiza o status para "Ganhou".
   */
  const setFormGanhou = () => {
    setFormDisabled(true); // Desabilita o formulário
    setContratoLocacao((prevState) => ({
      ...prevState,
      status: "Ganhou",
    }));
  };

  /**
   * Manipula a ação de clicar no botão de andamento.
   * Atualiza o status do contrato para "Andamento".
   */
  const handleAndamentoClick = async () => {
    contratoLocacao.status = "Andamento";
  };

  /**
   * Manipula a ação de clicar no botão de andamento.
   * Atualiza o status do contrato para "Andamento".
   */
  return (
    <Content className="m-2">
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          <Form
            ref={formRef}
            className="subContent formSpacing"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            model={getSchemaForStep(step)} // Esquema dinâmico com base no step atual
            formValue={contratoLocacao}
            onChange={handleFormChange}
            onCheck={(formError) => setFormError(formError)}
            onSubmit={handleSubmit}
            disabled={formDisabled} // Desabilita o formulário com base no estado formDisabled
            fluid
          >
            {/* Modal de confirmação de "Perdeu" */}
            <Modal
              open={showModalPerdeu}
              onClose={() => setShowModalPerdeu(false)}
            >
              <Modal.Header>
                <Modal.Title className="modal-title">
                  Alterar status para "Perdeu"
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="modal-message">
                  Tem certeza que deseja alterar o status para "Perdeu"?
                </p>
                <hr />
                {/* Campo de Motivo Desaprovado para "Perdeu" */}
                <Form fluid>
                  <Form.Group controlId="motivoDesaprovadoPerdeu">
                    <Form.ControlLabel>Motivo Desaprovado</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      name="motivoDesaprovadoPerdeu"
                      data={motivoDesaprovadoData}
                      accepter={SelectPicker}
                      searchable={false}
                      style={{ width: "100%" }}
                      placeholder="Motivo Desaprovado"
                      onChange={setMotivoDesaprovadoPerdeu} // Atualiza o estado do motivo de "Perdeu"
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  appearance="ghost"
                  onClick={() => setShowModalPerdeu(false)}
                >
                  Cancelar
                </Button>
                <Button appearance="primary" onClick={handleSubmitPerdeu}>
                  Confirmar
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Modal de confirmação de "Ganhou" */}
            <Modal
              open={showModalGanhou}
              onClose={() => setShowModalGanhou(false)}
            >
              <Modal.Header>
                <Modal.Title className="modal-title">
                  Alterar status para "Ganhou"
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="modal-message">
                  Tem certeza que deseja alterar o status para "Ganhou"?
                </p>
                <hr />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  appearance="ghost"
                  onClick={() => setShowModalGanhou(false)}
                >
                  Cancelar
                </Button>
                <Button appearance="primary" onClick={handleSubmitGanhou}>
                  Confirmar
                </Button>
              </Modal.Footer>
            </Modal>
            <MySteps step={step} setStep={setStep} />
            <FlexboxGrid.Item colspan={24} align="right">
              <Text bold className="status-label">
                Status:
                <span
                  className={`status-value ${getStatusClass(
                    contratoLocacao.status || "Aberto"
                  )}`}
                >
                  {contratoLocacao.status || "Aberto"}
                </span>
              </Text>
            </FlexboxGrid.Item>
            <MyComponent
              step={step}
              handleFormChange={handleFormChange}
              handleGanhouClick={handleGanhouClick}
            />
            <FlexboxGrid
              className="d-flex justify-content-start"
              style={{ marginTop: "auto" }}
            >
              <Divider />
              <FlexboxGrid.Item colspan={12} className="justify-content-start">
                <ButtonGroup>
                  <Button onClick={onPrevious} disabled={step === 1}>
                    Anterior
                  </Button>
                  <Button onClick={onNext} disabled={step === 7}>
                    Próximo
                  </Button>
                </ButtonGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item
                colspan={12}
                className="d-flex justify-content-end"
              >
                <Button
                  size="lg"
                  appearance="primary"
                  color="red"
                  className="button-spacing" // Adiciona a classe de estilo e espaçamento
                  onClick={handlePerdeuClick}
                >
                  Perdeu
                </Button>
                <Button
                  size="lg"
                  appearance="primary"
                  type="submit"
                  className="button-spacing"
                >
                  Salvar Contrato
                </Button>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Form>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Content>
  );
};

export default ContratoLocacao;
