import React from "react";
import { Steps } from "rsuite";

const MySteps = ({ step, setStep }) => {
  return (
    <Steps current={step - 1} onChange={setStep}>
      <Steps.Item title="Caixa De Entrada" />
      <Steps.Item title="Acompanhamento" />
      <Steps.Item title="Indenização" />
      <Steps.Item title="Acordo" />
    </Steps>
  );
};

export default MySteps;
