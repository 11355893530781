import React, { useEffect, useState, useContext } from "react";
import { FlexboxGrid, Heading, Table, Input, Button, Content } from "rsuite";
import SearchFieldContrato from "../../components/Search/SearchFieldContrato";
import { fetchWithAuth } from "../../services/apiNoState";
import { showError } from "../../components/Utils/toastUtils";
import { useNavigate } from "react-router-dom";
import EditIcon from "@rsuite/icons/Edit";
import { RescisaoContratoContext } from "./RescisaoContratoContext";

const { Column, HeaderCell, Cell } = Table;

const ListaRescisaoContrato = () => {
  const [rescisoes, setRescisoes] = useState([]);
  const [contratoSelecionado, setContratoSelecionado] = useState([]);

  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const { setRescisaoContrato, getStatusClass } = useContext(
    RescisaoContratoContext
  );

  // Função para carregar os Rescisoes de Locacao
  useEffect(() => {
    const fetchRescisoes = async () => {
      setLoading(true);
      try {
        const response = await fetchWithAuth("/rescisaoContrato", {
          method: "GET",
        });

        if (response.error) {
          showError("Erro ao carregar rescisao:", response.message);
          console.error("Erro ao carregar rescisao:", response.message);
        } else {
          setRescisoes(response.rescisaoContratos);
        }
      } catch (error) {
        showError("Erro ao buscar rescisao: " + error);
      } finally {
        setLoading(false);
      }
    };
    fetchRescisoes();
  }, []);

  const handleSortColumn = (sortColumn, sortType) => {
    setSortColumn(sortColumn);
    setSortType(sortType);
  };

  // Filtragem e ordenação dos dados
  const filteredData =
    rescisoes && rescisoes.length > 0
      ? rescisoes
          .filter((item) => {
            if (!search) return true;
            const endereco = item.imovel?.endereco || {};
            return (
              endereco.rua?.toLowerCase().includes(search.toLowerCase()) ||
              endereco.bairro?.toLowerCase().includes(search.toLowerCase()) ||
              endereco.cidade?.toLowerCase().includes(search.toLowerCase()) ||
              item.status?.toLowerCase().includes(search.toLowerCase())
            );
          })
          .sort((a, b) => {
            if (sortColumn && sortType) {
              const valueA = a[sortColumn];
              const valueB = b[sortColumn];
              if (sortType === "asc") {
                return valueA > valueB ? 1 : -1;
              } else {
                return valueA < valueB ? 1 : -1;
              }
            }
            return 0;
          })
      : [];

  const handleSetRescisao = (rescisaoData) => {
    console.log("handleSetRescisao - rescisaoData: ", rescisaoData);
    setRescisaoContrato((prevState) => ({
      ...prevState, // preserva o restante das informações
      rescisao: rescisaoData, // adiciona ou atualiza o campo Locacao
    }));
  };

  const handleSetContrato = (contratoData) => {
    setContratoSelecionado(contratoData);
  };

  return (
    <Content className="m-2">
      <Heading level={3} className="titulo-imovel">
        Lista de Rescisões
      </Heading>

      {/* Campo de busca */}
      <Input
        placeholder="Buscar por endereço"
        value={search}
        onChange={setSearch}
        style={{ marginBottom: 10, width: 300 }}
      />

      <Table
        height={400}
        data={filteredData}
        loading={loading}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        rowHeight={60} // Aumenta a altura de cada linha
      >
        <Column width={200} align="center" sortable>
          <HeaderCell>Rua e Número</HeaderCell>
          <Cell>
            {(rowData) => {
              const rua =
                rowData.contratoId.imovel?.endereco?.rua || "Sem informação";
              const numero = rowData.contratoId.imovel?.endereco?.numero || "";
              return `${rua} - ${numero}`.trim(); // Retorna "Rua Nome 123" ou apenas "Rua Nome" se não houver número
            }}
          </Cell>
        </Column>

        <Column width={200} align="center" sortable>
          <HeaderCell>Bairro</HeaderCell>
          <Cell>
            {(rowData) =>
              rowData.contratoId.imovel?.endereco?.bairro || "Sem informação"
            }
          </Cell>
        </Column>

        <Column width={200} align="center" sortable>
          <HeaderCell>Cidade</HeaderCell>
          <Cell>
            {(rowData) =>
              rowData.contratoId.imovel?.endereco?.cidade || "Sem informação"
            }
          </Cell>
        </Column>

        <Column width={200} align="center" sortable>
          <HeaderCell>Status</HeaderCell>
          <Cell>
            {(rowData) => (
              <span
                className={`status-value ${getStatusClass(rowData.status)}`}
              >
                {rowData.status || "Sem informação"}
              </span>
            )}
          </Cell>
        </Column>

        <Column width={200} fixed="right">
          <HeaderCell>Ação</HeaderCell>
          <Cell>
            {(rowData) => (
              <EditIcon
                appearance="link"
                className="icon-big icon-link"
                onClick={() =>
                  navigate("/rescisaoContrato", {
                    state: { rescisao: rowData }, // Agora rowData está acessível aqui
                  })
                }
              />
            )}
          </Cell>
        </Column>
      </Table>
      <FlexboxGrid.Item colspan={12}>
        <Heading level={3} className="titulo-imovel">
          Selecione um Contrato
        </Heading>
        <SearchFieldContrato setContrato={handleSetContrato} />
      </FlexboxGrid.Item>
      <Button
        appearance="primary"
        onClick={() =>
          navigate("/rescisaoContrato", {
            state: { contrato: contratoSelecionado },
          })
        }
      >
        Cadastrar Nova Rescisão
      </Button>
    </Content>
  );
};

export default ListaRescisaoContrato;
