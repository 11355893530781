const padraoLocalizacaoData = [
  "Boa",
  "Médio",
  "Ótima",
  "Privilegiada",
  "Regular",
  "Não Informada",
].map((item) => ({
  label: item,
  value: item,
}));

module.exports = { padraoLocalizacaoData };
