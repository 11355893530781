import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Table,
  IconButton,
  Input,
  List,
  Uploader,
  Divider,
  Modal,
  FlexboxGrid,
  DatePicker,
  InputGroup,
  SelectPicker,
  RadioGroup,
  Form,
  Radio,
  Button,
} from "rsuite";
import { Plus, Edit, Trash } from "@rsuite/icons";
import { RescisaoContratoContext } from "./RescisaoContratoContext";
import { showError, showSuccess } from "../../components/Utils/toastUtils";
import { fetchWithAuth } from "../../services/apiNoState";

const Vistoria = ({ handleFormChange }) => {
  const { Column, HeaderCell, Cell } = Table;
  const { rescisaoContrato, setRescisaoContrato } = useContext(
    RescisaoContratoContext
  ); // Use o contexto correto
  const [showModal, setShowModal] = useState(false);
  const [showModalVistoriador, setShowModalVistoriador] = useState(false);
  const [showModalFornecedor, setShowModalFornecedor] = useState(false);

  const formRefVistoriador = useRef();
  const formRefFornecedor = useRef();
  const [vistoriador, setVistoriador] = useState([]);
  const [fornecedor, setFornecedor] = useState([]);

  const [formErrorVistoriador, setFormErrorVistoriador] = useState({});
  const [formErrorFornecedor, setFormErrorFornecedor] = useState({});

  const [previewUrl, setPreviewUrl] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [listaVistoriador, setListaVistoriador] = useState([]);
  const [listaFornecedor, setListaFornecedor] = useState([]);

  const carregarVistoriadorEFornecedor = async () => {
    // Faz a requisição para buscar os vistoriadores
    const response = await fetchWithAuth(`/vistoriador`, {
      method: "GET",
    });

    if (response.error) {
      showError("Erro ao buscar os vistoriador: ", response.message);
      throw response.message; // Propaga o erro para ser tratado em outro lugar
    }

    const vistoriadorData = response.vistoriadores.map((vistoriador) => ({
      label: vistoriador.nome, // Texto exibido no SelectPicker
      value: vistoriador._id, // Valor associado (usado para salvar)
    }));

    // Dispatch the action to update the Redux state
    setListaVistoriador(vistoriadorData);

    // Faz a requisição para buscar os Fornecedores
    const responseFornecedor = await fetchWithAuth(`/fornecedor`, {
      method: "GET",
    });

    if (responseFornecedor.error) {
      showError("Erro ao buscar os vistoriador: ", responseFornecedor.message);
      throw responseFornecedor.message; // Propaga o erro para ser tratado em outro lugar
    }

    const fornecedorData = responseFornecedor.fornecedores.map(
      (fornecedor) => ({
        label: fornecedor.nome, // Texto exibido no SelectPicker
        value: fornecedor._id, // Valor associado (usado para salvar)
      })
    );

    // Dispatch the action to update the Redux state
    setListaFornecedor(fornecedorData);
  };

  useEffect(() => {
    carregarVistoriadorEFornecedor();
  }, []);

  /*Informações referente a manipulação da tabela dinamica */
  // Adicionar um novo registro
  const handleAddRecord = () => {
    const newRecord = {
      id: Date.now(),
      tipo: "",
      fornecedor: "",
      descricao: "",
      valor: "",
    };
    setRescisaoContrato((prev) => ({
      ...prev,
      listaOrcamento: [...(prev.listaOrcamento || []), newRecord],
    }));
  };

  // Atualizar um valor no estado
  const handleSave = (rowIndex, key, value) => {
    setRescisaoContrato((prev) => {
      const updatedData = JSON.parse(JSON.stringify(prev.listaOrcamento)); // Clonagem profunda
      updatedData[rowIndex][key] = value;

      return {
        ...prev,
        listaOrcamento: updatedData,
      };
    });
  };

  // Remover um registro
  const handleRemoveRecord = (rowId) => {
    setRescisaoContrato((prev) => ({
      ...prev,
      listaOrcamento: prev.listaOrcamento.filter((item) => item.id !== rowId),
    }));
  };

  // Componente EditableCell
  const EditableCell = ({ rowIndex, dataKey, rowData, onClose }) => {
    const [value, setValue] = useState(rowData[dataKey]); // Valor que será salvo no banco
    const selectedLabel =
      listaFornecedor.find((item) => item.value === value)?.label || value; // Nome (label) correspondente

    const handleSaveAndClose = (newValue) => {
      // Salva o value diretamente no banco de dados
      handleSave(rowIndex, dataKey, newValue);
      onClose(); // Sai do modo de edição
    };

    if (dataKey === "fornecedor") {
      return (
        <Form.Control
          size="lg"
          data={listaFornecedor}
          searchable={false}
          value={value} // O value que será salvo no banco
          style={{ width: 224 }}
          accepter={SelectPicker}
          placeholder="Fornecedor"
          onChange={(newValue) => {
            setValue(newValue); // Atualiza o estado local com o value
            handleSaveAndClose(newValue); // Salva o value
          }}
        />
      );
    } else {
      return (
        <input
          style={{ width: "100%" }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => handleSaveAndClose(value)}
        />
      );
    }
  };

  /*Fim das informações da tabela dinamica */

  const responsavelBenfeitoriaData = [
    "Imobiliária",
    "Proprietário",
    "Locatário",
  ].map((item) => ({
    label: item,
    value: item,
  }));

  const documentoTipoData = ["Orçamento", "Notas Materiais"].map((item) => ({
    label: item,
    value: item,
  }));

  const addDocumento = () => {
    let clearFields = {
      imagemDocumento: "",
      tipoDocumento: "",
    };

    setRescisaoContrato((prevRescisao) => {
      const novaLista = prevRescisao.documentosOrcamento;

      return {
        ...prevRescisao,
        documentosOrcamento: [
          ...novaLista,
          {
            tipo: prevRescisao.tipoDocumento,
            imagemDocumento: prevRescisao.imagemDocumento[0],
          },
        ],
        ...clearFields,
      };
    });

    setFileList([]);
  };

  const removeDocumento = (index) => {
    const updatedDocumento = rescisaoContrato.documentosOrcamento.filter(
      (_, i) => i !== index
    );
    setRescisaoContrato(updatedDocumento);
  };

  const handlePreview = (file) => {
    const reader = new FileReader();
    if (typeof file === "string") {
      // Quando o arquivo for uma URL (string)
      setPreviewUrl(file);
      setShowModal(true);
    } else if (file.blobFile) {
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file.blobFile);
    } else {
      console.error("Arquivo inválido para preview:", file);
    }
  };

  const handleFormChangeVistoriador = (formValue) => {
    setVistoriador((prevState) => ({
      ...prevState,
      ...formValue,
    }));
  };

  const handleFormChangeFornecedor = (formValue) => {
    setFornecedor((prevState) => ({
      ...prevState,
      ...formValue,
    }));
  };

  const handleSubmitVistoriador = async () => {
    formRefVistoriador.current.check((isValid, formErrorVistoriador) => {
      if (isValid) {
        // Wrapping the async code inside an async IIFE
        (async () => {
          try {
            const response = await fetchWithAuth("/vistoriador", {
              method: "POST",
              body: JSON.stringify({ vistoriadorData: vistoriador }),
              headers: {
                "Content-Type": "application/json", // Especifica o tipo de conteúdo
              },
            });

            //Fechar o modal de adicionar proprietário
            setShowModalVistoriador(false);

            // Supondo que `response.vistoriador` contém o novo vistoriador
            const novoVistoriador = {
              label: response.vistoriador.nome, // Nome do vistoriador exibido no SelectPicker
              value: response.vistoriador._id, // ID do vistoriador
            };

            // Atualiza a lista de vistoriadores
            setListaVistoriador((prevLista) => [...prevLista, novoVistoriador]);

            // Exibe a mensagem de sucesso
            showSuccess(response.vistoriador.nome + " salvo com sucesso");
          } catch (error) {
            showError("Erro: " + error.message);
          }
        })();
      } else {
        const errorFields = Object.keys(formErrorVistoriador)
          .filter((key) => formErrorVistoriador[key] !== undefined)
          .join(", ");
        showError(`Por favor, verifique os campos: ${errorFields}`);
      }
    });
  };

  const handleSubmitFornecedor = async () => {
    formRefFornecedor.current.check((isValid, formErrorFornecedor) => {
      if (isValid) {
        // Wrapping the async code inside an async IIFE
        (async () => {
          try {
            const response = await fetchWithAuth("/fornecedor", {
              method: "POST",
              body: JSON.stringify({ fornecedorData: fornecedor }),
              headers: {
                "Content-Type": "application/json", // Especifica o tipo de conteúdo
              },
            });

            //Limpa os campos de fornecedor
            setFornecedor([]);

            //Fechar o modal de adicionar proprietário
            setShowModalFornecedor(false);

            // Supondo que `response.fornecedor` contém o novo fornecedor
            const novoFornecedor = {
              label: response.fornecedor.nome, // Nome do fornecedor exibido no SelectPicker
              value: response.fornecedor._id, // ID do fornecedor
            };

            // Atualiza a lista de fornecedor
            setListaFornecedor((prevLista) => [...prevLista, novoFornecedor]);

            // Exibe a mensagem de sucesso
            showSuccess(response.fornecedor.nome + " salvo com sucesso");
          } catch (error) {
            showError("Erro: " + error.message);
          }
        })();
      } else {
        const errorFields = Object.keys(formErrorFornecedor)
          .filter((key) => formErrorFornecedor[key] !== undefined)
          .join(", ");
        showError(`Por favor, verifique os campos: ${errorFields}`);
      }
    });
  };

  const getFileList = (tipoDocumento) => {
    const fileList = rescisaoContrato[tipoDocumento]
      ? [rescisaoContrato[tipoDocumento]]
      : [];

    return fileList;
  };

  const handleImageChange = (files) => {
    if (files.length > 1) {
      files = [files[files.length - 1]];
    }
    setFileList(files);
  };

  const styleButton = { alignSelf: "center" };

  return (
    <>
      <FlexboxGrid>
        <Modal
          open={showModalVistoriador}
          onClose={() => setShowModalVistoriador(false)}
        >
          <Form
            ref={formRefVistoriador}
            className="subContent"
            formValue={vistoriador}
            onChange={handleFormChangeVistoriador}
            onCheck={(formErrorVistoriador) =>
              setFormErrorVistoriador(formErrorVistoriador)
            }
            onSubmit={handleSubmitVistoriador}
            fluid
          >
            <Modal.Header>
              <Modal.Title>Cadastrar Vistoriador</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item colspan={12}>
                  <Form.Group controlId="nome">
                    <Form.ControlLabel>Nome</Form.ControlLabel>
                    <Form.Control size="lg" name="nome" placeholder="Nome" />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                  <Form.Group controlId="cpf">
                    <Form.ControlLabel>CPF</Form.ControlLabel>
                    <Form.Control size="lg" name="cpf" placeholder="CPF" />
                  </Form.Group>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={12}>
                  <Form.Group controlId="endereco">
                    <Form.ControlLabel>Endereço</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      name="endereco"
                      placeholder="Endereço"
                    />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                  <Form.Group controlId="telefone">
                    <Form.ControlLabel>Telefone</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      name="telefone"
                      placeholder="Telefone"
                    />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                  <Form.Group controlId="email">
                    <Form.ControlLabel>E-mail</Form.ControlLabel>
                    <Form.Control size="lg" name="email" placeholder="E-mail" />
                  </Form.Group>
                </FlexboxGrid.Item>

                <Divider />
              </FlexboxGrid>
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="lg"
                appearance="ghost"
                onClick={() => setShowModalVistoriador(false)}
              >
                Cancelar
              </Button>
              <Button size="lg" appearance="primary" type="submit">
                Salvar Vistoriador
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          open={showModalFornecedor}
          onClose={() => setShowModalFornecedor(false)}
        >
          <Form
            ref={formRefFornecedor}
            className="subContent"
            formValue={fornecedor}
            onChange={handleFormChangeFornecedor}
            onCheck={(formErrorFornecedor) =>
              setFormErrorFornecedor(formErrorFornecedor)
            }
            onSubmit={handleSubmitFornecedor}
            fluid
          >
            <Modal.Header>
              <Modal.Title>Cadastrar Fornecedor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item colspan={24}>
                  <Form.Group controlId="nome">
                    <Form.ControlLabel>Nome</Form.ControlLabel>
                    <Form.Control size="lg" name="nome" placeholder="Nome" />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                  <Form.Group controlId="cpf">
                    <Form.ControlLabel>CPF</Form.ControlLabel>
                    <Form.Control size="lg" name="cpf" placeholder="CPF" />
                  </Form.Group>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={12}>
                  <Form.Group controlId="endereco">
                    <Form.ControlLabel>Endereço</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      name="endereco"
                      placeholder="Endereço"
                    />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                  <Form.Group controlId="telefone">
                    <Form.ControlLabel>Telefone</Form.ControlLabel>
                    <Form.Control
                      size="lg"
                      name="telefone"
                      placeholder="Telefone"
                    />
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                  <Form.Group controlId="email">
                    <Form.ControlLabel>E-mail</Form.ControlLabel>
                    <Form.Control size="lg" name="email" placeholder="E-mail" />
                  </Form.Group>
                </FlexboxGrid.Item>

                <Divider />
              </FlexboxGrid>
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="lg"
                appearance="ghost"
                onClick={() => setShowModalFornecedor(false)}
              >
                Cancelar
              </Button>
              <Button size="lg" appearance="primary" type="submit">
                Salvar Fornecedor
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <FlexboxGrid.Item colspan={6}>
          <Form.Group controlId="realizarBenfeitoria">
            <Form.ControlLabel>
              Quem irá realizar a benfeitoria?{" "}
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="realizarBenfeitoria"
              data={responsavelBenfeitoriaData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Quem irá realizar a benfeitoria?"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6}>
          <Form.Group controlId="custearBenfeitoria">
            <Form.ControlLabel>
              Quem irá custear a benfeitoria?{" "}
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="custearBenfeitoria"
              data={responsavelBenfeitoriaData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Quem irá custear a benfeitoria?"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5} align="center">
          <Form.ControlLabel>Proprietário aprovou?</Form.ControlLabel>
          <Form.Control
            size="lg"
            name="proprietarioAprovou"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6}>
          <Form.Group controlId="dataAprovacaoProprietario">
            <Form.ControlLabel>Data aprovação Proprietário</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="dataAprovacaoProprietario"
              searchable={false}
              style={{ width: "100%" }}
              accepter={DatePicker}
              value={
                rescisaoContrato.dataAprovacaoProprietario
                  ? new Date(rescisaoContrato.dataAprovacaoProprietario)
                  : null
              }
              format="dd/MM/yyyy"
              placeholder="Data aprovação Proprietário"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5} align="center">
          <Form.ControlLabel>Vistoria concluída?</Form.ControlLabel>
          <Form.Control
            size="lg"
            name="vistoriaConcluida"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6}>
          <Form.Group controlId="dataServicoExecutado">
            <Form.ControlLabel>Serviço executado em</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="dataServicoExecutado"
              searchable={false}
              style={{ width: "100%" }}
              accepter={DatePicker}
              value={
                rescisaoContrato.dataServicoExecutado
                  ? new Date(rescisaoContrato.dataServicoExecutado)
                  : null
              }
              format="dd/MM/yyyy"
              placeholder="Serviço executado em"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4.5}>
          <Form.Group controlId="vistoriador">
            <Form.ControlLabel>Vistoriador</Form.ControlLabel>
            <InputGroup>
              <Form.Control
                size="lg"
                name="vistoriador"
                data={listaVistoriador}
                searchable={false}
                style={{ width: 224 }}
                accepter={SelectPicker}
                placeholder="Vistoriador"
                onChange={(value) => {
                  // Salva apenas o _id no estado de rescisaoContrato
                  setRescisaoContrato((prev) => ({
                    ...prev,
                    vistoriador: value, // Apenas o _id é salvo
                  }));
                }}
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={1} />
        <FlexboxGrid.Item colspan={4} style={styleButton}>
          <Button
            size="lg"
            appearance="primary"
            onClick={() => setShowModalVistoriador(true)}
            loading={loading}
          >
            Cadastrar Vistoriador
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5} align="center">
          <Form.ControlLabel>Foi lançada no aplicativo?</Form.ControlLabel>
          <Form.Control
            size="lg"
            name="lancadaAplicativo"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4.5}>
          <Form.Group controlId="dataVistoria">
            <Form.ControlLabel>Data vistoria</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="dataVistoria"
              searchable={false}
              style={{ width: "100%" }}
              accepter={DatePicker}
              value={
                rescisaoContrato.dataVistoria
                  ? new Date(rescisaoContrato.dataVistoria)
                  : null
              }
              format="dd/MM/yyyy"
              placeholder="Data vistoria"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={1} />
        <FlexboxGrid.Item colspan={4} style={styleButton}>
          <Button
            size="lg"
            appearance="primary"
            onClick={() => setShowModalFornecedor(true)}
            loading={loading}
          >
            Cadastrar Fornecedor
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={1} />
        <FlexboxGrid.Item colspan={18}>
          <Form.Group controlId="anotacoesVistoria">
            <Form.ControlLabel>Anotações vistoria:</Form.ControlLabel>
            <Input
              as="textarea"
              rows={5}
              size="lg"
              name="anotacoesVistoria"
              placeholder="Anotações vistoria"
              value={rescisaoContrato.anotacoesVistoria || ""} // Vincula o valor ao anotacoesVistoria
              onChange={(event) => {
                handleFormChange({
                  ...rescisaoContrato,
                  anotacoesVistoria: event,
                });
              }}
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24}>
          <Button
            appearance="primary"
            onClick={handleAddRecord}
            style={{ marginBottom: 10 }}
            icon={<Plus />}
          >
            Adicionar Registro
          </Button>
          <Table
            data={rescisaoContrato.listaOrcamento || []}
            height={200}
            bordered
            cellBordered
            wordWrap
            autoHeight
          >
            {/* Tipo de Orçamento */}
            <Column flexGrow={1}>
              <HeaderCell>Tipo de Orçamento</HeaderCell>
              <Cell>
                {(rowData, rowIndex) =>
                  editingRow === rowIndex ? (
                    <EditableCell
                      rowIndex={rowIndex}
                      dataKey="tipo"
                      rowData={rowData}
                      onClose={() => setEditingRow(null)}
                    />
                  ) : (
                    <span>{rowData.tipo}</span>
                  )
                }
              </Cell>
            </Column>

            {/* Fornecedor */}
            <Column flexGrow={1}>
              <HeaderCell>Fornecedor</HeaderCell>
              <Cell>
                {(rowData, rowIndex) =>
                  editingRow === rowIndex ? (
                    <EditableCell
                      rowIndex={rowIndex}
                      dataKey="fornecedor"
                      rowData={rowData}
                      onClose={() => setEditingRow(null)}
                    />
                  ) : (
                    // Exibe o nome (label) correspondente ao value
                    <span>
                      {listaFornecedor.find(
                        (item) => item.value === rowData.fornecedor
                      )?.label || rowData.fornecedor}
                    </span>
                  )
                }
              </Cell>
            </Column>

            {/* Descrição */}
            <Column flexGrow={1}>
              <HeaderCell>Descrição</HeaderCell>
              <Cell>
                {(rowData, rowIndex) =>
                  editingRow === rowIndex ? (
                    <EditableCell
                      rowIndex={rowIndex}
                      dataKey="descricao"
                      rowData={rowData}
                      onClose={() => setEditingRow(null)}
                    />
                  ) : (
                    <span>{rowData.descricao}</span>
                  )
                }
              </Cell>
            </Column>

            {/* Valor de Orçamento */}
            <Column flexGrow={1}>
              <HeaderCell>Valor de Orçamento</HeaderCell>
              <Cell>
                {(rowData, rowIndex) =>
                  editingRow === rowIndex ? (
                    <EditableCell
                      rowIndex={rowIndex}
                      dataKey="valor"
                      rowData={rowData}
                      onClose={() => setEditingRow(null)}
                    />
                  ) : (
                    <span>{rowData.valor}</span>
                  )
                }
              </Cell>
            </Column>

            {/* Ações */}
            <Column width={150} fixed="right">
              <HeaderCell>Ações</HeaderCell>
              <Cell>
                {(rowData, rowIndex) => (
                  <div style={{ display: "flex", gap: 10 }}>
                    <IconButton
                      icon={<Edit />}
                      size="xs"
                      appearance="primary"
                      onClick={() => setEditingRow(rowIndex)}
                    />
                    <IconButton
                      icon={<Trash />}
                      size="xs"
                      appearance="ghost"
                      color="red"
                      onClick={() => handleRemoveRecord(rowData.id)}
                    />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          <hr />
          <Form.Group controlId="tipoDocumento">
            <Form.ControlLabel>Tipo Documento</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="tipoDocumento"
              data={documentoTipoData}
              searchable={false}
              style={{ width: 224 }}
              accepter={SelectPicker}
              placeholder="Tipo Documento"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={styleButton}>
          <Form.Group controlId="imagemDocumento">
            <Form.Control
              size="lg"
              name="imagemDocumento"
              fileList={fileList}
              maxFiles={1}
              onPreview={handlePreview}
              autoUpload={false} // Prevents automatic upload
              listType="picture-text"
              onChange={handleImageChange}
              renderFileInfo={(file, fileElement) => {
                return (
                  <div onClick={() => handlePreview(file)}>
                    <span>Nome do arquivo: {file.name}</span>
                  </div>
                );
              }}
              accepter={Uploader}
            >
              <Button appearance="primary" size="lg">
                Selecione o arquivo
              </Button>
            </Form.Control>
            <Modal open={showModal} onClose={() => setShowModal(false)}>
              <Modal.Header>
                <Modal.Title>Pré-visualização</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {previewUrl && (
                  <img
                    src={previewUrl}
                    alt="Preview"
                    style={{ width: "100%" }}
                  />
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button size="lg" onClick={() => setShowModal(false)}>
                  Fechar
                </Button>
              </Modal.Footer>
            </Modal>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={styleButton}>
          <Button appearance="primary" size="lg" onClick={addDocumento}>
            +
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24}>
          <List size="lg" autoScroll bordered hover>
            {!rescisaoContrato.documentosOrcamento ||
            rescisaoContrato?.documentosOrcamento?.length === 0 ? (
              <List.Item>Sem documentos associados no momento</List.Item>
            ) : (
              rescisaoContrato?.documentosOrcamento?.map((doc, index) => (
                <List.Item key={index} index={index}>
                  <FlexboxGrid align="middle">
                    <FlexboxGrid.Item colspan={10}>{doc.tipo}</FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={10}>
                      {" "}
                      <div onClick={() => handlePreview(doc.imagemDocumento)}>
                        <img
                          src={
                            // Verifica se o retorno é uma URL (exemplo 1)
                            typeof doc.imagemDocumento === "string"
                              ? doc.imagemDocumento
                              : // Verifica se é um objeto com blobFile (exemplo 2)
                              doc.imagemDocumento.blobFile
                              ? URL.createObjectURL(
                                  doc.imagemDocumento.blobFile
                                )
                              : null
                          }
                          alt="thumbnail"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4}>
                      <Button
                        color="red"
                        size="md"
                        onClick={() => removeDocumento(index)}
                      >
                        🗑️
                      </Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </List.Item>
              ))
            )}
          </List>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Vistoria;
