import React, { useEffect, useState, useContext } from "react";
import {
  FlexboxGrid,
  Table,
  Button,
  Modal,
  Form,
  Divider,
  SelectPicker,
} from "rsuite";
import { RescisaoContratoContext } from "./RescisaoContratoContext";
import { format } from "date-fns";

const AguardandoReembolso = ({ handleSubmitComunicacao }) => {
  const { rescisaoContrato, setRescisaoContrato, usuarioLogado } = useContext(
    RescisaoContratoContext
  ); // Use o contexto correto
  const { Column, HeaderCell, Cell } = Table;
  const [showModalAdicionarRegistro, setShowModalAdicionarRegistro] =
    useState(false);

  const quemData = ["Seguradora", "Locatário", "Proprietário", "Interna"].map(
    (item) => ({
      label: item,
      value: item,
    })
  );

  useEffect(() => {
    if (rescisaoContrato.listaComunicacao?.length) {
      handleSubmitComunicacao(); // Chama o submit somente após a atualização do estado
    }
  }, [rescisaoContrato.listaComunicacao]); // Dependência na lista de comunicação

  /*Informações referente a manipulação da tabela dinamica */
  // Adicionar um novo registro
  const handleAddRecord = () => {
    const newRecord = {
      quem: rescisaoContrato.quem,
      descricao: rescisaoContrato.descricao,
      criadoEm: format(new Date(), "dd/MM/yyyy HH:mm"), // Formato personalizado
      criadoPor: usuarioLogado?.name || "Desconhecido", // Preenche com o nome do usuário
    };
    setRescisaoContrato((prev) => ({
      ...prev,
      listaComunicacao: [...(prev.listaComunicacao || []), newRecord],
    }));
  };

  const handleAdicionarRegistroComunicacaoClick = () => {
    setShowModalAdicionarRegistro(true);
  };

  const handleSubmitAdicionarRegistro = () => {
    handleAddRecord();

    setShowModalAdicionarRegistro(false); // Fecha a modal de Adicionar Registro
  };

  /*Fim das informações da tabela dinamica */

  return (
    <>
      <FlexboxGrid>
        {/* Modal para adicionar Comunicacao */}
        <Modal
          open={showModalAdicionarRegistro}
          onClose={() => setShowModalAdicionarRegistro(false)}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>Adicionar registro de comunicação</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FlexboxGrid justify="space-between">
              <FlexboxGrid.Item colspan={12}>
                <Form.Group controlId="quem">
                  <Form.ControlLabel>Quem</Form.ControlLabel>
                  <Form.Control
                    size="lg"
                    name="quem"
                    data={quemData}
                    searchable={false}
                    style={{ width: 224 }}
                    accepter={SelectPicker}
                    placeholder="Quem"
                  />
                </Form.Group>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={12}>
                <Form.Group controlId="descricao">
                  <Form.ControlLabel>Descrição</Form.ControlLabel>
                  <Form.Control
                    size="lg"
                    name="descricao"
                    placeholder="Descrição"
                  />
                </Form.Group>
              </FlexboxGrid.Item>

              <Divider />
            </FlexboxGrid>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="lg"
              appearance="ghost"
              onClick={() => setShowModalAdicionarRegistro(false)}
            >
              Cancelar
            </Button>
            <Button
              size="lg"
              appearance="primary"
              onClick={handleSubmitAdicionarRegistro}
            >
              Adicionar Registro Novo
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Fim Modal para adicionar Comunicacao */}
        <FlexboxGrid.Item colspan={24}>
          <Button
            size="lg"
            appearance="primary"
            color="gray"
            className="button-spacing" // Adiciona a classe de estilo e espaçamento
            onClick={handleAdicionarRegistroComunicacaoClick}
          >
            Adicionar Registro de Comunicação
          </Button>
          <hr />
          <Table
            data={rescisaoContrato.listaComunicacao || []}
            height={200}
            bordered
            cellBordered
            wordWrap
            autoHeight
          >
            {/* Quem */}
            <Column flexGrow={1}>
              <HeaderCell>Quem</HeaderCell>
              <Cell>{(rowData) => <span>{rowData.quem}</span>}</Cell>
            </Column>

            {/* Descrição */}
            <Column flexGrow={1}>
              <HeaderCell>Descrição</HeaderCell>
              <Cell>{(rowData) => <span>{rowData.descricao}</span>}</Cell>
            </Column>

            {/* Criado em */}
            <Column flexGrow={1}>
              <HeaderCell>Criado em</HeaderCell>
              <Cell>{(rowData) => <span>{rowData.criadoEm}</span>}</Cell>
            </Column>

            {/* Criado por */}
            <Column flexGrow={1}>
              <HeaderCell>Criado por</HeaderCell>
              <Cell>{(rowData) => <span>{rowData.criadoPor}</span>}</Cell>
            </Column>
          </Table>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default AguardandoReembolso;
