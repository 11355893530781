import { addMonths, format } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { useContext } from "react";
import {
  Button,
  DatePicker,
  Divider,
  FlexboxGrid,
  Form,
  Input,
  InputGroup,
  Radio,
  RadioGroup,
  SelectPicker,
} from "rsuite";
import { contaBancariaData } from "../../enums/contaBancariaData";
import { garantidoData } from "../../enums/garantidoData";
import { modalidadeData } from "../../enums/modalidadeData";
import { prazoRepasseData } from "../../enums/prazoRepasseData";
import { ContratoLocacaoContext } from "./ContratoLocacaoContext";
import "./style.css";

const Contrato = ({ handleFormChange }) => {
  const { contratoLocacao } = useContext(ContratoLocacaoContext); // Use o contexto correto

  const indiceReajusteData = ["IGPM", "IPCA"].map((item) => ({
    label: item,
    value: item,
  }));

  const validadeContratoData = ["12", "24", "30", "36"].map((item) => ({
    label: item,
    value: item,
  }));

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="inicioContrato">
            <Form.ControlLabel>Início do Contrato</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="inicioContrato"
              searchable={false}
              style={{ width: "100%" }}
              accepter={DatePicker}
              value={
                contratoLocacao.inicioContrato
                  ? new Date(contratoLocacao.inicioContrato)
                  : null
              }
              format="dd/MM/yyyy"
              placeholder="Início do Contrato"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="validadeContrato">
            <Form.ControlLabel>Prazo do Contrato</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="validadeContrato"
              data={validadeContratoData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Prazo do Contrato"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="valorTaxaLocacao">
            <Form.ControlLabel>Final do Contrato</Form.ControlLabel>
            <Form.Control
              value={
                contratoLocacao?.inicioContrato &&
                contratoLocacao?.validadeContrato
                  ? format(
                      addMonths(
                        new Date(contratoLocacao.inicioContrato), // Converte ISO para Date
                        parseInt(contratoLocacao.validadeContrato, 10)
                      ),
                      "dd/MM/yyyy",
                      { locale: ptBR }
                    )
                  : ""
              }
              readOnly
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="isentoMultaRescisoria">
            <Form.ControlLabel>
              Contrato Isento de Multa Rescisória?{" "}
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="isentoMultaRescisoria"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4.5}>
          <Form.Group controlId="isentoApos">
            <Form.ControlLabel>Isento Após</Form.ControlLabel>
            <InputGroup>
              <Form.Control
                size="lg"
                name="isentoApos"
                placeholder="Isento Após"
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} />
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="indiceReajuste">
            <Form.ControlLabel>Índice de Reajuste</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="indiceReajuste"
              data={indiceReajusteData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Índice de Reajuste"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="modalidade">
            <Form.ControlLabel>Modalidade</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="modalidade"
              data={modalidadeData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Prazo do Contrato"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="garantido">
            <Form.ControlLabel>Garantido</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="garantido"
              data={garantidoData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Garantido"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="contaBancaria">
            <Form.ControlLabel>Conta Bancária</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="contaBancaria"
              data={contaBancariaData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Conta Bancária"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="prazoRepasse">
            <Form.ControlLabel>Prazo Repasse</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="prazoRepasse"
              data={prazoRepasseData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Prazo Repasse"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <Divider />
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="apoliceGerada">
            <Form.ControlLabel>Apólice gerada?</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="apoliceGerada"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="contratoLocacaoGerado">
            <Form.ControlLabel>Contrato de Locação Gerado?</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="contratoLocacaoGerado"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="contratoAdmGerado">
            <Form.ControlLabel>
              Contrato de Administração gerado?
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="contratoAdmGerado"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <Divider />
        <FlexboxGrid.Item colspan={24}>
          <Form.Group controlId="clausulaEspecial">
            <Form.ControlLabel>Haverá Cláusulas Especiais?</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="clausulaEspecial"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="clausulaEspecialObs">
            <Form.ControlLabel>Cláusulas Especiais</Form.ControlLabel>
            <Input
              as="textarea"
              rows={5}
              size="lg"
              name="clausulaEspecialObs"
              placeholder="Cláusulas Especiais"
              value={contratoLocacao.clausulaEspecialObs || ""} // Vincula o valor ao rateioObs
              onChange={(event) => {
                handleFormChange({
                  ...contratoLocacao,
                  clausulaEspecialObs: event,
                });
              }}
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24}>
          <Form.Group controlId="obsContrato">
            <Form.ControlLabel>Observações Contrato</Form.ControlLabel>
            <Input
              as="textarea"
              rows={5}
              size="lg"
              name="obsContrato"
              placeholder="Observações Contrato"
              value={contratoLocacao.obsContrato || ""} // Vincula o valor ao rateioObs
              onChange={(event) => {
                handleFormChange({
                  ...contratoLocacao,
                  obsContrato: event,
                });
              }}
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <hr />
        <FlexboxGrid.Item colspan={8}>
          <Button
            size="lg"
            appearance="primary"
            type="submit"
            className="button-spacing"
          >
            Imprimir contrato
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Contrato;
