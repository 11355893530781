import { fetchWithAuth } from "./apiNoState";

const getFileList = (tipoDocumento, component) => {
  return component[tipoDocumento] ? [component[tipoDocumento]] : [];
};

const handleImageChange = (files, tipoDocumento, setComponent) => {
  if (files.length > 1) {
    files = [files[files.length - 1]]; // Mantém apenas o último arquivo
  }

  setComponent((prev) => ({
    ...prev,
    [tipoDocumento]: files[0] || null, // Armazena apenas o arquivo atual ou null
  }));
};

/**
 * Transforma um único documento em um objeto Blob.
 * @param {Object} documento - Objeto do documento contendo informações do arquivo.
 * @returns {Object|null} - Informações do blob ou null se o arquivo não for encontrado.
 */
const transformarDocumento = (documento) => {
  if (!documento || !documento.conteudo) {
    return null; // Retorna null se o documento não for válido
  }

  const byteCharacters = atob(documento.conteudo);
  const byteNumbers = new Array(byteCharacters.length)
    .fill()
    .map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: documento.tipo });
  const url = URL.createObjectURL(blob);

  return {
    // ...documento,
    ...(documento.id && { id: documento.id }), // Adiciona 'id' somente se existir
    ...(documento.tipo && { tipo: documento.tipo }), // Adiciona 'id' somente se existir
    ...(documento.dbPathName && { dbPathName: documento.dbPathName }), // Adiciona 'dbPathName' somente se existir
    imagemDocumento: url,
  };
};

/**
 * Converte dados de documentos em formato base64 para objetos Blob.
 * @param {Array} documentosData - Lista de objetos de documentos contendo informações como tipo e conteúdo em base64.
 * @returns {Array} - Array de documentos com blobs e URLs correspondentes.
 */
const fetchDocumentos = (documentosData) => {
  return documentosData.map(transformarDocumento).filter(Boolean); // Usa a função transformarDocumento e filtra nulos
};

/**
 * Faz o upload de arquivos para o backend.
 * @param {Array} arquivos - Lista de arquivos a serem enviados, podendo ser blobs ou objetos de arquivo.
 * @returns {Array|null} - Array de arquivos retornados do backend se o upload for bem-sucedido, ou null em caso de erro.
 * @throws {Error} - Lança um erro em caso de falha na operação.
 */
const fazerUpload = async (arquivos) => {
  try {
    const formData = new FormData();

    // Adiciona os arquivos ao formData
    arquivos.forEach((item, index) => {
      if (item && (item.arquivo || item.blobFile || item)) {
        const arquivo = item.arquivo || item; // Prioriza a propriedade `arquivo`, mas usa o item diretamente como fallback
        const contexto = item.contexto || null; // Contexto opcional

        // Verifica se o arquivo é válido antes de adicioná-lo
        if (arquivo instanceof File) {
          formData.append("files", arquivo.blobFile || arquivo);
        } else {
          console.warn(
            `Item no índice ${index} não é um arquivo válido:`,
            item
          );
        }

        // Adiciona informações de contexto (opcional)
        if (contexto) {
          formData.append("contexto", JSON.stringify(contexto));
        }
      } else {
        console.warn(`Item inválido ou undefined no índice ${index}:`, item);
      }
    });

    // Envia os dados ao backend para a rota estática de arquivos
    const response = await fetchWithAuth("/arquivo/upload", {
      method: "POST",
      body: formData,
    });

    // Verifica se a resposta foi bem-sucedida
    if (response.success) {
      // Retorna os dados recebidos do backend, incluindo os contextos
      return response.arquivos.map((arquivo, index) => ({
        ...arquivo,
        contexto: arquivos[index]?.contexto || null, // Preserva o contexto, se disponível
      }));
    } else {
      console.error("Erro no upload:", response.error || response);
      return null;
    }
  } catch (error) {
    console.error("Erro inesperado durante o upload:", error);
    throw error;
  }
};

/**
 * Verifica se uma string está em formato base64 válido.
 * @param {string} base64 - A string em base64 a ser verificada.
 * @returns {boolean} - Retorna true se a string for um base64 válido, caso contrário false.
 */
function isValidBase64(base64) {
  const regex =
    /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
  return regex.test(base64);
}

/**
 * Remove o prefixo de uma string base64, se presente.
 * @param {string} base64 - A string em base64 a ser limpa.
 * @returns {string} - A string base64 limpa, sem o prefixo.
 */
function cleanBase64(base64) {
  return base64.split(",")[1] || base64;
}

/**
 * Prepara e valida uma string base64, lançando um erro se não for válida.
 * @param {string} base64 - A string em base64 a ser preparada.
 * @returns {string} - A string base64 limpa e validada.
 * @throws {Error} - Lança um erro se a string não estiver em formato base64 válido.
 */
function prepareBase64(base64) {
  const cleanedBase64 = cleanBase64(base64);
  if (!isValidBase64(cleanedBase64)) {
    throw new Error("String não está em formato base64 válido.");
  }
  return cleanedBase64;
}

/**
 * Converte uma string base64 em um objeto Blob.
 * @param {string} base64 - A string em base64 a ser convertida.
 * @param {string} mimeType - O tipo MIME do blob resultante.
 * @returns {Blob|null} - O objeto Blob resultante ou null em caso de erro.
 */
function base64ToBlob(base64, mimeType) {
  try {
    const preparedBase64 = prepareBase64(base64);
    const byteCharacters = atob(preparedBase64);
    const byteNumbers = Array.from(byteCharacters, (char) =>
      char.charCodeAt(0)
    );
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  } catch (error) {
    console.error("Erro ao converter base64 para Blob:", error);
    return null;
  }
}

/**
 * Adiciona um arquivo a um uploader a partir de uma string base64.
 * @param {string} base64 - A string em base64 do arquivo.
 * @param {string} fileName - O nome do arquivo a ser criado.
 * @returns {Object} - Um objeto contendo o arquivo, nome e URL para visualização.
 */
function addFileToUploader(base64, fileName) {
  try {
    // Determina o MIME type com base no nome do arquivo, se não fornecido
    const resolvedMimeType = getMimeTypeFromExtension(fileName);

    // Convertendo Base64 para Blob
    const blob = base64ToBlob(base64, resolvedMimeType);

    // Criando um objeto File a partir do Blob
    const file = new File([blob], fileName, { type: resolvedMimeType });

    // Criando URL para visualização
    const url = URL.createObjectURL(blob);

    // Formato esperado pelo Uploader
    const uploadedFile = {
      blobFile: file,
      name: fileName,
      url, // Necessário para preview no Uploader
      status: "inited",
      fileKey: Date.now().toString(), // Gera um identificador único
    };

    return uploadedFile; // Retorna o arquivo formatado, se necessário
  } catch (error) {
    console.error("Erro em addFileToUploader: ", error);
    throw error; // Relança o erro para ser tratado onde for necessário
  }
}

/**
 * Mapeia a extensão de um arquivo para seu tipo MIME correspondente.
 * @param {string} fileName - O nome do arquivo, incluindo a extensão.
 * @returns {string} - O tipo MIME correspondente à extensão do arquivo ou "application/octet-stream" se a extensão não for reconhecida.
 */
const getMimeTypeFromExtension = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();

  const mimeTypes = {
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    pdf: "application/pdf",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    txt: "text/plain",
    json: "application/json",
    csv: "text/csv",
  };

  return mimeTypes[extension] || "application/octet-stream"; // Retorna um genérico se não encontrar
};

// Exporta as funções para uso em outros módulos
export {
  addFileToUploader,
  fazerUpload,
  fetchDocumentos,
  getFileList,
  getMimeTypeFromExtension,
  handleImageChange,
  transformarDocumento,
};
