const tipoAngariacaoData = [
  "Captação",
  "Indicação",
  "Fotos",
  "Anúncio",
  "Cadastro",
  "Corretor parceiro",
  "Exclusividade",
  "Placa",
  "Promoção",
  "Plantão",
].map((item) => ({
  label: item,
  value: item,
}));

module.exports = { tipoAngariacaoData };
