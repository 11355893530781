// Calcula o atraso em milissegundos, retornando 0 se não houver atraso.
export const getDelay = (sinistro) => {
  if (!sinistro) return 0;
  // Se currentStep for 2, usa currentStepDate e regra de 5 dias
  if (sinistro.currentStep === 2 && sinistro.currentStepDate) {
    const diff = Date.now() - new Date(sinistro.currentStepDate).getTime();
    const fiveDays = 5 * 24 * 60 * 60 * 1000;
    if (diff > fiveDays) return diff;
  }
  // Se currentStep for diferente de 2, e o status não for concluído, usa dataEdicao e regra de 15 dias
  else if (
    sinistro.currentStep !== 2 &&
    sinistro.dataEdicao &&
    sinistro.status !== "Concluido" &&
    sinistro.status !== "Concluído"
  ) {
    const diff = Date.now() - new Date(sinistro.dataEdicao).getTime();
    const fifteenDays = 15 * 24 * 60 * 60 * 1000;
    if (diff > fifteenDays) return diff;
  }
  return 0;
};

// Filtro por status
const filterFunctions = {
  Aberto: (s) => s.status === "Aberto",
  Andamento: (s) => s.status === "Andamento",
  Concluido: (s) => s.status === "Concluido" || s.status === "Concluído",
  atraso_5: (s) => {
    if (s.currentStep === 2 && s.currentStepDate) {
      const diff = Date.now() - new Date(s.currentStepDate).getTime();
      const fiveDays = 5 * 24 * 60 * 60 * 1000;
      return diff > fiveDays;
    }
    return false;
  },
  atraso_15: (s) => {
    if (
      s.currentStep !== 2 &&
      s.dataEdicao &&
      s.status !== "Concluido" &&
      s.status !== "Concluído"
    ) {
      const diff = Date.now() - new Date(s.dataEdicao).getTime();
      const fifteenDays = 15 * 24 * 60 * 60 * 1000;
      return diff > fifteenDays;
    }
    return false;
  },
};

// Função para filtrar sinistros conforme um filtro escolhido
export const filterSinistrosByStatus = (sinistros, filterValue) => {
  if (!filterValue || filterValue === "mostrarPadrao") return sinistros;
  const filterFn = filterFunctions[filterValue];
  return sinistros.filter(filterFn);
};

// Ordem dos status para sinistros sem atraso
const statusOrder = {
  Aberto: 1,
  Andamento: 2,
  Concluido: 3,
  Concluído: 3,
};

// Função para ordenar sinistros na opção "Mostrar Padrão"
export const orderSinistros = (sinistros) => {
  return [...sinistros].sort((a, b) => {
    const delayA = getDelay(a);
    const delayB = getDelay(b);
    // Se um dos sinistros tem atraso e o outro não, o com atraso vem primeiro
    if (delayA > 0 && delayB === 0) return -1;
    if (delayB > 0 && delayA === 0) return 1;
    // Se ambos têm atraso, ordena do maior atraso para o menor
    if (delayA > 0 && delayB > 0 && delayA !== delayB) {
      return delayB - delayA;
    }
    // Se nenhum tem atraso (ou os atrasos são iguais), ordena por status
    const sA = statusOrder[a.status] || 99;
    const sB = statusOrder[b.status] || 99;
    return sA - sB;
  });
};

// Função para destacar células: retorna true se houver atraso
export const shouldHighlightCell = (rowData) => {
  return getDelay(rowData) > 0;
};
