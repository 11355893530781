const vinculoTrabalhistaData = [
  "Funcionário Público",
  "Autônomo",
  "CLT",
  "PJ",
].map((item) => ({
  label: item,
  value: item,
}));

module.exports = { vinculoTrabalhistaData };
