import React, { useContext } from "react";
import {
  DatePicker,
  FlexboxGrid,
  InputGroup,
  Form,
  SelectPicker,
} from "rsuite";
import { administracaoAcordoData } from "../../enums/administracaoAcordoData";
import { formaPagamentoData } from "../../enums/formaPagamentoData";
import { SinistroContext } from "./SinistroContext";

const Acordo = () => {
  const { sinistro } = useContext(SinistroContext);

  return (
    <>
      <FlexboxGrid justify="space-between">
        <FlexboxGrid.Item colspan={4}>
          <Form.Group controlId="valorDebito">
            <Form.ControlLabel>Valor do débito</Form.ControlLabel>
            <InputGroup>
              <InputGroup.Addon>R$</InputGroup.Addon>
              <Form.Control
                size="lg"
                name="valorDebito"
                placeholder="Valor do débito"
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4}>
          <Form.Group controlId="qtdParcelas">
            <Form.ControlLabel>Quantidade de parcelas</Form.ControlLabel>
            <InputGroup>
              <Form.Control
                size="lg"
                name="qtdParcelas"
                placeholder="Quantidade de parcelas"
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4}>
          <Form.Group controlId="valorCadaParcela">
            <Form.ControlLabel>Valor de cada parcela</Form.ControlLabel>
            <InputGroup>
              <InputGroup.Addon>R$</InputGroup.Addon>
              <Form.Control
                size="lg"
                name="valorCadaParcela"
                placeholder="Valor de cada parcela"
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4.5}>
          <Form.Group controlId="vencimentoPrimeiraParcela">
            <Form.ControlLabel>
              Vencimento da primeira parcela
            </Form.ControlLabel>
            <InputGroup>
              <Form.Control
                size="lg"
                name="vencimentoPrimeiraParcela"
                searchable={false}
                style={{ width: "100%" }}
                accepter={DatePicker}
                value={
                  sinistro.vencimentoPrimeiraParcela
                    ? new Date(sinistro.vencimentoPrimeiraParcela)
                    : null
                }
                format="dd/MM/yyyy"
                placeholder="Vencimento da primeira parcela"
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4.5}>
          <Form.Group controlId="formaPagamento">
            <Form.ControlLabel>Forma de pagamento</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="formaPagamento"
              data={formaPagamentoData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Forma de pagamento"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4.5}>
          <Form.Group controlId="administracaoAcordo">
            <Form.ControlLabel>Administração do acordo</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="administracaoAcordo"
              data={administracaoAcordoData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Administração do acordo"
            />
          </Form.Group>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Acordo;
