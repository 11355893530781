import React, { useState, useContext } from "react";
import { FlexboxGrid, Text, RadioGroup, Form, Radio } from "rsuite";
import { RescisaoContratoContext } from "./RescisaoContratoContext";
import "./style.css";
import tipoImovelData from "../../enums/tipoImovel";

const CancelamentoSeguros = () => {
  const { rescisaoContrato, setRescisaoContrato } = useContext(
    RescisaoContratoContext
  ); // Use o contexto correto

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={6}>
          <Text bold className="status-label">
            Seguro Fiança
          </Text>
          <Text className="cancel-seguro-label">
            Corretora: {rescisaoContrato.contratoId.corretora}
          </Text>
          <Text className="cancel-seguro-label">
            Seguradora: {rescisaoContrato.contratoId.seguradora}
          </Text>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} align="center">
          <Form.ControlLabel>Seguro Fiança foi cancelado?</Form.ControlLabel>
          <Form.Control
            size="lg"
            name="seguroFiancaCancelado"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6}>
          <Text bold className="status-label">
            Seguro Incêndio
          </Text>
          <Text className="cancel-seguro-label">
            Corretora: {rescisaoContrato.contratoId.corretoraInc}
          </Text>
          <Text className="cancel-seguro-label">
            Seguradora: {rescisaoContrato.contratoId.seguradoraInc}
          </Text>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} align="center">
          <Form.ControlLabel>Seguro Incêndio foi cancelado?</Form.ControlLabel>
          <Form.Control
            size="lg"
            name="seguroIncendioCancelado"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default CancelamentoSeguros;
