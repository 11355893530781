import React, { useEffect, useState, useContext } from "react";
import {
  FlexboxGrid,
  Text,
  Panel,
  RadioGroup,
  Heading,
  List,
  Form,
  Radio,
  Divider,
  Modal,
  Button,
  Uploader,
} from "rsuite";
import { ContratoLocacaoContext } from "./ContratoLocacaoContext";
import tipoImovelData from "../../enums/tipoImovel";

const CadastroInicial = () => {
  const { contratoLocacao, setContratoLocacao } = useContext(
    ContratoLocacaoContext
  ); // Use o contexto correto
  const [showModal, setShowModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handlePreview = (file) => {
    const reader = new FileReader();
    if (typeof file === "string") {
      // Quando o arquivo for uma URL (string)
      setPreviewUrl(file);
      setShowModal(true);
    } else if (file.blobFile) {
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file.blobFile);
    } else {
      console.error("Arquivo inválido para preview:", file);
    }
  };

  const getFileList = (tipoDocumento) => {
    const fileList = contratoLocacao[tipoDocumento]
      ? [contratoLocacao[tipoDocumento]]
      : [];

    return fileList;
  };

  const handleImageChange = (files, tipoDocumento) => {
    if (!Array.isArray(files)) {
      console.error("Arquivos inválidos recebidos:", files);
      return;
    }

    if (files.length > 1) {
      files = [files[files.length - 1]]; // Mantém apenas o último arquivo
    }

    const file = files[0] || null;

    setContratoLocacao((prev) => ({
      ...prev,
      [tipoDocumento]: file,
    }));
  };

  // Valor correspondente ao tipo de imóvel
  const tipoImovel = contratoLocacao.imovel?.tipoImovel;

  // Encontre o objeto correspondente ao tipo de imóvel
  const tipoImovelInfo = tipoImovel
    ? tipoImovelData.find((item) => item.valor === tipoImovel)
    : null;

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24} align="center">
          <Form.ControlLabel>Dados cadastrais conferidos?</Form.ControlLabel>
          <Form.Control
            size="lg"
            name="dadosCadastraisConferidos"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
          <hr />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12}>
          <Panel className="imovel-panel" shaded>
            <>
              <Text bold className="imovel-titulo">
                Endereço do Imóvel:
              </Text>
              <Text block className="imovel-info">
                Tipo imóvel:{" "}
                {tipoImovelInfo
                  ? `${tipoImovelInfo.tipoImovel} - ${tipoImovelInfo.label}`
                  : "Tipo não encontrado"}
              </Text>
              <Text block className="imovel-info">
                Rua: {contratoLocacao.imovel.endereco.rua},{" "}
                {contratoLocacao.imovel.endereco.numero}
              </Text>
              <Text block className="imovel-info">
                Bairro: {contratoLocacao.imovel.endereco.bairro}
              </Text>
              <Text block className="imovel-info">
                Cidade: {contratoLocacao.imovel.endereco.cidade}, Estado:{" "}
                {contratoLocacao.imovel.endereco.estado}, CEP:{" "}
                {contratoLocacao.imovel.endereco.cep}
              </Text>
              <Text block className="imovel-info">
                País: {contratoLocacao.imovel.endereco.pais}
              </Text>
              {contratoLocacao.imovel.endereco.complemento && (
                <Text block className="imovel-info">
                  Complemento: {contratoLocacao.imovel.endereco.complemento}
                </Text>
              )}
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12}>
          <Heading level={3} className="titulo-locatario">
            Locatário(s)
          </Heading>
          <List size="lg" autoScroll bordered hover>
            {contratoLocacao.locatario &&
            contratoLocacao?.locatario.length === 0 ? (
              <List.Item>Sem locatários associados no momento</List.Item>
            ) : (
              contratoLocacao?.locatario?.map((doc, index) => (
                <List.Item key={index} index={index}>
                  <FlexboxGrid align="middle">
                    <FlexboxGrid.Item colspan={12}>{doc.nome}</FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={12}>{doc.cpf}</FlexboxGrid.Item>
                  </FlexboxGrid>
                </List.Item>
              ))
            )}
          </List>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12}>
          <Heading level={3} className="titulo-fiador">
            Fiador(es)
          </Heading>
          <List size="lg" autoScroll bordered hover>
            {contratoLocacao.fiador && contratoLocacao?.fiador.length === 0 ? (
              <List.Item>Sem fiadores associados no momento</List.Item>
            ) : (
              contratoLocacao?.fiador?.map((doc, index) => (
                <List.Item key={index} index={index}>
                  <FlexboxGrid align="middle">
                    <FlexboxGrid.Item colspan={12}>{doc.nome}</FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={12}>{doc.cpf}</FlexboxGrid.Item>
                  </FlexboxGrid>
                </List.Item>
              ))
            )}
          </List>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12}>
          <Heading level={3} className="titulo-proprietario">
            Proprietário(s)
          </Heading>
          <List size="lg" autoScroll bordered hover>
            {contratoLocacao.imovel.proprietario &&
            contratoLocacao?.imovel?.proprietario.length === 0 ? (
              <List.Item>Sem proprietarios associados no momento</List.Item>
            ) : (
              contratoLocacao?.imovel?.proprietario?.map((doc, index) => (
                <List.Item key={index} index={index}>
                  <FlexboxGrid align="middle">
                    <FlexboxGrid.Item colspan={12}>
                      {doc.proprietarioId.nome}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={12}>
                      {doc.proprietarioId.cpf}
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </List.Item>
              ))
            )}
          </List>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12}>
          <Heading level={3} className="titulo-morador">
            Morador(es)
          </Heading>
          <List size="lg" autoScroll bordered hover>
            {contratoLocacao.morador &&
            contratoLocacao?.morador.length === 0 ? (
              <List.Item>Sem proprietarios associados no momento</List.Item>
            ) : (
              contratoLocacao?.morador?.map((doc, index) => (
                <List.Item key={index} index={index}>
                  <FlexboxGrid align="middle">
                    <FlexboxGrid.Item colspan={12}>{doc.nome}</FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={12}>{doc.cpf}</FlexboxGrid.Item>
                  </FlexboxGrid>
                </List.Item>
              ))
            )}
          </List>
        </FlexboxGrid.Item>
        <Divider />
        <FlexboxGrid.Item colspan={24} align="center">
          <Form.ControlLabel>Contas de consumo conferidos?</Form.ControlLabel>
          <Form.Control
            size="lg"
            name="contasConsumoConferido"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
          <hr />
        </FlexboxGrid.Item>
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Modal.Header>
            <Modal.Title>Pré-visualização</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {previewUrl && (
              <img src={previewUrl} alt="Preview" style={{ width: "100%" }} />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button size="lg" onClick={() => setShowModal(false)}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
        <FlexboxGrid.Item colspan={12} align="center">
          <Panel className="imovel-panel" shaded>
            <>
              <Form.Group controlId="imagemCpflField">
                <Form.ControlLabel>CPFL</Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="imagemCpflField"
                  fileList={getFileList("imagemCpflField")}
                  maxFiles={1}
                  onPreview={handlePreview}
                  autoUpload={false}
                  listType="picture-text"
                  onChange={(files) =>
                    handleImageChange(files, "imagemCpflField")
                  }
                  renderFileInfo={(file, fileElement) => {
                    return <div onClick={() => handlePreview(file)}></div>;
                  }}
                  accepter={Uploader}
                >
                  <Button appearance="primary" size="lg">
                    Selecione o arquivo
                  </Button>
                </Form.Control>
              </Form.Group>
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Panel className="imovel-panel" shaded>
            <>
              <Form.Group controlId="imagemDaeField">
                <Form.ControlLabel>DAE</Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="imagemDaeField"
                  fileList={getFileList("imagemDaeField")}
                  maxFiles={1}
                  onPreview={handlePreview}
                  autoUpload={false}
                  listType="picture-text"
                  onChange={(files) =>
                    handleImageChange(files, "imagemDaeField")
                  }
                  renderFileInfo={(file, fileElement) => {
                    return <div onClick={() => handlePreview(file)}></div>;
                  }}
                  accepter={Uploader}
                >
                  <Button appearance="primary" size="lg">
                    Selecione o arquivo
                  </Button>
                </Form.Control>
              </Form.Group>
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Panel className="imovel-panel" shaded>
            <>
              <Form.Group controlId="imagemCondominioField">
                <Form.ControlLabel>Condomínio</Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="imagemCondominioField"
                  fileList={getFileList("imagemCondominioField")}
                  maxFiles={1}
                  onPreview={handlePreview}
                  autoUpload={false}
                  listType="picture-text"
                  onChange={(files) =>
                    handleImageChange(files, "imagemCondominioField")
                  }
                  renderFileInfo={(file, fileElement) => {
                    return <div onClick={() => handlePreview(file)}></div>;
                  }}
                  accepter={Uploader}
                >
                  <Button appearance="primary" size="lg">
                    Selecione o arquivo
                  </Button>
                </Form.Control>
              </Form.Group>
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Panel className="imovel-panel" shaded>
            <>
              <Form.Group controlId="imagemGasField">
                <Form.ControlLabel>Gás</Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="imagemGasField"
                  fileList={getFileList("imagemGasField")}
                  maxFiles={1}
                  onPreview={handlePreview}
                  autoUpload={false}
                  listType="picture-text"
                  onChange={(files) =>
                    handleImageChange(files, "imagemGasField")
                  }
                  renderFileInfo={(file, fileElement) => {
                    return <div onClick={() => handlePreview(file)}></div>;
                  }}
                  accepter={Uploader}
                >
                  <Button appearance="primary" size="lg">
                    Selecione o arquivo
                  </Button>
                </Form.Control>
              </Form.Group>
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <hr />
          <Form.ControlLabel>Entra na Dimob?</Form.ControlLabel>
          <Form.Control
            size="lg"
            name="entraDimob"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <hr />
          <Form.ControlLabel>Cadastrado no Superlógica?</Form.ControlLabel>
          <Form.Control
            size="lg"
            name="cadastradoSuperlogica"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default CadastroInicial;
