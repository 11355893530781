import React, { useEffect, useState } from "react";
import {
  ButtonGroup,
  FlexboxGrid,
  Heading,
  Table,
  Input,
  Button,
  Content,
} from "rsuite";
import { fetchWithAuth } from "../../services/apiNoState";
import { showError } from "../../components/Utils/toastUtils";
import { useNavigate } from "react-router-dom";
import EditIcon from "@rsuite/icons/Edit";
import { SinistroContext } from "./SinistroContext";
import SearchFieldContrato from "../../components/Search/SearchFieldContrato";
import { getStepName } from "../../enums/stepsEnum";
import { statusFiltroSinistro } from "../../enums/statusFiltroSinistro";
import {
  filterSinistrosByStatus,
  orderSinistros,
  shouldHighlightCell,
} from "../../services/sinistroHelpers";

import { format } from "date-fns";

const { Column, HeaderCell, Cell } = Table;

const ListaSinistro = () => {
  const [sinistros, setSinistros] = useState([]);
  const [filteredSinistros, setFilteredSinistros] = useState([]);
  const [activeFilter, setActiveFilter] = useState("mostrarPadrao");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [contratoSelecionado, setContratoSelecionado] = useState([]);

  // UseEffect para carregar sinistros na montagem
  useEffect(() => {
    const fetchSinistro = async () => {
      try {
        const response = await fetchWithAuth("/sinistro", {
          method: "GET",
        });
        if (response.error) {
          showError("Erro ao carregar sinistro:", response.message);
        } else {
          setSinistros(response.sinistro);
          setFilteredSinistros(response.sinistro);
        }
      } catch (error) {
        showError("Erro ao buscar sinistro: " + error);
      }
    };
    fetchSinistro();
  }, []);

  // UseEffect separado para filtrar e ordenar sinistros
  useEffect(() => {
    let result = filterSinistrosByStatus(sinistros, activeFilter);
    if (activeFilter === "mostrarPadrao") {
      result = orderSinistros(result);
    }
    setFilteredSinistros(result);
  }, [sinistros, activeFilter]);

  const handleFilterByStatus = (filterValue) => {
    setActiveFilter(filterValue);
  };

  const handleShowAll = () => {
    setActiveFilter("mostrarPadrao");
    setSearch("");
  };

  const handleSetContrato = (contratoData) => {
    setContratoSelecionado(contratoData);
  };

  return (
    <Content className="m-2">
      <Heading level={3} className="titulo-imovel">
        Lista de Sinistros
      </Heading>
      <Input
        placeholder="Buscar por endereço"
        value={search}
        onChange={setSearch}
        style={{ marginBottom: 10, width: 300 }}
      />

      {/* Botões de filtro */}
      <ButtonGroup style={{ marginBottom: 10 }}>
        {statusFiltroSinistro.map((filtro) => (
          <Button
            key={filtro.value}
            appearance={activeFilter === filtro.value ? "primary" : "default"}
            onClick={() => handleFilterByStatus(filtro.value)}
          >
            {filtro.label}
          </Button>
        ))}
        <Button
          appearance={activeFilter === "mostrarPadrao" ? "primary" : "default"}
          onClick={handleShowAll}
        >
          Mostrar Padrão
        </Button>
      </ButtonGroup>

      <Table
        height={500}
        data={filteredSinistros}
        rowHeight={50}
        loading={false}
      >
        <Column width={200} align="center" sortable>
          <HeaderCell>Locatário(s)</HeaderCell>
          <Cell>
            {(rowData) => {
              const highlight = shouldHighlightCell(rowData);
              if (
                !rowData.contratoId ||
                !rowData.contratoId.locatario ||
                rowData.contratoId.locatario.length === 0
              ) {
                return "Sem informação";
              }
              const listaLocatario = rowData.contratoId.locatario.map(
                (item) => item.nome
              );
              return (
                <div
                  className={highlight ? "warning-row" : ""}
                  style={{
                    whiteSpace: "pre-wrap",
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {listaLocatario.join("\n")}
                </div>
              );
            }}
          </Cell>
        </Column>

        <Column width={200} align="center" sortable>
          <HeaderCell>Contrato</HeaderCell>
          <Cell>
            {(rowData) => {
              const highlight = shouldHighlightCell(rowData);
              return (
                <div
                  className={highlight ? "warning-row" : ""}
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {rowData.contratoId.codigo || "Sem informação"}
                </div>
              );
            }}
          </Cell>
        </Column>

        <Column width={200} align="center" sortable>
          <HeaderCell>Rua e Número</HeaderCell>
          <Cell>
            {(rowData) => {
              const highlight = shouldHighlightCell(rowData);
              const endereco = rowData.contratoId.imovel?.endereco || {};
              const content = `${endereco.rua || "Sem informação"} - ${
                endereco.numero || ""
              }`.trim();
              return (
                <div
                  className={highlight ? "warning-row" : ""}
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {content}
                </div>
              );
            }}
          </Cell>
        </Column>

        <Column width={200} align="center" sortable>
          <HeaderCell>Fase</HeaderCell>
          <Cell>
            {(rowData) => {
              const highlight = shouldHighlightCell(rowData);
              return (
                <div
                  className={highlight ? "warning-row" : ""}
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {getStepName(rowData.currentStep)}
                </div>
              );
            }}
          </Cell>
        </Column>

        <Column width={200} align="center" sortable>
          <HeaderCell>Fase alterada em</HeaderCell>
          <Cell>
            {(rowData) => {
              const highlight = shouldHighlightCell(rowData);
              const dateValue = rowData.currentStepDate
                ? new Date(rowData.currentStepDate)
                : null;
              const isValidDate = dateValue && !isNaN(dateValue.getTime());

              return (
                <div
                  className={highlight ? "warning-row" : ""}
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {isValidDate
                    ? format(dateValue, "dd/MM/yyyy HH:mm")
                    : "Sem informação"}
                </div>
              );
            }}
          </Cell>
        </Column>

        <Column width={200} align="center" sortable>
          <HeaderCell>Status</HeaderCell>
          <Cell>
            {(rowData) => {
              const highlight = shouldHighlightCell(rowData);
              return (
                <div
                  className={highlight ? "warning-row" : ""}
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {rowData.status || "Sem informação"}
                </div>
              );
            }}
          </Cell>
        </Column>

        <Column width={50} fixed="right">
          <HeaderCell>Ação</HeaderCell>
          <Cell>
            {(rowData) => (
              <div
                style={{ display: "flex", gap: 10, justifyContent: "center" }}
              >
                <EditIcon
                  appearance="link"
                  className="icon-big icon-link"
                  onClick={() =>
                    navigate("/sinistro", {
                      state: { sinistro: rowData },
                    })
                  }
                />
              </div>
            )}
          </Cell>
        </Column>
      </Table>
      <FlexboxGrid.Item colspan={12}>
        <Heading level={3} className="titulo-imovel">
          Selecione um Contrato
        </Heading>
        <SearchFieldContrato setContrato={handleSetContrato} />
      </FlexboxGrid.Item>
      <br />
      <Button
        appearance="primary"
        onClick={() =>
          navigate("/sinistro", {
            state: { contrato: contratoSelecionado },
          })
        }
      >
        Cadastrar Novo Sinistro
      </Button>
    </Content>
  );
};

export default ListaSinistro;
