import PhoneFillIcon from "@rsuite/icons/PhoneFill";
import update from "immutability-helper";
import { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FaTimes } from "react-icons/fa"; // Importa o ícone de "X"
import { useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Content,
  Divider,
  FlexboxGrid,
  Form,
  Input,
  InputGroup,
  List,
  Modal,
  Panel,
  Radio,
  RadioGroup,
  SelectPicker,
  Tabs,
  Text,
  Tooltip,
  Uploader,
  Whisper,
} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import SearchFieldImoveis from "../../components/Search/SearchFieldImoveis";
import SearchFieldPessoas from "../../components/Search/SearchFieldPessoas";
import { showError, showSuccess } from "../../components/Utils/toastUtils";
import { disponibilidadeData } from "../../enums/disponibilidadeData";
import { documentoTipoData } from "../../enums/documentoTipoData";
import { finalidadeData } from "../../enums/finalidadeData";
import { habiteSeData } from "../../enums/habiteSeData";
import { localChavesData } from "../../enums/localChavesData";
import { ocupadoPorData } from "../../enums/ocupadoPorData";
import { padraoImovelData } from "../../enums/padraoImovelData";
import { padraoLocalizacaoData } from "../../enums/padraoLocalizacaoData";
import { situacaoEscrituraData } from "../../enums/situacaoEscrituraData";
import { statusData } from "../../enums/statusData";
import { statusSiteData } from "../../enums/statusSiteData";
import { tipoAngariacaoData } from "../../enums/tipoAngariacaoData";
import tipoImovelData from "../../enums/tipoImovel";
import { tipoLocacaoData } from "../../enums/tipoLocacaoData";
import { tipoTelefoneData } from "../../enums/tipoTelefoneData";
import { topografiaData } from "../../enums/topografiaData";
import validacaoPessoa from "../Pessoa/validacao";
import validacao from "./validacao";
//import validacaoAtuCom from "../AtuCom/validacao";
import { fetchWithAuth } from "../../services/apiNoState";
import { fetchDocumentos } from "../../services/manageUploads";

const Imovel = () => {
  const [imovel, setImovel] = useState({});

  const { comodidadesData } = require("../../enums/comodidadesData");
  const { itensLazerData } = require("../../enums/itensLazerData");
  const { tipoPisoData } = require("../../enums/tipoPisoData");

  // Outras listas de itens
  const [pessoa, setPessoa] = useState([]);
  const [atuCom, setAtuCom] = useState([]);
  const [codigoImovel, setCodigoImovel] = useState("");
  const [sequencial, setSequencial] = useState(null);

  const [tipoSelecionado, setTipoSelecionado] = useState([]);
  const [telefones, setTelefones] = useState([]);
  const [contratos, setContratos] = useState([]);
  const [orcamentos, setOrcamentos] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [proprietarios, setProprietarios] = useState([]);
  const [angariadores, setAngariadores] = useState([]);
  const [selectedNegocios, setSelectedNegocios] = useState([]);
  const [selectedNegociosAtuCom, setSelectedNegociosAtuCom] = useState([]);
  const [selectedComodidades, setSelectedComodidades] = useState([]);
  const [selectedItensLazer, setSelectedItensLazer] = useState([]);
  const [selectedTipoPiso, setSelectedTipoPiso] = useState([]);
  const [selectedPermuta, setSelectedPermuta] = useState([]);
  const [selectedPosicaoSolar, setSelectedPosicaoSolar] = useState([]);

  const { behavior } = useSelector((state) => state.imovel);

  const [formError, setFormError] = useState({});
  const [formErrorPessoa, setFormErrorPessoa] = useState({});
  const [formErrorAtuCom, setFormErrorAtuCom] = useState({});
  const [formDisabled, setFormDisabled] = useState(false); // Estado para controlar o formulário

  const [showModal, setShowModal] = useState(false);
  const [showModalProp, setShowModalProp] = useState(false);
  const [showModalAtuCom, setShowModalAtuCom] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [showModalClonar, setShowModalClonar] = useState(false);

  const handlePreview = (file) => {
    const reader = new FileReader();
    if (typeof file === "string") {
      // Quando o arquivo for uma URL (string)
      setPreviewUrl(file);
      setShowModal(true);
    } else {
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file.blobFile);
    }
  };

  const [fileList, setFileList] = useState([]);

  const handleImageChange = (files) => {
    if (files.length > 1) {
      files = [files[files.length - 1]];
    }
    setFileList(files);
  };

  //Trecho do código para solucionar problema do Id ao mudar a ordem das fotos
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message.startsWith(
          "Uncaught Invariant Violation: Expected to find a valid targe"
        )
      ) {
        const validTargetObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const validTargetObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (validTargetObserverErr) {
          validTargetObserverErr.setAttribute("style", "display: none");
        }
        if (validTargetObserverErrDiv) {
          validTargetObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    // Só busca o próximo sequencial se for para criar
    if (behavior === "create") {
      const fetchSequencial = async () => {
        try {
          const response = await fetchWithAuth(`/imovel/proximo-codigo`, {
            method: "GET",
          });

          if (response.error) {
            showError(
              "Erro ao tentar gerar o código do imovel: " + response.message
            );
          }

          setSequencial(response.sequencial);
        } catch (error) {
          console.error("Erro ao buscar o próximo sequencial:", error);
        }
      };

      fetchSequencial();
    }
  }, [behavior]);

  useEffect(() => {
    if (behavior === "create" && sequencial) {
      // Gera o código no formato desejado: IM-[TIPO]-[SEQUENCIAL]
      setCodigoImovel(`IM-${String(sequencial).padStart(3, "0")}`);
    }
  }, [behavior, sequencial]);

  useEffect(() => {
    if (behavior === "update" && imovel && imovel.codigo) {
      setCodigoImovel(imovel.codigo);
    }
  }, [imovel?.codigo, behavior]);

  const formRef = useRef();
  const formRefPessoa = useRef();
  const formRefAtuCom = useRef();

  const [formData] = useState(new FormData());

  const handleFormChange = (formValue) => {
    setImovel((prevImovel) => ({
      ...prevImovel,
      ...formValue, // Apenas atualiza os campos alterados
    }));
    for (const key in formValue) {
      formData.set(key, formValue[key]);
    }
  };

  const handleFormChangePessoa = (formValue) => {
    setPessoa(formValue);
    for (const key in formValue) {
      formData.set(key, formValue[key]);
    }
  };

  const handleFormChangeAtuCom = (formValue) => {
    setAtuCom(formValue);
    for (const key in formValue) {
      formData.set(key, formValue[key]);
    }
  };

  const validateAndAddToList = (listName, newItem, clearFields) => {
    const emptyFields = Object.entries(newItem).filter(
      ([key, value]) => value === undefined || value === ""
    );

    if (emptyFields.length > 0) {
      const emptyFieldNames = emptyFields.map(([key]) => key).join(", ");
      showError(
        `Não pode incluir na lista, os seguintes campos estão vazios: ${emptyFieldNames}`
      );
      return;
    }

    formRef.current.checkForField(listName, (checkResult) => {
      if (checkResult && checkResult.hasError) {
        showError(
          `Não pode incluir na lista, por favor checar se a informação está dentro dos padrões para o campo: ${listName}.`
        );
      } else {
        setImovel((prevImovel) => {
          const updatedList = [...(prevImovel[listName] || []), newItem];

          const newImovel = {
            ...prevImovel,
            [listName]: updatedList,
            ...clearFields,
          };
          return newImovel;
        });
        updateStateList(listName, newItem);
      }
    });
  };

  const validateAndAddToListPessoa = (listName, newItem, clearFields) => {
    const emptyFields = Object.entries(newItem).filter(
      ([key, value]) => value === undefined || value === ""
    );

    if (emptyFields.length > 0) {
      const emptyFieldNames = emptyFields.map(([key]) => key).join(", ");
      showError(
        `Não pode incluir na lista, os seguintes campos estão vazios: ${emptyFieldNames}`
      );
      return;
    }

    formRefPessoa.current.checkForField(listName, (checkResult) => {
      if (checkResult && checkResult.hasError) {
        showError(
          `Não pode incluir na lista, por favor checar se a informação está dentro dos padrões para o campo: ${listName}.`
        );
      } else {
        setPessoa((prevPessoa) => {
          const updatedList = [...(prevPessoa[listName] || []), newItem];

          const newPessoa = {
            ...prevPessoa,
            [listName]: updatedList,
            ...clearFields,
          };
          return newPessoa;
        });

        updateStateListPessoa(listName, newItem);
      }
    });
  };

  const updateStateListPessoa = (listName, newItem) => {
    switch (listName) {
      case "telefones":
        setTelefones((prevList) => [...prevList, newItem]);
        break;
      default:
        console.warn(`List name ${listName} is not recognized.`);
    }
  };

  const updateStateList = (listName, newItem) => {
    switch (listName) {
      case "proprietarios":
        setProprietarios((prevList) => [...prevList, newItem]);
        break;
      case "documentos":
        setDocumentos((prevList) => [...prevList, newItem]);
        //Limpar o campo de arquivo
        setFileList([]);
        break;
      case "angariadores":
        setAngariadores((prevList) => [...prevList, newItem]);
        break;

      default:
        console.warn(`List name ${listName} is not recognized.`);
    }
  };

  const addDocumento = () => {
    validateAndAddToList(
      "documentos",
      {
        tipo: imovel.documentoTipoDocumento,
        imagemDocumento: imovel.imagemDocumento[0],
      },
      {
        documentoTipoDocumento: "",
        imagemDocumento: "",
      }
    );
  };

  const addTelefone = () => {
    validateAndAddToListPessoa(
      "telefones",
      { telefone: pessoa.pessoaTelefone, tipo: pessoa.tipoTelefone },
      {
        pessoaTelefone: "",
        tipoTelefone: "",
      }
    );
  };

  function validateAndSendImovel() {
    // Remove campos vazios de arrays aninhados
    if (imovel.documentos) {
      imovel.documentos = imovel.documentos.filter(
        (doc) => doc.tipo && doc.imagemDocumento
      );
    }

    if (imovel.fotos && imovel.fotos.length == 0) {
      imovel.fotos = imovel.fotos.filter((doc) => doc.fotos);
    }

    if (imovel.proprietarios) {
      imovel.proprietarios = imovel.proprietarios.filter(
        (doc) => doc.cpf && doc.percentual
      );
    }

    if (imovel.angariadores) {
      imovel.angariadores = imovel.angariadores.filter(
        (doc) =>
          doc.cpf &&
          doc.tipoAngariacao &&
          doc.taxaLocacao &&
          doc.taxaAdministracao &&
          doc.comissaoVenda
      );
    }
  }

  function validateAndSendPessoa() {
    // Remove campos vazios de arrays aninhados
    if (pessoa.telefones) {
      pessoa.telefones = pessoa.telefones.filter((t) => t.numero && t.tipo);
    }
  }

  const handleNegociosCheckboxChange = (value, checked) => {
    setImovel((prevImovel) => {
      const tipoNegocio = prevImovel.tipoNegocio || [];

      // Adicionar ou remover o valor dependendo do estado do checkbox
      if (checked) {
        return { ...prevImovel, tipoNegocio: [...tipoNegocio, value] };
      } else {
        return {
          ...prevImovel,
          tipoNegocio: tipoNegocio.filter((item) => item !== value),
        };
      }
    });
  };

  // Função para lidar com a mudança dos checkboxes
  const handleNegociosAtuComCheckboxChange = (value, checked) => {
    setAtuCom((prevImovel) => {
      const tipoNegocio = prevImovel.tipoNegocio || [];

      // Adicionar ou remover o valor dependendo do estado do checkbox
      if (checked) {
        return {
          ...prevImovel,
          tipoNegocio: [...tipoNegocio, value],
        };
      } else {
        return {
          ...prevImovel,
          tipoNegocio: tipoNegocio.filter((item) => item !== value),
        };
      }
    });
  };

  const handleComodidadesCheckboxChange = (value, checked) => {
    setImovel((prevImovel) => {
      const comodidades = prevImovel.comodidades || [];

      if (checked) {
        return { ...prevImovel, comodidades: [...comodidades, value] };
      } else {
        return {
          ...prevImovel,
          comodidades: comodidades.filter((item) => item !== value),
        };
      }
    });
  };

  const handleItensLazerCheckboxChange = (value, checked) => {
    setImovel((prevImovel) => {
      const itensLazer = prevImovel.itensLazer || [];

      if (checked) {
        return { ...prevImovel, itensLazer: [...itensLazer, value] };
      } else {
        return {
          ...prevImovel,
          itensLazer: itensLazer.filter((item) => item !== value),
        };
      }
    });
  };

  const handleTipoPisoCheckboxChange = (value, checked) => {
    setImovel((prevImovel) => {
      const tipoPiso = prevImovel.tipoPiso || [];

      if (checked) {
        return { ...prevImovel, tipoPiso: [...tipoPiso, value] };
      } else {
        return {
          ...prevImovel,
          tipoPiso: tipoPiso.filter((item) => item !== value),
        };
      }
    });
  };

  const handlePermutaCheckboxChange = (value, checked) => {
    setImovel((prevImovel) => {
      const permuta = prevImovel.permuta || [];

      // Adicionar ou remover o valor dependendo do estado do checkbox
      if (checked) {
        return { ...prevImovel, permuta: [...permuta, value] };
      } else {
        return {
          ...prevImovel,
          permuta: permuta.filter((item) => item !== value),
        };
      }
    });
  };

  const handlePosicaoSolarCheckboxChange = (value, checked) => {
    setImovel((prevImovel) => {
      const posicaoSolar = checked
        ? [...(prevImovel.posicaoSolar || []), value] // Adiciona a posição solar
        : prevImovel.posicaoSolar.filter((item) => item !== value); // Remove a posição solar

      return { ...prevImovel, posicaoSolar };
    });
  };

  const validarPercentualProprietarios = (proprietarios) => {
    const totalPercentual = proprietarios.reduce(
      (total, prop) => total + Number(prop.percentual || 0),
      0
    );

    if (totalPercentual !== 100) {
      const diferenca = 100 - totalPercentual;
      showError(
        `O percentual total dos proprietários é ${totalPercentual}%. O valor deve ser 100%. Diferença: ${Math.abs(
          diferenca
        )}%.`
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    formRef.current.check((isValid, formError) => {
      if (isValid) {
        // Verifica se existe ao menos um telefone na lista
        if (proprietarios.length > 0) {
          // Exemplo de uso antes de prosseguir com a operação:
          if (!validarPercentualProprietarios(proprietarios)) {
            return;
          }
          // Wrapping the async code inside an async IIFE
          (async () => {
            try {
              validateAndSendImovel();
              const formData = new FormData();

              // Adicione listas
              ["proprietarios", "angariadores"].forEach((listName) => {
                const list = eval(listName); // Avalia o nome da lista para obter o array

                list.forEach((item, index) => {
                  Object.keys(item).forEach((key) => {
                    const value = item[key];

                    if (value) {
                      // Se o valor for um objeto (como proprietarioId), processa suas propriedades
                      if (typeof value === "object" && value !== null) {
                        Object.keys(value).forEach((subKey) => {
                          formData.append(
                            `${listName}[${index}][${key}][${subKey}]`,
                            value[subKey]
                          );
                        });
                      } else {
                        // Caso contrário, trata como valor simples
                        formData.append(`${listName}[${index}][${key}]`, value);
                      }
                    }
                  });
                });
              });

              // Adicione campos individuais, ignorando valores vazios
              Object.keys(imovel).forEach((key) => {
                if (imovel[key]) {
                  if (Array.isArray(imovel[key])) {
                    // Se for um array (ex: tipoNegocio), adicione cada item do array
                    imovel[key].forEach((item) =>
                      formData.append(`${key}[]`, item)
                    );
                  } else if (!(imovel[key] instanceof Object)) {
                    formData.append(key, imovel[key]);
                  }
                }
              });

              //Adicionar tipoNegocio
              selectedNegocios.forEach((eachItem) => {
                formData.append("tipoNegocio[]", eachItem);
              });

              //Adicionar comodidades
              selectedComodidades.forEach((eachItem) => {
                formData.append("comodidades[]", eachItem);
              });

              //Adicionar itensLazer
              selectedItensLazer.forEach((eachItem) => {
                formData.append("itensLazer[]", eachItem);
              });

              //Adicionar tipoPiso
              selectedTipoPiso.forEach((eachItem) => {
                formData.append("tipoPiso[]", eachItem);
              });

              //Adicionar permuta
              selectedPermuta.forEach((eachItem) => {
                formData.append("permuta[]", eachItem);
              });

              //Adicionar posicaoSolar
              selectedPosicaoSolar.forEach((eachItem) => {
                formData.append("posicaoSolar[]", eachItem);
              });

              formData.append("codigo", codigoImovel);

              // Adiciona endereco ao formData
              Object.keys(imovel.endereco).forEach((key) => {
                formData.append(`endereco[${key}]`, imovel.endereco[key]);
              });

              if (imovel.imovelCodigo) {
                // Adiciona imovelCodigo ao formData
                Object.keys(imovel.imovelCodigo).forEach((key) => {
                  formData.append(
                    `imovelCodigo[${key}]`,
                    imovel.imovelCodigo[key]
                  );
                });
              }

              // Adiciona documentos ao formData
              // console.log("documentos: ", documentos);
              documentos.forEach((doc, index) => {
                formData.append(`documentos[${index}][tipo]`, doc.tipo);
                if (doc.dbPathName) {
                  formData.append(
                    `documentos[${index}][imagemDocumento]`,
                    doc.dbPathName
                  );
                } else {
                  formData.append(
                    `documentos[${index}][imagemDocumento]`,
                    doc.imagemDocumento
                  );
                }
                let file = doc.imagemDocumento?.blobFile;
                if (file) {
                  formData.append("files-documentos", file);
                }
              });

              // Agora, removemos as referências indesejadas do formData
              for (var pair of formData.entries()) {
                if (pair[0].includes("[blobFile]")) {
                  formData.delete(pair[0]); // Remover a referência indesejada
                }
              }

              images.forEach((doc, index) => {
                formData.append(`images[${index}][id]`, doc.id);
                if (doc.dbPathName) {
                  formData.append(`images[${index}][src]`, doc.dbPathName);
                }

                let file = doc.file;
                if (file) {
                  formData.append("files-image", file);
                }
              });

              if (behavior === "create") {
                const response = await fetchWithAuth("/imovel", {
                  method: "POST",
                  body: formData,
                });

                showSuccess(
                  "Imóvel na rua: " + response.imovel.rua + " salvo com sucesso"
                );
              } else {
                // dataUltimaAtualizacaoComercial
                const response = await fetchWithAuth(`/imovel/${imovel._id}`, {
                  method: "PUT",
                  body: formData,
                });

                if (!response.error) {
                  showSuccess(imovel.endereco.rua + " alterado com sucesso");
                } else {
                  showError(
                    "Erro ao tentar alterar a imovel: " + response.message
                  );
                }
              }
            } catch (error) {
              showError("Erro: " + error.message);
            }
          })();
        } else {
          showError(
            "Para salvar o imóvel, é necessário ter ao menos 1 proprietário"
          );
        }
      } else {
        const errorFields = Object.keys(formError)
          .filter((key) => formError[key] !== undefined)
          .join(", ");
        showError(`Por favor, verifique os campos: ${errorFields}`);
      }
    });
  };

  const handleSubmitAtuCom = async () => {
    formRefAtuCom.current.check((isValid, formErrorAtuCom) => {
      if (isValid) {
        // Wrapping the async code inside an async IIFE
        (async () => {
          try {
            const formDataAtuCom = new FormData();

            // Adicione campos individuais, ignorando valores vazios
            Object.keys(atuCom).forEach((key) => {
              if (atuCom[key]) {
                if (Array.isArray(atuCom[key])) {
                  // Se for um array (ex: tipoNegocio), adicione cada item do array
                  atuCom[key].forEach((item) =>
                    formDataAtuCom.append(`${key}[]`, item)
                  );
                } else if (!(atuCom[key] instanceof Object)) {
                  formDataAtuCom.append(key, atuCom[key]);
                }
              }
            });

            //Adicionar tipoNegocio
            selectedNegociosAtuCom.forEach((eachItem) => {
              formDataAtuCom.append("tipoNegocio[]", eachItem);
            });

            const response = await fetchWithAuth(
              `/imovel/AtuCom/${atuCom._id}`,
              {
                method: "PUT",
                body: formDataAtuCom,
              }
            );

            if (!response.error) {
              showSuccess(atuCom.endereco.rua + " alterado com sucesso");
            } else {
              showError("Erro ao tentar alterar o imovel: " + response.message);
            }

            //Fechar o modal de adicionar atuCom
            setShowModalAtuCom(false);
          } catch (error) {
            showError("Erro: " + error.message);
          }
        })();
      } else {
        const errorFields = Object.keys(formErrorAtuCom)
          .filter((key) => formErrorAtuCom[key] !== undefined)
          .join(", ");
        showError(`Por favor, verifique os campos: ${errorFields}`);
      }
    });
  };

  const handleSubmitPessoa = async () => {
    formRefPessoa.current.check((isValid, formErrorPessoa) => {
      if (isValid) {
        // Verifica se existe ao menos um telefone na lista
        if (telefones.length > 0) {
          // Wrapping the async code inside an async IIFE
          (async () => {
            try {
              validateAndSendPessoa();
              const formData = new FormData();

              // Adicione listas
              ["telefones"].forEach((listName) => {
                const list = eval(listName); // Avalia o nome da lista para obter o array

                list.forEach((item, index) => {
                  Object.keys(item).forEach((key) => {
                    if (item[key]) {
                      // Verifica se o valor não está vazio
                      formData.append(
                        `${listName}[${index}][${key}]`,
                        item[key]
                      );
                    }
                  });
                });
              });

              // Adicione campos individuais, ignorando valores vazios
              Object.keys(pessoa).forEach((key) => {
                if (pessoa[key]) {
                  if (!(pessoa[key] instanceof Object)) {
                    formData.append(key, pessoa[key]);
                  }
                }
              });

              const response = await fetchWithAuth("/pessoa", {
                method: "POST",
                body: formData,
              });

              //Limpa os campos de pessoas
              let clearFields = {
                nome: "",
                cpf: "",
                telefone: "",
                tipoTelefone: "",
              };
              setPessoa(() => {
                const newPessoa = {
                  ...clearFields,
                };
                return newPessoa;
              });

              //Remove todos os elementos da lista de telefone
              setTelefones([]);

              //Incluir o elemento na lista de proprietário
              // handleSetProprietario(response.pessoa);

              //Fechar o modal de adicionar proprietário
              setShowModalProp(false);

              showSuccess(response.pessoa.nome + " salvo com sucesso");
            } catch (error) {
              showError("Erro: " + error.message);
            }
          })();
        } else {
          showError("Por favor, adicione ao menos um item em todas as listas.");
        }
      } else {
        const errorFields = Object.keys(formErrorPessoa)
          .filter((key) => formErrorPessoa[key] !== undefined)
          .join(", ");
        showError(`Por favor, verifique os campos: ${errorFields}`);
      }
    });
  };

  const removeProprietario = (index) => {
    const updatedProprietarios = proprietarios.filter((_, i) => i !== index);
    setProprietarios(updatedProprietarios);
  };

  const removeAngariador = (index) => {
    const updatedAngariadores = angariadores.filter((_, i) => i !== index);
    setAngariadores(updatedAngariadores);
  };

  const removeDocumento = (index) => {
    const updatedDocumento = documentos.filter((_, i) => i !== index);
    setDocumentos(updatedDocumento);
  };

  const removeTelefone = (index) => {
    const updatedTelefone = telefones.filter((_, i) => i !== index);
    setTelefones(updatedTelefone);
  };

  const handleSetImovel = (imovelData) => {
    setImovel(imovelData);
    setAtuCom(imovelData);

    const proprietariosFormatados = imovelData.proprietario.map((item) => {
      // Verifica se existe o objeto 'proprietarioId' dentro de cada item
      const { proprietarioId, percentual } = item;

      // Retorna um novo objeto com nome, cpf e percentual extraídos
      return {
        nome: proprietarioId?.nome || "", // Nome do proprietário
        cpf: proprietarioId?.cpf || "", // CPF do proprietário
        percentual: percentual || "", // Percentual de propriedade
      };
    });

    // Agora você pode usar esse array formatado no setProprietarios
    setProprietarios(proprietariosFormatados);

    if (imovelData.angariador) {
      const angariadoresFormatados = imovelData.angariador.map((item) => {
        // Desestruturação de angariadorId e as taxas
        const {
          angariadorId,
          taxaLocacao,
          taxaAdministracao,
          comissaoVenda,
          tipoAngariacao,
        } = item;

        // Retorna um novo objeto com nome, cpf e as taxas associadas
        return {
          nome: angariadorId?.nome || "", // Nome do angariador
          cpf: angariadorId?.cpf || "", // CPF do angariador
          taxaLocacao: taxaLocacao || "", // Taxa de locação
          taxaAdministracao: taxaAdministracao || "", // Taxa de administração
          comissaoVenda: comissaoVenda || "", // Comissão de venda
          tipoAngariacao: tipoAngariacao || "", // Tipo de angariação
        };
      });

      // Agora você pode usar esse array formatado no setAngariadores
      setAngariadores(angariadoresFormatados);
    }
    // console.log("imovelData.documentos: ", imovelData.documentos);
    setImages(fetchDocumentos(imovelData.fotos));
    setDocumentos(fetchDocumentos(imovelData.documentos));
    // setDocumentos(imovelData.documentos);

    // setFileFotosList(imovelData.fotos);
    // Desabilitar o formulário quando handleSetImovel for chamado
    setFormDisabled(true);
  };

  const habilitarCampos = () => {
    setFormDisabled(false);
  };

  const clonarImovel = async () => {
    try {
      if (!imovel._id) {
        showError("Nenhum imóvel selecionado para clonar!");
        return;
      }

      const response = await fetchWithAuth("/imovel/clonar", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ imovelId: imovel._id }), // Envia o ID do imóvel
      });

      if (response.error) {
        throw new Error("Erro ao clonar imóvel");
      }

      window.location.reload();

      showSuccess(
        `Imovel ${imovel.codigo} clonado para o imovel ${response.imovel.codigo}`
      );
    } catch (error) {
      console.error("Erro ao clonar imóvel:", error);
      showError("Erro ao clonar imóvel");
    }
  };

  // Função para validar o formato do CEP
  const validarCep = (cep) => {
    const regex = /^[0-9]{5}-?[0-9]{3}$/;
    return regex.test(cep);
  };
  const [loading, setLoading] = useState(false);
  // Função para buscar o endereço pelo CEP
  const buscarCep = async () => {
    if (!validarCep(imovel.endereco.cep)) {
      showError(
        "CEP inválido. Por favor, insira um CEP no formato XXXXX-XXX ou XXXXXXXX."
      );
      return;
    }

    try {
      setLoading(true);

      const response = await fetchWithAuth(
        `/endereco/buscar-cep/${imovel.endereco.cep}`,
        {
          method: "GET",
        }
      );

      if (!response.error) {
        imovel.endereco.rua = response.rua;
        imovel.endereco.bairro = response.bairro;
        imovel.endereco.cidade = response.cidade;
        imovel.endereco.estado = response.estado;
        imovel.endereco.pais = response.pais;
        showSuccess("Endereço encontrado com sucesso!");
      } else {
        showError("CEP não encontrado.");
      }
    } catch (error) {
      showError("CEP não encontrado.");
    } finally {
      setLoading(false);
    }
  };

  const handleSetProprietario = (pessoaData) => {
    setImovel((prevImovel) => ({
      ...prevImovel,
      proprietario: {
        ...prevImovel.proprietario,
        nome: pessoaData.nome,
        cpf: pessoaData.cpf,
      },
    }));
  };

  const handleSetAngariador = (pessoaData) => {
    setImovel((prevImovel) => ({
      ...prevImovel,
      angariador: {
        ...prevImovel.angariador,
        nome: pessoaData.nome,
        cpf: pessoaData.cpf,
      },
    }));
  };

  const adicionaProprietarioLista = () => {
    validateAndAddToList(
      "proprietarios",
      {
        nome: imovel.proprietario.nome,
        cpf: imovel.proprietario.cpf,
        percentual: imovel.proprietario.percentual,
      },
      {
        proprietario: "",
        percentual: "",
      }
    );
  };

  const adicionaAngariadorLista = () => {
    validateAndAddToList(
      "angariadores",
      {
        nome: imovel.angariador.nome,
        cpf: imovel.angariador.cpf,
        tipoAngariacao: imovel.angariador.tipoAngariacao,
        taxaLocacao: imovel.angariador.taxaLocacao,
        taxaAdministracao: imovel.angariador.taxaAdministracao,
        comissaoVenda: imovel.angariador.comissaoVenda,
      },
      {
        angariador: "",
        tipoAngariacao: "",
        taxaLocacao: "",
        taxaAdministracao: "",
        comissaoVenda: "",
      }
    );
  };

  const [isOcupadoPorEnabled, setIsOcupadoPorEnabled] = useState(false);

  const [isPorCasaEnabled, setIsPorCasaEnabled] = useState();
  const [isPorApartamentoEnabled, setIsPorApartamentoEnabled] = useState();
  const [isPorChacaraEnabled, setIsPorChacaraEnabled] = useState();
  const [isPorTerrenoEnabled, setIsPorTerrenoEnabled] = useState();

  const handleOcupacaoChange = (value) => {
    setIsOcupadoPorEnabled(value === "Sim");
    if (value === "Não") {
      setImovel((prevImovel) => ({
        ...prevImovel,
        ocupadoPor: "",
      })); // Limpa o campo quando "Não" é selecionado
    }
  };

  const handlePermutaChange = (value) => {
    if (value === "Não") {
      setIsPorCasaEnabled(false);
      setIsPorApartamentoEnabled(false);
      setIsPorChacaraEnabled(false);
      setIsPorTerrenoEnabled(false);
      setImovel((prevImovel) => ({
        ...prevImovel,
        permuta: "",
      })); // Limpa o campo quando "Não" é selecionado
    } else {
      setIsPorCasaEnabled();
      setIsPorApartamentoEnabled();
      setIsPorChacaraEnabled();
      setIsPorTerrenoEnabled();
    }
  };

  // Mover o estado para o componente pai
  const [images, setImages] = useState([]);

  const handleImageUpload = (event) => {
    const files = event.target.files;
    const newImages = Array.from(files).map((file, index) => ({
      id: images.length + index + 1, // IDs gerados sequencialmente
      src: URL.createObjectURL(file),
      // Armazena o arquivo original
      file: file,
    }));
    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const moveImage = useCallback((dragIndex, hoverIndex) => {
    setImages((prevImages) => {
      const updatedImages = update(prevImages, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevImages[dragIndex]], // Mover imagem
        ],
      });
      // Atualizar IDs com base na nova ordem
      return updatedImages.map((img, idx) => ({ ...img, id: idx + 1 }));
    });
  }, []);

  const removeImage = (id) => {
    setImages((prevImages) => {
      const filteredImages = prevImages.filter((image) => image.id !== id);
      // Recalcular IDs após remoção
      return filteredImages.map((img, idx) => ({ ...img, id: idx + 1 }));
    });
  };

  // Componente Container
  const Container = ({ images, moveImage, removeImage }) => {
    return (
      <div style={styles.container}>
        {images.map((image, index) => (
          <div key={image.id} style={styles.imageItem}>
            <div style={styles.imageWrapper}>
              <FaTimes
                onClick={() => removeImage(image.id)}
                style={styles.removeIcon}
              />
              <ImagePreview index={index} image={image} moveImage={moveImage} />
            </div>
            <p>ID: {image.id}</p> {/* Exibe o ID da imagem */}
          </div>
        ))}
      </div>
    );
  };

  // Estilos
  const styles = {
    container: {
      display: "flex",
      flexWrap: "wrap", // Faz as imagens "quebrarem" para a linha de baixo
      gap: "10px",
      justifyContent: "flex-start",
    },
    imageItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
    },
    imageWrapper: {
      position: "relative",
    },
    removeIcon: {
      position: "absolute",
      top: "5px",
      right: "5px",
      color: "red",
      cursor: "pointer",
    },
  };

  // Componente ImagePreview
  const ImagePreview = ({ image, index, moveImage }) => {
    const ref = useRef(null);
    const [, drag] = useDrag({
      type: "image", // Certifique-se de que o tipo é o mesmo no useDrag e useDrop
      item: { index },
    });

    const [, drop] = useDrop({
      accept: "image", // O tipo precisa coincidir
      hover: (item) => {
        if (item.index !== index) {
          moveImage(item.index, index); // Move a imagem
          item.index = index;
        }
      },
    });

    useEffect(() => {
      if (ref.current) {
        drag(drop(ref));
      }
    }, [drag, drop]); // Remover a dependência de ref.current
    return (
      <div ref={ref}>
        <img
          src={
            // Verifica se o retorno é uma URL (exemplo 1)
            typeof image.imagemDocumento === "string"
              ? image.imagemDocumento
              : // Verifica se é um objeto com blobFile (exemplo 2)
              image.imagemDocumento && image.imagemDocumento.blobFile
              ? URL.createObjectURL(image.imagemDocumento.blobFile)
              : // Verifica se é um objeto com src (exemplo 3)
              image.src
              ? image.src
              : null
          }
          // src={image.conteudo}
          style={{ width: "130px", height: "130px", objectFit: "cover" }}
          alt={`Imagem ${index}`}
        />
      </div>
    );
  };

  const styleButton = { alignSelf: "center" };

  return (
    <Content className="m-2">
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          <FlexboxGrid className="contentHeader" align="middle">
            <FlexboxGrid.Item colspan={8}>
              <SearchFieldImoveis setImovel={handleSetImovel} />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={8} align="middle">
              <Text size="xxl" color="blue" weight="semibold">
                Gerenciar Imóvel
              </Text>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={8} align="end">
              <Text size="xxl" color="blue" weight="semibold">
                Cód. Imóvel: {codigoImovel}
              </Text>
            </FlexboxGrid.Item>
          </FlexboxGrid>

          {/* Modal de confirmação de "Clonar" */}
          <Modal
            open={showModalClonar}
            onClose={() => setShowModalClonar(false)}
          >
            <Modal.Header>
              <Modal.Title className="modal-title">
                Clonar imóvel {imovel?.codigo}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="modal-message">
                Tem certeza que deseja clonar o imóvel?
              </p>
              <hr />
            </Modal.Body>
            <Modal.Footer>
              <Button
                appearance="ghost"
                onClick={() => setShowModalClonar(false)}
              >
                Cancelar
              </Button>
              <Button appearance="primary" onClick={clonarImovel}>
                Confirmar
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal open={showModalProp} onClose={() => setShowModalProp(false)}>
            <Form
              ref={formRefPessoa}
              className="subContent"
              model={validacaoPessoa}
              formValue={pessoa}
              onChange={handleFormChangePessoa}
              onCheck={(formErrorPessoa) => setFormErrorPessoa(formErrorPessoa)}
              onSubmit={handleSubmitPessoa}
              fluid
            >
              <Modal.Header>
                <Modal.Title>Cadastrar Proprietário</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FlexboxGrid justify="space-between">
                  <FlexboxGrid.Item colspan={24}>
                    <Form.Group controlId="nome">
                      <Form.ControlLabel>Nome</Form.ControlLabel>
                      <Form.Control size="lg" name="nome" placeholder="Nome" />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={8}>
                    <Form.Group controlId="tipoTelefone">
                      <Form.ControlLabel>Tipo Telefone</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="tipoTelefone"
                        data={tipoTelefoneData}
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Tipo Telefone"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={12}>
                    <Form.Group controlId="pessoaTelefone">
                      <Form.ControlLabel>Telefone</Form.ControlLabel>
                      <InputGroup>
                        <InputGroup.Addon>
                          <PhoneFillIcon />
                        </InputGroup.Addon>
                        <Form.Control
                          size="lg"
                          name="pessoaTelefone"
                          placeholder="Telefone"
                        />
                      </InputGroup>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={2} style={styleButton}>
                    <Button
                      size="lg"
                      appearance="primary"
                      onClick={addTelefone}
                    >
                      +
                    </Button>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={12}>
                    <Form.Group controlId="cpf">
                      <Form.ControlLabel>CPF</Form.ControlLabel>
                      <Form.Control size="lg" name="cpf" placeholder="CPF" />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item
                    colspan={24}
                    className="mt-3"
                    style={{ height: "60px", overflowY: "auto" }}
                  >
                    <List size="sm" autoScroll bordered hover>
                      {telefones.map((doc, index) => (
                        <List.Item key={index} index={index}>
                          <div className="d-flex align-items-center">
                            <div className="form-group col-5">{doc.tipo}</div>
                            <div className="form-group col-5">
                              {doc.telefone}
                            </div>
                            <Button
                              color="red"
                              size="lg"
                              onClick={() => removeTelefone(index)}
                            >
                              🗑️
                            </Button>
                          </div>
                        </List.Item>
                      ))}
                    </List>
                  </FlexboxGrid.Item>
                  <Divider />
                </FlexboxGrid>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="lg"
                  appearance="ghost"
                  onClick={() => setShowModalProp(false)}
                >
                  Cancelar
                </Button>
                <Button size="lg" appearance="primary" type="submit">
                  Salvar Pessoa
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal
            open={showModalAtuCom}
            onClose={() => setShowModalAtuCom(false)}
          >
            <Form
              ref={formRefAtuCom}
              className="subContent"
              //model={validacaoAtuCom}
              formValue={atuCom}
              onChange={handleFormChangeAtuCom}
              onCheck={(formErrorAtuCom) => setFormErrorAtuCom(formErrorAtuCom)}
              onSubmit={handleSubmitAtuCom}
              fluid
            >
              <Modal.Header>
                <Modal.Title>Atualizar informação comercial</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FlexboxGrid justify="space-between">
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="status">
                      <Form.ControlLabel>
                        Status
                        <Whisper
                          placement="top"
                          controlId="control-id-click"
                          trigger="click"
                          speaker={
                            <Tooltip>
                              Se ele continua em nossa carteira da imobiliária.
                            </Tooltip>
                          }
                        >
                          <Button size="sm">?</Button>
                        </Whisper>
                      </Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="status"
                        data={statusData}
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Status"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={8}>
                    <Form.Group controlId="disponibilidade">
                      <Form.ControlLabel>
                        Disponibilidade
                        <Whisper
                          placement="top"
                          controlId="control-id-click"
                          trigger="click"
                          speaker={
                            <Tooltip>
                              Se o imóvel está disponivel para negociação no
                              momento.
                            </Tooltip>
                          }
                        >
                          <Button size="sm">?</Button>
                        </Whisper>
                      </Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="disponibilidade"
                        data={disponibilidadeData}
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Disponibilidade"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={8}>
                    <Form.Group controlId="statusSite">
                      <Form.ControlLabel>Status Site</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="statusSite"
                        data={statusSiteData}
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Status Site"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={8}>
                    <Form.Group controlId="tipoNegocio">
                      <Form.ControlLabel>Tipo de Negócio</Form.ControlLabel>
                      <Checkbox
                        size="lg"
                        name="tipoNegocioVenda"
                        accepter={Checkbox}
                        value="Venda"
                        onChange={(value, checked) =>
                          handleNegociosAtuComCheckboxChange(value, checked)
                        }
                        checked={atuCom.tipoNegocio?.includes("Venda")} // Verifica se 'Venda' está no array
                      >
                        Venda
                      </Checkbox>
                      <Checkbox
                        size="lg"
                        name="tipoNegocioLocacao"
                        accepter={Checkbox}
                        value="Locação"
                        onChange={(value, checked) =>
                          handleNegociosAtuComCheckboxChange(value, checked)
                        }
                        checked={atuCom.tipoNegocio?.includes("Locação")} // Verifica se 'Locação' está no array
                      >
                        Locação
                      </Checkbox>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={8}>
                    <Form.Group controlId="valorAluguel">
                      <Form.ControlLabel>Aluguel</Form.ControlLabel>
                      <InputGroup>
                        <InputGroup.Addon>R$</InputGroup.Addon>
                        <Form.Control
                          size="lg"
                          name="valorAluguel"
                          placeholder="Aluguel"
                        />
                      </InputGroup>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={8}>
                    <Form.Group controlId="valorVenda">
                      <Form.ControlLabel>Venda</Form.ControlLabel>
                      <InputGroup>
                        <InputGroup.Addon>R$</InputGroup.Addon>
                        <Form.Control
                          size="lg"
                          name="valorVenda"
                          placeholder="Venda"
                        />
                      </InputGroup>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <Divider />
                </FlexboxGrid>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="lg"
                  appearance="ghost"
                  onClick={() => setShowModalAtuCom(false)}
                >
                  Cancelar
                </Button>
                <Button size="lg" appearance="primary" type="submit">
                  Salvar Atualização Comercial
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Form
            ref={formRef}
            className="subContent formSpacing"
            style={{
              minHeight: "87vh",
              display: "flex",
              flexDirection: "column",
            }}
            model={validacao}
            formValue={imovel}
            onChange={handleFormChange}
            onCheck={(formError) => setFormError(formError)}
            onSubmit={handleSubmit}
            fluid
            disabled={formDisabled} // Desabilita o formulário com base no estado formDisabled
          >
            <Tabs defaultActiveKey="1">
              <Tabs.Tab eventKey="1" title="Cadastro Inicial">
                <FlexboxGrid justify="space-between">
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="finalidade">
                      <Form.ControlLabel>Finalidade</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="finalidade"
                        data={finalidadeData}
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Finalidade"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="tipoImovel">
                      <Form.ControlLabel>Tipo Imóvel</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="tipoImovel"
                        data={tipoImovelData}
                        groupBy="tipoImovel"
                        labelKey="label"
                        valueKey="valor"
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Tipo Imóvel"
                        // Adiciona a lógica para atualizar o tipoSelecionado
                        onChange={(value) => {
                          handleFormChange({ tipoImovel: value }); // Atualiza o formValue
                          setTipoSelecionado(value); // Atualiza o tipoSelecionado no estado
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="fase">
                      <Form.ControlLabel>Fase</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="fase"
                        Color="Blue"
                        style={{ backgroundColor: "white" }}
                        appearance="picker"
                        accepter={RadioGroup}
                        inline
                      >
                        <Radio value="Pronto">Pronto</Radio>
                        <Radio value="Em construção">Em construção</Radio>
                      </Form.Control>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="indicadoPor">
                      <Form.ControlLabel>Indicado Por</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="indicadoPor"
                        placeholder="Indicado Por"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}>
                    <Form.Group controlId="anoConstrucao">
                      <Form.ControlLabel>Ano da construção</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="anoConstrucao"
                        placeholder="Ano da construção"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="padraoImovel">
                      <Form.ControlLabel>Padrao do Imóvel</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="padraoImovel"
                        data={padraoImovelData}
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Padrao do Imóvel"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="padraoLocalizacao">
                      <Form.ControlLabel>
                        Padrão da localização
                      </Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="padraoLocalizacao"
                        data={padraoLocalizacaoData}
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Padrão da localização"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="ocultarDetalhes">
                      <Form.ControlLabel>
                        Ocultar detalhes do endereço no site
                      </Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="ocultarDetalhes"
                        style={{ backgroundColor: "white" }}
                        appearance="picker"
                        accepter={RadioGroup}
                        inline
                      >
                        <Radio value="Sim">Sim</Radio>
                        <Radio value="Não">Não</Radio>
                      </Form.Control>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="localChaves">
                      <Form.ControlLabel>Local das Chaves</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="localChaves"
                        data={localChavesData}
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Local das Chaves"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="numeroChave">
                      <Form.ControlLabel>Número Chave</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="numeroChave"
                        placeholder="Número Chave"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="status">
                      <Form.ControlLabel>
                        Status
                        <Whisper
                          placement="top"
                          controlId="control-id-click"
                          trigger="click"
                          speaker={
                            <Tooltip>
                              Se ele continua em nossa carteira da imobiliária.
                            </Tooltip>
                          }
                        >
                          <Button size="sm">?</Button>
                        </Whisper>
                      </Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="status"
                        data={statusData}
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Status"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="disponibilidade">
                      <Form.ControlLabel>
                        Disponibilidade
                        <Whisper
                          placement="top"
                          controlId="control-id-click"
                          trigger="click"
                          speaker={
                            <Tooltip>
                              Se o imóvel está disponivel para negociação no
                              momento.
                            </Tooltip>
                          }
                        >
                          <Button size="sm">?</Button>
                        </Whisper>
                      </Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="disponibilidade"
                        data={disponibilidadeData}
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Disponibilidade"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={12}>
                    <Form.Group controlId="observacao">
                      <Form.ControlLabel>Observação</Form.ControlLabel>
                      <Input
                        as="textarea"
                        rows={6}
                        size="lg"
                        name="observacao"
                        placeholder="Observação"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}>
                    <Form.Group controlId="ocupacao">
                      <Form.ControlLabel>Ocupação</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="ocupacao"
                        accepter={RadioGroup}
                        style={{ backgroundColor: "white" }}
                        appearance="picker"
                        onChange={handleOcupacaoChange}
                        inline
                      >
                        <Radio value="Sim">Sim</Radio>
                        <Radio value="Não">Não</Radio>
                      </Form.Control>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={7}>
                    <Form.Group controlId="ocupadoPor">
                      <Form.ControlLabel>Ocupado Por</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="ocupadoPor"
                        data={ocupadoPorData}
                        searchable={false}
                        disabled={!isOcupadoPorEnabled}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Ocupado Por"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={24}>
                    <Panel bordered="true" header="Endereço" shaded>
                      <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item colspan={6}>
                          <Form.Group controlId="endereco.cep">
                            <Form.ControlLabel>CEP</Form.ControlLabel>
                            <Form.Control
                              size="lg"
                              name="endereco.cep"
                              placeholder="CEP"
                              value={imovel?.endereco?.cep || ""}
                              onChange={(value) => {
                                setImovel((prevImovel) => ({
                                  ...prevImovel,
                                  endereco: {
                                    ...prevImovel.endereco,
                                    cep: value,
                                  },
                                }));
                              }}
                            />
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6} style={styleButton}>
                          <Button
                            size="lg"
                            appearance="primary"
                            onClick={buscarCep}
                            loading={loading}
                          >
                            Buscar Endereço
                          </Button>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={11} />
                        <FlexboxGrid.Item colspan={12}>
                          <Form.Group
                            name="endereco.rua"
                            controlId="endereco.rua"
                          >
                            <Form.ControlLabel>Rua</Form.ControlLabel>
                            <Form.Control
                              size="lg"
                              name="endereco.rua"
                              placeholder="Rua"
                              value={imovel?.endereco?.rua || ""}
                              onChange={(value) => {
                                setImovel((prevImovel) => ({
                                  ...prevImovel,
                                  endereco: {
                                    ...prevImovel.endereco,
                                    rua: value,
                                  },
                                }));
                              }}
                            />
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}>
                          <Form.Group controlId="endereco.numero">
                            <Form.ControlLabel>Número</Form.ControlLabel>
                            <Form.Control
                              size="lg"
                              name="endereco.numero"
                              placeholder="Número"
                              value={imovel?.endereco?.numero || ""}
                              onChange={(value) => {
                                setImovel((prevImovel) => ({
                                  ...prevImovel,
                                  endereco: {
                                    ...prevImovel.endereco,
                                    numero: value,
                                  },
                                }));
                              }}
                            />
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}>
                          <Form.Group controlId="endereco.bairro">
                            <Form.ControlLabel>Bairro</Form.ControlLabel>
                            <Form.Control
                              size="lg"
                              name="endereco.bairro"
                              placeholder="Bairro"
                              value={imovel?.endereco?.bairro || ""}
                              onChange={(value) => {
                                setImovel((prevImovel) => ({
                                  ...prevImovel,
                                  endereco: {
                                    ...prevImovel.endereco,
                                    bairro: value,
                                  },
                                }));
                              }}
                            />
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}>
                          <Form.Group controlId="endereco.cidade">
                            <Form.ControlLabel>Cidade</Form.ControlLabel>
                            <Form.Control
                              size="lg"
                              name="endereco.cidade"
                              placeholder="Cidade"
                              value={imovel?.endereco?.cidade || ""}
                              onChange={(value) => {
                                setImovel((prevImovel) => ({
                                  ...prevImovel,
                                  endereco: {
                                    ...prevImovel.endereco,
                                    cidade: value,
                                  },
                                }));
                              }}
                            />
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}>
                          <Form.Group controlId="endereco.estado">
                            <Form.ControlLabel>Estado</Form.ControlLabel>
                            <Form.Control
                              size="lg"
                              name="endereco.estado"
                              placeholder="Estado"
                              value={imovel?.endereco?.estado || ""}
                              onChange={(value) => {
                                setImovel((prevImovel) => ({
                                  ...prevImovel,
                                  endereco: {
                                    ...prevImovel.endereco,
                                    estado: value,
                                  },
                                }));
                              }}
                            />
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}>
                          <Form.Group controlId="endereco.pais">
                            <Form.ControlLabel>País</Form.ControlLabel>
                            <Form.Control
                              size="lg"
                              name="endereco.pais"
                              placeholder="País"
                              value={imovel?.endereco?.pais || ""}
                              onChange={(value) => {
                                setImovel((prevImovel) => ({
                                  ...prevImovel,
                                  endereco: {
                                    ...prevImovel.endereco,
                                    pais: value,
                                  },
                                }));
                              }}
                            />
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6} className="mb-4">
                          <Form.Group controlId="endereco.complemento">
                            <Form.ControlLabel>Complemento</Form.ControlLabel>
                            <Form.Control
                              size="lg"
                              name="endereco.complemento"
                              placeholder="Complemento"
                              value={imovel?.endereco?.complemento || ""}
                              onChange={(value) => {
                                setImovel((prevImovel) => ({
                                  ...prevImovel,
                                  endereco: {
                                    ...prevImovel.endereco,
                                    complemento: value,
                                  },
                                }));
                              }}
                            />
                          </Form.Group>
                        </FlexboxGrid.Item>
                      </FlexboxGrid>
                    </Panel>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </Tabs.Tab>
              <Tabs.Tab eventKey="2" title="Proprietário">
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={9}>
                    <Form.Group controlId="proprietario">
                      <Form.ControlLabel>Proprietário</Form.ControlLabel>
                      <SearchFieldPessoas setPessoa={handleSetProprietario} />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}>
                    <Form.ControlLabel>Percentual</Form.ControlLabel>
                    <InputGroup>
                      <InputGroup.Addon>%</InputGroup.Addon>
                      <Form.Control
                        size="lg"
                        name="percentual"
                        value={imovel?.proprietario?.percentual || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            proprietario: {
                              ...prevImovel.proprietario,
                              percentual: value,
                            },
                          }));
                        }}
                      />
                    </InputGroup>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={1} style={styleButton}>
                    <Button
                      size="lg"
                      appearance="primary"
                      onClick={() => adicionaProprietarioLista()}
                      loading={loading}
                    >
                      +
                    </Button>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4} style={styleButton}>
                    <Button
                      size="lg"
                      appearance="primary"
                      onClick={() => setShowModalProp(true)}
                      loading={loading}
                    >
                      Cadastrar Proprietário
                    </Button>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item
                    colspan={24}
                    className="mt-3"
                    style={{ height: "300px", overflowY: "auto" }}
                  >
                    <List size="lg" autoScroll bordered hover>
                      {proprietarios.length === 0 ? (
                        <List.Item>
                          <div className="d-flex align-items-center">
                            <div className="form-group col-12">
                              Sem proprietários associados no momento
                            </div>
                          </div>
                        </List.Item>
                      ) : (
                        proprietarios.map((doc, index) => (
                          <List.Item key={index} index={index}>
                            <div className="d-flex align-items-center">
                              <div className="form-group col-3">
                                {doc?.proprietarioId?.nome || doc.nome}
                              </div>
                              <div className="form-group col-3">
                                {doc?.proprietarioId?.cpf || doc.cpf}
                              </div>
                              <div className="form-group col-3">
                                {doc?.proprietarioId?.percentual ||
                                  doc.percentual}
                              </div>
                              {behavior === "create" && (
                                <Button
                                  color="red"
                                  size="lg"
                                  onClick={() => removeProprietario(index)}
                                >
                                  🗑️
                                </Button>
                              )}
                            </div>
                          </List.Item>
                        ))
                      )}
                    </List>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </Tabs.Tab>
              <Tabs.Tab eventKey="3" title="Informação Comercial">
                <FlexboxGrid justify="space-between">
                  <FlexboxGrid.Item colspan={5}>
                    <Button
                      size="lg"
                      appearance="primary"
                      onClick={() => setShowModalAtuCom(true)}
                      loading={loading}
                    >
                      Atualização comercial
                    </Button>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="tipoNegocio">
                      <Form.ControlLabel>Tipo de Negócio</Form.ControlLabel>
                      <Checkbox
                        size="lg"
                        name="tipoNegocioVenda"
                        accepter={Checkbox}
                        value="Venda"
                        checked={imovel.tipoNegocio?.includes("Venda")}
                        onChange={(value, checked) =>
                          handleNegociosCheckboxChange(value, checked)
                        }
                        disabled={formDisabled} // Desabilitar o Checkbox quando o formulário estiver desabilitado
                      >
                        Venda
                      </Checkbox>
                      <Checkbox
                        size="lg"
                        name="tipoNegocioLocacao"
                        accepter={Checkbox}
                        value="Locação"
                        checked={imovel.tipoNegocio?.includes("Locação")}
                        onChange={(value, checked) =>
                          handleNegociosCheckboxChange(value, checked)
                        }
                        disabled={formDisabled} // Desabilitar o Checkbox quando o formulário estiver desabilitado
                      >
                        Locação
                      </Checkbox>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="valorAluguel">
                      <Form.ControlLabel>Aluguel</Form.ControlLabel>
                      <InputGroup>
                        <InputGroup.Addon>R$</InputGroup.Addon>
                        <Form.Control
                          size="lg"
                          name="valorAluguel"
                          placeholder="Aluguel"
                        />
                      </InputGroup>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="valorVenda">
                      <Form.ControlLabel>Venda</Form.ControlLabel>
                      <InputGroup>
                        <InputGroup.Addon>R$</InputGroup.Addon>
                        <Form.Control
                          size="lg"
                          name="valorVenda"
                          placeholder="Venda"
                        />
                      </InputGroup>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="tipoLocacao">
                      <Form.ControlLabel>Tipo de locação</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="tipoLocacao"
                        data={tipoLocacaoData}
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Tipo de locação"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="valorIptu">
                      <Form.ControlLabel>IPTU</Form.ControlLabel>
                      <InputGroup>
                        <InputGroup.Addon>R$</InputGroup.Addon>
                        <Form.Control
                          size="lg"
                          name="valorIptu"
                          placeholder="IPTU"
                        />
                      </InputGroup>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}>
                    <Form.Group controlId="aceitaFinanciamento">
                      <Form.ControlLabel>
                        Aceita financiamento
                      </Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="aceitaFinanciamento"
                        style={{ backgroundColor: "white" }}
                        appearance="picker"
                        accepter={RadioGroup}
                        inline
                      >
                        <Radio value="Sim">Sim</Radio>
                        <Radio value="Não">Não</Radio>
                      </Form.Control>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}>
                    <Form.Group controlId="aceitaPermuta">
                      <Form.ControlLabel>Aceita permuta</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="aceitaPermuta"
                        style={{ backgroundColor: "white" }}
                        appearance="picker"
                        accepter={RadioGroup}
                        onChange={handlePermutaChange}
                        inline
                      >
                        <Radio value="Sim">Sim</Radio>
                        <Radio value="Não">Não</Radio>
                      </Form.Control>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={7}>
                    <Form.Group controlId="permuta">
                      <Checkbox
                        size="lg"
                        name="permutaPorCasa"
                        //checked={isPorCasaEnabled}
                        checked={imovel.permuta?.includes("Por casa")} // Verifica se 'Por casa' está no array
                        accepter={Checkbox}
                        value="Por casa"
                        onChange={(value, checked) =>
                          handlePermutaCheckboxChange(value, checked)
                        }
                        disabled={formDisabled} // Desabilitar o Checkbox quando o formulário estiver desabilitado
                      >
                        Por casa
                      </Checkbox>
                      <Checkbox
                        size="lg"
                        name="permutaPorApartamento"
                        //checked={isPorApartamentoEnabled}
                        checked={imovel.permuta?.includes("Por apartamento")} // Verifica se 'Por apartamento' está no array
                        accepter={Checkbox}
                        value="Por apartamento"
                        onChange={(value, checked) =>
                          handlePermutaCheckboxChange(value, checked)
                        }
                        disabled={formDisabled} // Desabilitar o Checkbox quando o formulário estiver desabilitado
                      >
                        Por apartamento
                      </Checkbox>
                      <Checkbox
                        size="lg"
                        name="permutaPorChacara"
                        //checked={isPorChacaraEnabled}
                        checked={imovel.permuta?.includes("Por chácara")} // Verifica se 'Por chácara' está no array
                        accepter={Checkbox}
                        value="Por chácara"
                        onChange={(value, checked) =>
                          handlePermutaCheckboxChange(value, checked)
                        }
                        disabled={formDisabled} // Desabilitar o Checkbox quando o formulário estiver desabilitado
                      >
                        Por chácara
                      </Checkbox>
                      <Checkbox
                        size="lg"
                        name="permutaPorTerreno"
                        //checked={isPorTerrenoEnabled}
                        checked={imovel.permuta?.includes("Por terreno")} // Verifica se 'Por terreno' está no array
                        accepter={Checkbox}
                        value="Por terreno"
                        onChange={(value, checked) =>
                          handlePermutaCheckboxChange(value, checked)
                        }
                        disabled={formDisabled} // Desabilitar o Checkbox quando o formulário estiver desabilitado
                      >
                        Por terreno
                      </Checkbox>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4}>
                    <Form.Group controlId="ocultarPreco">
                      <Form.ControlLabel>
                        Ocultar preço na publicação?
                      </Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="ocultarPreco"
                        style={{ backgroundColor: "white" }}
                        appearance="picker"
                        accepter={RadioGroup}
                        inline
                      >
                        <Radio value="Sim">Sim</Radio>
                        <Radio value="Não">Não</Radio>
                      </Form.Control>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="repasse">
                      <Form.ControlLabel>Repasse</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="repasse"
                        placeholder="Repasse"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="taxaAdministracao">
                      <Form.ControlLabel>Taxa Administração</Form.ControlLabel>
                      <InputGroup>
                        <Form.Control
                          size="lg"
                          name="taxaAdministracao"
                          placeholder="Taxa Administração"
                        />
                        <InputGroup.Addon>%</InputGroup.Addon>
                      </InputGroup>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={24}>
                    <Panel bordered="true" header="Condomínio" shaded>
                      <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item colspan={5}>
                          <Form.Group controlId="valorCondominio">
                            <Form.ControlLabel>Valor</Form.ControlLabel>
                            <InputGroup>
                              <InputGroup.Addon>R$</InputGroup.Addon>
                              <Form.Control
                                size="lg"
                                name="valorCondominio"
                                placeholder="Valor"
                              />
                            </InputGroup>
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                          <Form.Group controlId="admCondominio">
                            <Form.ControlLabel>Administração</Form.ControlLabel>
                            <Form.Control
                              size="lg"
                              name="admCondominio"
                              placeholder="Administração"
                            />
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                          <Form.Group controlId="telefoneAdmCondominio">
                            <Form.ControlLabel>Telefone Adm</Form.ControlLabel>
                            <Form.Control
                              size="lg"
                              name="telefoneAdmCondominio"
                              placeholder="Telefone Adm"
                            />
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                          <Form.Group controlId="emailAdmCondominio">
                            <Form.ControlLabel>Email Adm</Form.ControlLabel>
                            <Form.Control
                              size="lg"
                              name="emailAdmCondominio"
                              placeholder="Email Adm"
                            />
                          </Form.Group>
                        </FlexboxGrid.Item>
                      </FlexboxGrid>
                    </Panel>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </Tabs.Tab>
              <Tabs.Tab eventKey="4" title="Detalhes do Imóvel">
                <FlexboxGrid justify="space-between">
                  <FlexboxGrid.Item colspan={7}>
                    <Form.Group controlId="topografia">
                      <Form.ControlLabel>Topografia</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="topografia"
                        data={topografiaData}
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Topografia"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={7}>
                    <Form.Group controlId="posicaoSolar">
                      <Form.ControlLabel>Posição solar</Form.ControlLabel>
                      <Checkbox
                        size="lg"
                        name="posicaoSolar.solManha"
                        value="solManha"
                        checked={
                          imovel.posicaoSolar?.includes("solManha") || false
                        } // Verifica se 'solManha' está no array
                        onChange={(value, checked) =>
                          handlePosicaoSolarCheckboxChange(value, checked)
                        }
                        disabled={formDisabled} // Desabilitar o Checkbox quando o formulário estiver desabilitado
                      >
                        Sol da manhã
                      </Checkbox>
                      <Checkbox
                        size="lg"
                        name="posicaoSolar.solTarde"
                        value="solTarde"
                        checked={
                          imovel.posicaoSolar?.includes("solTarde") || false
                        } // Verifica se 'solTarde' está no array
                        onChange={(value, checked) =>
                          handlePosicaoSolarCheckboxChange(value, checked)
                        }
                        disabled={formDisabled} // Desabilitar o Checkbox quando o formulário estiver desabilitado
                      >
                        Sol da Tarde
                      </Checkbox>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={7}>
                    <Form.Group controlId="mobiliado">
                      <Form.ControlLabel>Mobiliado</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="mobiliado"
                        style={{ backgroundColor: "white" }}
                        appearance="picker"
                        accepter={RadioGroup}
                        inline
                      >
                        <Radio value="Sim">Sim</Radio>
                        <Radio value="Não">Não</Radio>
                      </Form.Control>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4}>
                    <Form.Group controlId="qtdDormitorio">
                      <Form.ControlLabel>Qtd Dormitório</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="qtdDormitorio"
                        placeholder="Qtd Dormitório"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4}>
                    <Form.Group controlId="qtdSuite">
                      <Form.ControlLabel>Qtd suítes</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="qtdSuite"
                        placeholder="Qtd suítes"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4}>
                    <Form.Group controlId="qtdBanheiro">
                      <Form.ControlLabel>Qtd Banheiro</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="qtdBanheiro"
                        placeholder="Qtd Banheiro"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4}>
                    <Form.Group controlId="qtdLavabos">
                      <Form.ControlLabel>Qtd Lavabos</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="qtdLavabos"
                        placeholder="Qtd Lavabos"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4}>
                    <Form.Group controlId="qtdSalas">
                      <Form.ControlLabel>Qtd Salas</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="qtdSalas"
                        placeholder="Qtd Salas"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4}>
                    <Form.Group controlId="qtdVagaGaragem">
                      <Form.ControlLabel>Qtd Vaga Garagem</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="qtdVagaGaragem"
                        placeholder="Qtd Vaga Garagem"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="identificadorVaga">
                      <Form.ControlLabel>
                        Identificador de vaga
                      </Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="identificadorVaga"
                        placeholder="Identificador de vaga"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="hobbyBox">
                      <Form.ControlLabel>Hobby Box</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="hobbyBox"
                        placeholder="Hobby Box"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={24}>
                    <Panel bordered="true" header="Metragem" shaded>
                      <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item colspan={5}>
                          <Form.Group controlId="metragemTerreno">
                            <Form.ControlLabel>
                              Metragem terreno
                            </Form.ControlLabel>
                            <InputGroup>
                              <InputGroup.Addon>m²</InputGroup.Addon>
                              <Form.Control
                                size="lg"
                                name="metragemTerreno"
                                placeholder="Metragem terreno"
                              />
                            </InputGroup>
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                          <Form.Group controlId="metragemConstrucao">
                            <Form.ControlLabel>
                              Metragem construção
                            </Form.ControlLabel>
                            <InputGroup>
                              <InputGroup.Addon>m²</InputGroup.Addon>
                              <Form.Control
                                size="lg"
                                name="metragemConstrucao"
                                placeholder="Metragem construção"
                              />
                            </InputGroup>
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                          <Form.Group controlId="areaTotal">
                            <Form.ControlLabel>Área total</Form.ControlLabel>
                            <InputGroup>
                              <InputGroup.Addon>m²</InputGroup.Addon>
                              <Form.Control
                                size="lg"
                                name="areaTotal"
                                placeholder="Área total"
                              />
                            </InputGroup>
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                          <Form.Group controlId="areaUtil">
                            <Form.ControlLabel>Área útil</Form.ControlLabel>
                            <InputGroup>
                              <InputGroup.Addon>m²</InputGroup.Addon>
                              <Form.Control
                                size="lg"
                                name="areaUtil"
                                placeholder="Área útil"
                              />
                            </InputGroup>
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                          <Form.Group controlId="areaPrivativa">
                            <Form.ControlLabel>
                              Área privativa
                            </Form.ControlLabel>
                            <InputGroup>
                              <InputGroup.Addon>m²</InputGroup.Addon>
                              <Form.Control
                                size="lg"
                                name="areaPrivativa"
                                placeholder="Área privativa"
                              />
                            </InputGroup>
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                          <Form.Group controlId="areaComum">
                            <Form.ControlLabel>Área comum</Form.ControlLabel>
                            <InputGroup>
                              <InputGroup.Addon>m²</InputGroup.Addon>
                              <Form.Control
                                size="lg"
                                name="areaComum"
                                placeholder="Área comum"
                              />
                            </InputGroup>
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                          <Form.Group controlId="hectare">
                            <Form.ControlLabel>Hectare</Form.ControlLabel>
                            <InputGroup>
                              <InputGroup.Addon>ha</InputGroup.Addon>
                              <Form.Control
                                size="lg"
                                name="hectare"
                                placeholder="Hectare"
                              />
                            </InputGroup>
                          </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                          <Form.Group controlId="alqueires">
                            <Form.ControlLabel>Alqueires</Form.ControlLabel>
                            <InputGroup>
                              <InputGroup.Addon>alq</InputGroup.Addon>
                              <Form.Control
                                size="lg"
                                name="alqueires"
                                placeholder="Alqueires"
                              />
                            </InputGroup>
                          </Form.Group>
                        </FlexboxGrid.Item>
                      </FlexboxGrid>
                    </Panel>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </Tabs.Tab>
              <Tabs.Tab eventKey="5" title="Detalhes de Anúncio">
                <FlexboxGrid justify="space-between">
                  <FlexboxGrid.Item colspan={12}>
                    <Form.Group controlId="tituloAnuncio">
                      <Form.ControlLabel>Título do anúncio</Form.ControlLabel>
                      <Form.Control
                        className="custom-titulo-anuncio"
                        size="lg"
                        name="tituloAnuncio"
                        placeholder="Título do anúncio"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="possuiPlaca">
                      <Form.ControlLabel>
                        Possui placa no imóvel?
                      </Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="possuiPlaca"
                        style={{ backgroundColor: "white" }}
                        appearance="picker"
                        accepter={RadioGroup}
                        inline
                      >
                        <Radio value="Sim">Sim</Radio>
                        <Radio value="Não">Não</Radio>
                      </Form.Control>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="urlVideo">
                      <Form.ControlLabel>
                        URL do vídeo do imóvel
                      </Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="urlVideo"
                        placeholder="URL do vídeo do imóvel"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="urlTourVirtual">
                      <Form.ControlLabel>
                        URL do Tour Virtual do imóvel
                      </Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="urlTourVirtual"
                        placeholder="URL do Tour Virtual do imóvel"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                    <Form.Group controlId="statusSite">
                      <Form.ControlLabel>Status Site</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="statusSite"
                        data={statusSiteData}
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Status Site"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={12}></FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={12}>
                    <Form.Group controlId="descricaoImovel">
                      <Form.ControlLabel>Descrição do imóvel</Form.ControlLabel>
                      <Input
                        as="textarea"
                        rows={6}
                        size="lg"
                        name="descricaoImovel"
                        value={imovel.descricaoImovel || ""} // Vincula o valor ao estado
                        onChange={(value) => {
                          handleFormChange({ descricaoImovel: value }); // Atualiza o formValue
                        }}
                        placeholder="Descrição do imóvel"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </Tabs.Tab>
              <Tabs.Tab eventKey="6" title="Características">
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={24}>
                    <Form.Group controlId="comodidades">
                      <Form.ControlLabel>Comodidades</Form.ControlLabel>
                      <div style={{ textAlign: "-webkit-left" }}>
                        {comodidadesData.map((comodidade) => (
                          <Checkbox
                            key={comodidade.name}
                            size="lg"
                            name={`comodidades.${comodidade.name}`}
                            accepter={Checkbox}
                            value={comodidade.label}
                            checked={imovel.comodidades?.includes(
                              comodidade.label
                            )}
                            onChange={(value, checked) =>
                              handleComodidadesCheckboxChange(value, checked)
                            }
                            disabled={formDisabled} // Desabilitar o Checkbox quando o formulário estiver desabilitado
                          >
                            {comodidade.label}
                          </Checkbox>
                        ))}
                      </div>
                    </Form.Group>
                  </FlexboxGrid.Item>

                  <FlexboxGrid.Item colspan={24}>
                    <Form.Group controlId="itensLazer">
                      <Form.ControlLabel>Itens de lazer</Form.ControlLabel>
                      {itensLazerData.map((item) => (
                        <Checkbox
                          key={item.name}
                          size="lg"
                          name={`itensLazer.${item.name}`}
                          accepter={Checkbox}
                          value={item.label}
                          checked={imovel.itensLazer?.includes(item.label)}
                          onChange={(value, checked) =>
                            handleItensLazerCheckboxChange(value, checked)
                          }
                          disabled={formDisabled} // Desabilitar o Checkbox quando o formulário estiver desabilitado
                        >
                          {item.label}
                        </Checkbox>
                      ))}
                    </Form.Group>
                  </FlexboxGrid.Item>

                  <FlexboxGrid.Item colspan={24}>
                    <Form.Group controlId="tipoPiso">
                      <Form.ControlLabel>Piso</Form.ControlLabel>
                      {tipoPisoData.map((item) => (
                        <Checkbox
                          key={item.name}
                          size="lg"
                          name={`tipoPiso.${item.name}`}
                          accepter={Checkbox}
                          value={item.label}
                          checked={imovel.tipoPiso?.includes(item.label)}
                          onChange={(value, checked) =>
                            handleTipoPisoCheckboxChange(value, checked)
                          }
                          disabled={formDisabled} // Desabilitar o Checkbox quando o formulário estiver desabilitado
                        >
                          {item.label}
                        </Checkbox>
                      ))}
                    </Form.Group>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </Tabs.Tab>
              <Tabs.Tab eventKey="7" title="Códigos">
                <FlexboxGrid justify="space-between">
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.matricula">
                      <Form.ControlLabel>Matrícula</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Matrícula"
                        value={imovel?.imovelCodigo?.matricula || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              matricula: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.inscricaoMunicipal">
                      <Form.ControlLabel>Inscrição Municipal</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Inscrição Municipal"
                        value={imovel?.imovelCodigo?.inscricaoMunicipal || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              inscricaoMunicipal: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.localizacao">
                      <Form.ControlLabel>Localização</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Localização"
                        value={imovel?.imovelCodigo?.localizacao || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              localizacao: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.cartorio">
                      <Form.ControlLabel>Cartório</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Cartório"
                        value={imovel?.imovelCodigo?.cartorio || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              cartorio: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.taxaLixo">
                      <Form.ControlLabel>Taxa Lixo</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Taxa Lixo"
                        value={imovel?.imovelCodigo?.taxaLixo || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              taxaLixo: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.sequencial">
                      <Form.ControlLabel>Sequêncial</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Sequêncial"
                        value={imovel?.imovelCodigo?.sequencial || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              sequencial: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.sdu">
                      <Form.ControlLabel>Sdu</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Sdu"
                        value={imovel?.imovelCodigo?.sdu || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              sdu: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.imovel">
                      <Form.ControlLabel>Imóvel</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Imóvel"
                        value={imovel?.imovelCodigo?.imovel || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              imovel: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.iptuGaragem">
                      <Form.ControlLabel>IPTU Garagem</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="IPTU Garagem"
                        value={imovel?.imovelCodigo?.iptuGaragem || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              iptuGaragem: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.iptu">
                      <Form.ControlLabel>IPTU</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="IPTU"
                        value={imovel?.imovelCodigo?.iptu || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              iptu: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.gas">
                      <Form.ControlLabel>Gás</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Gás"
                        value={imovel?.imovelCodigo?.gas || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              gas: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.medidorGas">
                      <Form.ControlLabel>Num. Medidor Gas</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Num. Medidor Gas"
                        value={imovel?.imovelCodigo?.medidorGas || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              medidorGas: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.situacaoEscritura">
                      <Form.ControlLabel>
                        Situação da Escritura
                      </Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        data={situacaoEscrituraData}
                        searchable={false}
                        value={imovel?.imovelCodigo?.situacaoEscritura || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              situacaoEscritura: value,
                            },
                          }));
                        }}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Situação da Escritura"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.habiteSe">
                      <Form.ControlLabel>Habite-se</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        data={habiteSeData}
                        value={imovel?.imovelCodigo?.habiteSe || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              habiteSe: value,
                            },
                          }));
                        }}
                        searchable={false}
                        style={{ width: "100%" }}
                        accepter={SelectPicker}
                        placeholder="Habite-se"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.imovelAdm">
                      <Form.ControlLabel>Imóvel no Adm</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Imóvel no Adm"
                        value={imovel?.imovelCodigo?.imovelAdm || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              imovelAdm: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.dae">
                      <Form.ControlLabel>DAE</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="DAE"
                        value={imovel?.imovelCodigo?.dae || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              dae: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.medidorAgua">
                      <Form.ControlLabel>Num. Medidor Agua</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Num. Medidor Agua"
                        value={imovel?.imovelCodigo?.medidorAgua || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              medidorAgua: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.inscAgua">
                      <Form.ControlLabel>Insc Agua</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Insc Agua"
                        value={imovel?.imovelCodigo?.inscAgua || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              inscAgua: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.cpfl">
                      <Form.ControlLabel>CPFL</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="CPFL"
                        value={imovel?.imovelCodigo?.cpfl || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              cpfl: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.medidorEnergia">
                      <Form.ControlLabel>
                        Num. Medidor Energia
                      </Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Num. Medidor Energia"
                        value={imovel?.imovelCodigo?.medidorEnergia || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              medidorEnergia: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.contribuinte">
                      <Form.ControlLabel>Contribuinte</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Contribuinte"
                        value={imovel?.imovelCodigo?.contribuinte || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              contribuinte: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="imovelCodigo.bombeiro">
                      <Form.ControlLabel>Bombeiro</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        placeholder="Bombeiro"
                        value={imovel?.imovelCodigo?.bombeiro || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            imovelCodigo: {
                              ...prevImovel.imovelCodigo,
                              bombeiro: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </Tabs.Tab>
              <Tabs.Tab eventKey="8" title="Fotos">
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={24}>
                    <input type="file" multiple onChange={handleImageUpload} />

                    {/* Passa as imagens e funções para o Container */}
                    <DndProvider backend={HTML5Backend}>
                      <Container
                        images={images}
                        moveImage={moveImage}
                        removeImage={removeImage}
                      />
                    </DndProvider>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </Tabs.Tab>
              <Tabs.Tab eventKey="9" title="Angariadores">
                <FlexboxGrid justify="space-between">
                  <FlexboxGrid.Item colspan={8}>
                    <Form.Group controlId="angariador.angariador">
                      <Form.ControlLabel>Angariador</Form.ControlLabel>
                      <SearchFieldPessoas setPessoa={handleSetAngariador} />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4}>
                    <Form.Group controlId="tipoAngariacao">
                      <Form.ControlLabel>Tipo de Angariação</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        data={tipoAngariacaoData}
                        searchable={false}
                        style={{ width: 224 }}
                        accepter={SelectPicker}
                        placeholder="Tipo de Angariação"
                        value={imovel?.angariador?.tipoAngariacao || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            angariador: {
                              ...prevImovel.angariador,
                              tipoAngariacao: value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}>
                    <Form.ControlLabel>Taxa de Locação</Form.ControlLabel>
                    <InputGroup>
                      <InputGroup.Addon>%</InputGroup.Addon>
                      <Form.Control
                        size="lg"
                        value={imovel?.angariador?.taxaLocacao || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            angariador: {
                              ...prevImovel.angariador,
                              taxaLocacao: value,
                            },
                          }));
                        }}
                      />
                    </InputGroup>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4}>
                    <Form.ControlLabel>Taxa de Administração</Form.ControlLabel>
                    <InputGroup>
                      <InputGroup.Addon>%</InputGroup.Addon>
                      <Form.Control
                        size="lg"
                        value={imovel?.angariador?.taxaAdministracao || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            angariador: {
                              ...prevImovel.angariador,
                              taxaAdministracao: value,
                            },
                          }));
                        }}
                      />
                    </InputGroup>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}>
                    <Form.ControlLabel>Comissão venda</Form.ControlLabel>
                    <InputGroup>
                      <InputGroup.Addon>%</InputGroup.Addon>
                      <Form.Control
                        size="lg"
                        value={imovel?.angariador?.comissaoVenda || ""}
                        onChange={(value) => {
                          setImovel((prevImovel) => ({
                            ...prevImovel,
                            angariador: {
                              ...prevImovel.angariador,
                              comissaoVenda: value,
                            },
                          }));
                        }}
                      />
                    </InputGroup>
                  </FlexboxGrid.Item>

                  <FlexboxGrid.Item colspan={1} style={styleButton}>
                    <Button
                      size="lg"
                      appearance="primary"
                      onClick={() => adicionaAngariadorLista()}
                      loading={loading}
                    >
                      +
                    </Button>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item
                    colspan={24}
                    className="mt-3"
                    style={{ height: "300px", overflowY: "auto" }}
                  >
                    <List size="lg" autoScroll bordered hover>
                      {angariadores.length === 0 ? (
                        <List.Item>
                          <div className="d-flex align-items-center">
                            <div className="form-group col-12">
                              Sem angariadores associados no momento
                            </div>
                          </div>
                        </List.Item>
                      ) : (
                        angariadores.map((doc, index) => (
                          <List.Item key={index} index={index}>
                            <div className="d-flex align-items-center">
                              <div className="form-group col-3">
                                {doc?.angariadorId?.nome || doc.nome}
                              </div>
                              <div className="form-group col-3">
                                {doc?.angariadorId?.cpf || doc.cpf}
                              </div>
                              <div className="form-group col-2">
                                {doc?.angariador?.tipoAngariacao ||
                                  doc.tipoAngariacao}
                              </div>
                              <div className="form-group col-1">
                                {doc?.angariador?.taxaLocacao ||
                                  doc.taxaLocacao}
                              </div>
                              <div className="form-group col-1">
                                {doc?.angariador?.taxaAdministracao ||
                                  doc.taxaAdministracao}
                              </div>
                              <div className="form-group col-1">
                                {doc?.angariador?.comissaoVenda ||
                                  doc.comissaoVenda}
                              </div>

                              <Button
                                color="red"
                                size="lg"
                                onClick={() => removeAngariador(index)}
                              >
                                🗑️
                              </Button>
                            </div>
                          </List.Item>
                        ))
                      )}
                    </List>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </Tabs.Tab>
              <Tabs.Tab eventKey="10" title="Anexos">
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={5}>
                    <Form.Group controlId="documentoTipoDocumento">
                      <Form.ControlLabel>Tipo Documento</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="documentoTipoDocumento"
                        data={documentoTipoData}
                        searchable={false}
                        style={{ width: 224 }}
                        accepter={SelectPicker}
                        placeholder="Tipo Documento"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6} style={styleButton}>
                    <Form.Group controlId="imagemDocumento">
                      <Form.Control
                        size="lg"
                        name="imagemDocumento"
                        fileList={fileList}
                        onPreview={handlePreview}
                        autoUpload={false} // Prevents automatic upload
                        listType="picture-text"
                        onChange={handleImageChange}
                        renderFileInfo={(file, fileElement) => {
                          return (
                            <div onClick={() => handlePreview(file)}>
                              <span>Nome do arquivo: {file.name}</span>
                            </div>
                          );
                        }}
                        accepter={Uploader}
                      >
                        <Button appearance="primary" size="lg">
                          Selecione o arquivo
                        </Button>
                      </Form.Control>
                      <Modal
                        open={showModal}
                        onClose={() => setShowModal(false)}
                      >
                        <Modal.Header>
                          <Modal.Title>Pré-visualização</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {previewUrl && (
                            <img
                              src={previewUrl}
                              alt="Preview"
                              style={{ width: "100%" }}
                            />
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button size="lg" onClick={() => setShowModal(false)}>
                            Fechar
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3} style={styleButton}>
                    <Button
                      appearance="primary"
                      size="lg"
                      onClick={addDocumento}
                    >
                      +
                    </Button>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
                <List size="lg" autoScroll bordered hover>
                  {documentos &&
                    documentos.map((doc, index) => (
                      <List.Item key={index} index={index}>
                        <FlexboxGrid align="middle">
                          <FlexboxGrid.Item colspan={10}>
                            {doc.tipo}
                          </FlexboxGrid.Item>
                          <FlexboxGrid.Item colspan={10}>
                            {" "}
                            <div
                              onClick={() => handlePreview(doc.imagemDocumento)}
                            >
                              <img
                                src={
                                  // Verifica se o retorno é uma URL (exemplo 1)
                                  typeof doc.imagemDocumento === "string"
                                    ? doc.imagemDocumento
                                    : // Verifica se é um objeto com blobFile (exemplo 2)
                                    doc.imagemDocumento.blobFile
                                    ? URL.createObjectURL(
                                        doc.imagemDocumento.blobFile
                                      )
                                    : null
                                }
                                alt="thumbnail"
                                style={{ width: "50px", height: "50px" }}
                              />
                            </div>
                          </FlexboxGrid.Item>
                          <FlexboxGrid.Item colspan={4}>
                            <Button
                              color="red"
                              size="md"
                              onClick={() => removeDocumento(index)}
                            >
                              🗑️
                            </Button>
                          </FlexboxGrid.Item>
                        </FlexboxGrid>
                      </List.Item>
                    ))}
                </List>
              </Tabs.Tab>
              <Tabs.Tab eventKey="11" title="Lista de Contratos">
                <FlexboxGrid>
                  <FlexboxGrid.Item
                    colspan={24}
                    className="mt-3"
                    style={{ height: "60px", overflowY: "auto" }}
                  >
                    <List size="sm" autoScroll bordered hover>
                      {contratos.length === 0 ? (
                        <List.Item>
                          <div className="d-flex align-items-center">
                            <div className="form-group col-12">
                              Sem contratos associados no momento
                            </div>
                          </div>
                        </List.Item>
                      ) : (
                        contratos.map((doc, index) => (
                          <List.Item key={index} index={index}>
                            <div className="d-flex align-items-center">
                              <div className="form-group col-5">{doc._id}</div>
                            </div>
                          </List.Item>
                        ))
                      )}
                    </List>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </Tabs.Tab>
              <Tabs.Tab eventKey="12" title="Orçamentos">
                <FlexboxGrid>
                  <FlexboxGrid.Item
                    colspan={24}
                    className="mt-3"
                    style={{ height: "60px", overflowY: "auto" }}
                  >
                    <List size="sm" autoScroll bordered hover>
                      {orcamentos.length === 0 ? (
                        <List.Item>
                          <div className="d-flex align-items-center">
                            <div className="form-group col-12">
                              Sem orçamentos associados no momento
                            </div>
                          </div>
                        </List.Item>
                      ) : (
                        orcamentos.map((doc, index) => (
                          <List.Item key={index} index={index}>
                            <div className="d-flex align-items-center">
                              <div className="form-group col-5">{doc._id}</div>
                            </div>
                          </List.Item>
                        ))
                      )}
                    </List>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </Tabs.Tab>
              <Tabs.Tab eventKey="13" title="CCM">
                Lista Custos de Captção e Marketing
              </Tabs.Tab>
            </Tabs>
            <Divider />
            <FlexboxGrid.Item
              colspan={24}
              className="d-flex justify-content-end"
              style={{ marginTop: "auto" }}
            >
              <Button
                size="lg"
                appearance="primary"
                onClick={() => setShowModalClonar(true)}
                className="button-spacing"
              >
                Clonar Imóvel
              </Button>
              <Button
                size="lg"
                appearance="primary"
                onClick={() => habilitarCampos()}
                className="button-spacing"
              >
                Editar Imóvel
              </Button>
              <Button
                size="lg"
                appearance="primary"
                onClick={() => window.location.reload()}
                className="button-spacing"
              >
                Cadastrar Imóvel
              </Button>
              <Button
                size="lg"
                appearance="primary"
                type="submit"
                className="button-spacing"
              >
                {behavior === "create" ? "Salvar" : "Atualizar"} Imóvel
              </Button>
            </FlexboxGrid.Item>
          </Form>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Content>
  );
};

export default Imovel;
