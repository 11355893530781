import types from "./types";

export function allAprovacaoLocatario() {
  return { type: types.ALL_APROVACAOLOCATARIO };
}

export function updateAprovacaoLocatario(payload) {
  return { type: types.UPDATE_APROVACAOLOCATARIO, payload };
}

export function filterImoveis() {
  return { type: types.FILTER_APROVACAOLOCATARIOS };
}

export function addAprovacaoLocatario() {
  return { type: types.ADD_APROVACAOLOCATARIO };
}

export function saveAprovacaoLocatario() {
  return { type: types.SAVE_APROVACAOLOCATARIO };
}

export function resetAprovacaoLocatario() {
  return { type: types.RESET_APROVACAOLOCATARIO };
}

export function unlinkAprovacaoLocatario() {
  return { type: types.UNLINK_APROVACAOLOCATARIO };
}
