import React, { createContext, useState } from "react";
import { fetchWithAuth } from "../../services/apiNoState";
// arquivo onde você está utilizando validateCurrentStep

// Cria o contexto
export const TransferenciaContasContext = createContext();

// Provedor do contexto
export const TransferenciaContasProvider = ({ children }) => {
  const [usuarioLogado, setUsuarioLogado] = useState(null);
  const [transferenciaContas, setTransferenciaContas] = useState({
    detalhesTransferencia: [], // Array para armazenar os campos organizados
  });
  const [contratoLocacao, setContratoLocacao] = useState({});
  const [fieldsState, setFieldsState] = useState({}); // Novo estado global

  const [stepData, setStepData] = useState({});

  // Função para buscar o usuário logado
  const carregarUsuarioLogado = async () => {
    try {
      const response = await fetchWithAuth(`/auth/usuario`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // Especifica o tipo de conteúdo
        },
      });
      setUsuarioLogado(response.data);
    } catch (error) {
      console.error("Erro ao carregar usuário logado:", error);
    }
  };

  const handleStepChange = (step) => {
    let filteredData = null;
    switch (step) {
      case 2:
        filteredData = filterStep2Data(transferenciaContas);
        break;
      case 3:
        filteredData = filterStep3Data();
        break;
      default:
        break;
    }
    setStepData((prevData) => ({
      ...prevData,
      [`step${step}`]: JSON.parse(JSON.stringify(filteredData)), // Garante cópia profunda
    }));
  };

  const preencherSteps = (rescisaoParam) => {
    // Objeto com os filtros correspondentes para cada step
    const filtros = {
      2: filterStep2Data,
      3: filterStep3Data,
    };

    // Inicializa o stepData atualizado
    const novoStepData = {};

    // Itera sobre os steps definidos no filtro
    Object.keys(filtros).forEach((step) => {
      const stepNum = parseInt(step, 10); // Converte para número
      if (rescisaoParam) {
        // Aplica o filtro correspondente ao step atual
        const dadosFiltrados = filtros[stepNum](rescisaoParam);
        novoStepData[`step${stepNum}`] = dadosFiltrados;
      }
    });
    // Atualiza o stepData no contexto
    setStepData((prevData) => ({
      ...prevData,
      ...novoStepData, // Mescla os contratoParam novos
    }));
  };

  const filterStep2Data = (transferenciaContas) => {
    const { contasSelecionadas } = transferenciaContas;

    return {
      contasSelecionadas,
    };
  };

  const filterStep3Data = (newData = null) => {
    const { iptuLancado, condominioLancado, rateioLancado } = newData
      ? newData
      : transferenciaContas;

    return {
      iptuLancado,
      condominioLancado,
      rateioLancado,
    };
  };

  const validateCurrentStep = (formRef) => {
    if (!formRef?.current) return false;

    // Executa a validação usando o ref do formulário
    const isValid = formRef.current.check();

    if (!isValid) return false;

    return true; // Retorna true se não houver erros
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Aberto":
        return "status-aberto";
      case "Concluído":
        return "status-concluir";
      case "Andamento":
        return "status-andamento";
      default:
        return "";
    }
  };

  carregarUsuarioLogado();

  return (
    <TransferenciaContasContext.Provider
      value={{
        stepData,
        preencherSteps,
        handleStepChange,
        validateCurrentStep,
        contratoLocacao,
        setContratoLocacao,
        transferenciaContas,
        setTransferenciaContas,
        fieldsState,
        setFieldsState,
        filterStep2Data,
        filterStep3Data,
        getStatusClass,
        usuarioLogado,
      }}
    >
      {children}
    </TransferenciaContasContext.Provider>
  );
};
