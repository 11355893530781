const statusSiteData = [
  "Em anúncio",
  "Anúncio Pausado",
  "Reservado",
  "Em negociação",
  "Locado",
  "Vendido",
  "Inativo",
].map((item) => ({
  label: item,
  value: item,
}));

module.exports = { statusSiteData };
