import { Schema } from "rsuite";

import { tipoSinistroData } from "../../enums/tipoSinistroData";

const { StringType, ArrayType } = Schema.Types;

// Regex que valida números positivos (acima de zero)
const positiveNumberRegex = /^(?!0+(?:\.0+)?$)\d+(?:\.\d+)?$/;

// Função auxiliar para formatar a lista de campos com erro
const joinFields = (fields) => {
  if (fields.length === 0) return "";
  if (fields.length === 1) return fields[0];
  if (fields.length === 2) return fields.join(" e ");
  return (
    fields.slice(0, fields.length - 1).join(", ") +
    " e " +
    fields[fields.length - 1]
  );
};

let dynamicError = "";

export const debitosSchema = Schema.Model({
  listaDebitos: ArrayType()
    .isRequired("A lista de débitos é obrigatória.")
    .addRule(
      function (value) {
        // Reinicia a mensagem de erro a cada validação
        dynamicError = "";

        // Verifica se é um array e possui pelo menos um item
        if (!Array.isArray(value) || value.length === 0) {
          dynamicError += "A lista de débitos é obrigatória.\n";
        }

        // Percorre cada débito e acumula os campos com erro em um array para cada linha
        for (let i = 0; i < value.length; i++) {
          const debito = value[i];
          const errorFields = [];

          // Valida o campo "item" (obrigatório)
          if (
            !debito.item ||
            typeof debito.item !== "string" ||
            debito.item.trim() === ""
          ) {
            errorFields.push("Item");
          }
          // Valida "valor" (obrigatório e deve ser positivo)
          if (
            debito.valor === undefined ||
            debito.valor === null ||
            String(debito.valor).trim() === "" ||
            !positiveNumberRegex.test(String(debito.valor).trim())
          ) {
            errorFields.push("Valor");
          }
          // Valida "multa" (opcional; se informado, deve ser positivo)
          if (
            debito.multa !== undefined &&
            debito.multa !== null &&
            String(debito.multa).trim() !== "" &&
            !positiveNumberRegex.test(String(debito.multa).trim())
          ) {
            errorFields.push("Multa");
          }
          // Valida "juros" (opcional; se informado, deve ser positivo)
          if (
            debito.juros !== undefined &&
            debito.juros !== null &&
            String(debito.juros).trim() !== "" &&
            !positiveNumberRegex.test(String(debito.juros).trim())
          ) {
            errorFields.push("Juros");
          }
          // Valida "total" (obrigatório e deve ser positivo)
          if (
            debito.total === undefined ||
            debito.total === null ||
            String(debito.total).trim() === "" ||
            !positiveNumberRegex.test(String(debito.total).trim())
          ) {
            errorFields.push("Total");
          }
          // Valida "dataVencimento" (obrigatório; deve ser uma data válida)
          const data = new Date(debito.dataVencimento);
          if (!debito.dataVencimento || isNaN(data.getTime())) {
            errorFields.push("Data de Vencimento");
          }

          if (errorFields.length > 0) {
            dynamicError += `Erro linha ${
              i + 1
            } da lista de débitos (${joinFields(errorFields)})\n`;
          }
        }
        return dynamicError === "";
      },
      function () {
        if (dynamicError) {
          const errorsArray = dynamicError
            .split("\n")
            .filter((line) => line.trim() !== "");
          return `Foram encontrados ${errorsArray.length} erro(s):\n${dynamicError}`;
        }
        return "";
      }
    ),
});

export const step1Schema = Schema.Model({
  tipoSinistro: StringType()
    .isRequired("O Tipo Sinistro é obrigatório.")
    .addRule(
      (value) => tipoSinistroData.some((item) => item.value === value),
      "Valor inválido para Tipo Sinistro."
    ),
  locatarioMoraImovel: StringType()
    .isRequired("É necessário informar se locatário mora no imóvel.")
    .addRule(
      (value) => ["Sim", "Não"].includes(value),
      "O valor deve ser 'Sim' ou 'Não'"
    ),
});

export const step2Schema = Schema.Model({
  numeroSinistro: StringType()
    .isRequired("Número do sinistro é obrigatório.")
    .minLength(3, "O número do sinistro deve ter pelo menos 3 caracteres.")
    .maxLength(100, "Número do sinistro deve ter no máximo 100 caracteres."),
});

export const step3Schema = Schema.Model({
  numeroSinistro: StringType()
    .isRequired("Número do sinistro é obrigatório.")
    .minLength(3, "O número do sinistro deve ter pelo menos 3 caracteres.")
    .maxLength(100, "Número do sinistro deve ter no máximo 100 caracteres."),
});

export const step4Schema = Schema.Model({
  numeroSinistro: StringType()
    .isRequired("Número do sinistro é obrigatório.")
    .minLength(3, "O número do sinistro deve ter pelo menos 3 caracteres.")
    .maxLength(100, "Número do sinistro deve ter no máximo 100 caracteres."),
});
