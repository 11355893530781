import { useState } from "react";

const useHandlePreview = () => {
  const [showModal, setShowModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handlePreview = (file) => {
    const reader = new FileReader();
    if (typeof file === "string") {
      setPreviewUrl(file);
      setShowModal(true);
    } else if (file.blobFile) {
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file.blobFile);
    } else {
      console.error("Arquivo inválido para preview:", file);
    }
  };

  return { showModal, setShowModal, previewUrl, handlePreview };
};

export default useHandlePreview;
