import React, { createContext, useState } from "react";
// arquivo onde você está utilizando validateCurrentStep

// Cria o contexto
export const ContratoLocacaoContext = createContext();

// Provedor do contexto
export const ContratoLocacaoProvider = ({ children }) => {
  const [contratoLocacao, setContratoLocacao] = useState({});

  const [stepData, setStepData] = useState({});

  const handleStepChange = (step) => {
    let filteredData = null;
    switch (step) {
      case 1:
        filteredData = filterStep1Data(contratoLocacao);
        break;
      case 2:
        filteredData = filterStep2Data(contratoLocacao);
        break;
      case 3:
        filteredData = filterStep3Data(contratoLocacao);
        break;
      case 4:
        filteredData = filterStep4Data(contratoLocacao);
        break;
      case 5:
        filteredData = filterStep5Data(contratoLocacao);
        break;
      case 6:
        filteredData = filterStep6Data(contratoLocacao);
        break;
      case 7:
        filteredData = filterStep7Data(contratoLocacao);
        break;
      default:
        // Para outros steps, siga o comportamento padrão

        break;
    }
    setStepData((prevData) => ({
      ...prevData,
      [`step${step}`]: filteredData, // Atualiza o step1 com os dados filtrados
    }));
  };

  const preencherSteps = (contratoParam) => {
    // Objeto com os filtros correspondentes para cada step
    const filtros = {
      1: filterStep1Data,
      2: filterStep2Data,
      3: filterStep3Data,
      4: filterStep4Data,
      5: filterStep5Data,
      6: filterStep6Data,
      7: filterStep7Data,
    };

    // Inicializa o stepData atualizado
    const novoStepData = {};

    // Itera sobre os steps definidos no filtro
    Object.keys(filtros).forEach((step) => {
      const stepNum = parseInt(step, 10); // Converte para número
      if (contratoParam) {
        // Aplica o filtro correspondente ao step atual
        const dadosFiltrados = filtros[stepNum](contratoParam);
        novoStepData[`step${stepNum}`] = dadosFiltrados;
      }
    });
    // Atualiza o stepData no contexto
    setStepData((prevData) => ({
      ...prevData,
      ...novoStepData, // Mescla os contratoParam novos
    }));
  };

  const filterStep1Data = (contratoLocacao) => {
    const {
      status,
      rateioObs,
      rateioAgua,
      rateioEnergia,
      valorAluguel,
      corretora,
      diaVencimentoAluguel,
      seguradora,
      valorTaxaLocacao,
      valorParcelaSeguro,
      qtdParcelaTaxaLocacao,
      formaPagamento,
      percentualTaxaAdmMensal,
      valorCondominio,
      valorIptu,
      cobrancaCondominio,
      responsavelFinanceiroCondominio,
      cobrancaIptu,
      responsavelFinanceiroIptu,
      informacaoAdicional,
    } = contratoLocacao;

    return {
      status,
      rateioObs,
      rateioAgua,
      rateioEnergia,
      valorAluguel,
      corretora,
      diaVencimentoAluguel,
      seguradora,
      valorTaxaLocacao,
      valorParcelaSeguro,
      qtdParcelaTaxaLocacao,
      formaPagamento,
      percentualTaxaAdmMensal,
      valorCondominio,
      valorIptu,
      cobrancaCondominio,
      responsavelFinanceiroCondominio,
      cobrancaIptu,
      responsavelFinanceiroIptu,
      informacaoAdicional,
    };
  };

  const filterStep2Data = (contratoLocacao) => {
    const {
      dadosCadastraisConferidos,
      contasConsumoConferido,
      imagemCpflField,
      imagemDaeField,
      imagemCondominioField,
      imagemGasField,
      entraDimob,
      cadastradoSuperlogica,
    } = contratoLocacao;

    return {
      dadosCadastraisConferidos,
      contasConsumoConferido,
      imagemCpflField,
      imagemDaeField,
      imagemCondominioField,
      imagemGasField,
      entraDimob,
      cadastradoSuperlogica,
    };
  };

  const filterStep3Data = (contratoLocacao) => {
    const {
      inicioContrato,
      validadeContrato,
      valorTaxaLocacao,
      isentoMultaRescisoria,
      indiceReajuste,
      isentoApos,
      modalidade,
      garantido,
      contaBancaria,
      prazoRepasse,
      apoliceGerada,
      contratoLocacaoGerado,
      contratoAdmGerado,
      clausulaEspecial,
      clausulaEspecialObs,
      obsContrato,
    } = contratoLocacao;

    return {
      inicioContrato,
      validadeContrato,
      valorTaxaLocacao,
      isentoMultaRescisoria,
      indiceReajuste,
      isentoApos,
      modalidade,
      garantido,
      contaBancaria,
      prazoRepasse,
      apoliceGerada,
      contratoLocacaoGerado,
      contratoAdmGerado,
      clausulaEspecial,
      clausulaEspecialObs,
      obsContrato,
    };
  };

  const filterStep4Data = (contratoLocacao) => {
    const { vistoriaLancada, dataVistoria, vistoriador, obsVistoria } =
      contratoLocacao;

    return {
      vistoriaLancada,
      dataVistoria,
      vistoriador,
      obsVistoria,
    };
  };

  const filterStep5Data = (contratoLocacao) => {
    const {
      seguradoraInc,
      apoliceSeguroIncendioGerada,
      imagemSeguroIncendioField,
      apoliceSeguroIncendio,
      dataSeguroIncendio,
      valorSeguroIncendio,
      vencimentoSeguroIncendio,
      obsSeguroIncendio,
    } = contratoLocacao;

    return {
      seguradoraInc,
      apoliceSeguroIncendioGerada,
      imagemSeguroIncendioField,
      apoliceSeguroIncendio,
      dataSeguroIncendio,
      valorSeguroIncendio,
      vencimentoSeguroIncendio,
      obsSeguroIncendio,
    };
  };

  const filterStep6Data = (contratoLocacao) => {
    const {
      modeloAssinatura,
      situacaoDocumentos,
      obsContrato,
      imagemLocacao,
      imagemVistoria,
      imagemAdministracao,
    } = contratoLocacao;

    return {
      modeloAssinatura,
      situacaoDocumentos,
      obsContrato,
      imagemLocacao,
      imagemVistoria,
      imagemAdministracao,
    };
  };

  const filterStep7Data = (contratoLocacao) => {
    const {
      condominioContratoEnviadoAdministradora,
      termoEntregaChaves,
      chavesEntregues,
      imagemTermoEntregaAssinado,
      lancamentoSuperlogica,
      codigoContrato,
    } = contratoLocacao;

    return {
      condominioContratoEnviadoAdministradora,
      termoEntregaChaves,
      chavesEntregues,
      imagemTermoEntregaAssinado,
      lancamentoSuperlogica,
      codigoContrato,
    };
  };

  const validateCurrentStep = (step, formRef) => {
    if (!formRef?.current) return false;

    // Executa a validação usando o ref do formulário
    const isValid = formRef.current.check();

    if (!isValid) return false;

    return true; // Retorna true se não houver erros
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Aberto":
        return "status-aberto";
      case "Ganhou":
        return "status-ganhou";
      case "Perdeu":
        return "status-perdeu";
      case "Andamento":
        return "status-andamento";
      default:
        return "";
    }
  };

  return (
    <ContratoLocacaoContext.Provider
      value={{
        stepData,
        preencherSteps,
        handleStepChange,
        validateCurrentStep,
        contratoLocacao,
        setContratoLocacao,
        filterStep1Data,
        filterStep2Data,
        filterStep3Data,
        filterStep4Data,
        filterStep5Data,
        filterStep6Data,
        filterStep7Data,
        getStatusClass,
      }}
    >
      {children}
    </ContratoLocacaoContext.Provider>
  );
};
