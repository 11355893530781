import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import "./index.css"; // Certifique-se de que o caminho está correto

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/pessoa"); // Redireciona para a página inicial ou outra rota protegida após login
    }
  }, [isAuthenticated, navigate]);

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`; // URL do seu backend
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        <Button appearance="primary" onClick={handleGoogleLogin}>
          Login com Google
        </Button>
      </div>
    </div>
  );
};

export default Login;
