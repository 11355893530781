import React, { useContext } from "react";
import { Steps } from "rsuite";
import { AprovacaoLocatarioContext } from "./AprovacaoLocatarioContext";

const MySteps = ({ step, setStep, isFiadorSelected, isSeguroSelected }) => {
  const { aprovacaoLocatario } = useContext(AprovacaoLocatarioContext);

  return (
    <Steps current={step - 1} onChange={setStep}>
      <Steps.Item title="Caixa de Entrada" />
      <Steps.Item
        title="Seguro fiança"
        status={isFiadorSelected ? "error" : null}
      />
      <Steps.Item title="Fiador" status={isSeguroSelected ? "error" : null} />
      <Steps.Item title="Documentos" />
      <Steps.Item title="Valores e Locação" />
      <Steps.Item title="Aprovação" />
    </Steps>
  );
};

export default MySteps;
