const documentoPessoaTipoData = [
  "RG",
  "Cert. Nascimento/Casamento",
  "Comp. Renda",
  "Comp. End.",
  "Imposto de renda",
  "Antecedentes Criminais",
  "Tributários Federais/Dívida Ativa União",
  "Certidão Negativa Débitos Trabalhistas",
  "Processos I & II",
  "Certidão Civil",
  "Certidão Criminal",
  "Certidão de Reclamação Trabalhista",
  "TRF 3a Região Cert. Civil",
  "TRF 3a Região Cert. Criminal",
  "TRF 3a Região Cert. Eleitoral",
  "Tribunal Superior Eleitoral",
  "Situação Cadastral no CPF",
  "Comprovante Inscrição/Situação Cadastral CNPJ",
  "ACISB",
  "SERASA",
  "Matrícula do imóvel do Fiador",
  "IPTU do Fiador",
].map((item) => ({
  label: item,
  value: item,
}));

module.exports = { documentoPessoaTipoData };
