import React, { useContext, useState } from "react";
import {
  FlexboxGrid,
  Text,
  Panel,
  List,
  Button,
  Heading,
  Divider,
  Form,
  InputGroup,
  SelectPicker,
} from "rsuite";
import SearchFieldImoveis from "../../components/Search/SearchFieldImoveis";
import { AprovacaoLocatarioContext } from "./AprovacaoLocatarioContext";
import "./style.css";
import tipoImovelData from "../../enums/tipoImovel";
import SearchFieldPessoas from "../../components/Search/SearchFieldPessoas";

const garantiaData = ["Seguro fiança", "Fiador"].map((item) => ({
  label: item,
  value: item,
}));

const CaixaDeEntrada = () => {
  const { aprovacaoLocatario, setAprovacaoLocatario } = useContext(
    AprovacaoLocatarioContext
  ); // Use o contexto correto

  const handleSetImovel = (imovelData) => {
    setAprovacaoLocatario((prevState) => ({
      ...prevState, // preserva o restante das informações
      imovel: imovelData, // adiciona ou atualiza o campo imovel
    }));
  };

  const handleSetLocatario = (locatarioData) => {
    setAprovacaoLocatario((prevState) => ({
      ...prevState, // preserva o restante das informações
      locatarios: [...prevState.locatarios, locatarioData], // adiciona o novo locatário
    }));
  };

  const removeMorador = (index) => {
    setAprovacaoLocatario((prevState) => ({
      ...prevState, // preserva o restante das informações
      moradores: prevState.moradores.filter((_, i) => i !== index), // remove o morador pelo índice
    }));
  };

  const handleSetMorador = (locatarioData) => {
    setAprovacaoLocatario((prevState) => ({
      ...prevState, // preserva o restante das informações
      moradores: [...prevState.moradores, locatarioData], // adiciona o novo morador
    }));
  };

  const removeLocatario = (index) => {
    setAprovacaoLocatario((prevState) => ({
      ...prevState, // preserva o restante das informações
      locatarios: prevState.locatarios.filter((_, i) => i !== index), // remove o locatario pelo índice
    }));
  };

  // Valor correspondente ao tipo de imóvel
  const tipoImovel = aprovacaoLocatario?.imovel?.tipoImovel;

  // Encontre o objeto correspondente ao tipo de imóvel
  const tipoImovelInfo = tipoImovel
    ? tipoImovelData.find((item) => item.valor === tipoImovel)
    : null;

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={12}>
          <Heading level={3} className="titulo-imovel">
            Selecione um Imóvel
          </Heading>
          <SearchFieldImoveis setImovel={handleSetImovel} />
          <hr />
          <Panel className="imovel-panel" shaded>
            {aprovacaoLocatario?.imovel ? (
              <>
                <Text bold className="imovel-titulo">
                  Endereço do Imóvel:
                </Text>
                <Text block className="imovel-info">
                  Tipo imóvel:{" "}
                  {tipoImovelInfo
                    ? `${tipoImovelInfo.tipoImovel} - ${tipoImovelInfo.label}`
                    : "Tipo não encontrado"}
                </Text>
                <Text block className="imovel-info">
                  Rua: {aprovacaoLocatario.imovel.endereco.rua},{" "}
                  {aprovacaoLocatario.imovel.endereco.numero}
                </Text>
                <Text block className="imovel-info">
                  Bairro: {aprovacaoLocatario.imovel.endereco.bairro}
                </Text>
                <Text block className="imovel-info">
                  Cidade: {aprovacaoLocatario.imovel.endereco.cidade}, Estado:{" "}
                  {aprovacaoLocatario.imovel.endereco.estado}, CEP:{" "}
                  {aprovacaoLocatario.imovel.endereco.cep}
                </Text>
                <Text block className="imovel-info">
                  País: {aprovacaoLocatario.imovel.endereco.pais}
                </Text>
                {aprovacaoLocatario.imovel.endereco.complemento && (
                  <Text block className="imovel-info">
                    Complemento:{" "}
                    {aprovacaoLocatario.imovel.endereco.complemento}
                  </Text>
                )}
              </>
            ) : (
              <Text italic className="imovel-sem-info">
                Nenhum imóvel selecionado
              </Text>
            )}
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12}>
          <Heading level={3} className="titulo-locatario">
            Selecione um ou mais Locatário(s)
          </Heading>
          <SearchFieldPessoas
            setPessoa={handleSetLocatario}
            pessoasAssociadas={aprovacaoLocatario.locatarios}
          />
          <hr />
          <List size="lg" autoScroll bordered hover>
            {aprovacaoLocatario.locatarios &&
            aprovacaoLocatario?.locatarios.length === 0 ? (
              <List.Item>Sem locatários associados no momento</List.Item>
            ) : (
              aprovacaoLocatario?.locatarios?.map((doc, index) => (
                <List.Item key={index} index={index}>
                  <FlexboxGrid align="middle">
                    <FlexboxGrid.Item colspan={10}>{doc.nome}</FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={10}>{doc.cpf}</FlexboxGrid.Item>
                    <FlexboxGrid.Item
                      colspan={4}
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        color="red"
                        size="lg"
                        onClick={() => removeLocatario(index)}
                      >
                        🗑️
                      </Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </List.Item>
              ))
            )}
          </List>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Divider />
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={12}>
          <Heading level={3} className="titulo-morador">
            Selecione um ou mais Morador(es)
          </Heading>
          <SearchFieldPessoas
            setPessoa={handleSetMorador} // Função para definir o morador
            pessoasAssociadas={aprovacaoLocatario.moradores} // Lista de moradores
          />
          <hr />
          <List size="lg" autoScroll bordered hover>
            {aprovacaoLocatario.moradores &&
            aprovacaoLocatario?.moradores.length === 0 ? (
              <List.Item>Sem moradores associados no momento</List.Item>
            ) : (
              aprovacaoLocatario?.moradores?.map((doc, index) => (
                <List.Item key={index} index={index}>
                  <FlexboxGrid align="middle">
                    <FlexboxGrid.Item colspan={10}>{doc.nome}</FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={10}>{doc.cpf}</FlexboxGrid.Item>
                    <FlexboxGrid.Item
                      colspan={4}
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        color="red"
                        size="lg"
                        onClick={() => removeMorador(index)} // Função para remover o morador
                      >
                        🗑️
                      </Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </List.Item>
              ))
            )}
          </List>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12}>
          <Form.Group controlId="valorAluguel">
            <Form.ControlLabel>Aluguel</Form.ControlLabel>
            <InputGroup>
              <InputGroup.Addon>R$</InputGroup.Addon>
              <Form.Control
                size="lg"
                name="valorAluguel"
                placeholder="Aluguel"
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="valorIptu">
            <Form.ControlLabel>IPTU</Form.ControlLabel>
            <InputGroup>
              <InputGroup.Addon>R$</InputGroup.Addon>
              <Form.Control size="lg" name="valorIptu" placeholder="IPTU" />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="valorCondominio">
            <Form.ControlLabel>Condominio</Form.ControlLabel>
            <InputGroup>
              <InputGroup.Addon>R$</InputGroup.Addon>
              <Form.Control
                size="lg"
                name="valorCondominio"
                placeholder="Valor"
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="garantia">
            <Form.ControlLabel>Garantia</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="garantia"
              data={garantiaData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Garantia"
            />
          </Form.Group>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default CaixaDeEntrada;
