const types = {
  ALL_APROVACAOLOCATARIO: "@AprovacaoLocatario/ALL",
  UPDATE_APROVACAOLOCATARIO: "@AprovacaoLocatario/UPDATE",
  FILTER_APROVACAOLOCATARIOS: "@AprovacaoLocatario/FILTER",
  ADD_APROVACAOLOCATARIO: "@AprovacaoLocatario/ADD",
  SAVE_APROVACAOLOCATARIO: "@AprovacaoLocatario/SAVE",
  RESET_APROVACAOLOCATARIO: "@AprovacaoLocatario/RESET",
  UNLINK_APROVACAOLOCATARIO: "@AprovacaoLocatario/UNLINK",
};
export default types;
