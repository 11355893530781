const localChavesData = [
  "Imobiliaria",
  "Proprietário",
  "Outro",
  "Portaria",
].map((item) => ({
  label: item,
  value: item,
}));

module.exports = { localChavesData };
