import React, { useContext } from "react";
import {
  FlexboxGrid,
  DatePicker,
  InputGroup,
  Input,
  RadioGroup,
  Form,
  Radio,
  Divider,
  Button,
} from "rsuite";
import { RescisaoContratoContext } from "./RescisaoContratoContext";

const RepasseFinal = ({ handleFormChange, handleGanhouClick }) => {
  const { rescisaoContrato } = useContext(RescisaoContratoContext); // Use o contexto correto

  const styleButton = { alignSelf: "center" };

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={18}>
          <Form.Group controlId="detalheRepasse">
            <Form.ControlLabel>Detalhes do Repasse</Form.ControlLabel>
            <Input
              as="textarea"
              rows={5}
              size="lg"
              name="detalheRepasse"
              placeholder="Detalhes do Repasse"
              value={rescisaoContrato.detalheRepasse || ""}
              onChange={(event) => {
                handleFormChange({
                  ...rescisaoContrato,
                  detalheRepasse: event,
                });
              }}
            />
          </Form.Group>
          <Divider />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={styleButton}>
          <Button
            size="lg"
            appearance="primary"
            color="green"
            className="button-spacing" // Adiciona a classe de estilo e espaçamento
            onClick={handleGanhouClick}
          >
            Ganhou
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          <Form.Group controlId="dataRepasse">
            <Form.ControlLabel>Data do Repasse</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="dataRepasse"
              searchable={false}
              style={{ width: "100%" }}
              accepter={DatePicker}
              value={
                rescisaoContrato.dataRepasse
                  ? new Date(rescisaoContrato.dataRepasse)
                  : null
              }
              format="dd/MM/yyyy"
              placeholder="Data do Repasse"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} align="center">
          <Form.ControlLabel>Repasse liquidado?</Form.ControlLabel>
          <Form.Control
            size="lg"
            name="repasseLiquidado"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3}>
          <Form.ControlLabel>Multa Rescisória</Form.ControlLabel>
          <InputGroup>
            <InputGroup.Addon>%</InputGroup.Addon>
            <Form.Control
              size="lg"
              name="multaRescisoria"
              placeholder="Multa Rescisória"
            />
          </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={1} />
        <FlexboxGrid.Item colspan={6} align="center">
          <Form.ControlLabel>
            Efetuados os ajustes nos repasses?
          </Form.ControlLabel>
          <Form.Control
            size="lg"
            name="ajustesRepasse"
            style={{ backgroundColor: "white" }}
            appearance="picker"
            accepter={RadioGroup}
            inline
          >
            <Radio value="Sim">Sim</Radio>
            <Radio value="Não">Não</Radio>
          </Form.Control>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default RepasseFinal;
