import { toaster, Message } from "rsuite";

export const showSuccess = (message) => {
  toaster.push(
    <Message type="success" showIcon>
      {message}
    </Message>,
    {
      placement: "topEnd",
      duration: 5000,
    }
  );
};

export const showError = (message) => {
  toaster.push(
    <Message type="error" showIcon>
      {message}
    </Message>,
    {
      placement: "topEnd",
      duration: 5000,
    }
  );
};
