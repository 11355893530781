import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useContext,
} from "react";
import {
  List,
  DatePicker,
  Uploader,
  FlexboxGrid,
  IconButton,
  Table,
  Modal,
  Text,
  SelectPicker,
  Panel,
  RadioGroup,
  Heading,
  Form,
  Radio,
  Button,
  Divider,
} from "rsuite";
import { Plus, Check, Edit, Trash } from "@rsuite/icons";
import { SinistroContext } from "./SinistroContext";
import "./style.css";
import { format } from "date-fns";
import { tipoSinistroData } from "../../enums/tipoSinistroData";
import useHandlePreview from "../../hooks/useHandlePreview";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import DeclaracaoDebitosPDF from "../../services/pdf/DeclaracaoDebitosPDF";

const CaixaDeEntrada = () => {
  const { contratoLocacao, sinistro, setSinistro } =
    useContext(SinistroContext);

  const [fileList, setFileList] = useState([]);

  const { showModal, setShowModal, previewUrl, handlePreview } =
    useHandlePreview();

  const { Column, HeaderCell, Cell } = Table;
  const [editingRow, setEditingRow] = useState(null);
  // Objeto para armazenar as refs dos EditableCells da linha em edição
  const editingRowRefs = useRef({});

  const handleGeneratePDF = async () => {
    const dados = {
      apolice: "123456",
      endereco: "Rua Exemplo, 123 - Bairro - Cidade/Estado",
      ocupado: true,
      dataDesocupacao: "01/03/2025",
      nomeContato: "João Silva",
      telefoneContato: "(11) 99999-9999",
      emailContato: "joao@email.com",
      valorIndenizacao: "10.000,00",
      favorecido: "Maria Oliveira",
      banco: "Banco Exemplo",
      agencia: "1234",
      contaCorrente: "56789-0",
      localData: "São Paulo, 03 de Março de 2025",
    };

    const debitos = [
      {
        descricao: "Aluguel",
        vencimento: "10/02/2025",
        competencia: "Fev/2025",
        valor: "R$ 2.000,00",
      },
      {
        descricao: "Condomínio",
        vencimento: "10/02/2025",
        competencia: "Fev/2025",
        valor: "R$ 800,00",
      },
    ];

    const blob = await pdf(
      <DeclaracaoDebitosPDF dados={dados} debitos={debitos} />
    ).toBlob();
    saveAs(blob, "Declaracao_Debitos.pdf");
  };

  // Função para calcular a validade do contrato
  const calcularValidadeContrato = () => {
    if (contratoLocacao.inicioContrato && contratoLocacao.validadeContrato) {
      const inicioContrato = new Date(contratoLocacao.inicioContrato);
      const dataInicioFormatada = format(inicioContrato, "dd/MM/yyyy");
      const mesesValidade = parseInt(contratoLocacao.validadeContrato, 10);
      const dataFinalContrato = new Date(inicioContrato);
      dataFinalContrato.setMonth(dataFinalContrato.getMonth() + mesesValidade);
      const dataFinalFormatada = format(dataFinalContrato, "dd/MM/yyyy");
      return `${dataInicioFormatada} à ${dataFinalFormatada}`;
    }
    return "";
  };

  // Função para renderizar a lista de proprietários
  const renderizarProprietarios = () => {
    if (contratoLocacao.imovel) {
      return contratoLocacao.imovel.proprietario.map((item, index) => (
        <div key={index}>
          <Text block className="contrato-info">
            {item.proprietarioId.nome} - {item.proprietarioId.cpf}
          </Text>
          <Text block className="contrato-info">
            Telefones:
            {item.proprietarioId.telefones.map((telefone, idx) => (
              <div key={idx}>
                {telefone.tipo}: {telefone.telefone}
              </div>
            ))}
          </Text>
        </div>
      ));
    }
    return "";
  };

  // Função para renderizar a lista de locatario
  const renderizarLocatarios = () => {
    if (contratoLocacao.locatario) {
      return contratoLocacao.locatario.map((item, index) => (
        <div key={index}>
          <Text block className="contrato-info">
            {item.nome} - {item.cpf}
          </Text>
          <Text block className="contrato-info">
            Telefones:
            {item.telefones.map((telefone, idx) => (
              <div key={idx}>
                {telefone.tipo}: {telefone.telefone}
              </div>
            ))}
          </Text>
        </div>
      ));
    }
    return "";
  };

  // Atualizar um valor no estado
  const handleSave = (rowIndex, key, value) => {
    setSinistro((prev) => {
      const updatedData = JSON.parse(JSON.stringify(prev.listaDebitos)); // Clonagem profunda
      updatedData[rowIndex][key] = value;

      return {
        ...prev,
        listaDebitos: updatedData,
      };
    });
  };

  const EditableCell = forwardRef(
    ({ rowIndex, dataKey, rowData, onSave }, ref) => {
      const initialValue =
        dataKey === "dataVencimento"
          ? rowData[dataKey] instanceof Date
            ? rowData[dataKey]
            : new Date(rowData[dataKey]) // Converter a data para objeto Date se necessário
          : rowData[dataKey] || "";

      const [value, setValue] = useState(initialValue);

      const handleChange = (e) => {
        setValue(e.target.value);
      };

      // Função commit (para outros campos) que envia o valor atual para o pai
      const commit = () => {
        onSave(rowIndex, dataKey, value);
      };

      useImperativeHandle(ref, () => ({
        commit,
        getValue: () => value,
      }));

      if (dataKey === "dataVencimento") {
        return (
          <Form.Control
            size="lg"
            searchable={false}
            style={{ width: "100%" }}
            accepter={DatePicker}
            value={value}
            format="dd/MM/yyyy"
            placeholder="Data de Vencimento"
            onChange={(newValue) => {
              // Atualiza o estado local e chama onSave diretamente com o novo valor.
              setValue(newValue);
              onSave(rowIndex, dataKey, newValue);
            }}
          />
        );
      } else {
        return (
          <input
            style={{ width: "100%" }}
            value={value}
            onChange={handleChange}
          />
        );
      }
    }
  );

  // Função para atualizar o sinistro (listaDebitos) quando o usuário confirmar a edição
  const handleSaveCell = (rowIndex, dataKey, newValue) => {
    setSinistro((prev) => {
      const newDebitos = [...(prev.listaDebitos || [])];
      if (!newDebitos[rowIndex]) {
        newDebitos[rowIndex] = {};
      }
      newDebitos[rowIndex][dataKey] = newValue;
      return { ...prev, listaDebitos: newDebitos };
    });
  };

  const toggleEditingRow = (rowIndex) => {
    if (editingRow === rowIndex) {
      // Se a linha estiver em edição, chama commit em todas as células dessa linha
      Object.entries(editingRowRefs.current).forEach(([key, cellRef]) => {
        if (
          cellRef &&
          cellRef.current &&
          typeof cellRef.current.commit === "function"
        ) {
          cellRef.current.commit();
        }
      });

      // Em vez de ler do estado (que pode não ter sido atualizado ainda),
      // obtém os valores diretamente dos refs:
      const valor = parseFloat(
        editingRowRefs.current["valor"]?.current?.getValue() || 0
      );
      const multa = parseFloat(
        editingRowRefs.current["multa"]?.current?.getValue() || 0
      );
      const juros = parseFloat(
        editingRowRefs.current["juros"]?.current?.getValue() || 0
      );
      const computedTotal = (valor + multa + juros).toFixed(2);

      // Salva o total calculado
      handleSaveCell(rowIndex, "total", computedTotal);

      // Limpa as refs da linha em edição e fecha o modo de edição
      editingRowRefs.current = {};
      setEditingRow(null);
    } else {
      editingRowRefs.current = {};
      setEditingRow(rowIndex);
    }
  };

  /*Informações referente a manipulação da tabela dinamica */
  // Adicionar um novo registro
  const handleAddRecord = () => {
    const newRecord = {
      id: Date.now(),
      item: "",
      valor: "",
      multa: "",
      juros: "",
      dataVencimento: "",
      total: "",
    };
    setSinistro((prev) => ({
      ...prev,
      listaDebitos: [...(prev.listaDebitos || []), newRecord],
    }));
  };

  // Remover um registro
  const handleRemoveRecord = (rowId) => {
    setSinistro((prev) => ({
      ...prev,
      listaDebitos: prev.listaDebitos.filter((item) => item.id !== rowId),
    }));
  };

  const documentoTipoData = [
    "Boleto do débito",
    "Boleto de Aluguel",
    "Condominio",
    "Conta de Agua",
    "Conta de CPFL",
    "Conta de Gás",
  ].map((item) => ({
    label: item,
    value: item,
  }));

  const addDocumento = () => {
    let clearFields = {
      imagemDocumento: "",
      tipoDocumento: "",
    };

    setSinistro((prevSinistro) => {
      const novaLista = prevSinistro?.listaAnexos || [];

      return {
        ...prevSinistro,
        listaAnexos: [
          ...novaLista,
          {
            tipo: prevSinistro.tipoDocumento,
            imagemDocumento: prevSinistro.imagemDocumento[0],
          },
        ],
        ...clearFields,
      };
    });

    setFileList([]);
  };

  const removeDocumento = (index) => {
    const updatedDocumento = sinistro.listaAnexos.filter((_, i) => i !== index);
    setSinistro(updatedDocumento);
  };

  const handleImageChange = (files) => {
    if (files.length > 1) {
      files = [files[files.length - 1]];
    }
    setFileList(files);
  };

  const styleButton = { alignSelf: "center" };

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          <Form.Group controlId="tipoSinistro">
            <Form.ControlLabel>Tipo Sinistro</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="tipoSinistro"
              data={tipoSinistroData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Tipo Sinistro"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Heading level={3} className="titulo-imovel">
            Resumo do Contrato
          </Heading>
          <Panel className="rescisao-panel" shaded>
            <>
              <Text block className="imovel-info">
                Validade do contrato: {calcularValidadeContrato()}
              </Text>
              <hr />
              <Text block className="transferencia-subtitulo">
                Locador:
              </Text>
              {renderizarProprietarios()}
              <hr />
              <Text block className="transferencia-subtitulo">
                Locatario:
              </Text>
              {renderizarLocatarios()}
              <hr />
              <Text block className="transferencia-subtitulo">
                Corretora:
              </Text>
              <Text block className="contrato-info">
                {contratoLocacao?.seguroFianca?.corretora || "-"}
              </Text>
              <Text block className="transferencia-subtitulo">
                Garantidora:
              </Text>
              <Text block className="contrato-info">
                {contratoLocacao?.seguroFianca?.seguradora || "-"}
              </Text>
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Heading level={3} className="titulo-imovel">
            Resumo do imóvel
          </Heading>
          <Panel className="rescisao-panel" shaded>
            <>
              {contratoLocacao.endereco && (
                <>
                  <Text block className="transferencia-subtitulo">
                    Endereço:
                  </Text>
                  <Text block className="proprietario-info">
                    {contratoLocacao.endereco.rua},{" "}
                    {contratoLocacao.endereco.numero},{" "}
                    {contratoLocacao.endereco.complemento}
                    {" - "}
                    {contratoLocacao.endereco.bairro}
                  </Text>
                </>
              )}
            </>
          </Panel>
        </FlexboxGrid.Item>
        <Divider />
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="locatarioMoraImovel">
            <Form.ControlLabel>
              Locatário ainda mora no imóvel?
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="locatarioMoraImovel"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24}>
          <Button
            appearance="primary"
            onClick={handleGeneratePDF}
            style={{ marginBottom: 10 }}
            icon={<Plus />}
          >
            Gerar PDF
          </Button>

          <Button
            appearance="primary"
            onClick={handleAddRecord}
            style={{ marginBottom: 10 }}
            icon={<Plus />}
          >
            Adicionar Débito
          </Button>
          <h5 style={{ textAlign: "center", marginBottom: "10px" }}>
            Lista de débitos
          </h5>

          <Table
            data={sinistro.listaDebitos || []}
            height={200}
            bordered
            cellBordered
            wordWrap
            autoHeight
          >
            {/* item */}
            <Column flexGrow={1}>
              <HeaderCell>Item</HeaderCell>
              <Cell>
                {(rowData, rowIndex) =>
                  editingRow === rowIndex ? (
                    <EditableCell
                      ref={
                        // Cria ou reutiliza uma ref para esta célula, armazenando-a com a chave "item"
                        editingRowRefs.current["item"] ||
                        (editingRowRefs.current["item"] = React.createRef())
                      }
                      rowIndex={rowIndex}
                      dataKey="item"
                      rowData={rowData}
                      onSave={handleSaveCell}
                    />
                  ) : (
                    <span>{rowData.item}</span>
                  )
                }
              </Cell>
            </Column>

            {/* valor */}
            <Column flexGrow={1}>
              <HeaderCell>Valor</HeaderCell>
              <Cell>
                {(rowData, rowIndex) =>
                  editingRow === rowIndex ? (
                    <EditableCell
                      ref={
                        // Cria ou reutiliza uma ref para esta célula, armazenando-a com a chave "item"
                        editingRowRefs.current["valor"] ||
                        (editingRowRefs.current["valor"] = React.createRef())
                      }
                      rowIndex={rowIndex}
                      dataKey="valor"
                      rowData={rowData}
                      onSave={handleSaveCell}
                    />
                  ) : (
                    // Exibe o nome (label) correspondente ao value
                    <span>{rowData.valor}</span>
                  )
                }
              </Cell>
            </Column>

            {/* multa */}
            <Column flexGrow={1}>
              <HeaderCell>Multa</HeaderCell>
              <Cell>
                {(rowData, rowIndex) =>
                  editingRow === rowIndex ? (
                    <EditableCell
                      ref={
                        // Cria ou reutiliza uma ref para esta célula, armazenando-a com a chave "multa"
                        editingRowRefs.current["multa"] ||
                        (editingRowRefs.current["multa"] = React.createRef())
                      }
                      rowIndex={rowIndex}
                      dataKey="multa"
                      rowData={rowData}
                      onSave={handleSaveCell}
                    />
                  ) : (
                    <span>{rowData.multa}</span>
                  )
                }
              </Cell>
            </Column>

            {/* juros */}
            <Column flexGrow={1}>
              <HeaderCell>Juros</HeaderCell>
              <Cell>
                {(rowData, rowIndex) =>
                  editingRow === rowIndex ? (
                    <EditableCell
                      ref={
                        // Cria ou reutiliza uma ref para esta célula, armazenando-a com a chave "juros"
                        editingRowRefs.current["juros"] ||
                        (editingRowRefs.current["juros"] = React.createRef())
                      }
                      rowIndex={rowIndex}
                      dataKey="juros"
                      rowData={rowData}
                      onSave={handleSaveCell}
                    />
                  ) : (
                    <span>{rowData.juros}</span>
                  )
                }
              </Cell>
            </Column>

            {/* dataVencimento */}
            <Column flexGrow={1}>
              <HeaderCell>Data de Vencimento</HeaderCell>
              <Cell>
                {(rowData, rowIndex) =>
                  editingRow === rowIndex ? (
                    <EditableCell
                      ref={
                        // Cria ou reutiliza uma ref para esta célula, armazenando-a com a chave "multa"
                        editingRowRefs.current["dataVencimento"] ||
                        (editingRowRefs.current["dataVencimento"] =
                          React.createRef())
                      }
                      rowIndex={rowIndex}
                      dataKey="dataVencimento"
                      rowData={rowData}
                      onSave={handleSaveCell}
                    />
                  ) : (
                    // Aqui, formatamos a data para garantir que o React a renderize corretamente
                    <span>
                      {rowData.dataVencimento
                        ? new Date(rowData.dataVencimento).toLocaleDateString(
                            "pt-BR"
                          )
                        : "-"}
                    </span>
                  )
                }
              </Cell>
            </Column>

            {/* total */}
            <Column flexGrow={1}>
              <HeaderCell>Total</HeaderCell>
              <Cell>
                {(rowData, rowIndex) => {
                  const total = (
                    (parseFloat(rowData.valor) || 0) +
                    (parseFloat(rowData.multa) || 0) +
                    (parseFloat(rowData.juros) || 0)
                  ).toFixed(2);
                  return <span>{total}</span>;
                }}
              </Cell>
            </Column>

            {/* Ações */}
            <Column width={150} fixed="right">
              <HeaderCell>Ações</HeaderCell>
              <Cell>
                {(rowData, rowIndex) => (
                  <div style={{ display: "flex", gap: 10 }}>
                    <IconButton
                      icon={editingRow === rowIndex ? <Check /> : <Edit />}
                      size="xs"
                      appearance="primary"
                      onClick={() => toggleEditingRow(rowIndex)}
                    />
                    <IconButton
                      icon={<Trash />}
                      size="xs"
                      appearance="ghost"
                      color="red"
                      onClick={() => handleRemoveRecord(rowData.id)}
                    />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
        </FlexboxGrid.Item>
        <Divider />
        <FlexboxGrid.Item colspan={12} align="center">
          <Panel className="imovel-panel" shaded>
            <>
              <Form.Group controlId="imagemLocacaoField">
                <Form.ControlLabel>Contrato de locação</Form.ControlLabel>
                <img
                  src={sinistro?.imagemLocacaoField?.url}
                  width={200}
                  height={150}
                  alt="Contrato de Locação"
                  onClick={() => handlePreview(sinistro?.imagemLocacaoField)}
                  style={{ cursor: "pointer", borderRadius: 8 }}
                />
              </Form.Group>
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Panel className="imovel-panel" shaded>
            <>
              <Form.Group controlId="imagemAdministracaoField">
                <Form.ControlLabel>Contrato de Administração</Form.ControlLabel>
                <img
                  src={sinistro?.imagemAdministracaoField?.url}
                  width={200}
                  height={150}
                  alt="Contrato de Administração"
                  onClick={() =>
                    handlePreview(sinistro?.imagemAdministracaoField)
                  }
                  style={{ cursor: "pointer", borderRadius: 8 }}
                />
              </Form.Group>
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24} align="center">
          <hr />
          <Panel className="imovel-panel" shaded>
            <Form.Group controlId="imagensRgLocadores">
              <Form.ControlLabel>RGs dos Locadores</Form.ControlLabel>
              <FlexboxGrid
                justify="start"
                align="top"
                style={{ gap: "20px", flexWrap: "wrap" }}
              >
                {sinistro?.imagemRgLocadores &&
                  Object.entries(sinistro.imagemRgLocadores).map(
                    ([proprietario, rgs]) => (
                      <FlexboxGrid.Item
                        key={proprietario}
                        colspan={8}
                        style={{ minWidth: "250px" }}
                      >
                        <h5
                          style={{ textAlign: "center", marginBottom: "10px" }}
                        >
                          {proprietario}
                        </h5>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                            justifyContent: "center",
                          }}
                        >
                          {rgs.map((rg, index) => (
                            <img
                              key={index}
                              src={rg.url}
                              width={200}
                              height={150}
                              alt={`RG de ${proprietario} - ${index + 1}`}
                              onClick={() => handlePreview(rg)}
                              style={{ cursor: "pointer", borderRadius: 8 }}
                            />
                          ))}
                        </div>
                      </FlexboxGrid.Item>
                    )
                  )}
              </FlexboxGrid>
            </Form.Group>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24} align="center">
          <hr />
          <Panel className="imovel-panel" shaded>
            <Form.Group controlId="imagemRgLocatarios">
              <Form.ControlLabel>RGs dos Locatarios</Form.ControlLabel>
              <FlexboxGrid
                justify="start"
                align="top"
                style={{ gap: "20px", flexWrap: "wrap" }}
              >
                {sinistro?.imagemRgLocatarios &&
                  Object.entries(sinistro.imagemRgLocatarios).map(
                    ([inquilino, rgs]) => (
                      <FlexboxGrid.Item
                        key={inquilino}
                        colspan={8}
                        style={{ minWidth: "250px" }}
                      >
                        <h5
                          style={{ textAlign: "center", marginBottom: "10px" }}
                        >
                          {inquilino}
                        </h5>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                            justifyContent: "center",
                          }}
                        >
                          {rgs.map((rg, index) => (
                            <img
                              key={index}
                              src={rg.url}
                              width={200}
                              height={150}
                              alt={`RG de ${inquilino} - ${index + 1}`}
                              onClick={() => handlePreview(rg)}
                              style={{ cursor: "pointer", borderRadius: 8 }}
                            />
                          ))}
                        </div>
                      </FlexboxGrid.Item>
                    )
                  )}
              </FlexboxGrid>
            </Form.Group>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          <hr />
          <Form.Group controlId="tipoDocumento">
            <Form.ControlLabel>Tipo Documento</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="tipoDocumento"
              data={documentoTipoData}
              searchable={false}
              style={{ width: 224 }}
              accepter={SelectPicker}
              placeholder="Tipo Documento"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={styleButton}>
          <Form.Group controlId="imagemDocumento">
            <Form.Control
              size="lg"
              name="imagemDocumento"
              fileList={fileList}
              maxFiles={1}
              onPreview={handlePreview}
              autoUpload={false} // Prevents automatic upload
              listType="picture-text"
              onChange={handleImageChange}
              renderFileInfo={(file, fileElement) => {
                return (
                  <div onClick={() => handlePreview(file)}>
                    <span>Nome do arquivo: {file.name}</span>
                  </div>
                );
              }}
              accepter={Uploader}
            >
              <Button appearance="primary" size="lg">
                Selecione o arquivo
              </Button>
            </Form.Control>
            <Modal open={showModal} onClose={() => setShowModal(false)}>
              <Modal.Header>
                <Modal.Title>Pré-visualização</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {previewUrl && (
                  <img
                    src={previewUrl}
                    alt="Preview"
                    style={{ width: "100%" }}
                  />
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button size="lg" onClick={() => setShowModal(false)}>
                  Fechar
                </Button>
              </Modal.Footer>
            </Modal>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={styleButton}>
          <hr />
          <Button appearance="primary" size="lg" onClick={addDocumento}>
            +
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={21} style={styleButton}>
          <h5 style={{ textAlign: "center", marginBottom: "20px" }}>
            Lista de Anexos
          </h5>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={24}>
          <List size="lg" autoScroll bordered hover>
            {!sinistro.listaAnexos || sinistro?.listaAnexos?.length === 0 ? (
              <List.Item>Sem documentos associados no momento</List.Item>
            ) : (
              sinistro?.listaAnexos?.map((doc, index) => (
                <List.Item key={index} index={index}>
                  <FlexboxGrid align="middle">
                    <FlexboxGrid.Item colspan={10}>{doc.tipo}</FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={10}>
                      {" "}
                      <div onClick={() => handlePreview(doc.imagemDocumento)}>
                        <img
                          src={
                            // Verifica se o retorno é uma URL (exemplo 1)
                            typeof doc.imagemDocumento === "string"
                              ? doc.imagemDocumento
                              : // Verifica se é um objeto com blobFile (exemplo 2)
                              doc.imagemDocumento.blobFile
                              ? URL.createObjectURL(
                                  doc.imagemDocumento.blobFile
                                )
                              : null
                          }
                          alt="thumbnail"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4}>
                      <Button
                        color="red"
                        size="md"
                        onClick={() => removeDocumento(index)}
                      >
                        🗑️
                      </Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </List.Item>
              ))
            )}
          </List>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default CaixaDeEntrada;
