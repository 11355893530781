import React, { useContext } from "react";

import Inicio from "./Inicio";
import Vistoria from "./Vistoria";
import EtapaFinal from "./EtapaFinal";
import CancelamentoSeguros from "./CancelamentoSeguros";
import AguardandoReembolso from "./AguardandoReembolso";
import RepasseFinal from "./RepasseFinal";
import { RescisaoContratoContext } from "./RescisaoContratoContext";

const MyComponent = ({
  step,
  handleFormChange,
  handleGanhouClick,
  handleSubmitComunicacao,
}) => {
  const { stepData, handleStepChange } = useContext(RescisaoContratoContext);

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <Inicio
            formData={stepData.step1}
            onChange={(data) => handleStepChange(1)}
            handleFormChange={handleFormChange}
          />
        );
      case 2:
        return (
          <Vistoria
            formData={stepData.step2}
            onChange={(data) => handleStepChange(2)}
            handleFormChange={handleFormChange}
          />
        );
      case 3:
        return (
          <EtapaFinal
            formData={stepData.step3}
            onChange={(data) => handleStepChange(3)}
            handleFormChange={handleFormChange}
          />
        );
      case 4:
        return (
          <CancelamentoSeguros
            formData={stepData.step4}
            onChange={(data) => handleStepChange(4)}
            handleFormChange={handleFormChange}
          />
        );
      case 5:
        return (
          <AguardandoReembolso
            formData={stepData.step5}
            onChange={(data) => handleStepChange(5)}
            handleFormChange={handleFormChange}
            handleSubmitComunicacao={handleSubmitComunicacao}
          />
        );
      case 6:
        return (
          <RepasseFinal
            formData={stepData.step6}
            onChange={(data) => handleStepChange(6)}
            handleFormChange={handleFormChange}
            handleGanhouClick={handleGanhouClick}
          />
        );
      default:
        return <div>Selecione uma etapa</div>;
    }
  };

  return (
    <>
      <hr />
      {renderContent()}
    </>
  );
};

export default MyComponent;
