import { Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { authenticateSuccess } from "./store/modules/auth/actions";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ element }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    // Primeiro, verificar se já está autenticado
    if (isAuthenticated) {
      return;
    }

    // Em seguida, verificar se existe um token na URL
    const query = new URLSearchParams(location.search);
    const token = query.get("token");

    if (token) {
      // Salvar o token no localStorage e autenticar o usuário
      localStorage.setItem("token", token);
      const decoded = jwtDecode(token);

      if (decoded.exp * 1000 > Date.now()) {
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("email", decoded.email);
        dispatch(authenticateSuccess());
      }
    }
  }, [isAuthenticated, dispatch, location]);

  return isAuthenticated ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
