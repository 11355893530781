import {
  FlexboxGrid,
  Form,
  Button,
  Divider,
  ButtonGroup,
  Content,
  Text,
  Modal,
  SelectPicker,
  Table,
} from "rsuite";
import { useRef, useContext, useState, useEffect } from "react";
import MySteps from "./MySteps";
import MyComponent from "./MyComponent";
import { SinistroContext } from "./SinistroContext";
import { format } from "date-fns";
import { fetchWithAuth } from "../../services/apiNoState";
import {
  debitosSchema,
  step1Schema,
  step2Schema,
  step3Schema,
  step4Schema,
} from "./validacao";
import { showError, showSuccess } from "../../components/Utils/toastUtils";
import { useLocation } from "react-router-dom";
import { fazerUpload, addFileToUploader } from "../../services/manageUploads";

/**
 * Componente principal do contrato de locação.
 * Este componente gerencia o estado do contrato, a navegação entre etapas e a validação do formulário.
 */
const Sinistro = () => {
  const {
    setContratoLocacao,
    sinistro,
    setSinistro,
    validateCurrentStep,
    stepData,
    handleStepChange,
    preencherSteps,
    filterStep1Data,
    filterStep2Data,
    filterStep3Data,
    filterStep4Data,
    getStatusClass,
    usuarioLogado,
  } = useContext(SinistroContext);

  const { Column, HeaderCell, Cell } = Table;

  const [step, setStep] = useState(1);

  const [formError, setFormError] = useState({});
  const [formDisabled, setFormDisabled] = useState(false); // Estado para controlar o formulário

  const location = useLocation();
  const contratoParam = location.state?.contrato; // Acessa 'contrato' de 'location.state'
  const sinistroParam = location.state?.sinistro; // Acessa 'sinistro' de 'location.state'

  const [showModalComunicacao, setShowModalComunicacao] = useState(false);
  const [showModalConcluir, setShowModalConcluir] = useState(false);
  const [showModalAdicionarRegistro, setShowModalAdicionarRegistro] =
    useState(false);

  const formRef = useRef();

  const quemData = ["Seguradora", "Locatário", "Proprietário", "Interna"].map(
    (item) => ({
      label: item,
      value: item,
    })
  );

  /**
   * Efeito colateral que carrega os dados do contrato de locação ao montar o componente.
   * Atualiza o estado com os dados do contrato se o parâmetro de contrato estiver presente na localização.
   */
  useEffect(() => {
    const carregarSinistro = async () => {
      if (contratoParam) {
        setContratoLocacao(contratoParam);
        sinistro.origem = "Manual";
      } else if (sinistroParam) {
        setContratoLocacao(sinistroParam.contratoId);

        // Atualiza o estado com os dados vindos de `sinistroParam`
        setSinistro(() => {
          let updates = {
            ...sinistroParam,
          };

          if (sinistroParam.status === "Concluído") {
            setFormConcluir();
          } else {
            handleAndamentoClick(); // Se nenhuma das condições acima for atendida
          }

          return updates;
        });

        preencherSteps(sinistroParam);
      }
      // Atualiza o estado com os dados vindos de `sinistroParam`
      setSinistro(() => {
        let updates = {};
        let contratoSelecionado = contratoParam || sinistroParam.contratoId;
        if (sinistroParam) {
          updates = {
            ...sinistroParam,
          };
        }
        if (contratoSelecionado.contratoTarefaId.imagemLocacaoField) {
          updates.imagemLocacaoField = addFileToUploader(
            contratoSelecionado.contratoTarefaId.imagemLocacaoField.conteudo,
            contratoSelecionado.contratoTarefaId.imagemLocacaoField.nome
          );
        }
        if (contratoSelecionado.contratoTarefaId.imagemAdministracaoField) {
          updates.imagemAdministracaoField = addFileToUploader(
            contratoSelecionado.contratoTarefaId.imagemAdministracaoField
              .conteudo,
            contratoSelecionado.contratoTarefaId.imagemAdministracaoField.nome
          );
        }

        if (contratoSelecionado.imagemRgLocadores) {
          updates.imagemRgLocadores = Object.entries(
            contratoSelecionado.imagemRgLocadores
          ).reduce((acc, [proprietario, rgs]) => {
            acc[proprietario] = rgs.map((rg) =>
              addFileToUploader(rg.conteudo, rg.nome)
            );
            return acc;
          }, {});
        }

        if (contratoSelecionado.imagemRgLocatarios) {
          updates.imagemRgLocatarios = Object.entries(
            contratoSelecionado.imagemRgLocatarios
          ).reduce((acc, [inquilino, rgs]) => {
            acc[inquilino] = rgs.map((rg) =>
              addFileToUploader(rg.conteudo, rg.nome)
            );
            return acc;
          }, {});
        }

        // Processa documentosOrcamento e adiciona ao estado
        if (sinistroParam.listaAnexos && sinistroParam.listaAnexos.length) {
          updates.listaAnexos = sinistroParam.listaAnexos.map((documento) => {
            const arquivoUploader = addFileToUploader(
              documento.imagemDocumento.conteudo,
              documento.imagemDocumento.nome
            );
            return {
              tipo: documento.tipo, // Preserva o tipo
              imagemDocumento: arquivoUploader, // Inclui os dados formatados para o Uploader
            };
          });
        }

        return updates;
      });
    };

    carregarSinistro();
  }, []);

  /**
   * Manipula as mudanças no formulário e atualiza o estado do contrato de locação.
   * @param {Object} formValue - Os valores do formulário que foram alterados.
   */
  const handleFormChange = (formValue) => {
    setSinistro((prevState) => ({
      ...prevState,
      ...formValue,
    }));
  };

  /**
   * Envia os dados do formulário para o backend.
   * Valida o passo atual e verifica se houve alterações antes de enviar os dados.
   * @param {string} status - O status atual do contrato.
   */
  const handleSubmit = async (status = sinistro.status) => {
    if (validateCurrentStep(step, formRef)) {
      // if (sinistro.status === "Aberto") {
      //   await handleAndamentoClick();
      // }

      //Verifica se tem id, caso não, significa que preciso fazer um post ao invés de PUT
      if (!sinistro._id) {
        await handleAndamentoClick();
        try {
          // Salva a aprovação caso o ID não exista
          const savedRescisao = await saveSinistro(sinistro);

          // Atualiza o sinistro com o ID retornado
          setSinistro((prev) => ({
            ...prev,
            _id: savedRescisao.id, // ou o campo correspondente retornado pela função
          }));

          // Se houver mudanças, salva as alterações no stepData
          handleStepChange(step);
        } catch (error) {
          console.error("Erro ao salvar o sinistro:", error);
          return; // Interrompe se houver erro ao salvar
        }

        // Verifica se houve alterações
      } else if (await hasAnyChanges(step)) {
        //Chama o backend e atualiza todos os campos que tiveram mudança
        await atualizarSinistro(status);
        // Se houver mudanças, salva as alterações no stepData
        handleStepChange(step);
      }
    } else {
      showError(
        "Falha na validação, por favor valide todos os campos obrigatórios"
      );
      return false;
    }
  };

  /**
   * Verifica se houve alterações nos dados do formulário.
   * Compara os dados atuais com os dados salvos para determinar se houve alguma mudança.
   * @param {number} step - O número do passo atual do formulário.
   * @returns {boolean} - Retorna true se houver alterações, caso contrário false.
   */
  const hasAnyChanges = async (step) => {
    let currentData;

    switch (step) {
      case 1:
        currentData = filterStep1Data(sinistro); // Filtra os dados relevantes do step 1
        break;
      case 2:
        currentData = filterStep2Data(sinistro); // Filtra os dados relevantes do step 2
        break;
      case 3:
        currentData = filterStep3Data(); // Filtra os dados relevantes do step 3
        break;
      case 4:
        currentData = filterStep4Data(); // Filtra os dados relevantes do step 4
        break;
      default:
        return false;
    }

    const savedData = stepData[`step${step}`]; // Dados salvos para o step atual

    // Comparação dos dados
    const hasChanges = Object.keys(currentData).some((key) => {
      const currentValue = currentData[key]
        ? JSON.parse(JSON.stringify(currentData[key]))
        : null;

      const savedValue =
        savedData && savedData[key]
          ? JSON.parse(JSON.stringify(savedData[key]))
          : null;

      // Comparação padrão para outros campos
      return JSON.stringify(currentValue) !== JSON.stringify(savedValue);
    });

    return hasChanges;
  };

  /**
   * Atualiza os dados de sinistro no backend.
   * Envia uma requisição PUT para atualizar o sinistro com os dados atuais.
   * @param {string} status - O status atual do sinistro.
   */
  const atualizarSinistro = async (status) => {
    try {
      sinistro.step = step;
      //Caso o handleSubmit seja chamado pelo aprovação está aprovada?, então alterar o status
      if (status === "Concluído") {
        sinistro.status = status;
      }

      const response = await fetchWithAuth(`/sinistro/${sinistro._id}`, {
        method: "PUT",
        body: JSON.stringify(sinistro), // Salva os dados
        headers: {
          "Content-Type": "application/json", // Especifica o tipo de conteúdo
        },
      });

      if (response.error) {
        showError("Erro ao alterar o sinistro: ", response.message);
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Sinistro parcial alterado");
      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao salvar o sinistro: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  const saveSinistro = async (sinistro) => {
    try {
      const response = await fetchWithAuth("/sinistro", {
        method: "POST",
        body: JSON.stringify({
          ...sinistro,
          contratoId: contratoParam._id,
        }), // Salva os dados
        headers: {
          "Content-Type": "application/json", // Especifica o tipo de conteúdo
        },
      });

      if (response.error) {
        showError("Erro ao salvar o sinistro: ", response.message);
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Sinistro parcial salvo");
      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao salvar o sinistro: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  /**
   * Valida e faz upload das imagens se houver alterações.
   * Verifica quais arquivos de imagem foram alterados e os envia para o backend.
   */
  const validaEUploadImagens = async () => {
    // Map de campos relacionados ao step, incluindo sub-elementos para arrays
    const stepFieldsMap = {
      1: ["listaAnexos.imagemDocumento"], // Campo em lista
    };

    // Recupera os campos baseados no step atual
    const fieldsToValidate = stepFieldsMap[step];
    if (!fieldsToValidate) return; // Se não houver campos para o step, encerra.

    // Obter os campos do sinistro e savedFields
    const savedFieldsForStep = stepData[`step${step}`];

    const arquivosParaUpload = [];

    fieldsToValidate.forEach((fieldPath) => {
      const [field, subField] = fieldPath.split("."); // Divide em campo e subcampo, se existir

      if (Array.isArray(sinistro[field])) {
        // Caso seja uma lista de objetos
        sinistro[field].forEach((item, index) => {
          const currentField = item?.[subField]?.blobFile;
          const savedField = savedFieldsForStep?.[field]?.[index]?.[subField];

          // Verifica se há alterações
          if (currentField && currentField.name !== savedField) {
            arquivosParaUpload.push({
              arquivo: currentField,
              contexto: { field, subField, index }, // Passa o contexto
            });
          }
        });
      } else {
        // Caso seja um campo simples
        const currentField = sinistro[field]?.blobFile;
        const savedField =
          savedFieldsForStep?.[field]?.nome ||
          savedFieldsForStep?.[field]?.name;

        // Verifica se há alterações
        if (currentField && currentField.name !== savedField) {
          arquivosParaUpload.push({
            arquivo: currentField,
            contexto: { field }, // Passa o contexto
          });
        }
      }
    });

    if (arquivosParaUpload.length === 0) return; // Nada a ser feito se não houver alterações.

    // Faz o upload utilizando o método ajustado no manageUpload
    const resultado = await fazerUpload(arquivosParaUpload);

    // Atualiza o sinistro com os nomes retornados
    if (resultado) {
      resultado.forEach((file) => {
        const { field, subField, index } = file.contexto;

        if (Array.isArray(sinistro[field]) && typeof index === "number") {
          // Atualiza o subcampo dentro da lista
          sinistro[field][index][subField] = file.fileName;
        } else {
          // Atualiza o estado no contexto
          setSinistro((prevState) => {
            const updatedState = {
              ...prevState,
              [field.replace("Field", "")]: file.fileName, // Atualiza o campo correspondente
            };
            return updatedState; // Retorna o estado atualizado
          });
        }
      });
    }
  };

  /**
   * Navega para o próximo passo do formulário.
   * Valida o passo atual e, se tudo estiver correto, avança para o próximo passo.
   */
  const onNext = async () => {
    const status = sinistro.status;
    if (status === "Concluído") {
      goNextStep();
      return;
    }

    if (validateCurrentStep(step, formRef)) {
      const { listaDebitos } = sinistro; // certifique-se de que "sinistro" esteja acessível aqui
      // Aqui você pode usar seu schema customizado (por exemplo, debitosSchema) para validar a lista de débitos
      const errorsDebitos = debitosSchema.check({ listaDebitos });
      if (step === 1 && errorsDebitos.listaDebitos.hasError) {
        showError(errorsDebitos.listaDebitos.errorMessage);
        return false;
      }

      // Verifica se o ID da aprovação já existe, caso não, significa que preciso ter a primeira inclusão da contrato no banco
      if (status === "Aberto") {
        await handleAndamentoClick();
      }

      //Verifica se tem id, caso não, significa que preciso fazer um post ao invés de PUT
      if (!sinistro._id) {
        await handleAndamentoClick();
        try {
          //Verifica se o step atual possui imagens
          await validaEUploadImagens();

          // Salva a aprovação caso o ID não exista
          const savedSinistro = await saveSinistro(sinistro);

          // Atualiza o sinistro com o ID retornado
          setSinistro((prev) => ({
            ...prev,
            _id: savedSinistro.id, // ou o campo correspondente retornado pela função
          }));

          // Se houver mudanças, salva as alterações no stepData
          handleStepChange(step);
        } catch (error) {
          console.error("Erro ao salvar o sinistro:", error);
          return; // Interrompe se houver erro ao salvar
        }

        // Verifica se houve alterações
      } else if (await hasAnyChanges(step)) {
        //Verifica se o step atual possui imagens
        await validaEUploadImagens();
        //Chama o backend e atualiza todos os campos que tiveram mudança
        await atualizarSinistro(status); // Chama o método separado
        // Se houver mudanças, salva as alterações no stepData
        handleStepChange(step);
      }
      goNextStep();
    } else {
      showError(
        "Falha na validação, por favor valide todos os campos obrigatórios"
      );
    }
  };

  const goNextStep = () => {
    // Valida os campos do step atual
    try {
      setStep(step + 1);
    } catch (error) {
      console.error("Error saving step data:", error);
    }
  };

  /**
   * Navega para o passo anterior do formulário.
   * Atualiza o estado para o passo anterior.
   */
  const onPrevious = () => {
    let prevStep = step - 1;

    setStep(prevStep);
  };

  /**
   * Obtém o esquema de validação para o passo atual.
   * Retorna o esquema de validação correspondente ao passo atual.
   * @param {number} step - O número do passo atual.
   * @returns {Object} - O esquema de validação para o passo atual.
   */
  const getSchemaForStep = (step) => {
    switch (step) {
      case 1:
        return step1Schema;
      case 2:
        return step2Schema;
      case 3:
        return step3Schema;
      case 4:
        return step4Schema;
      default:
    }
  };

  const handleComunicacaoClick = () => {
    setShowModalComunicacao(true); // Abrir a modal de "Comunicacao"
  };

  /**
   * Manipula a ação de clicar no botão "Concluir".
   * Abre a modal de confirmação para alterar o status para "Concluir".
   */
  const handleConcluirClick = () => {
    setShowModalConcluir(true); // Abrir a modal de "Concluir"
  };

  const handleAdicionarRegistroComunicacaoClick = () => {
    setShowModalAdicionarRegistro(true);
  };

  const handleSubmitComunicacao = async () => {
    // Ações ao confirmar a Comunicação
    try {
      sinistro.step = step;
      const response = await fetchWithAuth(
        `/sinistro/comunicacao/${sinistro._id}`,
        {
          method: "PUT",
          body: JSON.stringify(sinistro.listaComunicacao), // Salva os dados
          headers: {
            "Content-Type": "application/json", // Especifica o tipo de conteúdo
          },
        }
      );

      if (response.error) {
        showError(
          "Erro interno ao atualizar a comunicação: ",
          response.message
        );
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Comunicação atualizada com sucesso!");

      setShowModalComunicacao(false);

      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao atualizar a comunicação: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  const handleSubmitAdicionarRegistro = async () => {
    handleAddRecord();

    // Ação de confirmar a adção de Registro
    showSuccess("Registro adicionado com sucesso");

    setShowModalAdicionarRegistro(false); // Fecha a modal de Adicionar Registro
  };

  /**
   * Envia os dados para alterar o status do contrato para "Concluir".
   * Envia uma requisição para atualizar o status do contrato no backend.
   */
  const handleSubmitConcluir = async () => {
    // Ações ao confirmar a mudança para "Concluir"
    try {
      sinistro.step = step;
      const response = await fetchWithAuth(
        `/sinistro/concluir/${sinistro._id}`,
        {
          method: "PUT",
        }
      );

      if (response.error) {
        showError(
          "Erro interno ao alterar o status para concluir: ",
          response.message
        );
        throw response.message; // Propaga o erro para ser tratado em outro lugar
      }

      showSuccess("Status alterado para concluir com sucesso");

      setFormConcluir();
      setShowModalConcluir(false); // Fecha a modal de "Concluir"

      return await response; // Retorna a resposta, se necessário
    } catch (error) {
      showError("Erro ao alterar o status para concluir: ", error);
      throw error; // Propaga o erro para ser tratado em outro lugar
    }
  };

  /**
   * Altera o formulário para o estado "Concluir".
   * Desabilita o formulário e atualiza o status para "Concluir".
   */
  const setFormConcluir = () => {
    setFormDisabled(true); // Desabilita o formulário
    setSinistro((prevState) => ({
      ...prevState,
      status: "Concluído",
    }));
  };

  /**
   * Manipula a ação de clicar no botão de andamento.
   * Atualiza o status do contrato para "Andamento".
   */
  const handleAndamentoClick = async () => {
    sinistro.status = "Andamento";
  };

  /*Informações referente a manipulação da tabela dinamica */
  // Adicionar um novo registro
  const handleAddRecord = () => {
    const newRecord = {
      quem: sinistro.quem,
      descricao: sinistro.descricao,
      criadoEm: format(new Date(), "dd/MM/yyyy HH:mm"), // Formato personalizado
      criadoPor: usuarioLogado?.name || "Desconhecido", // Preenche com o nome do usuário
    };
    setSinistro((prev) => ({
      ...prev,
      listaComunicacao: [...(prev.listaComunicacao || []), newRecord],
    }));
  };
  /*Fim das informações da tabela dinamica */
  const styleButton = { alignSelf: "center" };

  /**
   * Manipula a ação de clicar no botão de andamento.
   * Atualiza o status do contrato para "Andamento".
   */
  return (
    <Content className="m-2">
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={24}>
          <Form
            ref={formRef}
            className="subContent formSpacing"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            model={getSchemaForStep(step)} // Esquema dinâmico com base no step atual
            formValue={sinistro}
            onChange={handleFormChange}
            onCheck={(formError) => setFormError(formError)}
            onSubmit={handleSubmit}
            disabled={formDisabled} // Desabilita o formulário com base no estado formDisabled
            fluid
          >
            {/* Modal para adicionar Comunicacao */}
            <Modal
              open={showModalAdicionarRegistro}
              onClose={() => setShowModalAdicionarRegistro(false)}
              backdrop="static"
            >
              <Modal.Header>
                <Modal.Title>Adicionar registro de comunicação</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FlexboxGrid justify="space-between">
                  <FlexboxGrid.Item colspan={12}>
                    <Form.Group controlId="quem">
                      <Form.ControlLabel>Quem</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="quem"
                        data={quemData}
                        searchable={false}
                        style={{ width: 224 }}
                        accepter={SelectPicker}
                        placeholder="Quem"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={12}>
                    <Form.Group controlId="descricao">
                      <Form.ControlLabel>Descrição</Form.ControlLabel>
                      <Form.Control
                        size="lg"
                        name="descricao"
                        placeholder="Descrição"
                      />
                    </Form.Group>
                  </FlexboxGrid.Item>

                  <Divider />
                </FlexboxGrid>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="lg"
                  appearance="ghost"
                  onClick={() => setShowModalAdicionarRegistro(false)}
                >
                  Cancelar
                </Button>
                <Button
                  size="lg"
                  appearance="primary"
                  onClick={handleSubmitAdicionarRegistro}
                >
                  Adicionar Registro Novo
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Fim Modal para adicionar Comunicacao */}

            {/* Modal de Comunicacao */}
            <Modal
              size="lg"
              open={showModalComunicacao}
              onClose={() => setShowModalComunicacao(false)}
            >
              <Modal.Header>
                <Modal.Title className="modal-title">Comunicação</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="modal-message">
                  Gerenciamento de comunicações internas
                </p>
                <hr />
                <Form fluid>
                  <FlexboxGrid.Item colspan={24}>
                    <Button
                      size="lg"
                      appearance="primary"
                      color="gray"
                      className="button-spacing" // Adiciona a classe de estilo e espaçamento
                      onClick={handleAdicionarRegistroComunicacaoClick}
                    >
                      Adicionar Registro de Comunicação
                    </Button>
                    <hr />
                    <Table
                      data={sinistro.listaComunicacao || []}
                      height={200}
                      bordered
                      cellBordered
                      wordWrap
                      autoHeight
                    >
                      {/* Quem */}
                      <Column flexGrow={1}>
                        <HeaderCell>Quem</HeaderCell>
                        <Cell>{(rowData) => <span>{rowData.quem}</span>}</Cell>
                      </Column>

                      {/* Descrição */}
                      <Column flexGrow={1}>
                        <HeaderCell>Descrição</HeaderCell>
                        <Cell>
                          {(rowData) => <span>{rowData.descricao}</span>}
                        </Cell>
                      </Column>

                      {/* Criado em */}
                      <Column flexGrow={1}>
                        <HeaderCell>Criado em</HeaderCell>
                        <Cell>
                          {(rowData) => <span>{rowData.criadoEm}</span>}
                        </Cell>
                      </Column>

                      {/* Criado por */}
                      <Column flexGrow={1}>
                        <HeaderCell>Criado por</HeaderCell>
                        <Cell>
                          {(rowData) => <span>{rowData.criadoPor}</span>}
                        </Cell>
                      </Column>
                    </Table>
                  </FlexboxGrid.Item>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  appearance="ghost"
                  onClick={() => setShowModalComunicacao(false)}
                >
                  Cancelar
                </Button>
                <Button appearance="primary" onClick={handleSubmitComunicacao}>
                  Confirmar
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Modal de confirmação de "Concluir" */}
            <Modal
              open={showModalConcluir}
              onClose={() => setShowModalConcluir(false)}
            >
              <Modal.Header>
                <Modal.Title className="modal-title">
                  Alterar status para "Concluído"
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="modal-message">
                  Tem certeza que deseja alterar o status para "Concluído"?
                </p>
                <hr />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  appearance="ghost"
                  onClick={() => setShowModalConcluir(false)}
                >
                  Cancelar
                </Button>
                <Button appearance="primary" onClick={handleSubmitConcluir}>
                  Confirmar
                </Button>
              </Modal.Footer>
            </Modal>
            <FlexboxGrid justify="space-between" align="middle">
              <FlexboxGrid.Item colspan={6} />
              <FlexboxGrid.Item colspan={12} style={{ textAlign: "center" }}>
                <Text size="xxl" color="blue" weight="semibold">
                  Gerenciar Sinistro
                </Text>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={6} style={{ textAlign: "right" }}>
                <Text bold className="status-label">
                  Status:{" "}
                  <span
                    className={`status-value ${getStatusClass(
                      sinistro.status || "Aberto"
                    )}`}
                  >
                    {sinistro.status || "Aberto"}
                  </span>
                </Text>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <br />

            <MySteps step={step} setStep={setStep} />
            <MyComponent
              step={step}
              handleFormChange={handleFormChange}
              handleSubmitComunicacao={handleSubmitComunicacao}
            />
            <FlexboxGrid
              className="d-flex justify-content-start"
              style={{ marginTop: "auto" }}
            >
              <Divider />
              <FlexboxGrid.Item colspan={12} className="justify-content-start">
                <ButtonGroup>
                  <Button onClick={onPrevious} disabled={step === 1}>
                    Anterior
                  </Button>
                  <Button onClick={onNext} disabled={step === 4}>
                    Próximo
                  </Button>
                </ButtonGroup>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item
                colspan={12}
                className="d-flex justify-content-end"
              >
                <Button
                  size="lg"
                  appearance="primary"
                  color="green"
                  className="button-spacing"
                  onClick={handleConcluirClick}
                >
                  Concluir
                </Button>
                <Button
                  size="lg"
                  appearance="primary"
                  color="gray"
                  className="button-spacing" // Adiciona a classe de estilo e espaçamento
                  onClick={handleComunicacaoClick}
                >
                  Comunicação
                </Button>
                <Button
                  size="lg"
                  appearance="primary"
                  type="submit"
                  className="button-spacing"
                >
                  Salvar Sinistro
                </Button>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Form>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Content>
  );
};

export default Sinistro;
