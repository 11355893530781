import React, { useContext } from "react";

import CaixaDeEntrada from "./CaixaDeEntrada";
import Seguradora from "./Seguradora";
import Fiador from "./Fiador";
import Documentos from "./Documentos";
import Valores from "./Valores";
import Aprovacao from "./Aprovacao";
import { FlexboxGrid } from "rsuite";
import { AprovacaoLocatarioContext } from "./AprovacaoLocatarioContext";

const MyComponent = ({
  step,
  handleFormChange,
  isSeguroSelected,
  handleSubmit,
  setFormDisabled,
}) => {
  const { stepData, handleStepChange } = useContext(AprovacaoLocatarioContext);

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <CaixaDeEntrada
            formData={stepData.step1}
            onChange={(data) => handleStepChange(data, 1)}
          />
        );
      case 2:
        return (
          <Seguradora
            formData={stepData.step2}
            onChange={(data) => handleStepChange(data, 2)}
          />
        );
      case 3:
        return (
          <Fiador
            formData={stepData.step3}
            onChange={(data) => handleStepChange(data, 3)}
            handleFormChange={handleFormChange}
          />
        );
      case 4:
        return (
          <Documentos
            formData={stepData.step4}
            onChange={(data) => handleStepChange(data, 4)}
          />
        );
      case 5:
        return (
          <Valores
            formData={stepData.step5}
            onChange={(data) => handleStepChange(data, 5)}
            handleFormChange={handleFormChange}
            isSeguroSelected={isSeguroSelected}
          />
        );
      case 6:
        return (
          <Aprovacao
            formData={stepData.step6}
            onChange={(data) => handleStepChange(data, 6)}
            handleSubmit={handleSubmit}
            setFormDisabled={setFormDisabled}
          />
        );
      default:
        return <div>Selecione uma etapa</div>;
    }
  };

  return (
    <>
      <hr />
      <FlexboxGrid.Item
        colspan={24}
        // style={{ height: "67vh", overflowY: "auto" }}
      >
        {renderContent()}
      </FlexboxGrid.Item>
    </>
  );
};

export default MyComponent;
