import React, { useContext, useState } from "react";
import {
  FlexboxGrid,
  Button,
  Modal,
  Uploader,
  Input,
  Form,
  SelectPicker,
  Panel,
  Divider,
  Checkbox,
} from "rsuite";
import { ContratoLocacaoContext } from "./ContratoLocacaoContext";
import "./style.css";

const Assinaturas = ({ handleFormChange }) => {
  const { contratoLocacao, setContratoLocacao } = useContext(
    ContratoLocacaoContext
  ); // Use o contexto correto

  const [showModal, setShowModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const modeloAssinaturaData = ["Contrato Digital", "Contrato Físico"].map(
    (item) => ({
      label: item,
      value: item,
    })
  );

  const handleDocumentosCheckbox = (value, checked) => {
    setContratoLocacao((prevContrato) => {
      const situacaoDocumentos = checked
        ? [...(prevContrato.situacaoDocumentos || []), value]
        : prevContrato.situacaoDocumentos.filter((item) => item !== value);

      return { ...prevContrato, situacaoDocumentos };
    });
  };

  const handlePreview = (file) => {
    const reader = new FileReader();
    if (typeof file === "string") {
      // Quando o arquivo for uma URL (string)
      setPreviewUrl(file);
      setShowModal(true);
    } else {
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file.blobFile);
    }
  };

  const getFileList = (tipoDocumento) => {
    return contratoLocacao[tipoDocumento]
      ? [contratoLocacao[tipoDocumento]]
      : [];
  };

  const handleImageChange = (files, tipoDocumento) => {
    if (files.length > 1) {
      files = [files[files.length - 1]]; // Mantém apenas o último arquivo
    }

    setContratoLocacao((prev) => ({
      ...prev,
      [tipoDocumento]: files[0] || null, // Armazena apenas o arquivo atual ou null
    }));
  };

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={12}>
          <Form.Group controlId="modeloAssinatura">
            <Form.ControlLabel>Modelo Assinatura</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="modeloAssinatura"
              data={modeloAssinaturaData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Modelo Assinatura"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12}>
          <Form.Group controlId="situacaoDocumentos">
            <Form.ControlLabel>Situação dos Documentos</Form.ControlLabel>
            <Checkbox
              size="lg"
              name="situacaoDocumentos.baixados"
              value="Baixados"
              checked={
                contratoLocacao.situacaoDocumentos?.includes("Baixados") ||
                false
              }
              onChange={(value, checked) =>
                handleDocumentosCheckbox(value, checked)
              }
            >
              Documentos baixados
            </Checkbox>
            <Checkbox
              size="lg"
              name="situacaoDocumentos.enviados"
              value="Enviados"
              checked={
                contratoLocacao.situacaoDocumentos?.includes("Enviados") ||
                false
              } //
              onChange={(value, checked) =>
                handleDocumentosCheckbox(value, checked)
              }
            >
              Documentos enviados
            </Checkbox>
            <Checkbox
              size="lg"
              name="situacaoDocumentos.assinados"
              value="Assinados"
              checked={
                contratoLocacao.situacaoDocumentos?.includes("Assinados") ||
                false
              } //
              onChange={(value, checked) =>
                handleDocumentosCheckbox(value, checked)
              }
            >
              Documentos assinados
            </Checkbox>
            <Checkbox
              size="lg"
              name="situacaoDocumentos.conferida"
              value="Conferida"
              checked={
                contratoLocacao.situacaoDocumentos?.includes("Conferida") ||
                false
              } //
              onChange={(value, checked) =>
                handleDocumentosCheckbox(value, checked)
              }
            >
              Assinatura conferida
            </Checkbox>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24}>
          <Form.Group controlId="obsContrato">
            <Form.ControlLabel>Observações Contrato</Form.ControlLabel>
            <Input
              as="textarea"
              rows={5}
              size="lg"
              name="obsContrato"
              placeholder="Observações Contrato"
              value={contratoLocacao.obsContrato || ""} // Vincula o valor ao rateioObs
              onChange={(event) => {
                handleFormChange({
                  ...contratoLocacao,
                  obsContrato: event,
                });
              }}
            />
          </Form.Group>
          <Divider />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24} align="center">
          <Form.Group controlId="contratos">
            <Form.ControlLabel>
              Contratos com assinatura digital ou firma reconhecida
            </Form.ControlLabel>
          </Form.Group>
        </FlexboxGrid.Item>
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Modal.Header>
            <Modal.Title>Pré-visualização</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {previewUrl && (
              <img src={previewUrl} alt="Preview" style={{ width: "100%" }} />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button size="lg" onClick={() => setShowModal(false)}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
        <FlexboxGrid.Item colspan={12} align="center">
          <Panel className="imovel-panel" shaded>
            <>
              <Form.Group controlId="imagemLocacaoField">
                <Form.ControlLabel>Locação</Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="imagemLocacaoField"
                  fileList={getFileList("imagemLocacaoField")}
                  maxFiles={1}
                  onPreview={handlePreview}
                  autoUpload={false}
                  listType="picture-text"
                  onChange={(files) =>
                    handleImageChange(files, "imagemLocacaoField")
                  }
                  renderFileInfo={(file, fileElement) => {
                    return <div onClick={() => handlePreview(file)}></div>;
                  }}
                  accepter={Uploader}
                >
                  <Button appearance="primary" size="lg">
                    Selecione o arquivo
                  </Button>
                </Form.Control>
              </Form.Group>
            </>
          </Panel>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Panel className="imovel-panel" shaded>
            <>
              <Form.Group controlId="imagemVistoriaField">
                <Form.ControlLabel>Vistoria</Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="imagemVistoriaField"
                  fileList={getFileList("imagemVistoriaField")}
                  maxFiles={1}
                  onPreview={handlePreview}
                  autoUpload={false}
                  listType="picture-text"
                  onChange={(files) =>
                    handleImageChange(files, "imagemVistoriaField")
                  }
                  renderFileInfo={(file, fileElement) => {
                    return <div onClick={() => handlePreview(file)}></div>;
                  }}
                  accepter={Uploader}
                >
                  <Button appearance="primary" size="lg">
                    Selecione o arquivo
                  </Button>
                </Form.Control>
              </Form.Group>
            </>
          </Panel>
          <hr />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Panel className="imovel-panel" shaded>
            <>
              <Form.Group controlId="imagemAdministracaoField">
                <Form.ControlLabel>Administração</Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="imagemAdministracaoField"
                  fileList={getFileList("imagemAdministracaoField")}
                  maxFiles={1}
                  onPreview={handlePreview}
                  autoUpload={false}
                  listType="picture-text"
                  onChange={(files) =>
                    handleImageChange(files, "imagemAdministracaoField")
                  }
                  renderFileInfo={(file, fileElement) => {
                    return <div onClick={() => handlePreview(file)}></div>;
                  }}
                  accepter={Uploader}
                >
                  <Button appearance="primary" size="lg">
                    Selecione o arquivo
                  </Button>
                </Form.Control>
              </Form.Group>
            </>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Assinaturas;
