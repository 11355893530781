import types from "./types";

export function allImovel() {
  return { type: types.ALL_IMOVEL };
}

export function updateImovel(payload) {
  return { type: types.UPDATE_IMOVEL, payload };
}

export function filterImoveis() {
  return { type: types.FILTER_IMOVEIS };
}

export function addImovel() {
  return { type: types.ADD_IMOVEL };
}

export function saveImovel() {
  return { type: types.SAVE_IMOVEL };
}

export function resetImovel() {
  return { type: types.RESET_IMOVEL };
}

export function unlinkImovel() {
  return { type: types.UNLINK_IMOVEL };
}
