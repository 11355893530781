import React, { useContext } from "react";

import CaixaDeEntrada from "./CaixaDeEntrada";
import FaseInicial from "./FaseInicial";
import Transferindo from "./Transferindo";
import { TransferenciaContasContext } from "./TransferenciaContasContext";

const MyComponent = ({ step, handleFormChange, handleConcluirClick }) => {
  const { stepData, handleStepChange } = useContext(TransferenciaContasContext);

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <CaixaDeEntrada
            formData={stepData.step1}
            onChange={(data) => handleStepChange(1)}
            handleFormChange={handleFormChange}
          />
        );
      case 2:
        return (
          <FaseInicial
            formData={stepData.step2}
            onChange={(data) => handleStepChange(2)}
            handleFormChange={handleFormChange}
          />
        );
      case 3:
        return (
          <Transferindo
            formData={stepData.step3}
            onChange={(data) => handleStepChange(3)}
            handleFormChange={handleFormChange}
            handleConcluirClick={handleConcluirClick}
          />
        );
      default:
        return <div>Selecione uma etapa</div>;
    }
  };

  return (
    <>
      <hr />
      {renderContent()}
    </>
  );
};

export default MyComponent;
