const contaBancariaData = [
  "ITAU",
  "BRADESCO",
  "SANTANDER",
  "BANCO DO BRASIL",
  "CAIXA ECONÔMICA FEDERAL",
  "BTG PACTUAL",
  "SAFRA",
  "C6 BANK",
  "INTER",
  "NU BANK",
].map((item) => ({
  label: item,
  value: item,
}));

module.exports = { contaBancariaData };
