import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  page: { padding: 40, fontSize: 10, lineHeight: 1.4 },
  header: { textAlign: "center", marginBottom: 20 },
  title: { fontSize: 14, fontWeight: "bold", textDecoration: "underline" },
  section: { marginBottom: 10 },
  row: { flexDirection: "row", marginBottom: 4 },
  label: { width: "30%", fontWeight: "bold" },
  value: { width: "70%" },
  // Estilo para linhas com subitens (para campos lado a lado)
  inlineField: { flexDirection: "row", alignItems: "center", marginBottom: 4 },
  underline: { textDecoration: "underline" },
  // Tabela de débitos
  table: {
    display: "table",
    width: "100%",
    borderWidth: 1,
    borderColor: "#000",
    marginVertical: 8,
  },
  tableRow: { flexDirection: "row" },
  tableCell: {
    borderWidth: 1,
    borderColor: "#000",
    padding: 4,
    flex: 1,
    textAlign: "center",
  },
});

const DeclaracaoDebitosPDF = ({ dados, debitos }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Cabeçalho */}
      <View style={styles.header}>
        <Text style={styles.title}>Declaração de Débitos</Text>
      </View>

      {/* Destinatário e Assunto */}
      <View style={styles.section}>
        <Text>À: Tokio Marine Seguradora</Text>
        <Text>Assunto: Comunicação de Sinistro Fiança Locatícia</Text>
      </View>

      {/* Dados da Apólice e Imóvel */}
      <View style={styles.section}>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Apólice nº:</Text>
          <Text style={styles.value}>{dados.apolice || "__________"}</Text>
        </View>
        <View style={styles.inlineField}>
          <Text style={styles.value}>
            {dados.nomeSeguradora || "_______________________________"}
          </Text>
          <Text>, na qualidade de {dados.qualidade || "__________"}</Text>
        </View>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Do imóvel situado à Rua/Avenida:</Text>
          <Text style={styles.value}>{dados.endereco || "__________"}</Text>
        </View>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Complemento:</Text>
          <Text style={styles.value}>{dados.complemento || "__________"}</Text>
        </View>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Bairro:</Text>
          <Text style={styles.value}>{dados.bairro || "__________"}</Text>
        </View>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Cidade:</Text>
          <Text style={styles.value}>{dados.cidade || "__________"}</Text>
        </View>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Estado:</Text>
          <Text style={styles.value}>{dados.estado || "__________"}</Text>
        </View>
      </View>

      {/* Dados do Imóvel */}
      <View style={styles.section}>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Imóvel:</Text>
          <Text style={styles.value}>
            {dados.ocupado ? "□ Ocupado" : "□ Desocupado"}
          </Text>
          <Text style={[styles.label, { marginLeft: 10 }]}>
            Data da Desocupação:
          </Text>
          <Text style={styles.value}>
            {dados.dataDesocupacao || "__________"}
          </Text>
        </View>
      </View>

      {/* Informativo */}
      <View style={styles.section}>
        <Text>
          Informo(amos) que o(s) garantido(s) encontra(am) -se inadimplente(s),
          com o(s) débito(s) discriminado(s) na tabela abaixo:
        </Text>
      </View>

      {/* Tabela de Débitos */}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>Discriminação</Text>
          <Text style={styles.tableCell}>Vencimento</Text>
          <Text style={styles.tableCell}>Competência</Text>
          <Text style={styles.tableCell}>Valor</Text>
        </View>
        {debitos.map((item, index) => (
          <View style={styles.tableRow} key={index}>
            <Text style={styles.tableCell}>{item.descricao || ""}</Text>
            <Text style={styles.tableCell}>{item.vencimento || ""}</Text>
            <Text style={styles.tableCell}>{item.competencia || ""}</Text>
            <Text style={styles.tableCell}>{item.valor || ""}</Text>
          </View>
        ))}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, { flex: 3 }]}>Total:</Text>
          <Text style={styles.tableCell}>{dados.total || ""}</Text>
        </View>
      </View>

      {/* Contatos com o garantido */}
      <View style={styles.section}>
        <Text style={styles.title}>
          Contatos com o garantido (preenchimento obrigatório)
        </Text>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Nome:</Text>
          <Text style={styles.value}>
            {dados.nomeContato || "_________________"}
          </Text>
        </View>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Telefones:</Text>
          <Text style={styles.value}>
            {dados.telefonesContato || "-------------"}
          </Text>
          <Text style={[styles.label, { marginLeft: 10 }]}>E-mail:</Text>
          <Text style={styles.value}>
            {dados.emailContato || "---------------"}
          </Text>
        </View>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Multa:</Text>
          <Text style={styles.value}>{dados.multa || "_________"}</Text>
          <Text style={[styles.label, { marginLeft: 10 }]}>
            Outros Acréscimos:
          </Text>
          <Text style={styles.value}>
            {dados.outrosAcrescimos || "_________"}
          </Text>
        </View>
      </View>

      {/* Contatos com o ocupante */}
      <View style={styles.section}>
        <Text style={styles.title}>
          Contatos com ocupante (preenchimento obrigatório)
        </Text>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Nome:</Text>
          <Text style={styles.value}>
            {dados.nomeContatoOcupante || "_________________"}
          </Text>
        </View>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Telefones:</Text>
          <Text style={styles.value}>
            {dados.telefonesContatoOcupante || "-------------"}
          </Text>
          <Text style={[styles.label, { marginLeft: 10 }]}>E-mail:</Text>
          <Text style={styles.value}>
            {dados.emailContatoOcupante || "---------------"}
          </Text>
        </View>
      </View>

      {/* Observações */}
      <View style={styles.section}>
        <Text style={styles.title}>Observações</Text>
        <Text>
          {dados.observacoes ||
            "______________________________________________________"}
        </Text>
      </View>

      {/* Dados de Indenização */}
      <View style={styles.section}>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Indenização no valor de:</Text>
          <Text style={styles.value}>
            R$ {dados.valorIndenizacao || "__________"}
          </Text>
        </View>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Ao(à) favorecido(a):</Text>
          <Text style={styles.value}>
            {dados.favorecido || "-----------------------------"}
          </Text>
        </View>
        <View style={styles.inlineField}>
          <Text style={styles.label}>CPF/CNPJ nº:</Text>
          <Text style={styles.value}>{dados.cpfCnpj || "-------"}</Text>
        </View>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Banco:</Text>
          <Text style={styles.value}>{dados.banco || "________"}</Text>
          <Text style={[styles.label, { marginLeft: 10 }]}>Agência:</Text>
          <Text style={styles.value}>{dados.agencia || "_______"}</Text>
          <Text style={[styles.label, { marginLeft: 10 }]}>
            Conta Corrente nº:
          </Text>
          <Text style={styles.value}>
            {dados.contaCorrente || "----------"}
          </Text>
        </View>
      </View>

      {/* Local, Data e Assinatura */}
      <View style={styles.section}>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Local e Data:</Text>
          <Text style={styles.value}>
            {dados.localData || "________________________"}
          </Text>
        </View>
        <View style={styles.inlineField}>
          <Text style={styles.label}>Assinatura:</Text>
          <Text style={styles.value}>__________________________</Text>
        </View>
      </View>

      {/* Rodapé */}
      <View style={styles.section}>
        <Text style={{ textAlign: "center", marginTop: 20 }}>
          Uma Seguradora - Nossa Transparência, Sua Confiança
        </Text>
      </View>
    </Page>
  </Document>
);

export default DeclaracaoDebitosPDF;
