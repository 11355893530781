import React, { useContext, useState } from "react";
import {
  FlexboxGrid,
  Button,
  Radio,
  RadioGroup,
  Input,
  Modal,
  Form,
  InputGroup,
  SelectPicker,
  DatePicker,
  Uploader,
} from "rsuite";
import { ContratoLocacaoContext } from "./ContratoLocacaoContext";
import "./style.css";

const SeguroIncendio = ({ handleFormChange }) => {
  const { contratoLocacao, setContratoLocacao } = useContext(
    ContratoLocacaoContext
  ); // Use o contexto correto
  const [showModal, setShowModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const seguradoraIncData = ["Alfa Seguradora", "Porto Seguro"].map((item) => ({
    label: item,
    value: item,
  }));

  const handlePreview = (file) => {
    const reader = new FileReader();
    if (typeof file === "string") {
      // Quando o arquivo for uma URL (string)
      setPreviewUrl(file);
      setShowModal(true);
    } else {
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file.blobFile);
    }
  };

  const getFileList = (tipoDocumento) => {
    const fileList = contratoLocacao[tipoDocumento]
      ? [contratoLocacao[tipoDocumento]]
      : [];

    return fileList;
  };

  const handleImageChange = (files, tipoDocumento) => {
    if (!Array.isArray(files)) {
      console.error("Arquivos inválidos recebidos:", files);
      return;
    }

    if (files.length > 1) {
      files = [files[files.length - 1]]; // Mantém apenas o último arquivo
    }

    const file = files[0] || null;

    setContratoLocacao((prev) => ({
      ...prev,
      [tipoDocumento]: file,
    }));
  };

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="seguradoraInc">
            <Form.ControlLabel>Seguradora</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="seguradoraInc"
              data={seguradoraIncData}
              searchable={false}
              style={{ width: "100%" }}
              accepter={SelectPicker}
              placeholder="Seguradora"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="apoliceSeguroIncendioGerada">
            <Form.ControlLabel>
              Apólice Seguro Incêndio gerada?{" "}
            </Form.ControlLabel>
            <Form.Control
              size="lg"
              name="apoliceSeguroIncendioGerada"
              style={{ backgroundColor: "white" }}
              appearance="picker"
              accepter={RadioGroup}
              inline
            >
              <Radio value="Sim">Sim</Radio>
              <Radio value="Não">Não</Radio>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Modal.Header>
            <Modal.Title>Pré-visualização</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {previewUrl && (
              <img src={previewUrl} alt="Preview" style={{ width: "100%" }} />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button size="lg" onClick={() => setShowModal(false)}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="imagemSeguroIncendioField">
            <Form.ControlLabel>Seguro incêndio</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="imagemSeguroIncendioField"
              fileList={getFileList("imagemSeguroIncendioField")}
              maxFiles={1}
              onPreview={handlePreview}
              autoUpload={false}
              listType="picture-text"
              onChange={(files) =>
                handleImageChange(files, "imagemSeguroIncendioField")
              }
              renderFileInfo={(file, fileElement) => {
                return <div onClick={() => handlePreview(file)}></div>;
              }}
              accepter={Uploader}
            >
              <Button appearance="primary" size="lg">
                Selecione o arquivo
              </Button>
            </Form.Control>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4.5}>
          <Form.Group controlId="apoliceSeguroIncendio">
            <Form.ControlLabel>Apólice Seguro Incêndio</Form.ControlLabel>
            <InputGroup>
              <Form.Control
                size="lg"
                name="apoliceSeguroIncendio"
                placeholder="Apólice Seguro Incêndio"
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3}></FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="dataSeguroIncendio">
            <Form.ControlLabel>Data do Seguro Incêndio</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="dataSeguroIncendio"
              searchable={false}
              style={{ width: "100%" }}
              accepter={DatePicker}
              value={
                contratoLocacao.dataSeguroIncendio
                  ? new Date(contratoLocacao.dataSeguroIncendio)
                  : null
              }
              format="dd/MM/yyyy"
              placeholder="Data do Seguro Incêndio"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4.5}>
          <Form.Group controlId="valorSeguroIncendio">
            <Form.ControlLabel>Valor do Seguro Incêndio</Form.ControlLabel>
            <InputGroup>
              <Form.Control
                size="lg"
                name="valorSeguroIncendio"
                placeholder="Valor do Seguro Incêndio"
              />
            </InputGroup>
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8}>
          <Form.Group controlId="vencimentoSeguroIncendio">
            <Form.ControlLabel>Vencimento do Seguro Incêndio</Form.ControlLabel>
            <Form.Control
              size="lg"
              name="vencimentoSeguroIncendio"
              searchable={false}
              style={{ width: "100%" }}
              accepter={DatePicker}
              value={
                contratoLocacao.vencimentoSeguroIncendio
                  ? new Date(contratoLocacao.vencimentoSeguroIncendio)
                  : null
              }
              format="dd/MM/yyyy"
              placeholder="Vencimento do Seguro Incêndio"
            />
          </Form.Group>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={24}>
          <Form.Group controlId="obsSeguroIncendio">
            <Form.ControlLabel>
              Observações sobre o Seguro Incêndio
            </Form.ControlLabel>
            <Input
              as="textarea"
              rows={5}
              size="lg"
              name="obsSeguroIncendio"
              placeholder="Observações sobre o Seguro Incêndio"
              value={contratoLocacao.obsSeguroIncendio || ""} // Vincula o valor ao rateioObs
              onChange={(event) => {
                handleFormChange({
                  ...contratoLocacao,
                  obsSeguroIncendio: event,
                });
              }}
            />
          </Form.Group>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default SeguroIncendio;
