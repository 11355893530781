const types = {
  ALL_IMOVEL: "@Imovel/ALL",
  UPDATE_IMOVEL: "@Imovel/UPDATE",
  FILTER_IMOVEIS: "@Imovel/FILTER",
  ADD_IMOVEL: "@Imovel/ADD",
  SAVE_IMOVEL: "@Imovel/SAVE",
  RESET_IMOVEL: "@Imovel/RESET",
  UNLINK_IMOVEL: "@Imovel/UNLINK",
};
export default types;
