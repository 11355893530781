import React, { useContext } from "react";

import CaixaDeEntrada from "./CaixaDeEntrada";
import Acompanhamento from "./Acompanhamento";
import Indenizacao from "./Indenizacao";
import Acordo from "./Acordo";

import { SinistroContext } from "./SinistroContext";

const MyComponent = ({ step, handleFormChange, handleSubmitComunicacao }) => {
  const { stepData, handleStepChange } = useContext(SinistroContext);

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <CaixaDeEntrada
            formData={stepData.step1}
            onChange={(data) => handleStepChange(1)}
            handleFormChange={handleFormChange}
          />
        );
      case 2:
        return (
          <Acompanhamento
            formData={stepData.step2}
            onChange={(data) => handleStepChange(2)}
            handleFormChange={handleFormChange}
            handleSubmitComunicacao={handleSubmitComunicacao}
          />
        );
      case 3:
        return (
          <Indenizacao
            formData={stepData.step3}
            onChange={(data) => handleStepChange(3)}
            handleFormChange={handleFormChange}
          />
        );
      case 4:
        return (
          <Acordo
            formData={stepData.step4}
            onChange={(data) => handleStepChange(4)}
            handleFormChange={handleFormChange}
          />
        );
      default:
        return <div>Selecione uma etapa</div>;
    }
  };

  return (
    <>
      <hr />
      {renderContent()}
    </>
  );
};

export default MyComponent;
