import { AUTHENTICATE_SUCCESS, AUTHENTICATE_FAILURE, LOGOUT } from "./types";

// Estado inicial do reducer
const initialState = {
  isAuthenticated: !!localStorage.getItem("isAuthenticated"),
  email: null,
  error: null,
};

// Reducer para autenticação
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        email: localStorage.getItem("email"),
        error: null,
      };
    case AUTHENTICATE_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        email: null,
        error: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        email: null,
      };
    default:
      return state;
  }
};

export default authReducer;
