import React, { useState, useContext } from "react";
import {
  FlexboxGrid,
  DatePicker,
  Text,
  SelectPicker,
  Checkbox,
  Input,
  Panel,
  RadioGroup,
  Heading,
  Form,
  Radio,
  Button,
  Uploader,
} from "rsuite";
import { RescisaoContratoContext } from "./RescisaoContratoContext";
import "./style.css";
import { format } from "date-fns";

const Inicio = ({ handleFormChange }) => {
  const { contratoLocacao, rescisaoContrato, setRescisaoContrato } = useContext(
    RescisaoContratoContext
  ); // Use o contexto correto
  const [showModal, setShowModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const motivoRescisaoData = [
    "Problemas Financeiros",
    "Imóvel Condições Precárias",
    "Mudança de Cidade",
    "Problemas Familiares",
    "Venda do Imóvel",
    "Sinistro",
    "Outros",
  ].map((item) => ({
    label: item,
    value: item,
  }));

  const checklistItems = [
    "Baixar Débitos CPFL",
    "Baixar Débitos DAE",
    "Imprimir laudo sem foto da Vistoria",
    "Lançar Vistoria de Saída",
    "Baixar Vistoria de Entrada",
    "Criar Pasta",
  ];

  const handlePreview = (file) => {
    const reader = new FileReader();
    if (typeof file === "string") {
      // Quando o arquivo for uma URL (string)
      setPreviewUrl(file);
      setShowModal(true);
    } else if (file.blobFile) {
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file.blobFile);
    } else {
      console.error("Arquivo inválido para preview:", file);
    }
  };

  const getFileList = (tipoDocumento) => {
    const fileList = rescisaoContrato[tipoDocumento]
      ? [rescisaoContrato[tipoDocumento]]
      : [];

    return fileList;
  };

  const handleImageChange = (files, tipoDocumento) => {
    if (!Array.isArray(files)) {
      console.error("Arquivos inválidos recebidos:", files);
      return;
    }

    if (files.length > 1) {
      files = [files[files.length - 1]]; // Mantém apenas o último arquivo
    }

    const file = files[0] || null;

    setRescisaoContrato((prev) => ({
      ...prev,
      [tipoDocumento]: file,
    }));
  };

  // Função para calcular a validade do contrato
  const calcularValidadeContrato = () => {
    if (contratoLocacao.inicioContrato && contratoLocacao.validadeContrato) {
      const inicioContrato = new Date(contratoLocacao.inicioContrato);
      const dataInicioFormatada = format(inicioContrato, "dd/MM/yyyy");
      const mesesValidade = parseInt(contratoLocacao.validadeContrato, 10);
      const dataFinalContrato = new Date(inicioContrato);
      dataFinalContrato.setMonth(dataFinalContrato.getMonth() + mesesValidade);
      const dataFinalFormatada = format(dataFinalContrato, "dd/MM/yyyy");
      return `${dataInicioFormatada} à ${dataFinalFormatada}`;
    }
    return "";
  };

  // Função para renderizar a lista de proprietários
  const renderizarProprietarios = () => {
    if (contratoLocacao.imovel) {
      return contratoLocacao.imovel.proprietario.map((item, index) => (
        <div key={index}>
          <Text block className="proprietario-info">
            {item.proprietarioId.nome} - {item.proprietarioId.cpf}
          </Text>
          <Text block className="proprietario-info">
            Telefones:
            {item.proprietarioId.telefones.map((telefone, idx) => (
              <div key={idx}>
                {telefone.tipo}: {telefone.telefone}
              </div>
            ))}
          </Text>
        </div>
      ));
    }
    return "";
  };

  // Função para renderizar a lista de locatario
  const renderizarLocatarios = () => {
    if (contratoLocacao.locatario) {
      return contratoLocacao.locatario.map((item, index) => (
        <div key={index}>
          <Text block className="proprietario-info">
            {item.nome} - {item.cpf}
          </Text>
          <Text block className="proprietario-info">
            Telefones:
            {item.telefones.map((telefone, idx) => (
              <div key={idx}>
                {telefone.tipo}: {telefone.telefone}
              </div>
            ))}
          </Text>
        </div>
      ));
    }
    return "";
  };

  return (
    <>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={12}>
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={12}>
              <Form.Group controlId="dataPrevistaDesocupacao">
                <Form.ControlLabel>
                  Data prevista para a desocupação do imóvel
                </Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="dataPrevistaDesocupacao"
                  searchable={false}
                  style={{ width: "100%" }}
                  accepter={DatePicker}
                  value={
                    rescisaoContrato.dataPrevistaDesocupacao
                      ? new Date(rescisaoContrato.dataPrevistaDesocupacao)
                      : null
                  }
                  format="dd/MM/yyyy"
                  placeholder="Data prevista para a desocupação do imóvel"
                />
              </Form.Group>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={12}>
              <Form.Group controlId="motivoRescisao">
                <Form.ControlLabel>Motivo Rescisão</Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="motivoRescisao"
                  data={motivoRescisaoData}
                  searchable={false}
                  style={{ width: "100%" }}
                  accepter={SelectPicker}
                  placeholder="Motivo Rescisão"
                />
              </Form.Group>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={12} align="center">
              <Form.ControlLabel>Avisou Proprietário?</Form.ControlLabel>
              <Form.Control
                size="lg"
                name="avisouProprietario"
                style={{ backgroundColor: "white" }}
                appearance="picker"
                accepter={RadioGroup}
                inline
              >
                <Radio value="Sim">Sim</Radio>
                <Radio value="Não">Não</Radio>
              </Form.Control>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={12} align="center">
              <Form.Group controlId="imagemDeclaracaoAdimplenciaField">
                <Form.ControlLabel>Declaração de Adimplencia</Form.ControlLabel>
                <Form.Control
                  size="lg"
                  name="imagemDeclaracaoAdimplenciaField"
                  fileList={getFileList("imagemDeclaracaoAdimplenciaField")}
                  maxFiles={1}
                  onPreview={handlePreview}
                  autoUpload={false}
                  listType="picture-text"
                  onChange={(files) =>
                    handleImageChange(files, "imagemDeclaracaoAdimplenciaField")
                  }
                  renderFileInfo={(file, fileElement) => {
                    return <div onClick={() => handlePreview(file)}></div>;
                  }}
                  accepter={Uploader}
                >
                  <Button appearance="primary" size="lg">
                    Selecione o arquivo
                  </Button>
                </Form.Control>
              </Form.Group>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={12} align="center">
              <Form.ControlLabel>Possui débitos DAE e CPFL?</Form.ControlLabel>
              <Form.Control
                size="lg"
                name="possuiDebitoAguaEnergia"
                style={{ backgroundColor: "white" }}
                appearance="picker"
                accepter={RadioGroup}
                inline
              >
                <Radio value="Sim">Sim</Radio>
                <Radio value="Não">Não</Radio>
              </Form.Control>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={12} align="center">
              <Form.ControlLabel>Tem Sinistro Aberto?</Form.ControlLabel>
              <Form.Control
                size="lg"
                name="temSinistroAberto"
                style={{ backgroundColor: "white" }}
                appearance="picker"
                accepter={RadioGroup}
                inline
              >
                <Radio value="Sim">Sim</Radio>
                <Radio value="Não">Não</Radio>
              </Form.Control>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={14}>
              <hr />
              <Form.Group controlId="checklist">
                <Form.ControlLabel>Checklist</Form.ControlLabel>
                {checklistItems.map((item, index) => (
                  <Checkbox
                    key={index}
                    size="lg"
                    name={`checklist.${item.replace(/\s+/g, "")}`}
                    value={item}
                    checked={
                      rescisaoContrato.checklist?.includes(item) || false
                    }
                    onChange={(value, checked) => {
                      const updatedChecklist = checked
                        ? [...(rescisaoContrato.checklist || []), item]
                        : rescisaoContrato.checklist?.filter((i) => i !== item);
                      setRescisaoContrato({
                        ...rescisaoContrato,
                        checklist: updatedChecklist,
                      });
                    }}
                  >
                    {item}
                  </Checkbox>
                ))}
              </Form.Group>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={24}>
              <Form.Group controlId="informacoesDebitosRescisao">
                <Form.ControlLabel>
                  Anote aqui as informações dos débitos da rescisão
                </Form.ControlLabel>
                <Input
                  as="textarea"
                  rows={5}
                  size="lg"
                  name="informacoesDebitosRescisao"
                  placeholder="Anote aqui as informações dos débitos da rescisão"
                  value={rescisaoContrato.informacoesDebitosRescisao || ""}
                  onChange={(event) => {
                    handleFormChange({
                      ...rescisaoContrato,
                      informacoesDebitosRescisao: event,
                    });
                  }}
                />
              </Form.Group>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} align="center">
          <Heading level={3} className="titulo-imovel">
            Resumo do Contrato
          </Heading>
          <Panel className="rescisao-panel" shaded>
            <>
              <Text block className="imovel-info">
                Validade do contrato: {calcularValidadeContrato()}
              </Text>
              <Text block className="imovel-info">
                Proprietários:
              </Text>
              {renderizarProprietarios()}
              <Text block className="imovel-info">
                Inquilinos:
              </Text>
              {renderizarLocatarios()}
              {contratoLocacao.endereco && (
                <Text block className="imovel-info">
                  Endereço:
                  <Text block className="proprietario-info">
                    {contratoLocacao.endereco.rua},{" "}
                    {contratoLocacao.endereco.numero},{" "}
                    {contratoLocacao.endereco.complemento}
                    {" - "}
                    {contratoLocacao.endereco.bairro}
                  </Text>
                </Text>
              )}
              <hr />
              <Text block className="contrato-info">
                Taxa de locação: {contratoLocacao.valorTaxaLocacao} X{" "}
                {contratoLocacao.qtdParcelaTaxaLocacao}
              </Text>
              <Text block className="contrato-info">
                Taxa de administração: {contratoLocacao.percentualTaxaAdmMensal}{" "}
                % (R${" "}
                {(
                  contratoLocacao.valorAluguel *
                  (contratoLocacao.percentualTaxaAdmMensal / 100)
                ).toFixed(2)}
                )
              </Text>
              <Text block className="contrato-info">
                Valor do aluguel: {contratoLocacao.valorAluguel}
              </Text>
              <Text block className="contrato-info">
                Valor do condominio: {contratoLocacao.valorCondominio}
              </Text>
              <Text block className="contrato-info">
                Valor do Iptu: {contratoLocacao.valorIptu}
              </Text>
              <Text block className="contrato-info">
                Valor Seguro Fiança: {contratoLocacao.valorParcelaSeguro}
              </Text>
              <Text block className="contrato-info">
                Valor Seguro Incêndio: {contratoLocacao.valorSeguroIncendio}
              </Text>
              <hr />
              <Text block className="contrato-info">
                Valor Corretora: {contratoLocacao.corretora}
              </Text>
              <Text block className="contrato-info">
                Valor Seguradora: {contratoLocacao.seguradora}
              </Text>
              <Text block className="contrato-info">
                Valor Rateio Água: {contratoLocacao.rateioAgua}
              </Text>
              <Text block className="contrato-info">
                Valor Rateio CPFL: {contratoLocacao.rateioEnergia}
              </Text>
              <hr />
              <Text block className="contrato-info">
                Cobrar IPTU do locatário: {contratoLocacao.cobrancaIptu}
              </Text>
              <Text block className="contrato-info">
                Responsável financeiro pelo IPTU:{" "}
                {contratoLocacao.responsavelFinanceiroIptu}
              </Text>
              <Text block className="contrato-info">
                Cobrar Condominio do locatário:{" "}
                {contratoLocacao.cobrancaCondominio}
              </Text>
              <Text block className="contrato-info">
                Responsável financeiro pelo Condominio:{" "}
                {contratoLocacao.responsavelFinanceiroCondominio}
              </Text>
            </>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Inicio;
