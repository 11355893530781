import React, { createContext, useState } from "react";
import { fetchWithAuth } from "../../services/apiNoState";
import { showError } from "../../components/Utils/toastUtils";

// Cria o contexto
export const SinistroContext = createContext();

// Provedor do contexto
export const SinistroProvider = ({ children }) => {
  const [usuarioLogado, setUsuarioLogado] = useState(null);
  const [sinistro, setSinistro] = useState({
    detalhesSinistro: [], // Array para armazenar os campos organizados
  });
  const [contratoLocacao, setContratoLocacao] = useState({});
  const [fieldsState, setFieldsState] = useState({}); // Novo estado global

  const [stepData, setStepData] = useState({});

  // Função para buscar o usuário logado
  const carregarUsuarioLogado = async () => {
    try {
      const response = await fetchWithAuth(`/auth/usuario`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // Especifica o tipo de conteúdo
        },
      });
      setUsuarioLogado(response.data);
    } catch (error) {
      console.error("Erro ao carregar usuário logado:", error);
    }
  };

  const handleStepChange = (step) => {
    let filteredData = null;
    switch (step) {
      case 1:
        filteredData = filterStep1Data(sinistro);
        break;
      case 2:
        filteredData = filterStep2Data(sinistro);
        break;
      case 3:
        filteredData = filterStep3Data(sinistro);
        break;
      case 4:
        filteredData = filterStep4Data(sinistro);
        break;
      default:
        break;
    }
    setStepData((prevData) => ({
      ...prevData,
      [`step${step}`]: JSON.parse(JSON.stringify(filteredData)), // Garante cópia profunda
    }));
  };

  const preencherSteps = (sinistroParam) => {
    // Objeto com os filtros correspondentes para cada step
    const filtros = {
      1: filterStep1Data,
      2: filterStep2Data,
      3: filterStep3Data,
      4: filterStep4Data,
    };

    // Inicializa o stepData atualizado
    const novoStepData = {};

    // Itera sobre os steps definidos no filtro
    Object.keys(filtros).forEach((step) => {
      const stepNum = parseInt(step, 10); // Converte para número
      if (sinistroParam) {
        // Aplica o filtro correspondente ao step atual
        const dadosFiltrados = filtros[stepNum](sinistroParam);
        novoStepData[`step${stepNum}`] = dadosFiltrados;
      }
    });
    // Atualiza o stepData no contexto
    setStepData((prevData) => ({
      ...prevData,
      ...novoStepData, // Mescla os contratoParam novos
    }));
  };

  const filterStep1Data = (sinistro) => {
    const { tipoSinistro, locatarioMoraImovel, listaDebitos, listaAnexos } =
      sinistro;

    return {
      tipoSinistro,
      locatarioMoraImovel,
      listaDebitos,
      listaAnexos,
    };
  };

  const filterStep2Data = (sinistro) => {
    const { numeroSinistro } = sinistro;

    return {
      numeroSinistro,
    };
  };

  const filterStep3Data = () => {
    const { listaDebitos } = sinistro;

    return {
      listaDebitos,
    };
  };

  const filterStep4Data = () => {
    const {
      valorDebito,
      qtdParcelas,
      valorCadaParcela,
      vencimentoPrimeiraParcela,
      formaPagamento,
      administracaoAcordo,
    } = sinistro;

    return {
      valorDebito,
      qtdParcelas,
      valorCadaParcela,
      vencimentoPrimeiraParcela,
      formaPagamento,
      administracaoAcordo,
    };
  };

  const validateCurrentStep = (step, formRef) => {
    if (!formRef?.current) return false;

    // Executa a validação usando o ref do formulário
    const isValid = formRef.current.check();

    if (!isValid) return false;

    return true; // Retorna true se não houver erros
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Aberto":
        return "status-aberto";
      case "Concluído":
        return "status-concluir";
      case "Andamento":
        return "status-andamento";
      default:
        return "";
    }
  };

  carregarUsuarioLogado();

  return (
    <SinistroContext.Provider
      value={{
        stepData,
        preencherSteps,
        handleStepChange,
        validateCurrentStep,
        contratoLocacao,
        setContratoLocacao,
        sinistro,
        setSinistro,
        fieldsState,
        setFieldsState,
        filterStep1Data,
        filterStep2Data,
        filterStep3Data,
        filterStep4Data,
        getStatusClass,
        usuarioLogado,
      }}
    >
      {children}
    </SinistroContext.Provider>
  );
};
