import { combineReducers } from "redux";

import aprovacaoLocatario from "./modules/aprovacaoLocatario/reducer";
import authReducer from "./modules/auth/reducer";
import imovel from "./modules/imovel/reducer";
import pessoa from "./modules/pessoa/reducer";

export default combineReducers({
  auth: authReducer,
  pessoa,
  imovel,
  aprovacaoLocatario,
});
